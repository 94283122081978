import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Col,
  InputGroup,
  FormControl,
  Form,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Logo,
  DashboardLogo,
  DashboardDocument,
  DashboardPassword,
} from "../assets/images/Index";

const SidebarSearch = () => {
  const [navBarMenu, setNavBarMenu] = useState(false);
  const [DraftApplication, setDraftApplication] = useState(true);
  const [submitApplication, setSubmitApplication] = useState(false);
  const [submitPending, setSubmitPending] = useState(false);

  const clicknavBarMenu = () => {
    setNavBarMenu(!navBarMenu);
  };

  const clickDraftApplication = () => {
    setDraftApplication(true);
    setSubmitApplication(false);
    setSubmitPending(false);
  };
  const clickAppliSubmit = () => {
    setDraftApplication(false);
    setSubmitApplication(true);
    setSubmitPending(false);
  };

  const clickSubmitPending = () => {
    setDraftApplication(false);
    setSubmitApplication(false);
    setSubmitPending(true);
  };

  return (
    <>
      <div
        className="bg-light pl-0 border-end border-dark"
        style={{ height: "100vh" }}
      >
        <Nav defaultActiveKey="/home" className="flex-column bg4 p-0">
          <Col className="d-flex align-items-center justify-content-between p-2 pt-5 bg-light border-bottom border-dark">
            <Col className="d-flex align-items-center">
              <DashboardLogo />
              <h3
                className="f15 c2 mb-0 ms-2 fw-bold"
                style={{ color: "#81abca" }}
              >
                TGIIC
              </h3>
            </Col>
          </Col>
          <Col className="px-3 bg-light h-100">
            <Form.Label className="mt-2 mb-0 f14">File No.</Form.Label>
            <InputGroup className="mb-1">
              <FormControl className="inputBox" />
            </InputGroup>

            <Form.Label className="mt-2 mb-0 f14">Name of Applicant</Form.Label>
            <InputGroup className="mb-1">
              <FormControl className="inputBox" />
            </InputGroup>

            <Form.Label className="mt-2 mb-0 f14">
              Architect / LE / SE
            </Form.Label>
            <InputGroup className="mb-1">
              <FormControl className="inputBox" />
            </InputGroup>

            <Form.Label className="mt-2 mb-0 f14">Serve Number</Form.Label>
            <InputGroup className="mb-1">
              <FormControl className="inputBox" />
            </InputGroup>

            <Form.Label className="mt-2 mb-0 f14">Permission Type</Form.Label>
            <Form.Select aria-label="Default select example">
              <option>select </option>
              <option value="1">Building Permission</option>
              <option value="2">Occupancy</option>
            </Form.Select>

            <Form.Label className="mt-2 mb-0 f14">Case Type</Form.Label>
            <Form.Select aria-label="Default select example">
              <option>select </option>
              <option value="1">New</option>
              <option value="2">Revision</option>
              <option value="2">Addition or Alteration</option>
            </Form.Select>

            <Form.Label className="mt-2 mb-0 f14">Land Use Zone</Form.Label>
            <Form.Select aria-label="Default select example">
              <option>select </option>
              <option value="1">Residential</option>
              <option value="2">Residential Mixed</option>
              <option value="3">Commercial</option>
              <option value="4">Industrial</option>
              <option value="5">Public and Semi Public</option>
              <option value="6">Recreational</option>
              <option value="7">Transportation and Communication</option>
              <option value="8">Water Body</option>
              <option value="9">Conservation</option>
            </Form.Select>

            <div className="text-center my-5 border-top py-3 sticky-end">
              <Button variant="outline-secondary">
                <FontAwesomeIcon icon={faSearch} />
                Search
              </Button>
            </div>
          </Col>
        </Nav>
      </div>
    </>
  );
};

export default SidebarSearch;
