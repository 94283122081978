import React, { useState } from "react";
import { Button, Row, Col, Pagination, Table, Dropdown, Form, ButtonGroup, Modal } from "react-bootstrap";
import SideBar from "../component/Sidebar";
import ProceedingIssuedProgressView from "../component/workProgress/ProceedingIssuedProgressView"

const ProceedingIssuedProgress = () => {

  return (
    <Col className="d-lg-flex">
      {/* <SideBar /> */}
      <ProceedingIssuedProgressView />
    </Col>
  );
};

export default ProceedingIssuedProgress;
