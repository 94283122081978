import { faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import ErrorHandler from "../../../services/constant/ErrorHandler";
import ApplicationService from "../../../services/service/ApplictionService";

const BuildingDetailsTabEdit = (props) => {
  const [disable, setDisable] = useState(false);
  const [building_permit_no, setBuilding_permit_no] = useState("");
  const [building_permit_date, setBuilding_permit_date] = useState("");
  const [work_commenced_date, setWork_commenced_date] = useState("");
  const [work_completion_date, setWork_completion_date] = useState("");
  const [due_completion_building_date, setDue_completion_building_date] =
    useState("");
  const [required, setRequired] = useState("");
  const [requiredType, setRequiredType] = useState(2);

  const save = () => {
    if (props.applicationID) {
      if (
        building_permit_no?.length !== 0 &&
        building_permit_date?.length !== 0 &&
        work_commenced_date?.length !== 0 &&
        work_completion_date?.length !== 0 &&
        due_completion_building_date?.length !== 0 &&
        required?.length !== 0
      ) {
        let form_data = new FormData();
        form_data.append("user_occupancy_id", props.applicationID);
        form_data.append("building_permit_no", building_permit_no);
        form_data.append("building_permit_date", building_permit_date);
        form_data.append("work_commence_date", work_commenced_date);
        form_data.append("due_completion_date", work_completion_date);
        form_data.append("work_completion_date", due_completion_building_date);
        if (required == 1) {
          form_data.append("type", required);
        } else {
          form_data.append("type", requiredType);
        }

        if (props?.list?.building_permit_no) {
          ApplicationService.occupancyBuildingEdit(form_data, props?.list?.id)
            .then((result) => {
              setDisable(true);
              alert("Construction Completion Details Page has been updated");
              // props.getViewOccupancy(props.applicationID);
            })
            .catch(function (error) {
              ErrorHandler(error);
            });
        } else {
          ApplicationService.occupancyBuildingStore(form_data)
            .then((result) => {
              setDisable(true);
              alert(result.data.message);
            })
            .catch(function (error) {
              ErrorHandler(error);
            });
        }
      } else {
        alert("please fill all Details ?");
      }
    } else {
      alert("please fill GeneralInformation !");
    }
  };

  useEffect(() => {
    if (props.list?.id) {
      let list = props.list;
      if (list?.building_permit_no) {
        setBuilding_permit_no(list?.building_permit_no);
      }

      if (list?.building_permit_date) {
        setBuilding_permit_date(list?.building_permit_date);
      }

      if (list?.work_commence_date) {
        setWork_commenced_date(list?.work_commence_date);
      }

      if (list?.work_completion_date) {
        setWork_completion_date(list?.work_completion_date);
      }

      if (list?.due_completion_date) {
        setDue_completion_building_date(list?.due_completion_date);
      }

      if (list?.type == 1) {
        setRequired(list?.type);
      } else {
        setRequired(2);
        setRequiredType(list?.type);
      }
    }
  }, [props.list]);

  return (
    <Col className="col-12 prop-norm">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5 className="f14 mb-0">Main Details</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col lg={12} className="d-md-flex flex-wrap">
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Building Permit No.*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    onChange={(e) => setBuilding_permit_no(e.target.value)}
                    value={building_permit_no}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Building Permit Date.*</p>
                </Col>
                <Col>
                  <input
                    type="date"
                    className="form-control px-2 py-1 rounded-0"
                    onChange={(e) => setBuilding_permit_date(e.target.value)}
                    value={building_permit_date}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Work Commenced Date.*</p>
                </Col>
                <Col>
                  <input
                    type="date"
                    className="form-control px-2 py-1 rounded-0"
                    onChange={(e) => setWork_commenced_date(e.target.value)}
                    value={work_commenced_date}
                  />
                </Col>
              </Col>

              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Work Completion Date.*</p>
                </Col>
                <Col>
                  <input
                    type="date"
                    className="form-control px-2 py-1 rounded-0"
                    onChange={(e) => setWork_completion_date(e.target.value)}
                    value={work_completion_date}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">
                    Due Date for completion of the building*
                  </p>
                </Col>
                <Col>
                  <input
                    type="date"
                    className="form-control px-2 py-1 rounded-0"
                    onChange={(e) =>
                      setDue_completion_building_date(e.target.value)
                    }
                    value={due_completion_building_date}
                  />
                </Col>
              </Col>

              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Occupancy Type*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    // disabled
                    onChange={(e) => setRequired(e.target.value)}
                    value={required}
                  >
                    <option className="f14 py-2" value={""}>
                      Select
                    </option>
                    <option className="f14 py-2" value={2}>
                      Part
                    </option>
                    <option className="f15 py-2" value={1}>
                      Full
                    </option>
                  </Form.Select>
                </Col>
              </Col>

              {required == 2 && (
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">Occupancy Type*</p>
                  </Col>
                  <Col>
                    <Form.Select
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                      // disabled
                      onChange={(e) => setRequiredType(e.target.value)}
                      value={requiredType}
                    >
                      <option className="f14 py-2" value={null}>
                        Select
                      </option>
                      <option className="f14 py-2" value={2}>
                        Tower/ Building wise
                      </option>
                      <option className="f15 py-2" value={3}>
                        Floor wise
                      </option>
                      <option className="f15 py-2" value={4}>
                        Wing wise
                      </option>
                    </Form.Select>
                  </Col>
                </Col>
              )}
            </Col>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Col className="d-flex justify-content-center mb-2">
        <Button
          onClick={save}
          className="tab-blu-back"
          style={{
            color: "#fff",
            fontSize: 15,
            marginTop: 10,
            padding: "6px 14px",
            backgroundColor: "#5593db",
          }}
        >
          <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col>
    </Col>
  );
};

export default BuildingDetailsTabEdit;

// export default BuildingDetailsTab;
