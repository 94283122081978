import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import GeneralInformation from "../../component/userApplication/GeneralInformation";
import ApplicantInformation from "../../component/userApplication/ApplicantInformation";
import ApplicantCheckList from "../../component/userApplication/ApplicantCheckList";
import GeoLocaion from "./GeoLocaion";
import ApplyNOC from "./ApplyNOCEdit";
import BuildingDetails from "../BuildingDetails/BuildingDetails";
import PlotDetails from "./PlotDetailsEdit";
import SiteDetails from "./SiteDetailsEdit";
import BuildingDetailsEdit from "../BuildingDetails/BuildingDetailsEdit";
import PlotDetailsCopy from "./PlotDetailsEdit copy";
import BuildingDetailsEditCopy from "../BuildingDetails/BuildingDetailsEdit copy";

const UserApplicationForm = (props) => {
  return (
    <Tabs id="uncontrolled-tab-example" className="mb-3 in-user-app">
      <Tab
        eventKey="generalinformation"
        title="General Information"
        className="py-0"
      >
        <GeneralInformation data={props.data} type="edit" />
      </Tab>
      <Tab
        eventKey="applicationinformation"
        title="Applicant Information"
        className=" py-0"
      >
        <ApplicantInformation data={props.data} type="edit" />
      </Tab>
      <Tab
        eventKey="applicationchecklist"
        title="Applicant CheckList"
        className="py-0"
      >
        <ApplicantCheckList data={props.data} type="edit" />
      </Tab>
      <Tab eventKey="plotdetails" title="Plot Details" className=" py-0">
        <PlotDetailsCopy data={props.data} type="edit" />
      </Tab>
      <Tab
        eventKey="buildingdetails"
        title="Building Details"
        className=" py-0"
      >
        {/* <BuildingDetailsEdit data={props.data} type="edit" /> */}
        <BuildingDetailsEditCopy data={props.data} type="edit" />
      </Tab>
      <Tab eventKey="sitedetails" title="Site Details" className=" py-0">
        <SiteDetails data={props.data} type="edit" />
      </Tab>
      <Tab eventKey="applynoc" title="Upload NOC" className=" py-0">
        <ApplyNOC data={props.data} type="edit" />
      </Tab>
      {/* <Tab eventKey="geolocaion" title="Geo Locaion" className=" py-0">
        <GeoLocaion />
      </Tab> */}
    </Tabs>
  );
};

export default UserApplicationForm;
