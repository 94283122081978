import React, { useState } from "react";
import { Button, Col, Form, Modal, Pagination, Table, Row, Dropdown, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faFileExcel,
  faSort,
  faSyncAlt,
  faTh,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { components } from "react-select";
import { Chart } from "react-charts";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PendencyDetails = () => {
  const [optionSelected, setOptionSelected] = useState(null);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const [show, setShow] = useState(false);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const colourOptions = [
    { value: "ocean", label: "Ocean", color: "#00B8D9" },
    { value: "blue", label: "Blue", color: "#0052CC" },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630" },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
  ];
  const MultiValue = (props) => (
    <components.MultiValue {...props}></components.MultiValue>
  );
  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  //  ********** Chart Scriprt files  ***************

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
      legend: {
        display: false
      },
    },
  };

  const labels = ['< 7 days', '8 - 14 days', '15 - 21 days', '22 - 30 days', '> 30 days'];

  const data = {
    labels,
    datasets: [
      {
        labels: ['< 7 days', '8 - 14 days', '15 - 21 days', '22 - 30 days', '> 30 days'],
        data: [4, 0, 1, 0, 13],
        backgroundColor: ['#036', '#ff0', '#ffa500', '#00cc55', '#f00', '#c00000'],
        hoverBackgroundColor: ['#194c7f', '#e1e119', '#e1c019', '#19e578', '#e11919', '#d91919'],

      }
    ],
  };

  //  ********** END Chart Scriprt files  ***************


  return (
    <Col className="rt-dashboard  mt-3">
      <Row className="justify-content-md-center justify-content-lg-start">
        <Col className="col-12 col-md-8 col-lg-5">
          <fieldset>
            <legend>Service Wise</legend>
            <Col className="mt-2">
              <Col className="overflow-scroll mb-3">

                <div style={{ maxHeight: "220px", Height: "220px", overflowY: 'auto' }}>
                  <Table variant="secondary" hover className="mb-0">
                    <thead className="sticky-top" style={{ verticalAlign: "middle" }}>
                      <tr className="bg-lt-white-f7">
                        <th className="f13 fw-bold black cursor-pointer ps-2 pe-0">
                          Sr. No
                        </th>
                        <th className="f13 fw-bold black cursor-pointer px-0">
                          Service Type
                        </th>
                        <th className="f13 fw-bold black cursor-pointer ps-0 pe-2 text-end">
                          Received
                        </th>
                      </tr>
                    </thead>
                    <tbody className="" style={{ verticalAlign: "middle" }}>
                      <tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0 sr-No">1.</td>
                        <td className="f14 black p-0 text-start">
                          Building Permission
                        </td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end">123</td>
                      </tr><tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0">1.</td>
                        <td className="f14 black p-0 text-start">
                          Building Permission
                        </td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end">123</td>
                      </tr><tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0">1.</td>
                        <td className="f14 black p-0 text-start">
                          Building Permission
                        </td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end">123</td>
                      </tr><tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0">1.</td>
                        <td className="f14 black p-0 text-start">
                          Building Permission
                        </td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end">123</td>
                      </tr><tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0">1.</td>
                        <td className="f14 black p-0 text-start">
                          Building Permission
                        </td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end">123</td>
                      </tr><tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0">1.</td>
                        <td className="f14 black p-0 text-start">
                          Building Permission
                        </td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end">123</td>
                      </tr><tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0">1.</td>
                        <td className="f14 black p-0 text-start">
                          Building Permission
                        </td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end">123</td>
                      </tr><tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0">1.</td>
                        <td className="f14 black p-0 text-start">
                          Building Permission
                        </td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end">123</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>

              </Col>

            </Col>
          </fieldset>
        </Col>

        <Col className="col-12 col-md-8 col-lg-5">
          <fieldset>
            <legend>Status of Building Permission</legend>
            <Bar options={options} data={data} />
          </fieldset>
        </Col>

      </Row>

      <Row>
        <Col className="col-12 col-md-12 mt-3">
          <fieldset>
            <legend>Pendency details:  7 days (1)</legend>
            <div style={{ maxHeight: "350px", Height: "320px", overflowY: 'auto' }}>
              <Table striped>
                <thead className="sticky-top">
                  <tr className="bg-light">
                    <th className="f14 fw-normal black cursor-pointer">
                      sr.No
                      <FontAwesomeIcon icon={faSort} className="mt-1" />
                    </th>
                    <th className="f14 fw-normal black cursor-pointer">
                      File No <FontAwesomeIcon icon={faSort} className="mt-1" />
                    </th>
                    <th className="f14 fw-normal black cursor-pointer">
                      Applicant Name<FontAwesomeIcon icon={faSort} className="mt-1" />
                    </th>
                    <th className="f14 fw-normal black cursor-pointer">
                      Contact No. <FontAwesomeIcon icon={faSort} className="mt-1" />
                    </th>
                    <th className="f14 fw-normal black cursor-pointer">
                      Locality / Village <FontAwesomeIcon icon={faSort} className="mt-1" />
                    </th>
                    <th className="f14 fw-normal black cursor-pointer">
                      Cast Type <FontAwesomeIcon icon={faSort} className="mt-1" />
                    </th>
                  </tr>
                  <tr className="bg-white">
                    <th className="f14 fw-normal black cursor-pointer"></th>
                    <th className="f14 fw-normal black cursor-pointer"></th>
                    <th className="f14 fw-normal black cursor-pointer"></th>
                    <th className="f14 fw-normal black cursor-pointer"></th>
                    <th className="f14 fw-normal black cursor-pointer py-1">
                      <Dropdown as={ButtonGroup} className="" align="end">
                        <Form.Control type="text" placeholder="search" className="py-0" />
                        <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="filter-dropdown">
                          <div className="px-0">
                            <div className="filter-dropdown-item">
                              <Row className="py-2 border-bottom">
                                <Col md={10} className="text-start pe-0">
                                  All
                                </Col>
                                <Col md={2} className="text-end ps-0">
                                  <Form.Check aria-label="option 1" />
                                </Col>
                              </Row>
                            </div>

                            <Row className="py-2">
                              <Col md={8} className="text-start ps-2 pe-0">
                                <Button variant="secondary" size="sm">
                                  Select All
                                </Button>
                              </Col>
                              <Col md={4} className="text-end ps-0">
                                <Button variant="secondary" size="sm">
                                  Ok
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </th>
                    <th className="f14 fw-normal black cursor-pointer py-1">
                      <Dropdown as={ButtonGroup} className="" align="end">
                        <Form.Control type="text" placeholder="search" className="py-0" />
                        <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="filter-dropdown">
                          <div className="px-0">
                            <div className="filter-dropdown-item">
                              <Row className="py-2 border-bottom">
                                <Col md={10} className="text-start pe-0">
                                  All
                                </Col>
                                <Col md={2} className="text-end ps-0">
                                  <Form.Check aria-label="option 1" />
                                </Col>
                              </Row>
                            </div>

                            <Row className="py-2">
                              <Col md={8} className="text-start ps-2 pe-0">
                                <Button variant="secondary" size="sm">
                                  Select All
                                </Button>
                              </Col>
                              <Col md={4} className="text-end ps-0">
                                <Button variant="secondary" size="sm">
                                  Ok
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="f14 c4">1.</td>
                    <td className="f14 c4">IIC/0758/2021</td>
                    <td className="f14 c4">sampath kumar</td>
                    <td className="f14 c4">9876543212</td>
                    <td className="f14 c4">New Village</td>
                    <td className="f14 c4">Cast Type</td>
                  </tr>
                  <tr>
                    <td className="f14 c4">2.</td>
                    <td className="f14 c4">IIC/0788/2021</td>
                    <td className="f14 c4">sampath kumar</td>
                    <td className="f14 c4">9876543212</td>
                    <td className="f14 c4">New Village</td>
                    <td className="f14 c4">Cast Type</td>
                  </tr>
                  <tr>
                    <td className="f14 c4">2.</td>
                    <td className="f14 c4">IIC/0788/2021</td>
                    <td className="f14 c4">sampath kumar</td>
                    <td className="f14 c4">9876543212</td>
                    <td className="f14 c4">New Village</td>
                    <td className="f14 c4">Cast Type</td>
                  </tr>
                  <tr>
                    <td className="f14 c4">2.</td>
                    <td className="f14 c4">IIC/0788/2021</td>
                    <td className="f14 c4">sampath kumar</td>
                    <td className="f14 c4">9876543212</td>
                    <td className="f14 c4">New Village</td>
                    <td className="f14 c4">Cast Type</td>
                  </tr>
                  <tr>
                    <td className="f14 c4">2.</td>
                    <td className="f14 c4">IIC/0788/2021</td>
                    <td className="f14 c4">sampath kumar</td>
                    <td className="f14 c4">9876543212</td>
                    <td className="f14 c4">New Village</td>
                    <td className="f14 c4">Cast Type</td>
                  </tr>
                  <tr>
                    <td className="f14 c4">2.</td>
                    <td className="f14 c4">IIC/0788/2021</td>
                    <td className="f14 c4">sampath kumar</td>
                    <td className="f14 c4">9876543212</td>
                    <td className="f14 c4">New Village</td>
                    <td className="f14 c4">Cast Type</td>
                  </tr>
                  <tr>
                    <td className="f14 c4">2.</td>
                    <td className="f14 c4">IIC/0788/2021</td>
                    <td className="f14 c4">sampath kumar</td>
                    <td className="f14 c4">9876543212</td>
                    <td className="f14 c4">New Village</td>
                    <td className="f14 c4">Cast Type</td>
                  </tr>
                  <tr>
                    <td className="f14 c4">2.</td>
                    <td className="f14 c4">IIC/0788/2021</td>
                    <td className="f14 c4">sampath kumar</td>
                    <td className="f14 c4">9876543212</td>
                    <td className="f14 c4">New Village</td>
                    <td className="f14 c4">Cast Type</td>
                  </tr>
                  <tr>
                    <td className="f14 c4">2.</td>
                    <td className="f14 c4">IIC/0788/2021</td>
                    <td className="f14 c4">sampath kumar</td>
                    <td className="f14 c4">9876543212</td>
                    <td className="f14 c4">New Village</td>
                    <td className="f14 c4">Cast Type</td>
                  </tr>
                  <tr>
                    <td className="f14 c4">2.</td>
                    <td className="f14 c4">IIC/0788/2021</td>
                    <td className="f14 c4">sampath kumar</td>
                    <td className="f14 c4">9876543212</td>
                    <td className="f14 c4">New Village</td>
                    <td className="f14 c4">Cast Type</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <Col className="d-flex" style={{ background: "#f7f7f7", width: "100%" }}>
              <Button className="border-0 bg-transparent">
                <FontAwesomeIcon
                  style={{
                    color: "green",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  icon={faSyncAlt}
                />
              </Button>
              <Button className="border-0 bg-transparent">
                <FontAwesomeIcon
                  style={{
                    color: "green",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  icon={faFileExcel}
                />
              </Button>

              <Pagination className="mb-0">{items}</Pagination>
            </Col>

          </fieldset>
        </Col>
      </Row>
    </Col>
  );
};

export default PendencyDetails;
