import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Tab, Tabs } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Header from "../component/Header";
import ApplicationService from "../services/service/ApplictionService";
import ErrorHandler from "../services/constant/ErrorHandler";
import UserDrawingView from "./ApplicOccupancy/ViewOccupancy/UserDrawingView";
import UserDocumentView from "./ApplicOccupancy/ViewOccupancy/UserDocumentView";
import BuildingDetailsTabView from "./ApplicOccupancy/ViewOccupancy/BuildingDetailsTabView";
import PlotDetailsView from "./ApplicOccupancy/ViewOccupancy/PlotDetailsView";
import ApplicantInformationView from "./ApplicOccupancy/ViewOccupancy/ApplicantInformationView";
import GeneralInformationView from "./ApplicOccupancy/ViewOccupancy/GeneralInformationView";
import OccupancyPaymentRecipt from "./ApplicOccupancy/ViewOccupancy/OccupancyPaymentRecipt";

const ApplicOccupancyView = () => {
  const location = useLocation();
  const history = useHistory();
  const [applicationID, setApplicationID] = useState(null);
  const [list, setList] = useState({});

  const getViewOccupancy = (id) => {
    ApplicationService.get_occupancy_view(id)
      .then((res) => {
        setApplicationID(res?.data?.user_occupancy?.id);
        setList(res?.data?.user_occupancy);
      })
      .catch((err) => {
        ErrorHandler(err);
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.id) {
      getViewOccupancy(location?.state?.id);
    } else {
      history.goBack();
    }
  }, []);

  return (
    <>
      <Col className="d-flex">
        {/* <SideBar /> */}
        <Col className="rt-dashboard">
          <Header />

          <Col className="d-flex justify-content-between w-100 px-3 bg-green">
            <Link
              to="/draft-application"
              className="d-flex black align-items-center py-2"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} />
              <p className="mb-0 ms-2">Back</p>
            </Link>
            {/* <Button className="proposal-risk-back">
              Proposal Risk Category
            </Button> */}
          </Col>
        </Col>
      </Col>

      <Col className="rt-dashboard mt-5">
        <Col>
          <Tabs id="uncontrolled-tab-example" className="mb-0">
            <Tab
              className="userApplic"
              eventKey="userapplicationform"
              title="Application Form"
            >
              <Col className="px-2 app-inform mt-1 bg-white">
                <Tabs
                  id="uncontrolled-tab-example aria-labelledby"
                  className="mb-0 bg-white"
                >
                  <Tab
                    eventKey="generalInformation"
                    title="General Information"
                  >
                    <GeneralInformationView
                      list={list}
                      setApplicationID={setApplicationID}
                      type="new"
                    />
                  </Tab>
                  <Tab
                    eventKey="applicationInformation"
                    title="Applicant Information"
                  >
                    <ApplicantInformationView list={list} />
                  </Tab>
                  <Tab eventKey="plotDetails" title="Proposed Use">
                    <PlotDetailsView
                      list={list}
                      applicationID={applicationID}
                      type="new"
                    />
                  </Tab>
                  <Tab
                    eventKey="buildingDetails"
                    title="Construction Completion Details"
                  >
                    <BuildingDetailsTabView
                      list={list}
                      applicationID={applicationID}
                      type="new"
                    />
                  </Tab>
                </Tabs>
              </Col>
            </Tab>
            <Tab
              className="userApplic"
              eventKey="userdocument"
              title="Document"
            >
              <UserDocumentView
                applicationID={applicationID}
                getViewOccupancy={getViewOccupancy}
                list={list}
                type="new"
              />
            </Tab>
            <Tab eventKey="userdrawing" title="Site Inspections">
              <UserDrawingView
                applicationID={applicationID}
                list={list}
                type="new"
              />
            </Tab>
            {/* {list.status == 12 && (
              <Tab eventKey="payment_receipt_upload" title="Payment Receipt">
                <OccupancyPaymentRecipt
                  applicationID={applicationID}
                  list={list}
                  type="new"
                />
              </Tab>
            )} */}

            {(list.status == 12 || list.status == 17) && (
              <Tab eventKey="payment_receipt_upload" title="Payment Receipt">
                <OccupancyPaymentRecipt
                  applicationID={applicationID}
                  list={list}
                  type="new"
                />
              </Tab>
            )}
          </Tabs>
        </Col>
      </Col>
    </>
  );
};

export default ApplicOccupancyView;
