import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import ApplicantCheckList from "./ApplicantCheckList";
import ApplicantInformation from "./ApplicantInformation";
import ApplyNOC from "./ApplyNOCEdit";
import BuildingDetailsEdit from "./BuildingDetailsEdit";
import BuildingDetailsEdit2 from "./BuildingDetailsEdit2";
import GeneralInformation from "./GeneralInformation";
import PlotDetails from "./PlotDetailsEdit";
import PlotDetailsEdit2 from "./PlotDetailsEdit2";
import SiteDetails from "./SiteDetailsEdit";

const UserApplicationForm = (props) => {
  return (
    <Tabs id="uncontrolled-tab-example" className="mb-3 in-user-app">
      <Tab
        eventKey="generalinformation"
        title="General Information"
        className="py-0"
      >
        <GeneralInformation data={props.data} type="edit" />
      </Tab>
      <Tab
        eventKey="applicationinformation"
        title="Applicant Information"
        className=" py-0"
      >
        <ApplicantInformation data={props.data} type="edit" />
      </Tab>
      <Tab
        eventKey="applicationchecklist"
        title="Applicant CheckList"
        className="py-0"
      >
        <ApplicantCheckList data={props.data} type="edit" />
      </Tab>
      <Tab eventKey="plotdetails" title="Plot Details" className=" py-0">
        {/* <PlotDetails data={props.data} type="edit" /> */}
        <PlotDetailsEdit2 data={props.data} type="edit" />
      </Tab>
      <Tab
        eventKey="buildingdetails"
        title="Building Details"
        className=" py-0"
      >
        {/* <BuildingDetailsEdit data={props.data} type="edit" /> */}
        <BuildingDetailsEdit2 data={props.data} type="edit" />
      </Tab>
      <Tab eventKey="sitedetails" title="Site Details" className=" py-0">
        <SiteDetails data={props.data} type="edit" />
      </Tab>
      <Tab eventKey="applynoc" title="Upload NOC" className=" py-0">
        <ApplyNOC data={props.data} type="edit" />
      </Tab>
    </Tabs>
  );
};

export default UserApplicationForm;
