import { faFileAlt, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { change, Field, FieldArray, reduxForm } from "redux-form";
// import validate from './validate';
import { useDispatch } from "react-redux";
import ApplicationService from "../../services/service/ApplictionService";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";
const BuildingDetailsEdit = (props) => {
  const dispatch = useDispatch();
  const { handleSubmit, pristine, reset, submitting } = props;
  const [tempArray, setTempArray] = useState([]);
  const [row, setRow] = useState([]);
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState([]);
  const [stilt, setStilt] = useState("");
  const [cellars, setCellars] = useState("");
  const addNewRow = () => {
    var temp = [...row];
    temp.push(row.length);
    setRow(temp);
  };
  const [useList, setUseList] = useState([]);
  const [subUseList, setSubUseList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    ApplicationService.getUseList()
      .then((result) => {
        setUseList(result.data.building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const changeUse = (e) => {
    if (e.target.value != "" && e.target.value != 0) {
      getSubList(e.target.value);
    }
  };
  const changeSubUse = (e) => {
    if (e.target.value != "" && e.target.value != 0) {
      getTypeList(e.target.value);
    }
  };
  const getSubList = (use) => {
    ApplicationService.getSubUseList(use)
      .then((result) => {
        setSubUseList(result.data.sub_building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const getTypeList = (sub_use) => {
    ApplicationService.getSubUseList(sub_use)
      .then((result) => {
        setTypeList(result.data?.sub_building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const changeNoofFloor = (e) => {
    let temp = [];
    for (let i = 0; i < e.target.value; i++) {
      temp.push(i);
    }
    setTempArray(temp);
  };
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = () => {
    ApplicationService.viewApplication({
      user_application_id: props.data.id,
      type: 5,
    })
      .then((result) => {
        if (result.data.user_application_building_details) {
          let response = result.data.user_application_building_details;
          let floorsDetails = result.data.user_application_building_floors;
          setData(response);
          let temp = [];
          setUpdate(true);
          props.initialize(response);
          props.dispatch(
            change("BuildingDetailsEditForm", "use", response.use.id)
          );
          getSubList(response.use.id);
          props.dispatch(
            change("BuildingDetailsEditForm", "sub_use", response.sub_use.id)
          );
          getTypeList(response.sub_use.id);
          props.dispatch(
            change("BuildingDetailsEditForm", "type", response.type.id)
          );
          for (let number = 0; number < floorsDetails.length; number++) {
            temp.push(number);
            props.dispatch(
              change(
                "BuildingDetailsEditForm",
                `floors[${number}].floor_name`,
                floorsDetails[number].floor_name
              )
            );
            props.dispatch(
              change(
                "BuildingDetailsEditForm",
                `floors[${number}].height`,
                floorsDetails[number].height
              )
            );
            props.dispatch(
              change(
                "BuildingDetailsEditForm",
                `floors[${number}].use`,
                floorsDetails[number].use.id
              )
            );
            props.dispatch(
              change(
                "BuildingDetailsEditForm",
                `floors[${number}].total_floor_area`,
                floorsDetails[number].total_floor_area
              )
            );
          }
          setTempArray(temp);
        }
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const save = (values) => {
    if (update) {
      var payload = values;
      payload.user_application_id = props.data.id;
      ApplicationUpdateService.updateBulidingDetails(data.id, payload)
        .then((result) => {
          alert(result.data.message);
          getDetails();
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      var payload = values;
      payload.user_application_id = props.data.id;
      ApplicationService.saveBuildingDetails(payload)
        .then((result) => {
          alert(result.data.message);
          getDetails();
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }
  };
  const renderField = ({
    disabled,
    input,
    label,
    type,
    meta: { touched, error },
  }) => (
    <div>
      {/* <label>{label}</label> */}
      <div>
        <input
          style={{
            width: "100%",
            border: "1px solid #c5c5c5",
            padding: "3px",
          }}
          {...input}
          type={type}
          placeholder={label}
          disabled={disabled}
        />
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
      </div>
    </div>
  );
  const renderSelectField = ({
    disabled,
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <div>
      {/* <label>{label}</label> */}
      <div>
        <select
          style={{
            width: "100%",
            border: "1px solid #c5c5c5",
            padding: "3px",
            backgroundColor: "#fff",
          }}
          disabled={disabled}
          {...input}
        >
          {children}
        </select>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
      </div>
    </div>
  );
  const renderMembers = ({
    fields,
    meta: { touched, error, submitFailed },
  }) => (
    <ul>
      {tempArray.map((member, index) => (
        <li key={index}>
          <h6>Floor #{index + 1}</h6>
          <Col className="d-md-flex flex-wrap mt-1">
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Floor Name*</p>
              </Col>
              <Col>
                <Field
                  disabled
                  name={`floors[${member}].floor_name`}
                  type="text"
                  component={renderField}
                  label="Floor Name"
                />
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Height (m)</p>
              </Col>
              <Col>
                <Field
                  disabled
                  name={`floors[${member}].height`}
                  type="number"
                  component={renderField}
                  label="Height"
                />
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Use</p>
              </Col>
              <Col>
                <Field
                  disabled
                  name={`floors[${member}].use`}
                  component={renderSelectField}
                >
                  <option className="f14 py-2" value="0">
                    Select
                  </option>
                  {useList.length > 0 &&
                    useList.map((item, index) => {
                      return (
                        <option
                          key={index}
                          className="f14 py-2"
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </Field>
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Total Floor Area</p>
              </Col>
              <Col>
                <Field
                  disabled
                  name={`floors[${member}].total_floor_area`}
                  type="number"
                  component={renderField}
                  label="Total Floor Area"
                />
              </Col>
            </Col>
          </Col>
        </li>
      ))}
    </ul>
  );
  return (
    <Tabs id="uncontrolled-tab-example" className="mb-3 in-user-app">
      <Tab eventKey="buildingDetails" title="BuildingAdd" className=" py-0">
        <form onSubmit={handleSubmit(save)}>
          <Col className="rt-dashboard px-3">
            <Col className="d-md-flex justify-content-between align-items-center px-3">
              <Col className="d-flex justify-content-md-end"></Col>
            </Col>
            <Col className="customers mb-3 mt-3">
              <Col
                style={{
                  border: "1px solid #e9e9e9",
                  padding: 15,
                  marginBottom: 10,
                }}
                xs={12}
                className="d-md-flex flex-wrap"
              >
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Name*</p>
                  </Col>
                  <Col>
                    <Field
                      disabled
                      name={"name"}
                      type="text"
                      component={renderField}
                      label="Name"
                    />
                  </Col>
                </Col>

                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Use*</p>
                  </Col>
                  <Col>
                    <Field
                      disabled
                      name="use"
                      component={renderSelectField}
                      onChange={changeUse}
                    >
                      <option className="f14 py-2" value="0">
                        Select
                      </option>
                      {useList.length > 0 &&
                        useList.map((item, index) => {
                          return (
                            <option
                              key={index}
                              className="f14 py-2"
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </Field>
                  </Col>
                </Col>

                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Sub Use*</p>
                  </Col>
                  <Col>
                    <Field
                      disabled
                      name="sub_use"
                      component={renderSelectField}
                      onChange={changeSubUse}
                    >
                      <option className="f14 py-2" value="0">
                        Select
                      </option>
                      {subUseList.length > 0 &&
                        subUseList.map((item, index) => {
                          return (
                            <option
                              key={index}
                              className="f14 py-2"
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </Field>
                  </Col>
                </Col>

                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Type*</p>
                  </Col>
                  <Col>
                    <Field disabled name="type" component={renderSelectField}>
                      <option className="f14 py-2" value="0">
                        Select
                      </option>
                      {typeList.length > 0 &&
                        typeList.map((item, index) => {
                          return (
                            <option
                              key={index}
                              className="f14 py-2"
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </Field>
                  </Col>
                </Col>

                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Type Number of dwelling units*</p>
                  </Col>
                  <Col>
                    <Col>
                      <Field
                        disabled
                        name={"dwelling_units"}
                        type="number"
                        component={renderField}
                        label="Dwelling Units"
                      />
                    </Col>
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">
                      Height excluding Stilt / Parking*
                    </p>
                  </Col>
                  <Col>
                    <Field
                      disabled
                      name={"height_excluding_slit"}
                      type="number"
                      component={renderField}
                      label="Height Excluding Slit"
                      onChange={(e) => setStilt(e.target.value)}
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Height excluding Cellars*</p>
                  </Col>
                  <Col>
                    <Field
                      disabled
                      name={"height_excluding_celler"}
                      type="number"
                      component={renderField}
                      label="Height Excluding Cellars"
                      onChange={(e) => setCellars(e.target.value)}
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">No. Of Floors*</p>
                  </Col>
                  <Col>
                    <Field
                      disabled
                      name={"no_of_floors"}
                      type="number"
                      component={renderField}
                      label="No of Floors"
                      onChange={changeNoofFloor}
                    />
                  </Col>
                </Col>
              </Col>
              <FieldArray name="floors" component={renderMembers} />
              {/* <Col className="d-flex justify-content-center mb-2">
            <Button
              type="submit"
              className="tab-blu-back"
              style={{
                color: "#fff",
                fontSize: 15,
                marginTop: 10,
                padding: "6px 14px",
                backgroundColor: "#5593db",
              }}
            >
              <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
              Save
            </Button>
          </Col> */}
            </Col>
          </Col>
        </form>
      </Tab>
      <Tab
        eventKey="buildingDetailsView"
        title="BuildingShow"
        className=" py-0"
      ></Tab>
    </Tabs>
  );
};
export default reduxForm({
  form: "BuildingDetailsEditForm", // a unique identifier for this form
  // validate
})(BuildingDetailsEdit);
