import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  faBell,
  faCheckDouble,
  faComments,
  faKey,
  faQuestion,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DashboardLogo, UserLogo } from "../assets/images/Index";
import {
  Row,
  Col,
  Popover,
  Overlay,
  Dropdown,
  DropdownButton,
  Button,
} from "react-bootstrap";
import UserDetails from "../services/storage/UserDetails";
import RitFixed from "./RitFixed";
const HeaderCopy = () => {
  const { userDetails, logout } = UserDetails();
  const selectFor = localStorage.getItem("selectFor");
  const changeLogout = () => {
    const result = window.confirm("Do you really want to Logout !");
    if (result) {
      logout();
      window.location.href = "/";
    }
  };
  return (
    <div className="border-bottom border-light bg-white">
      <RitFixed />
      <Col className="d-flex justify-content-between align-items-center px-3 py-2">
        <Col className="d-flex align-items-center justify-content-between p-2 pt-0 bg-white">
          <Col className="d-flex align-items-center">
            <Link to={"/"}>
              <DashboardLogo />
            </Link>
            <Link to={"/"}>
              <h3
                className="f15 c2 mb-0 ms-2 fw-bold"
                style={{ color: "#81abca" }}
              >
                TGIIC
              </h3>
            </Link>
            <Col className="p-0 justify-content-center justify-content-lg-end">
              <Col xs={12} className="text-center text-lg-end p-0"></Col>
            </Col>
          </Col>
          {/* <div className="nav-shape-box">
            <div className="nav-shape"></div>
            <div className="nav-shape"></div>
            <div className="nav-shape"></div>
          </div> */}
        </Col>
        <ul className="d-flex mb-0 draft-app-header">
          {/* <li className=" btnn text-center px-3 py-2 cursor-pointer popup">
            <Dropdown align="end">
              <Dropdown.Toggle
                variant="none"
                align
                id="dropdown-basic"
                className="p-0"
              >
                <FontAwesomeIcon icon={faBell} />
                <p className="f12 mb-0">Notification</p>
              </Dropdown.Toggle>

              <Dropdown.Menu className="popup-menu shadow p-2">
                <div className="bg-white my-2 mb-1 f12 text-start">
                  Notification
                </div>
                <div>
                  <div className="my-2 c9 f14 text-center">
                    No New Notification...
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>

          <li className=" btnn text-center px-3 py-2 cursor-pointer popup">
            <Dropdown align="end">
              <Dropdown.Toggle
                variant="none"
                align
                id="dropdown-basic"
                className="p-0"
              >
                <FontAwesomeIcon icon={faComments} />
                <p className="f12 mb-0">Message</p>
              </Dropdown.Toggle>

              <Dropdown.Menu className="popup-menu shadow p-2">
                <div className="bg-white my-2 mb-1 f12 text-start">
                  Messages
                </div>
                <div>
                  <div className="my-2 c9 f14 text-center">
                    No New Message...
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>

          <li className=" btnn text-center px-3 py-2 cursor-pointer popup">
            <Dropdown align="end">
              <Dropdown.Toggle
                variant="none"
                align
                id="dropdown-basic"
                className="p-0"
              >
                <FontAwesomeIcon icon={faCheckDouble} />
                <p className="f12 mb-0">Activities</p>
              </Dropdown.Toggle>

              <Dropdown.Menu className="popup-menu shadow p-2">
                <div className="bg-white my-2 py-2 mb-1 f12 text-start border-bottom">
                  You have new notification
                </div>
                <div>
                  <div className="my-2 c9 f14 text-center">
                    <UserLogo />
                    <br />
                    No New Notification...
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>

          <li className=" btnn text-center px-3 py-2 cursor-pointer popup">
            <Dropdown align="end">
              <Dropdown.Toggle
                variant="none"
                align
                id="dropdown-basic"
                className="p-0"
              >
                <FontAwesomeIcon icon={faQuestion} />
                <p className="f12 mb-0">Help</p>
              </Dropdown.Toggle>

              <Dropdown.Menu className="popup-menu shadow p-2">
                <div className="bg-white my-2 mb-1 f12 text-start">Help</div>
                <div>
                  <div className="my-2 c9 f14 text-center">...</div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li> */}

          {/* <li className=" btnn text-center px-3 py-2 cursor-pointer popup">
            <Dropdown align="end">
              <Dropdown.Toggle
                variant="none"
                align
                id="dropdown-basic"
                className="p-0"
              >
                <FontAwesomeIcon icon={faUser} />
                <p className="f12 mb-0">Users</p>
              </Dropdown.Toggle>

              <Dropdown.Menu className="popup-menu-user shadow">
                <Link to="/profile" href="" className="text-light">
                  <div className="f14 text-start px-3 py-2 user-item">
                    <FontAwesomeIcon icon={faUser} /> &nbsp; My Profile
                  </div>
                </Link>

                <Link to="/change-password" href="" className="text-light">
                  <div className="f14 text-start px-3 py-2 user-item">
                    <FontAwesomeIcon icon={faKey} /> &nbsp; Change Password
                  </div>
                </Link>

                <Link onClick={changeLogout} className="text-light">
                  <div className="f14 text-start px-3 py-2 user-item">
                    <FontAwesomeIcon icon={faSignOutAlt} /> &nbsp; Log Out
                  </div>
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </li> */}
        </ul>
        <h5 className="f15 fw-bold ms-2">
          {userDetails?.title}. {userDetails?.first_name}{" "}
          {userDetails?.middle_name} {userDetails?.last_name}
          <i>
            {userDetails?.type == 1
              ? "(Citizen)"
              : userDetails?.type == 2
              ? "(Architect)"
              : "(Structural Engineer)"}
          </i>
        </h5>
      </Col>
    </div>
  );
};

export default HeaderCopy;
