import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import ApplicationSubmissionService from "../services/service/ApplicationSubmissionService";
import ErrorHandler from "../services/constant/ErrorHandler";
import UserDetails from "../services/storage/UserDetails";
import DashboardLink from "./DashboardLink";
import MUIDataTable from "mui-datatables";
import ApplicationService from "../services/service/ApplictionService";
import ApplicationUpdateService from "../services/service/ApplicationUpdateService";
import ReportOneg from "./Report/thirdchangeReport";
import BuildingPermissionOrder from "../screens/form/BuildingPermissionOrder";
import DemandNoticeSample from "../screens/form/DemandNoticeSample";
import { PDFExport } from "@progress/kendo-react-pdf";
import HighRise from "../services/constant/HighRise";
import moment from "moment/moment";

const OccupancyPaymentReciept = () => {
  const { saveHighRiseStatus } = HighRise();
  const { userDetails } = UserDetails();
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalType, setModalType] = useState("");
  const history = useHistory();
  useEffect(() => {
    let formdata = new FormData();
    formdata.append("status", 12);
    ApplicationService.get_occupancy_link(formdata)
      .then((result) => {
        setList(result?.data?.lists);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const viewPreCheckReport = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalData(response.precheck_errors);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewDrawingPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalData(response.scrutiny_report_pdf);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewScrutinyReportPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        viewReport(response);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewDemondNoteSignaturetPDF = (value) => {
    ApplicationUpdateService.occupancyDemondNoteSignatureDownload({
      user_occupancy_id: value.id,
    })
      .then((result) => {
        let response = result.data;
        // viewReport(response);
        if (response.occupancy_fee_intimation_url) {
          setModalType("fees");
          setModalData(response?.occupancy_fee_intimation_url);
          setModal(true);
        } else {
          alert("Not Found");
        }
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewReport = async (values) => {
    let temp = {};
    // let details = values.scrutiny_report_datas;
    // let anpdf = values.scrutiny_report_pdf;
    // temp.owner = { firstName: details.applicant_name, lastName: details.applicant_name, email: details.email, mobile: details.mobile };
    // temp.mobile = details.mobile;
    // temp.architect = { name: details.applicant_name, email: details.email, mobile: details.mobile };
    // temp.appliedOn = details.receipt_date;
    // temp.applicationId = details.application_number;
    // temp.report = drawing;
    let drawing = JSON.parse(values.rule_engine_json_response);
    let details = values.scrutiny_report_datas;
    let anpdf = values.scrutiny_report_pdf;
    temp.owner = {
      firstName: details.applicant_name,
      lastName: details.applicant_name,
      email: details.email,
      address: details.address,
    };
    temp.mobile = details.mobile;
    temp.surveyNo = details.sector_number;
    temp.doorNo = details.plot_number;
    temp.locality = details.mandal_name;
    temp.village = details.village_name;
    temp.siteaddress = details.site_address;
    temp.useOfTheBuildings = details.land_use_category;
    temp.subuseOfTheBuildings = details.land_subuse_category;
    temp.existingRoadType = details.road_type;
    temp.proposedRoadType = details.land_subuse_category;
    temp.architect = {
      name: details.applicant_name,
      email: details.email,
      mobile: details.mobile,
    };
    temp.appliedOn = details.receipt_date;
    temp.applicationId = details.application_number;
    temp.report = drawing;
    // var temp = {};
    // temp.owner = { firstName: 'test', lastName: 'test2', email: 'test@gmail.com', mobile: '9566673188' };
    // temp.mobile = "9266673188";
    // temp.architect = { name: 'test', email: 'test@gmail.com', mobile: '9566673188' };
    // temp.report = JSON.parse(values.rule_engine_json_response);
    // temp.report = test1;
    let newreport = new ReportOneg(temp);
    let results = await newreport.show();
    setModalData(results);
    setModal(true);
  };

  const columns = [
    {
      name: "sl",
      label: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{tableMeta.rowIndex + 1}</div>;
        },
      },
    },
    {
      name: "file_no",
      label: "File No",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Link
                to={{
                  pathname: "/occupancy-view",
                  state: list[tableMeta.rowIndex],
                }}
                onClick={() => {
                  saveHighRiseStatus(
                    list[tableMeta.rowIndex]?.is_high_rise
                      ? list[tableMeta.rowIndex].is_high_rise
                      : 0
                  );
                }}
              >
                {value}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "application_type",
      label: "Applicant Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{userDetails?.first_name}</div>;
        },
      },
    },
    {
      name: "iala_name",
      label: "IALA",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {
                list[tableMeta.rowIndex]?.user_occupancy_detail?.iala_place
                  ?.name
              }
            </div>
          );
        },
      },
    },
    {
      name: "fee_intimation",
      label: "Fee Intimation",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status >= 11
                  ? "f14 c4 text-start  pre-pdf-btn"
                  : "f14 c4 text-start  pre-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status >= 11 ? false : true}
                onClick={() =>
                  viewDemondNoteSignaturetPDF(list[tableMeta.rowIndex])
                }
                className="view-btn"
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Date Of Submission",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{moment(value).format("YYYY-MM-DD")}</div>;
        },
      },
    },
  ];

  const options = {
    selectableRows: false,
    filter: true,
    filterType: "dropdown",
  };
  const pdfExportComponent = React.useRef(null);
  const pdfpermission = React.useRef(null);
  return (
    <Col className="col-12 mt-0 px-3">
      <MUIDataTable
        title={"Final Payment Completed"}
        data={list}
        columns={columns}
        options={options}
      />

      {modal === true ? (
        <>
          <Modal
            show={modal}
            onHide={() => {
              setModal(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                {modalType == "demond_note"
                  ? "Demand Note Signature"
                  : modalType == "account"
                  ? "User Account Details"
                  : modalType == "fees"
                  ? "Fees Intimation"
                  : modalType == "msb"
                  ? "MSB Agenda"
                  : modalType == "mom"
                  ? "MOM"
                  : modalType == "ta_letter"
                  ? "TA Letter"
                  : "Errors"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {modalType == "demond_note" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "account" ? (
                  <div style={{ height: "200px" }}>
                    <Row>
                      <Col className="mt-5">
                        Account No : {modalData.account_no}
                      </Col>
                      <Col className="mt-5">
                        IFSC Code : {modalData.ifsc_code}
                      </Col>
                    </Row>
                  </div>
                ) : modalType == "fees" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "msb" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "mom" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "ta_letter" ? (
                  <div>
                    <iframe
                      src={modalData?.ta_url ? modalData.ta_url : null}
                      style={{ height: "500px", width: "100%" }}
                    ></iframe>
                  </div>
                ) : (
                  modalType == "error" && (
                    <div>
                      <Row>
                        <Col>Errors</Col>
                      </Row>
                      {modalData.map((item, index) => {
                        return (
                          <Row>
                            <Col>{item}</Col>
                          </Row>
                        );
                      })}
                    </div>
                  )
                )}
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </Col>
  );
};

export default OccupancyPaymentReciept;
