import { faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationService from "../../services/service/ApplictionService";

const PlotDetails = (props) => {
  const [disable, setDisable] = useState(false);
  const [roadRadio, setroadRadio] = useState(null);
  const [plotPart, setplotPart] = useState(null);
  const [saleDeed, setSaleDeed] = useState(null);
  const [revisedPermit, setRevisedPermit] = useState(null);
  const [expansion, setExpansion] = useState(null);
  const [existingOnSite, setExistingOnSite] = useState(null);
  const [existingPublicRoad, setExistingPublicRoad] = useState(null);
  const [statusOfRoad, setStatusOfRoad] = useState(null);
  const [workOnSite, setWorkOnSite] = useState(null);

  const [northDrop, setNorthDrop] = useState(1);
  const [westDrop, setWestDrop] = useState(1);
  const [southDrop, setSouthDrop] = useState(1);
  const [eastDrop, setEastDrop] = useState(1);

  const [proposedUse, setProposedUse] = useState(1);
  const [activity, setActivity] = useState(null);
  const [activityList, setActivityList] = useState([]);
  const [perPlan, setPerPlan] = useState("");
  const [perDocument, setPerDocument] = useState("");
  const [onGrounds, setOnGrounds] = useState("");
  const [upArea, setUpArea] = useState("");
  const [exixtingBulidUpArea, setExixtingBulidUpArea] = useState("");
  const [roadWinding, setRoadWinding] = useState(0);
  const [layoutPlanRoads, setLayoutPlanRoads] = useState("");
  const [roadAffectedArea, setRoadAffectedArea] = useState("");
  const [netPlotArea, setNetPlotArea] = useState("");
  const [existingRoadWidth, setExistingRoadWidth] = useState("");
  const [proposedRoadWidth, setProposedRoadWidth] = useState("");

  const [plotWidth, setPlotWidth] = useState("");
  const [plotLength, setPlotLength] = useState("");
  const [noOfBlock, setNoOfBlock] = useState(null);
  const [noOfStoreys, setNoOfStoreys] = useState(null);
  const [noOfStoreysValue, setNoOfStoreysValue] = useState("");
  const [cellar, setCellar] = useState("");
  const [religiousStr, setReligiousStr] = useState(null);
  const [locationType, setLocationType] = useState(null);
  const [subLocation, setSubLocation] = useState(null);
  const [locationTypeList, setLocationTypeList] = useState([]);
  const [subLocationList, setSubLocationList] = useState([]);
  const [landUseZone, setLandUseZone] = useState("");
  const [setBack, setSetBack] = useState("");
  const [aerodromeType, setAerodromeType] = useState(0);
  const [aeroDistance, setAeroDistance] = useState(0);
  const [plotPartOf, setPlotPartOf] = useState("");

  const [sanctionNo, setSanctionNo] = useState("");
  const [sanctionDate, setSanctionDate] = useState("");
  const [lRSProceedingNo, setLRSProceedingNo] = useState("");
  const [lRSProceedingDate, setLRSProceedingDate] = useState("");
  const [registrationOfPlot, setRegistrationOfPlot] = useState("");

  const [documentNo, setDocumentNo] = useState("");
  const [documentDate, setDocumentDate] = useState("");
  // const [revisedPermit, setRevisedPermit] = useState('');
  // const [expansion, setExpansion] = useState('');

  const [extensionOfDate, setExtensionOfDate] = useState("");
  const [publicRoadPlan, setPublicRoadPlan] = useState(0);
  const [typeOfRoad, setTypeOfRoad] = useState("");
  const [widthOfTheApproachRoad, setWidthOfTheApproachRoad] = useState("");
  const [NorthValue, setNorthValue] = useState("");
  const [SouthValue, setSouthValue] = useState("");
  const [WestValue, setWestValue] = useState("");
  const [EastValue, setEastValue] = useState("");
  const [NorthFile, setNorthFile] = useState("");
  const [SouthFile, setSouthFile] = useState("");
  const [WestFile, setWestFile] = useState("");
  const [EastFile, setEastFile] = useState("");
  const [otherFiles, setOtherFiles] = useState([]);

  const [perPlanErr, setPerPlanErr] = useState(null);
  const [perDocumentErr, setPerDocumentErr] = useState(null);
  const [onGroundsErr, setOnGroundsErr] = useState(null);
  const [upAreaErr, setUpAreaErr] = useState(null);
  const [exixtingBulidUpAreaErr, setExixtingBulidUpAreaErr] = useState(null);
  const [layoutPlanRoadsErr, setLayoutPlanRoadsErr] = useState(null);
  const [roadAffectedAreaErr, setRoadAffectedAreaErr] = useState(null);
  const [netPlotAreaErr, setNetPlotAreaErr] = useState(null);
  const [existingRoadWidthErr, setExistingRoadWidthErr] = useState(null);
  const [proposedRoadWidthErr, setProposedRoadWidthErr] = useState(null);

  const [plotWidthErr, setPlotWidthErr] = useState(null);
  const [plotLengthErr, setPlotLengthErr] = useState(null);
  const [noOfBlockErr, setNoOfBlockErr] = useState(null);
  const [noOfStoreysErr, setNoOfStoreysErr] = useState(null);
  const [noOfStoreysValueErr, setNoOfStoreysValueErr] = useState(null);
  const [cellarErr, setCellarErr] = useState(null);
  const [religiousStrErr, setReligiousStrErr] = useState(null);
  const [locationTypeErr, setLocationTypeErr] = useState(null);
  const [subLocationErr, setSubLocationErr] = useState(null);
  const [landUseZoneErr, setLandUseZoneErr] = useState(null);
  const [setBackErr, setSetBackErr] = useState(null);
  const [aerodromeTypeErr, setAerodromeTypeErr] = useState(null);
  const [aeroDistanceErr, setAeroDistanceErr] = useState(null);
  const [plotPartOfErr, setPlotPartOfErr] = useState(null);

  const [sanctionNoErr, setSanctionNoErr] = useState(null);
  const [sanctionDateErr, setSanctionDateErr] = useState(null);
  const [lRSProceedingNoErr, setLRSProceedingNoErr] = useState(null);
  const [lRSProceedingDateErr, setLRSProceedingDateErr] = useState(null);
  const [registrationOfPlotErr, setRegistrationOfPlotErr] = useState(null);

  const [documentNoErr, setDocumentNoErr] = useState(null);
  const [documentDateErr, setDocumentDateErr] = useState(null);

  const [extensionOfDateErr, setExtensionOfDateErr] = useState(null);
  const [publicRoadPlanErr, setPublicRoadPlanErr] = useState(null);
  const [typeOfRoadErr, setTypeOfRoadErr] = useState(null);
  const [widthOfTheApproachRoadErr, setWidthOfTheApproachRoadErr] =
    useState(null);
  const [NorthValueErr, setNorthValueErr] = useState(null);
  const [SouthValueErr, setSouthValueErr] = useState(null);
  const [WestValueErr, setWestValueErr] = useState(null);
  const [EastValueErr, setEastValueErr] = useState(null);
  const [NorthFileErr, setNorthFileErr] = useState(null);
  const [SouthFileErr, setSouthFileErr] = useState(null);
  const [WestFileErr, setWestFileErr] = useState(null);
  const [EastFileErr, setEastFileErr] = useState(null);
  const [otherFilesErr, setOtherFilesErr] = useState(null);
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  var mindate = new Date();
  mindate.setMonth(mindate.getMonth() - 18);
  var d = mindate.getDate();
  var m = mindate.getMonth() + 1; //January is 0!
  var y = mindate.getFullYear();
  if (d < 10) {
    d = "0" + d;
  }

  if (m < 10) {
    m = "0" + m;
  }

  mindate = y + "-" + m + "-" + d;
  var maxdate = new Date();
  maxdate.setMonth(maxdate.getMonth() + 6);
  var maxd = maxdate.getDate();
  var maxm = maxdate.getMonth() + 1; //January is 0!
  var maxy = maxdate.getFullYear();
  if (maxd < 10) {
    maxd = "0" + maxd;
  }

  if (maxm < 10) {
    maxm = "0" + maxm;
  }

  maxdate = maxy + "-" + maxm + "-" + maxd;

  console.log("today", today);
  console.log("maxdate", maxdate);
  console.log("mindate", mindate);

  const temp = [
    { id: 1, name: "NA" },
    { id: 2, name: "Upto distance of 360 mt" },
    { id: 3, name: "More than 360 mt but not exceeding 510 mt" },
    { id: 4, name: "More than 510 mt but not exceeding 660 mt" },
    { id: 5, name: "More than 660 mt but not exceeding 810 mt" },
    { id: 6, name: "More than 810 mt but not exceeding 960 mt" },
    { id: 7, name: "More than 960 mt but not exceeding 1110 mt" },
    { id: 8, name: "More than 1110 mt but not exceeding 1260 mt" },
    { id: 9, name: "More than 1260 mt but not exceeding 1410 mt" },
    { id: 10, name: "More than 1410 mt but not exceeding 1560 mt" },
    { id: 11, name: "More than 1560 mt" },
    { id: 12, name: "Upto distance of 21 mt" },
  ];
  const temp2 = [
    { id: 13, name: "NA" },
    { id: 14, name: "More than 21 mt but not exceeding 42 mt" },
    { id: 15, name: "More than 42 mt but not exceeding 63 mt" },
    { id: 16, name: "More than 63 mt but not exceeding 84 mt" },
    { id: 17, name: "More than 84 mt but not exceeding 105 mt" },
    { id: 18, name: "More than 126 mt but not exceeding 147 mt" },
    { id: 19, name: "More than 147 mt but not exceeding 168 mt" },
    { id: 20, name: "More than 168 mt but not exceeding 189 mt" },
    { id: 21, name: "More than 189 mt but not exceeding 210 mt" },
    { id: 22, name: "More than 210 mt" },
  ];
  const [aerodromeDistanceList, setAerodromeDistanceList] = useState([]);
  const changeAerodromeType = (e) => {
    setAerodromeType(e.target.value);
    if (e.target.value != 0) {
      if (e.target.value == 1) {
        setAerodromeDistanceList([{ id: 1, name: "NA" }]);
      } else if (e.target.value == 2) {
        setAerodromeDistanceList(temp);
      } else if (e.target.value == 3) {
        setAerodromeDistanceList(temp2);
      }
    }
  };
  useEffect(() => {
    ApplicationService.getProposedActivity(proposedUse)
      .then((result) => {
        setActivityList(result.data.proposed_activities);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, [proposedUse]);
  useEffect(() => {
    ApplicationService.getPlotLocations(0)
      .then((result) => {
        setLocationTypeList(result.data.plot_locations);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  useEffect(() => {
    ApplicationService.getPlotLocations(locationType)
      .then((result) => {
        setSubLocationList(result.data.plot_locations);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, [locationType]);

  const changePerPlan = (e) => {
    setPerPlan(e.target.value);
    if (e.target.value != "") {
      setPerPlanErr(false);
    } else {
      setPerPlanErr(true);
    }
  };
  const changePerDocument = (e) => {
    setPerDocument(e.target.value);
    if (e.target.value != "") {
      setPerDocumentErr(false);
    } else {
      setPerDocumentErr(true);
    }
  };
  const changeOnGrounds = (e) => {
    setOnGrounds(e.target.value);
    if (e.target.value != "") {
      setOnGroundsErr(false);
    } else {
      setOnGroundsErr(true);
    }
  };
  const changeUpArea = (e) => {
    setUpArea(e.target.value);
    if (e.target.value != "") {
      setUpAreaErr(false);
    } else {
      setUpAreaErr(true);
    }
  };
  const changeExisitingBulidUpArea = (e) => {
    setExixtingBulidUpArea(e.target.value);
    if (e.target.value != "") {
      setExixtingBulidUpAreaErr(false);
    } else {
      setExixtingBulidUpAreaErr(true);
    }
  };
  const changePlotWidth = (e) => {
    setPlotWidth(e.target.value);
    if (e.target.value != "") {
      setPlotWidthErr(false);
    } else {
      setPlotWidthErr(true);
    }
  };
  const changePlotLength = (e) => {
    setPlotLength(e.target.value);
    if (e.target.value != "") {
      setPlotLengthErr(false);
    } else {
      setPlotLengthErr(true);
    }
  };
  const changeRoadAffectedArea = (e) => {
    setRoadAffectedArea(e.target.value);
    if (e.target.value != "") {
      setRoadAffectedAreaErr(false);
    } else {
      setRoadAffectedAreaErr(true);
    }
  };
  const changeNetPlotArea = (e) => {
    setNetPlotArea(e.target.value);
    if (e.target.value != "") {
      setNetPlotAreaErr(false);
    } else {
      setNetPlotAreaErr(true);
    }
  };
  const changeExistingRoadWidth = (e) => {
    setExistingRoadWidth(e.target.value);
    if (e.target.value != "") {
      setExistingRoadWidthErr(false);
    } else {
      setExistingRoadWidthErr(true);
    }
  };
  const changeProposedRoadWidth = (e) => {
    setProposedRoadWidth(e.target.value);
    if (e.target.value != "") {
      setProposedRoadWidthErr(false);
    } else {
      setProposedRoadWidthErr(true);
    }
  };
  const changeNoOfBlock = (e) => {
    setNoOfBlock(e.target.value);
    if (e.target.value != "" && e.target.value != null) {
      setNoOfBlockErr(false);
    } else {
      setNoOfBlockErr(true);
    }
  };
  const changeNoOfStoreys = (e) => {
    setNoOfStoreys(e.target.value);
    if (e.target.value != "") {
      setNoOfStoreysErr(false);
    } else {
      setNoOfStoreysErr(true);
    }
  };
  const changeNoOfStoreysValue = (e) => {
    setNoOfStoreysValue(e.target.value);
    if (e.target.value != "") {
      setNoOfStoreysValueErr(false);
    } else {
      setNoOfStoreysValueErr(true);
    }
  };
  const changeCellar = (e) => {
    setCellar(e.target.value);
    if (e.target.value != "") {
      setCellarErr(false);
    } else {
      setCellarErr(true);
    }
  };
  const changeReligiousStr = (e) => {
    setReligiousStr(e.target.value);
    if (e.target.value != "" && e.target.value != null) {
      setReligiousStrErr(false);
    } else {
      setReligiousStrErr(true);
    }
  };
  const changeLocationType = (e) => {
    setLocationType(e.target.value);
    if (e.target.value != "") {
      setLocationTypeErr(false);
    } else {
      setLocationTypeErr(true);
    }
  };
  const changeSubLocation = (e) => {
    setSubLocation(e.target.value);
    if (e.target.value != "") {
      setSubLocationErr(false);
    } else {
      setSubLocationErr(true);
    }
  };
  const changeLandUseZone = (e) => {
    setLandUseZone(e.target.value);
    if (e.target.value != "") {
      setLandUseZoneErr(false);
    } else {
      setLandUseZoneErr(true);
    }
  };

  const changeSetBack = (e) => {
    setSetBack(e.target.value);
    if (e.target.value != "") {
      setSetBackErr(false);
    } else {
      setSetBackErr(true);
    }
  };

  const changePlotPartOf = (e) => {
    setPlotPartOf(e.target.value);
    if (e.target.value != "") {
      setPlotPartOfErr(false);
    } else {
      setPlotPartOfErr(true);
    }
  };
  const changeSanctionNo = (e) => {
    setSanctionNo(e.target.value);
    if (e.target.value != "") {
      setSanctionNoErr(false);
    } else {
      setSanctionNoErr(true);
    }
  };
  const changeSanctionDate = (e) => {
    setSanctionDate(e.target.value);
    if (e.target.value != "") {
      setSanctionDateErr(false);
    } else {
      setSanctionDateErr(true);
    }
  };
  const changeLRSProceedingNo = (e) => {
    setLRSProceedingNo(e.target.value);
    if (e.target.value != "") {
      setLRSProceedingNoErr(false);
    } else {
      setLRSProceedingNoErr(true);
    }
  };
  const changeLRSProceedingDate = (e) => {
    setLRSProceedingDate(e.target.value);
    if (e.target.value != "") {
      setLRSProceedingDateErr(false);
    } else {
      setLRSProceedingDateErr(true);
    }
  };
  const changeDocumentNo = (e) => {
    setDocumentNo(e.target.value);
    if (e.target.value != "") {
      setDocumentNoErr(false);
    } else {
      setDocumentNoErr(true);
    }
  };
  const changeDocumentDate = (e) => {
    setDocumentDate(e.target.value);
    if (e.target.value != "") {
      setDocumentDateErr(false);
    } else {
      setDocumentDateErr(true);
    }
  };
  const changeExtensionofDate = (e) => {
    setExtensionOfDate(e.target.value);
    if (e.target.value != "") {
      setExtensionOfDateErr(false);
    } else {
      setExtensionOfDateErr(true);
    }
  };
  const changeWidthOfTheApproachRoad = (e) => {
    setWidthOfTheApproachRoad(e.target.value);
    if (e.target.value != "") {
      setWidthOfTheApproachRoadErr(false);
    } else {
      setWidthOfTheApproachRoadErr(true);
    }
  };
  const changeNorthValue = (e) => {
    setNorthValue(e.target.value);
    if (e.target.value != "") {
      setNorthValueErr(false);
    } else {
      setNorthValueErr(true);
    }
  };
  const changeSouthValue = (e) => {
    setSouthValue(e.target.value);
    if (e.target.value != "") {
      setSouthValueErr(false);
    } else {
      setSouthValueErr(true);
    }
  };
  const changeWestValue = (e) => {
    setWestValue(e.target.value);
    if (e.target.value != "") {
      setWestValueErr(false);
    } else {
      setWestValueErr(true);
    }
  };
  const changeEastValue = (e) => {
    setEastValue(e.target.value);
    if (e.target.value != "") {
      setEastValueErr(false);
    } else {
      setEastValueErr(true);
    }
  };
  const changeNorthFile = (e) => {
    if (e.target.files[0].size < 26214400) {
      setNorthFile(e.target.files[0]);
      if (e.target.files[0] != "") {
        setNorthFileErr(false);
      } else {
        setNorthFileErr(true);
      }
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const changeSouthFile = (e) => {
    if (e.target.files[0].size < 26214400) {
      setSouthFile(e.target.files[0]);
      if (e.target.files[0] != "") {
        setSouthFileErr(false);
      } else {
        setSouthFileErr(true);
      }
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const changeWestFile = (e) => {
    if (e.target.files[0].size < 26214400) {
      setWestFile(e.target.files[0]);
      if (e.target.files[0] != "") {
        setWestFileErr(false);
      } else {
        setWestFileErr(true);
      }
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const changeEastFile = (e) => {
    if (e.target.files[0].size < 26214400) {
      setEastFile(e.target.files[0]);
      if (e.target.files[0] != "") {
        setEastFileErr(false);
      } else {
        setEastFileErr(true);
      }
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const changeOtherFiles = (e) => {
    setOtherFiles(e.target.files);
    if (e.target.files[0] != "") {
      setOtherFilesErr(false);
    } else {
      setOtherFilesErr(true);
    }
  };
  const hiddenFileInput = React.useRef(null);
  const hiddenDateInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleDateClick = (event) => {
    hiddenDateInput.current.click();
  };
  // const handleChange = (event) => {
  //   const fileUploaded = event.target.files[0];
  //   props.handleFile(fileUploaded);
  // };

  const save = () => {
    if (props.applicationID) {
      if (
        activity != null &&
        perPlanErr == false &&
        perDocumentErr == false &&
        onGroundsErr == false &&
        upAreaErr == false &&
        exixtingBulidUpAreaErr == false &&
        roadWinding != null &&
        roadRadio != null &&
        plotWidthErr == false &&
        plotLengthErr == false &&
        noOfBlockErr == false &&
        noOfStoreys != null &&
        noOfStoreysValueErr == false &&
        cellarErr == false &&
        religiousStrErr == false &&
        locationType != null &&
        subLocation != null &&
        landUseZone != null &&
        // NEW
        setBack != null &&
        aerodromeType != 0 &&
        aeroDistance != 0 &&
        plotPart != null &&
        saleDeed != null &&
        // extensionOfDateErr == false &&
        existingOnSite != null &&
        existingPublicRoad != null &&
        statusOfRoad != null &&
        workOnSite != null &&
        northDrop != null &&
        southDrop != null &&
        eastDrop != null &&
        westDrop != null &&
        NorthFileErr == false &&
        SouthFileErr == false &&
        EastFileErr == false &&
        WestFileErr == false
      ) {
        var form_data = new FormData();
        // form_data.append('user_application_id', 5);
        form_data.append("user_application_id", props.applicationID);
        form_data.append("proposed_use", proposedUse);
        form_data.append("proposed_activity_id", activity);
        form_data.append("plot_area_plan", perPlan);
        form_data.append("plot_area_document", perDocument);
        form_data.append("plot_area_ground", onGrounds);
        form_data.append("proposed_buildup_area", upArea);
        form_data.append("existing_buildup_area", exixtingBulidUpArea);
        form_data.append("road_widening", roadWinding);
        form_data.append("is_site_affecting_roads", roadRadio);
        let roadErr = 1;
        if (roadRadio == 1) {
          if (roadAffectedAreaErr == false) {
            form_data.append("road_affected_area", roadAffectedArea);
          } else {
            roadErr = roadErr + 1;
          }
          if (netPlotAreaErr == false) {
            form_data.append("net_plot_area", netPlotArea);
          } else {
            roadErr = roadErr + 1;
          }
          if (existingRoadWidthErr == false) {
            form_data.append("existing_road_width", existingRoadWidth);
          } else {
            roadErr = roadErr + 1;
          }
          if (proposedRoadWidthErr == false) {
            form_data.append("proposed_road_width", proposedRoadWidth);
          } else {
            roadErr = roadErr + 1;
          }
        }
        form_data.append("plot_width", plotWidth);
        form_data.append("plot_length", plotLength);
        form_data.append("no_of_blocks", noOfBlock);
        form_data.append("no_of_storey", noOfStoreysValue);
        form_data.append("storey_type", noOfStoreys);
        form_data.append("cellar", cellar);
        form_data.append("religious_structure", religiousStr);
        form_data.append("location_type", locationType);
        form_data.append("sub_location", subLocation);
        form_data.append("land_user_zone", landUseZone);

        //  new
        form_data.append("setback_relaxation", setBack);

        form_data.append("aerodrome_type", aerodromeType);
        form_data.append("aerodrome_distance", aeroDistance);
        form_data.append("plot_part_of", plotPart);
        var plotPartErr = 1;
        if (plotPart == 1 || plotPart == 2) {
          if (sanctionNoErr == false) {
            form_data.append("sanction_lrs_no", sanctionNo);
          } else {
            plotPartErr = plotPartErr + 1;
          }
          if (sanctionDateErr == false) {
            form_data.append("sanction_lrs_date", sanctionDate);
          } else {
            plotPartErr = plotPartErr + 1;
          }
        } else if (plotPart == 3) {
          if (lRSProceedingNoErr == false) {
            form_data.append("sanction_lrs_no", lRSProceedingNo);
          } else {
            plotPartErr = plotPartErr + 1;
          }
          if (lRSProceedingDateErr == false) {
            form_data.append("sanction_lrs_date", lRSProceedingDate);
          } else {
            plotPartErr = plotPartErr + 1;
          }
        } else if (plotPart == 5) {
          form_data.append("is_registration_date", registrationOfPlot);
        }

        form_data.append("sale_deed_executed", saleDeed);
        var saleDeedErr = 1;
        if (saleDeed == 1) {
          if (documentNoErr == false) {
            form_data.append("sale_deed_doc_no", documentNo);
          } else {
            saleDeedErr = saleDeedErr + 1;
          }
          // if (documentDateErr == false) {
          // } else {
          //   saleDeedErr = saleDeedErr + 1;
          // }
          if (revisedPermit != null) {
            form_data.append("sale_deed_revised_permit", revisedPermit);
          } else {
            saleDeedErr = saleDeedErr + 1;
          }

          if (expansion != null) {
            form_data.append("sale_deed_expansion", expansion);
            if (expansion == 1) {
              if (extensionOfDate) {
                form_data.append("extension", extensionOfDate);
              } else {
                saleDeedErr = saleDeedErr + 1;
              }
            } else if (expansion == 2) {
              if (documentDate) {
                form_data.append("sale_deed_doc_date", documentDate);
              } else {
                saleDeedErr = saleDeedErr + 1;
              }
            }
          }
        }
        // else if (saleDeed == 2) {
        //   if (extensionOfDate) {
        //     form_data.append("extension", extensionOfDate);
        //   } else {
        //     saleDeedErr = saleDeedErr + 1;
        //   }
        // }
        form_data.append("approach_road", existingOnSite);
        form_data.append("approach_road_connected", existingPublicRoad);
        var existingPublicRoadErr = 1;
        if (existingPublicRoad == 1) {
          if (publicRoadPlan != 0) {
            form_data.append("public_road_plan", publicRoadPlan);
          } else {
            existingPublicRoadErr = existingPublicRoadErr + 1;
          }
        }
        form_data.append("road_status", statusOfRoad);
        var statusOfRoadErr = 1;
        if (statusOfRoad == 2) {
          if (typeOfRoad != null) {
            form_data.append("road_type", typeOfRoad);
          } else {
            statusOfRoadErr = statusOfRoadErr + 1;
          }
          if (widthOfTheApproachRoadErr == false) {
            form_data.append("road_width", widthOfTheApproachRoad);
          } else {
            statusOfRoadErr = statusOfRoadErr + 1;
          }
        }
        form_data.append("work_commencement", workOnSite);
        form_data.append("north_by", northDrop);
        form_data.append("south_by", southDrop);
        form_data.append("west_by", westDrop);
        form_data.append("east_by", eastDrop);
        var form_data_image = new FormData();
        form_data_image.append("user_application_id", props.applicationID);
        var fileErr = 1;
        if (NorthFileErr == false) {
          form_data_image.append("boundries[0][type]", 1);
          form_data_image.append("boundries[0][image]", NorthFile);
        } else {
          fileErr = fileErr + 1;
        }
        if (SouthFileErr == false) {
          form_data_image.append("boundries[1][type]", 2);
          form_data_image.append("boundries[1][image]", SouthFile);
          // form_data.append('boundries[2]', { type: 2, image: SouthFile });
        } else {
          fileErr = fileErr + 1;
        }
        if (WestFileErr == false) {
          form_data_image.append("boundries[2][type]", 3);
          form_data_image.append("boundries[2][image]", WestFile);
          // form_data.append('boundries[3]', { type: 3, image: WestFile });
        } else {
          fileErr = fileErr + 1;
        }
        if (EastFileErr == false) {
          form_data_image.append("boundries[3][type]", 4);
          form_data_image.append("boundries[3][image]", EastFile);
          // form_data.append('boundries[4]', { type: 4, image: EastFile });
        } else {
          fileErr = fileErr + 1;
        }
        if (otherFilesErr == false) {
          for (let i = 0; i < otherFiles.length; i++) {
            form_data_image.append(`boundries[${4 + i}][type]`, 5);
            form_data_image.append(`boundries[${4 + i}][image]`, otherFiles[i]);
          }
          // form_data.append('boundries[4][type]', 5);
          // form_data.append('boundries[4][image]', otherFiles[0]);

          // for (let i = 0; i < otherFiles.length; i++) {
          //   form_data.append(`boundries[4][image][${i}]`, otherFiles[i]);
          // }
          // form_data.append('boundries[5]', { type: 5, image: otherFiles });
        }
        var valueErr = 1;
        if (northDrop != 4 && northDrop != 5) {
          if (NorthValueErr == false) {
            form_data.append("north_width", NorthValue);
          } else {
            valueErr = valueErr + 1;
          }
        }
        if (southDrop != 4 && southDrop != 5) {
          if (SouthValueErr == false) {
            form_data.append("south_width", SouthValue);
          } else {
            valueErr = valueErr + 1;
          }
        }
        if (westDrop != 4 && westDrop != 5) {
          if (WestValueErr == false) {
            form_data.append("west_width", WestValue);
          } else {
            valueErr = valueErr + 1;
          }
        }
        if (eastDrop != 4 && eastDrop != 5) {
          if (EastValueErr == false) {
            form_data.append("east_width", EastValue);
          } else {
            valueErr = valueErr + 1;
          }
        }
        if (
          plotPartErr == 1 &&
          saleDeedErr == 1 &&
          existingPublicRoadErr == 1 &&
          statusOfRoadErr == 1 &&
          fileErr == 1 &&
          valueErr == 1
        ) {
          ApplicationService.savePlotDetails(form_data)
            .then((result) => {
              ApplicationService.saveBoundaries(form_data_image)
                .then((result) => {
                  setDisable(true);
                  alert(result.data.message);
                })
                .catch(function (error) {
                  ErrorHandler(error);
                });
            })
            .catch(function (error) {
              ErrorHandler(error);
            });
        } else {
          alert("please fill all Details ?6");
        }
      } else {
        alert("please fill all Details ?5");
      }
    } else {
      alert("please fill GeneralInformation !");
    }
  };
  return (
    <Col className="col-12 prop-norm">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5 className="f14 mb-0">Main Details</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col lg={12} className="d-md-flex flex-wrap">
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Proposed Use*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    // disabled
                    value={proposedUse}
                    onChange={(e) => setProposedUse(e.target.value)}
                  >
                    <option className="f14 py-2" value={1}>
                      Residential
                    </option>
                    <option className="f15 py-2" value={2}>
                      Commercial
                    </option>
                    <option className="f15 py-2" value={3}>
                      Mixed
                    </option>
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Proposed Activity*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    // disabled
                    value={activity}
                    onChange={(e) => setActivity(e.target.value)}
                  >
                    <option value={null}>Select</option>
                    {activityList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1"> Plot Area As Per Plan (Sq.Mt.)*</p>
                </Col>
                <Col>
                  <input
                    type="number"
                    value={perPlan}
                    onChange={changePerPlan}
                    className="form-control px-2 py-1 rounded-0"
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">
                    Plot Area As per Documents (Sq.Mt.)*
                  </p>
                </Col>
                <Col>
                  <input
                    type="number"
                    value={perDocument}
                    onChange={changePerDocument}
                    className="form-control px-2 py-1 rounded-0"
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Plot Area As On Grounds (Sq.Mt.)*</p>
                </Col>
                <Col>
                  <input
                    type="number"
                    value={onGrounds}
                    onChange={changeOnGrounds}
                    className="form-control px-2 py-1 rounded-0"
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Proposed Built Up Area (Sq.Mt.)*</p>
                </Col>
                <Col>
                  <input
                    type="number"
                    value={upArea}
                    onChange={changeUpArea}
                    className="form-control px-2 py-1 rounded-0"
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Existing Built up Area (Sq.Mt.)*</p>
                </Col>
                <Col>
                  <input
                    type="number"
                    value={exixtingBulidUpArea}
                    onChange={changeExisitingBulidUpArea}
                    className="form-control px-2 py-1 rounded-0"
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Road Widening (Sq.Mt.)*</p>
                </Col>
                <Col>
                  <input
                    type="number"
                    value={roadWinding}
                    onChange={(e) => setRoadWinding(e.target.value)}
                    className="form-control px-2 py-1 rounded-0"
                  />
                </Col>
              </Col>
              <Col
                md={12}
                className="ps-2 py-1 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <p className="f13 mb-0 me-4">
                  Is site affecting under proposed master/SRDP/RDP/Layout Plan
                  roads?
                </p>
                <div className="d-flex justify-content-lg-center align-items-center">
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      name="roadRadio"
                      onClick={() => setroadRadio(1)}
                    />
                    <label className="ms-1 me-2 f14">Yes</label>
                  </div>
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      name="roadRadio"
                      onClick={() => setroadRadio(2)}
                    />
                    <label className="ms-1 f14">No</label>
                  </div>
                </div>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Plot Width (Mt.)*</p>
                </Col>
                <Col>
                  <input
                    type="number"
                    value={plotWidth}
                    onChange={changePlotWidth}
                    className="form-control px-2 py-1 rounded-0"
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Plot Length (Mt.)</p>
                </Col>
                <Col>
                  <input
                    type="number"
                    value={plotLength}
                    onChange={changePlotLength}
                    className="form-control px-2 py-1 rounded-0"
                  />
                </Col>
              </Col>
              {roadRadio == 1 && (
                <>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1">Road affected Area (Sq.Mt.) *</p>
                    </Col>
                    <Col>
                      <input
                        type="number"
                        value={roadAffectedArea}
                        onChange={changeRoadAffectedArea}
                        className="form-control px-2 py-1 rounded-0"
                      />
                    </Col>
                  </Col>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1">Net Plot Area (Sq.Mt.) *</p>
                    </Col>
                    <Col>
                      <input
                        type="number"
                        value={netPlotArea}
                        onChange={changeNetPlotArea}
                        className="form-control px-2 py-1 rounded-0"
                      />
                    </Col>
                  </Col>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1"> Existing Road Width (Mt.) *</p>
                    </Col>
                    <Col>
                      <input
                        type="number"
                        value={existingRoadWidth}
                        onChange={changeExistingRoadWidth}
                        className="form-control px-2 py-1 rounded-0"
                      />
                    </Col>
                  </Col>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1">Proposed Road Width (Mt.) *</p>
                    </Col>
                    <Col>
                      <input
                        type="number"
                        value={proposedRoadWidth}
                        onChange={changeProposedRoadWidth}
                        className="form-control px-2 py-1 rounded-0"
                      />
                    </Col>
                  </Col>
                </>
              )}
              <Col
                md={12}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">No Of Block*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={noOfBlock}
                    onChange={changeNoOfBlock}
                  >
                    <option value={null}>Select</option>
                    <option value="1">Single</option>
                    <option value="2">Multiple</option>
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">No. of Storey's</p>
                </Col>
                <Col className="d-flex">
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0 me-3"
                    value={noOfStoreys}
                    onChange={(e) => setNoOfStoreys(e.target.value)}
                  >
                    <option value={null}>Select</option>
                    <option value="1">G(Ground)+</option>
                    <option value="2">S(Stilt)+</option>
                  </Form.Select>
                  <input
                    type="number"
                    value={noOfStoreysValue}
                    onChange={changeNoOfStoreysValue}
                    className="form-control text-center px-2 py-1 rounded-0"
                    style={{ width: "45px" }}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">C(Cellar)</p>
                </Col>
                <Col className="d-flex">
                  <input
                    type="number"
                    value={cellar}
                    onChange={changeCellar}
                    className="form-control text-center px-2 py-1 rounded-0"
                    style={{ width: "45px" }}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Plot Nearby Religious Structures</p>
                </Col>
                <Col className="d-flex">
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={religiousStr}
                    onChange={changeReligiousStr}
                  >
                    <option value={null}>Select</option>
                    <option value="0">NA</option>
                    <option value="1">
                      In case of Sites located within a radius of 100m
                    </option>
                    <option value="2">
                      For the Sites located within a radius of above 100m and up
                      to 300m
                    </option>
                    <option value="3">For the Sites located above 300m</option>
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Location Type</p>
                </Col>
                <Col className="d-flex">
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={locationType}
                    onChange={changeLocationType}
                  >
                    <option value={null}>Select</option>
                    {locationTypeList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Sub Location</p>
                </Col>
                <Col className="d-flex">
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={subLocation}
                    onChange={changeSubLocation}
                  >
                    <option value={null}>Select</option>
                    {subLocationList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={12}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Land Use Zone</p>
                </Col>
                <Col className="d-flex">
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={landUseZone}
                    onChange={changeLandUseZone}
                  >
                    <option value="0">Select</option>
                    <option value={null}>NA</option>
                    <option value="1">Residential</option>
                    <option value="2">Commercial</option>
                    <option value="3">Industrial</option>
                    <option value="4">Assembly</option>
                    <option value="5">Public Utility</option>
                    <option value="6">Transportation and Communication</option>
                    <option value="7">Educational</option>
                    <option value="8">Medical</option>
                    <option value="9">Storage</option>
                    <option value="10">Institutional</option>
                    <option value="11">Resi + Commercial</option>
                  </Form.Select>
                </Col>
              </Col>

              {/* NEW  START */}

              <Col
                md={12}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Setback Relaxation</p>
                </Col>
                <Col className="d-flex">
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={setBack}
                    onChange={changeSetBack}
                  >
                    <option value="0">Select</option>
                    <option value="no">No</option>
                    <option value="builduparea">
                      Availing additional built-up area
                    </option>
                    <option value="setback">Availing Setback relaxation</option>
                  </Form.Select>
                </Col>
              </Col>

              {/* NEW  END */}

              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Aerodrome Type</p>
                </Col>
                <Col className="d-flex">
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={aerodromeType}
                    onChange={changeAerodromeType}
                  >
                    <option value="0">Select</option>
                    <option value="1"> NA </option>
                    <option value="2">
                      Height Restriction with respect to Approach Funnels
                    </option>
                    <option value="3">
                      Height Restriction with respect to Transitional Area
                    </option>
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Aero-distance</p>
                </Col>
                <Col className="d-flex">
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={aeroDistance}
                    onChange={(e) => setAeroDistance(e.target.value)}
                  >
                    <option value="0">Select</option>
                    {aerodromeDistanceList.length > 0 &&
                      aerodromeDistanceList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>

              <Col
                xs={12}
                md={12}
                className="ps-2 py-1 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center d-none d-sm-block"
              >
                <p className="f13 mb-0 me-4">Is this plot part of</p>
                <div className="d-flex justify-content-lg-center align-items-center">
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      name="plotPart"
                      onClick={() => setplotPart(1)}
                    />
                    <label className="ms-1 me-4 f14">Approved Layout</label>
                  </div>
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      name="plotPart"
                      onClick={() => setplotPart(2)}
                    />
                    <label className="ms-1 me-4 f14">Approved Building</label>
                  </div>
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      name="plotPart"
                      onClick={() => setplotPart(3)}
                    />
                    <label className="ms-1 me-4 f14">LRS Approved</label>
                  </div>
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      name="plotPart"
                      onClick={() => setplotPart(4)}
                    />
                    <label className="ms-1 me-4 f14">
                      Piece of land / Part of Survey Number
                    </label>
                  </div>
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      name="plotPart"
                      onClick={() => setplotPart(5)}
                    />
                    <label className="ms-1 me-2 f14">Unapproved Layout</label>
                  </div>
                </div>
              </Col>

              {/* <Col xs={12} md={12} className="ps-2 py-1 bg-lt-white-f7 col-md-6 mb-2 align-items-center d-block d-sm-none">
                <p className="f13 mb-0 me-4">Is this plot part of</p>
                <Row>
                  <Col xs={12}>
                    <input type="Radio" />
                    <label className="ms-1 me-4 f14">Approved Layout</label>
                  </Col>
                  <Col xs={12}>
                    <input type="Radio" />
                    <label className="ms-1 me-4 f14">Approved Building</label>
                  </Col>
                  <Col xs={12}>
                    <input type="Radio" />
                    <label className="ms-1 me-4 f14">LRS Approved</label>
                  </Col>
                  <Col xs={12}>
                    <input type="Radio" />
                    <label className="ms-1 me-4 f14">
                      Piece of land / Part of Survey Number
                    </label>
                  </Col>
                  <Col xs={12}>
                    <input type="Radio" />
                    <label className="ms-1 me-2 f14">Unapproved Layout</label>
                  </Col>
                </Row>
              </Col> */}
              {plotPart == 1 || plotPart == 2 ? (
                <>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1"> Sanction No.</p>
                    </Col>
                    <Col>
                      <input
                        value={sanctionNo}
                        onChange={changeSanctionNo}
                        className="form-control px-2 py-1 rounded-0"
                      />
                    </Col>
                  </Col>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1"> Sanction Date</p>
                    </Col>
                    <Col>
                      <input
                        type="date"
                        value={sanctionDate}
                        onChange={changeSanctionDate}
                        className="form-control px-2 py-1 rounded-0"
                      />
                    </Col>
                  </Col>
                </>
              ) : null}
              {plotPart == 3 && (
                <>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1"> LRS Proceeding No.</p>
                    </Col>
                    <Col>
                      <input
                        value={lRSProceedingNo}
                        onChange={changeLRSProceedingNo}
                        className="form-control px-2 py-1 rounded-0"
                      />
                    </Col>
                  </Col>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1">LRS Proceeding Date</p>
                    </Col>
                    <Col>
                      <input
                        type="date"
                        value={lRSProceedingDate}
                        onChange={changeLRSProceedingDate}
                        className="form-control px-2 py-1 rounded-0"
                      />
                    </Col>
                  </Col>
                </>
              )}
              {plotPart == 5 && (
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Date of Registration of plot</p>
                  </Col>
                  <Col className="d-flex">
                    <Form.Select
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                      value={registrationOfPlot}
                      onChange={(e) => setRegistrationOfPlot(e.target.value)}
                    >
                      <option value="1">Before 28th Oct 2015</option>
                      <option value="2">After 28th Oct 2015</option>
                    </Form.Select>
                  </Col>
                </Col>
              )}
            </Col>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5 className="f14 mb-0">Sale Deed Details</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col lg={12} className="d-md-flex flex-wrap">
              <Col
                md={12}
                className="ps-2 py-1 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <p className="f13 mb-0 me-4">Sale deed executed</p>
                <div className="d-flex justify-content-lg-center align-items-center">
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      name="saleDeed"
                      onClick={() => setSaleDeed(1)}
                    />
                    <label className="ms-1 me-4 f14">Yes</label>
                  </div>
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      name="saleDeed"
                      onClick={() => setSaleDeed(2)}
                    />
                    <label className="ms-1 me-4 f14">No</label>
                  </div>
                </div>
              </Col>
              {saleDeed == 1 && (
                <>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1">Document No.</p>
                    </Col>
                    <Col>
                      <input
                        value={documentNo}
                        onChange={changeDocumentNo}
                        className="form-control px-2 py-1 rounded-0"
                      />
                    </Col>
                  </Col>
                  {expansion != 1 ? (
                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    >
                      <Col>
                        <p className="f13 mb-1">Document Date</p>
                      </Col>
                      <Col>
                        <input
                          type="date"
                          // onKeyDown={(e) => e.preventDefault()}
                          // min={mindate}
                          // max={today}
                          value={documentDate}
                          onChange={changeDocumentDate}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    </Col>
                  ) : (
                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    ></Col>
                  )}
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1">Revised Permit </p>
                    </Col>
                    <Col>
                      <input
                        type="Radio"
                        name="revisedPermit"
                        onClick={() => setRevisedPermit(1)}
                      />
                      <label className="ms-1 me-4 f14">Yes</label>

                      <input
                        type="Radio"
                        name="revisedPermit"
                        onClick={() => setRevisedPermit(2)}
                      />
                      <label className="ms-1 me-4 f14">No</label>
                    </Col>
                  </Col>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-1">Extension</p>
                    </Col>
                    <Col>
                      <input
                        type="Radio"
                        name="expansion"
                        onClick={() => setExpansion(1)}
                      />
                      <label className="ms-1 me-4 f14">Yes</label>

                      <input
                        type="Radio"
                        name="expansion"
                        onClick={() => setExpansion(2)}
                      />
                      <label className="ms-1 me-4 f14">No</label>
                    </Col>
                  </Col>
                  {expansion == 1 && (
                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    >
                      <Col>
                        <p className="f13 mb-1">Extension of Date and Time*</p>
                      </Col>
                      <Col>
                        <input
                          type="date"
                          // onKeyDown={(e) => e.preventDefault()}
                          // min={today}
                          // max={maxdate}
                          value={extensionOfDate}
                          onChange={changeExtensionofDate}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    </Col>
                  )}
                </>
              )}
            </Col>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5 className="f14 mb-0">Site Details</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col lg={12} className="d-flex flex-wrap">
              <Col
                md={12}
                lg={6}
                className="ps-2 py-1 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0 me-4">
                    Approach Road , Whether existing on site
                  </p>
                </Col>
                <Col>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-lg-center align-items-center">
                      <input
                        type="Radio"
                        name="existingOnSite"
                        onClick={() => setExistingOnSite(1)}
                      />
                      <label className="ms-1 me-4 f14">Yes</label>
                    </div>
                    <div className="d-flex justify-content-lg-center align-items-center">
                      <input
                        type="Radio"
                        name="existingOnSite"
                        onClick={() => setExistingOnSite(2)}
                      />
                      <label className="ms-1 me-4 f14">No</label>
                    </div>
                  </div>
                </Col>
              </Col>
              <Col
                lg={6}
                className="ps-2 py-1 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center d-none d-lg-block"
              ></Col>
              <Col
                md={12}
                lg={6}
                className="ps-2 py-1 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0 me-4">
                    Whether Approached Road connected with existing Public Road
                    Plan
                  </p>
                </Col>
                <Col>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-lg-center align-items-center">
                      <input
                        type="Radio"
                        name="existingPublicRoad"
                        onClick={() => setExistingPublicRoad(1)}
                      />
                      <label className="ms-1 me-4 f14">Yes</label>
                    </div>
                    <div className="d-flex justify-content-lg-center align-items-center">
                      <input
                        type="Radio"
                        name="existingPublicRoad"
                        onClick={() => setExistingPublicRoad(2)}
                      />
                      <label className="ms-1 me-4 f14">No</label>
                    </div>
                  </div>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                {existingPublicRoad == 1 && (
                  <>
                    <Col>
                      <p className="f13 mb-0">Public Road Plan</p>
                    </Col>
                    <Col className="d-flex">
                      <Form.Select
                        style={{ fontSize: "15px" }}
                        aria-label="Default select example"
                        className="form-control px-2 py-1 rounded-0"
                        value={publicRoadPlan}
                        onChange={(e) => setPublicRoadPlan(e.target.value)}
                      >
                        <option value="0">Select</option>
                        <option value="NA">NA</option>
                      </Form.Select>
                    </Col>
                  </>
                )}
              </Col>
              <Col
                md={12}
                lg={6}
                className="ps-2 py-1 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0 me-4">Status of Road</p>
                </Col>
                <Col>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-lg-center align-items-center">
                      <input
                        type="Radio"
                        name="statusOfRoad"
                        onClick={() => setStatusOfRoad(1)}
                      />
                      <label className="ms-1 me-4 f14">Public</label>
                    </div>
                    <div className="d-flex justify-content-lg-center align-items-center">
                      <input
                        type="Radio"
                        name="statusOfRoad"
                        onClick={() => setStatusOfRoad(2)}
                      />
                      <label className="ms-1 me-4 f14">Private</label>
                    </div>
                  </div>
                </Col>
              </Col>
              <Col
                lg={6}
                className="ps-2 py-1 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center d-none d-lg-block"
              ></Col>
              <Col
                md={12}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                {statusOfRoad == 2 && (
                  <>
                    <Col md={6}>
                      <Col className="pe-md-1">
                        <Col>
                          <p className="f13 mb-0">Type of Road</p>
                        </Col>
                        <Col className="d-flex">
                          <Form.Select
                            style={{ fontSize: "15px" }}
                            aria-label="Default select example"
                            className="form-control px-2 py-1 rounded-0"
                            value={typeOfRoad}
                            onChange={(e) => setTypeOfRoad(e.target.value)}
                          >
                            <option value={null}>Select</option>
                            <option value="0">NA</option>
                            <option value="1">Kutchha/Gravel </option>
                            <option value="2">WBM </option>
                            <option value="3">Blacktop</option>
                            <option value="4">Concrete </option>
                          </Form.Select>
                        </Col>
                      </Col>
                    </Col>
                    <Col md={6}>
                      <Col className="ps-md-1">
                        <Col>
                          <p className="f13 mb-0">
                            Width of the Approach Road in Mt.
                          </p>
                        </Col>
                        <Col>
                          <input
                            type="number"
                            value={widthOfTheApproachRoad}
                            onChange={changeWidthOfTheApproachRoad}
                            className="form-control px-2 py-1 rounded-0"
                          />
                        </Col>
                      </Col>
                    </Col>
                  </>
                )}
              </Col>
              <Col
                md={12}
                lg={6}
                className="ps-2 py-1 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0 me-4">Commencement Of Work On Site</p>
                </Col>
                <Col>
                  <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-lg-center align-items-center">
                      <input
                        type="Radio"
                        name="workOnSite"
                        onClick={() => setWorkOnSite(1)}
                      />
                      <label className="ms-1 me-4 f14">Yes</label>
                    </div>
                    <div className="d-flex justify-content-lg-center align-items-center">
                      <input
                        type="Radio"
                        name="workOnSite"
                        onClick={() => setWorkOnSite(2)}
                      />
                      <label className="ms-1 me-4 f14">No</label>
                    </div>
                  </div>
                </Col>
              </Col>
              <Col
                lg={6}
                className="ps-2 py-1 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center d-none d-lg-block"
              ></Col>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5 className="f14 mb-0">Schedule of boundaries</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col lg={12} className="">
              <Col xs={12} className="d-md-flex flex-wrap">
                <Col
                  md={6}
                  lg={4}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">North</p>
                  </Col>
                  <Col className="d-flex">
                    <Form.Select
                      value={northDrop}
                      onChange={(e) => setNorthDrop(e.target.value)}
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                    >
                      {/* <option value={null}>----- Select ----</option> */}
                      <option value={1}>Road</option>
                      <option value={2}>Plot</option>
                      <option value={3}>Survey</option>
                      <option value={4}>Vacant land</option>
                      <option value={5}>Existing building</option>
                      <option value={6}>Others</option>
                    </Form.Select>
                  </Col>
                </Col>
                <Col
                  md={6}
                  lg={8}
                  className="d-flex flex-wrap justify-content-between ps-4 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col md={7}>
                    {northDrop == 1 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">Width</p>
                        <input
                          type="number"
                          value={NorthValue}
                          onChange={changeNorthValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                        <p className="f13 mb-0 width-appl-cat ms-3">Mt.</p>
                      </Col>
                    )}
                    {northDrop == 2 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">No</p>
                        <input
                          type="number"
                          value={NorthValue}
                          onChange={changeNorthValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                    {northDrop == 3 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">No</p>
                        <input
                          type="number"
                          value={NorthValue}
                          onChange={changeNorthValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                    {northDrop == 6 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">Remarks</p>
                        <input
                          type="text"
                          value={NorthValue}
                          onChange={changeNorthValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                  </Col>
                  <Col
                    md={5}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 mb-2 align-items-center justify-content-between"
                  >
                    {/* <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                    // onClick={handleClick}
                    >
                      Photograph
                    </Button> */}
                    <input
                      onChange={changeNorthFile}
                      type="file"
                      accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                      // type="file"
                      // ref={hiddenFileInput}
                      // onChange={handleChange}
                      // style={{ display: "none" }}
                    />
                  </Col>
                </Col>
              </Col>
              <Col xs={12} className="d-md-flex flex-wrap">
                <Col
                  md={6}
                  lg={4}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">South</p>
                  </Col>
                  <Col className="d-flex">
                    <Form.Select
                      value={southDrop}
                      onChange={(e) => setSouthDrop(e.target.value)}
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                    >
                      <option value={1}>Road</option>
                      <option value={2}>Plot</option>
                      <option value={3}>Survey</option>
                      <option value={4}>Vacant land</option>
                      <option value={5}>Existing building</option>
                      <option value={6}>Others</option>
                    </Form.Select>
                  </Col>
                </Col>
                <Col
                  md={6}
                  lg={8}
                  className="d-flex flex-wrap justify-content-between ps-4 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col md={7}>
                    {southDrop == 1 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">Width</p>
                        <input
                          type="number"
                          value={SouthValue}
                          onChange={changeSouthValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                        <p className="f13 mb-0 width-appl-cat ms-3">Mt.</p>
                      </Col>
                    )}
                    {southDrop == 2 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">No</p>
                        <input
                          type="number"
                          value={SouthValue}
                          onChange={changeSouthValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                    {southDrop == 3 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">No</p>
                        <input
                          type="number"
                          value={SouthValue}
                          onChange={changeSouthValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                    {southDrop == 6 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">Remarks</p>
                        <input
                          type="text"
                          value={SouthValue}
                          onChange={changeSouthValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                  </Col>
                  <Col
                    md={5}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 mb-2 align-items-center justify-content-between"
                  >
                    {/* <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                    // onClick={handleClick}
                    >
                      Photograph
                    </Button> */}
                    <input
                      onChange={changeSouthFile}
                      type="file"
                      accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                      // type="file"
                      // ref={hiddenFileInput}
                      // onChange={handleChange}
                      // style={{ display: "none" }}
                    />
                  </Col>
                </Col>
              </Col>
              <Col xs={12} className="d-md-flex flex-wrap">
                <Col
                  md={6}
                  lg={4}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">West</p>
                  </Col>
                  <Col className="d-flex">
                    <Form.Select
                      value={westDrop}
                      onChange={(e) => setWestDrop(e.target.value)}
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                    >
                      <option value={1}>Road</option>
                      <option value={2}>Plot</option>
                      <option value={3}>Survey</option>
                      <option value={4}>Vacant land</option>
                      <option value={5}>Existing building</option>
                      <option value={6}>Others</option>
                    </Form.Select>
                  </Col>
                </Col>
                <Col
                  md={6}
                  lg={8}
                  className="d-flex flex-wrap justify-content-between ps-4 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col md={7}>
                    {westDrop == 1 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">Width</p>
                        <input
                          type="number"
                          value={WestValue}
                          onChange={changeWestValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                        <p className="f13 mb-0 width-appl-cat ms-3">Mt.</p>
                      </Col>
                    )}
                    {westDrop == 2 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">No</p>
                        <input
                          type="number"
                          value={WestValue}
                          onChange={changeWestValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                    {westDrop == 3 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">No</p>
                        <input
                          type="number"
                          value={WestValue}
                          onChange={changeWestValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                    {westDrop == 6 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">Remarks</p>
                        <input
                          type="text"
                          value={WestValue}
                          onChange={changeWestValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                  </Col>
                  <Col
                    md={5}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 mb-2 align-items-center justify-content-between"
                  >
                    {/* <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                    // onClick={handleClick}
                    >
                      Photograph
                    </Button> */}
                    <input
                      onChange={changeWestFile}
                      type="file"
                      accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                      // type="file"
                      // ref={hiddenFileInput}
                      // onChange={handleChange}
                      // style={{ display: "none" }}
                    />
                  </Col>
                </Col>
              </Col>
              <Col xs={12} className="d-md-flex flex-wrap">
                <Col
                  md={6}
                  lg={4}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">East</p>
                  </Col>
                  <Col className="d-flex">
                    <Form.Select
                      onChange={(e) => setEastDrop(e.target.value)}
                      style={{ fontSize: "15px" }}
                      value={eastDrop}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                    >
                      <option value={1}>Road</option>
                      <option value={2}>Plot</option>
                      <option value={3}>Survey</option>
                      <option value={4}>Vacant land</option>
                      <option value={5}>Existing building</option>
                      <option value={6}>Others</option>
                    </Form.Select>
                  </Col>
                </Col>
                <Col
                  md={6}
                  lg={8}
                  className="d-flex flex-wrap justify-content-between ps-4 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col md={7}>
                    {eastDrop == 1 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">Width</p>
                        <input
                          type="number"
                          value={EastValue}
                          onChange={changeEastValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                        <p className="f13 mb-0 width-appl-cat ms-3">Mt.</p>
                      </Col>
                    )}
                    {eastDrop == 2 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">No</p>
                        <input
                          type="number"
                          value={EastValue}
                          onChange={changeEastValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                    {eastDrop == 3 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">No</p>
                        <input
                          type="number"
                          value={EastValue}
                          onChange={changeEastValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                    {eastDrop == 6 && (
                      <Col className="d-flex justify-content-between align-items-center">
                        <p className="f13 mb-1 width-appl-cat me-3">Remarks</p>
                        <input
                          type="text"
                          value={EastValue}
                          onChange={changeEastValue}
                          className="form-control px-2 py-1 rounded-0"
                        />
                      </Col>
                    )}
                  </Col>
                  <Col
                    md={5}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 mb-2 align-items-center justify-content-between"
                  >
                    {/* <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                    // onClick={handleClick}
                    >
                      Photograph
                    </Button> */}
                    <input
                      onChange={changeEastFile}
                      type="file"
                      accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                      // type="file"
                      // ref={hiddenFileInput}
                      // onChange={handleChange}
                      // style={{ display: "none" }}
                    />
                  </Col>
                </Col>
              </Col>

              <Col
                md={12}
                lg={4}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center justify-content-between"
              >
                <p className="f13 mb-0">Others </p>
                {/* <Button
                  style={{ fontSize: "14px" }}
                  className="bg-transparent border-0 text-primary"
                // onClick={handleClick}
                >
                  Photographs
                </Button> */}
                <input
                  onChange={changeOtherFiles}
                  multiple
                  type="file"
                  accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                  // type="file"
                  // ref={hiddenFileInput}
                  // onChange={handleChange}
                  // style={{ display: "none" }}
                />
              </Col>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Col className="d-flex justify-content-center mb-2">
        <Button
          onClick={save}
          className="tab-blu-back"
          style={{
            color: "#fff",
            fontSize: 15,
            marginTop: 10,
            padding: "6px 14px",
            backgroundColor: "#5593db",
          }}
        >
          <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col>
    </Col>
  );
};

export default PlotDetails;
