import { faFileAlt, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { change, Field, FieldArray, reduxForm } from "redux-form";
// import validate from './validate';
import { useDispatch } from "react-redux";
import ApplicationService from "../../services/service/ApplictionService";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";
const BuildingDetailsEdit = (props) => {
  const dispatch = useDispatch();
  const { handleSubmit, pristine, reset, submitting } = props;
  const [tempArray, setTempArray] = useState([]);
  const [row, setRow] = useState([]);
  const [update, setUpdate] = useState(false);
  const [data, setData] = useState([]);
  const [stilt, setStilt] = useState("");
  const [cellars, setCellars] = useState("");
  const addNewRow = () => {
    var temp = [...row];
    temp.push(row.length);
    setRow(temp);
  };
  const [useList, setUseList] = useState([]);
  const [subUseList, setSubUseList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  const [name, setName] = useState("");
  const [typeNo, setTypeNo] = useState("");
  const [uses, setUses] = useState("");
  const [subUses, setSubUses] = useState("");
  const [types, setTypes] = useState("");
  const [heights, setHeights] = useState("");
  const [parkings, setParkings] = useState("");
  const [cellar, setCellar] = useState("");

  // NO OF FLOORS

  const [floorName, setFloorName] = useState({});
  const [floorUse, setFloorUse] = useState({});
  const [floorHeight, setFloorHeight] = useState({});
  const [floorTotal, setFloorTotal] = useState({});

  const onFloorNameChange = (e, index) => {
    const value = e.target.value;
    setFloorName((pre) => ({ ...pre, [index]: value }));
  };
  const onFloorUseChange = (e, index) => {
    const value = e.target.value;
    setFloorUse((pre) => ({ ...pre, [index]: value }));
  };
  const onFloorHeightChange = (e, index) => {
    const value = e.target.value;
    setFloorHeight((pre) => ({ ...pre, [index]: value }));
  };
  const onFloorTotalChange = (e, index) => {
    const value = e.target.value;
    setFloorTotal((pre) => ({ ...pre, [index]: value }));
  };

  useEffect(() => {
    ApplicationService.getUseList()
      .then((result) => {
        setUseList(result.data.building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const changeUse = (e) => {
    if (e.target.value != "" && e.target.value != 0) {
      getSubList(e.target.value);
    }
  };
  const changeSubUse = (e) => {
    if (e.target.value != "" && e.target.value != 0) {
      getTypeList(e.target.value);
    }
  };
  const getSubList = (use) => {
    ApplicationService.getSubUseList(use)
      .then((result) => {
        setSubUseList(result.data.sub_building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const getTypeList = (sub_use) => {
    ApplicationService.getSubUseList(sub_use)
      .then((result) => {
        setTypeList(result.data?.sub_building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const changeNoofFloor = (e) => {
    let temp = [];
    for (let i = 0; i < e.target.value; i++) {
      temp.push(i);
    }
    setTempArray(temp);
  };
  useEffect(() => {
    getDetails();
  }, []);
  const [list, setList] = useState([]);
  const getDetails = () => {
    ApplicationService.viewApplication({
      user_application_id: props.data.id,
      type: 5,
    })
      .then((result) => {
        if (result.data.user_application_building_details) {
          setList(result?.data?.user_application_building_details);
        }
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const save = (values) => {
    if (update) {
      var payload = values;
      payload.user_application_id = props.data.id;
      ApplicationUpdateService.updateBulidingDetails(data.id, payload)
        .then((result) => {
          alert(result.data.message);
          getDetails();
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      var payload = values;
      payload.user_application_id = props.data.id;
      ApplicationService.saveBuildingDetails(payload)
        .then((result) => {
          alert(result.data.message);
          getDetails();
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }
  };
  const renderField = ({
    disabled,
    input,
    label,
    type,
    meta: { touched, error },
  }) => (
    <div>
      {/* <label>{label}</label> */}
      <div>
        <input
          style={{
            width: "100%",
            border: "1px solid #c5c5c5",
            padding: "3px",
          }}
          {...input}
          type={type}
          placeholder={label}
          disabled={disabled}
        />
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
      </div>
    </div>
  );
  const renderSelectField = ({
    disabled,
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <div>
      {/* <label>{label}</label> */}
      <div>
        <select
          style={{
            width: "100%",
            border: "1px solid #c5c5c5",
            padding: "3px",
            backgroundColor: "#fff",
          }}
          disabled={disabled}
          {...input}
        >
          {children}
        </select>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
      </div>
    </div>
  );
  const renderMembers = ({
    fields,
    meta: { touched, error, submitFailed },
  }) => (
    <ul>
      {tempArray.map((member, index) => (
        <li key={index}>
          <h6>Floor #{index + 1}</h6>
          <Col className="d-md-flex flex-wrap mt-1">
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Floor Name*</p>
              </Col>
              <Col>
                <Field
                  disabled
                  name={`floors[${member}].floor_name`}
                  type="text"
                  component={renderField}
                  label="Floor Name"
                />
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Height (m)</p>
              </Col>
              <Col>
                <Field
                  disabled
                  name={`floors[${member}].height`}
                  type="number"
                  component={renderField}
                  label="Height"
                />
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Use</p>
              </Col>
              <Col>
                <Field
                  disabled
                  name={`floors[${member}].use`}
                  component={renderSelectField}
                >
                  <option className="f14 py-2" value="0">
                    Select
                  </option>
                  {useList.length > 0 &&
                    useList.map((item, index) => {
                      return (
                        <option
                          key={index}
                          className="f14 py-2"
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </Field>
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Total Floor Area</p>
              </Col>
              <Col>
                <Field
                  disabled
                  name={`floors[${member}].total_floor_area`}
                  type="number"
                  component={renderField}
                  label="Total Floor Area"
                />
              </Col>
            </Col>
          </Col>
        </li>
      ))}
    </ul>
  );
  return (
    <fieldset disabled>
      <form>
        <Col className="rt-dashboard px-3">
          <Col className="d-md-flex justify-content-between align-items-center px-3">
            <Col className="d-flex justify-content-md-end">
              <Link
                to="/application-permission"
                style={{
                  color: "#2f6db5",
                  fontSize: 13,
                }}
              ></Link>
            </Col>
          </Col>
          {list?.map((i, index) => (
            <Col className="customers mb-3 mt-3">
              <li key={index}>
                <h4>Building #{index + 1}</h4>

                <Col
                  style={{
                    border: "1px solid #e9e9e9",
                    padding: 15,
                    marginBottom: 10,
                  }}
                  xs={12}
                  className="d-md-flex flex-wrap"
                >
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-0">Name*</p>
                    </Col>
                    <Col>
                      <input
                        name={"name"}
                        type="text"
                        label="Name"
                        className="form-control px-2 py-1 rounded-0"
                        // onChange={(e) => setName(e.target.value)}
                        value={i?.name}
                      />
                    </Col>
                  </Col>

                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-0">Use*</p>
                    </Col>
                    <Col>
                      <Form.Select
                        style={{ fontSize: "15px" }}
                        aria-label="Default select example"
                        className="form-control px-2 py-1 rounded-0"
                        value={i?.subUses?.id}
                        // onChange={(e) => {
                        //   changeSubUse(e);
                        //   setSubUses(e.target.value);
                        // }}
                      >
                        <option
                          className="f14 py-2"
                          value={i?.use?.name}
                          selected
                        >
                          {i?.use?.name}
                        </option>
                      </Form.Select>
                    </Col>
                  </Col>

                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-0">Sub Use*</p>
                    </Col>
                    <Col>
                      <Form.Select
                        style={{ fontSize: "15px" }}
                        aria-label="Default select example"
                        className="form-control px-2 py-1 rounded-0"
                        value={i?.subUses?.id}
                        // onChange={(e) => {
                        //   changeSubUse(e);
                        //   setSubUses(e.target.value);
                        // }}
                      >
                        <option className="f14 py-2" value="0" selected>
                          {i?.sub_use?.name}
                        </option>
                      </Form.Select>
                    </Col>
                  </Col>

                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-0">Type*</p>
                    </Col>
                    <Col>
                      <Form.Select
                        style={{ fontSize: "15px" }}
                        aria-label="Default select example"
                        className="form-control px-2 py-1 rounded-0"
                        value={i?.type?.id}
                        // onChange={(e) => setTypes(e.target.value)}
                      >
                        <option className="f14 py-2" value="0" selected>
                          {i?.type?.name}
                        </option>
                        {typeList.length > 0 &&
                          typeList.map((item, index) => {
                            return (
                              <option
                                key={index}
                                className="f14 py-2"
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Col>
                  </Col>

                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-0">Type Number of dwelling units*</p>
                    </Col>
                    <Col>
                      <Col>
                        <input
                          name={"dwelling_units"}
                          type="number"
                          className="form-control px-2 py-1 rounded-0"
                          // onChange={(e) => setTypeNo(e.target.value)}
                          value={i?.dwelling_units}
                        />
                      </Col>
                    </Col>
                  </Col>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-0">
                        Height excluding Stilt / Parking*
                      </p>
                    </Col>
                    <Col>
                      <input
                        name={"height_excluding_slit"}
                        type="number"
                        // disabled={cellars ? true : false}
                        className="form-control px-2 py-1 rounded-0"
                        // onChange={(e) => setStilt(e.target.value)}
                        value={i?.height_excluding_slit}
                      />
                    </Col>
                  </Col>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-0">Height excluding Cellars*</p>
                    </Col>
                    <Col>
                      <input
                        name={"height_excluding_celler"}
                        type="number"
                        // disabled={stilt ? true : false}
                        className="form-control px-2 py-1 rounded-0"
                        // onChange={(e) => setCellars(e.target.value)}
                        value={i?.height_excluding_celler}
                      />
                    </Col>
                  </Col>
                  <Col
                    md={6}
                    className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                  >
                    <Col>
                      <p className="f13 mb-0">No. Of Floors*</p>
                    </Col>
                    <Col>
                      <input
                        name={"no_of_floors"}
                        type="number"
                        className="form-control px-2 py-1 rounded-0"
                        // component={renderField}
                        label="No of Floors"
                        // onChange={changeNoofFloor}
                        value={i?.no_of_floors}
                      />
                    </Col>
                  </Col>
                </Col>
                {i?.user_application_building_floors?.map((f, indexs) => (
                  <li key={indexs}>
                    <h4>Floor #{indexs + 1}</h4>
                    <Col className="d-md-flex flex-wrap mt-1">
                      <Col
                        md={6}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                      >
                        <Col>
                          <p className="f13 mb-0">Floor Name*</p>
                        </Col>
                        <Col>
                          <input
                            name="floor_name"
                            type="text"
                            label="Floor Name"
                            value={f?.floor_name}
                            className="form-control px-2 py-1 rounded-0"
                            // onChange={(e) => onFloorNameChange(e, index)}
                            // value={cellars}
                          />
                        </Col>
                      </Col>
                      <Col
                        md={6}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                      >
                        <Col>
                          <p className="f13 mb-0">Height (m)</p>
                        </Col>
                        <Col>
                          <input
                            name="height"
                            type="number"
                            label="Height"
                            value={f?.height}
                            className="form-control px-2 py-1 rounded-0"
                            // onChange={(e) => onFloorHeightChange(e, index)}
                            // value={cellars}
                          />
                        </Col>
                      </Col>
                      <Col
                        md={6}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                      >
                        <Col>
                          <p className="f13 mb-0">Use</p>
                        </Col>
                        <Col>
                          <Form.Select
                            name="use"
                            // component={renderSelectField}
                            // onChange={(e) => onFloorUseChange(e, index)}
                            value={f?.use?.id}
                          >
                            <option className="f14 py-2" value="0">
                              Select
                            </option>
                            {useList.length > 0 &&
                              useList.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    className="f14 py-2"
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Col>
                      </Col>
                      <Col
                        md={6}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                      >
                        <Col>
                          <p className="f13 mb-0">Total Floor Area</p>
                        </Col>
                        <Col>
                          <input
                            name="total_floor_area"
                            type="number"
                            label="Total Floor Area"
                            value={f?.total_floor_area}
                            className="form-control px-2 py-1 rounded-0"
                            // onChange={(e) => onFloorTotalChange(e, index)}
                            // value={cellars}
                          />
                        </Col>
                      </Col>
                    </Col>
                  </li>
                ))}

                {/* <Col className="d-flex justify-content-center mb-2">
        <Button
          type="button"
          className="tab-blu-back"
          onClick={save}
          style={{
            color: "#fff",
            fontSize: 15,
            marginTop: 10,
            padding: "6px 14px",
            backgroundColor: "#5593db",
          }}
        >
          <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col> */}
              </li>
            </Col>
          ))}
        </Col>
      </form>
    </fieldset>
  );
};
export default reduxForm({
  form: "BuildingDetailsEditForm", // a unique identifier for this form
  // validate
})(BuildingDetailsEdit);
