import React, { useState } from "react";
import { Button, Col, Form, Modal, Pagination, Table, Row, Dropdown, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faFileExcel,
  faSort,
  faSyncAlt,
  faTh,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { components } from "react-select";
import { Chart } from "react-charts";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const JoinInspectionReport = () => {
  const [optionSelected, setOptionSelected] = useState(null);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const [show, setShow] = useState(false);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const colourOptions = [
    { value: "ocean", label: "Ocean", color: "#00B8D9" },
    { value: "blue", label: "Blue", color: "#0052CC" },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630" },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
  ];
  const MultiValue = (props) => (
    <components.MultiValue {...props}></components.MultiValue>
  );
  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  //  ********** Chart Scriprt files  ***************
  
  const options = {
    responsive: true,
    indexAxis: 'y',
    plugins: {
      title: {
        display: false,
        text: 'Chart.js Bar Chart',
      },
      legend: {
        display: false
      },
    },
  };

  const labels = ['< = 48 Hours',  '> 48 Hours'];

  const data = {
    labels,
    datasets: [
      {
        labels: ['< = 48 Hours',  '> 48 Hours'],
        barPercentage: 0.5,
        barThickness: 6,
        maxBarThickness: 8,
        minBarLength: 2,
        data: [0, 0],
        backgroundColor: ['#036', '#ff0', '#ffa500', '#00cc55', '#f00', '#c00000'],
        hoverBackgroundColor: ['#194c7f', '#e1e119', '#e1c019', '#19e578', '#e11919', '#d91919'],

      }
    ],
  };

  //  ********** END Chart Scriprt files  ***************


  return (
    <Col className="rt-dashboard  mt-3">
      <Col className="col-12 mt-3">
        <div className="d-flex justify-content-lg-start align-items-center ms-4">
          <input type="Radio" />
          <label className="ms-1 me-2 f14">
            <strong>
              Building Permission
            </strong>
          </label>
          <input type="Radio" />
          <label className="ms-1 f14">
            <strong>
              Occupancy Certificate
            </strong>
          </label>
        </div>

      </Col>
      <Row>
        <Col className="col-12 col-md-10 col-lg-6">
          <fieldset>
            <legend>Status of Building Permission</legend>
            <Bar options={options} data={data} width={100} height={20}  />
          </fieldset>
        </Col>
      </Row>

      <Row>
        <Col className="col-12 col-md-12 mt-3">
          <fieldset>
            <legend>Pendency details:  7 days (1)</legend>
            <div style={{ maxHeight: "350px", Height: "320px", overflowY: 'auto' }}>
            <Table>
              <thead className="sticky-top">
                <tr className="bg-light">
                  <th className="f14 fw-normal black cursor-pointer">
                    sr.No
                    <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                    File No <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                    Proposal Date<FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                    Inspection Officer. <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                    Inspection Team. <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                    Inspection Done Date <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                    Inspection Report uploaded Date  <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                    Inspection Report  <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                    Join Inspection Report  <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                </tr>

                <tr className="bg-white">
                  <th className="f14 fw-normal black cursor-pointer"></th>

                  <th className="f14 fw-normal black cursor-pointer py-1">
                    <Dropdown as={ButtonGroup} className="" align="end">
                      <Form.Control type="text" placeholder="search" className="py-0" />
                      <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="filter-dropdown">
                        <div className="px-0">
                          <div className="filter-dropdown-item">
                            <Row className="py-2 border-bottom">
                              <Col md={10} className="text-start pe-0">
                                All
                              </Col>
                              <Col md={2} className="text-end ps-0">
                                <Form.Check aria-label="option 1" />
                              </Col>
                            </Row>
                          </div>

                          <Row className="py-2">
                            <Col md={8} className="text-start ps-2 pe-0">
                              <Button variant="secondary" size="sm">
                                Select All
                              </Button>
                            </Col>
                            <Col md={4} className="text-end ps-0">
                              <Button variant="secondary" size="sm">
                                Ok
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>

                  <th className="f14 fw-normal black cursor-pointer py-1">
                    <Dropdown as={ButtonGroup} className="" align="end">
                      <Form.Control type="text" placeholder="search" className="py-0" />
                      <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="filter-dropdown">
                        <div className="px-0">
                          <div className="filter-dropdown-item">
                            <Row className="py-2 border-bottom">
                              <Col md={10} className="text-start pe-0">
                                All
                              </Col>
                              <Col md={2} className="text-end ps-0">
                                <Form.Check aria-label="option 1" />
                              </Col>
                            </Row>
                          </div>

                          <Row className="py-2">
                            <Col md={8} className="text-start ps-2 pe-0">
                              <Button variant="secondary" size="sm">
                                Select All
                              </Button>
                            </Col>
                            <Col md={4} className="text-end ps-0">
                              <Button variant="secondary" size="sm">
                                Ok
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>

                  <th className="f14 fw-normal black cursor-pointer py-1">
                    <Dropdown as={ButtonGroup} className="" align="end">
                      <Form.Control type="text" placeholder="search" className="py-0" />
                      <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="filter-dropdown">
                        <div className="px-0">
                          <div className="filter-dropdown-item">
                            <Row className="py-2 border-bottom">
                              <Col md={10} className="text-start pe-0">
                                All
                              </Col>
                              <Col md={2} className="text-end ps-0">
                                <Form.Check aria-label="option 1" />
                              </Col>
                            </Row>
                          </div>

                          <Row className="py-2">
                            <Col md={8} className="text-start ps-2 pe-0">
                              <Button variant="secondary" size="sm">
                                Select All
                              </Button>
                            </Col>
                            <Col md={4} className="text-end ps-0">
                              <Button variant="secondary" size="sm">
                                Ok
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>

                  <th className="f14 fw-normal white cursor-pointer py-1">
                    <Dropdown as={ButtonGroup} className="" align="end">
                      <Form.Control type="text" placeholder="search" className="py-0" />
                      <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="filter-dropdown bg-light">
                        <Dropdown.Item className="filter-dropdown-item">Off</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Equal</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Not Equal</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Less than</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Less tha or Equal</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Greater than</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">greater than or Equal</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Begins With</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Do not Begins with</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Ends With</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Do not Ends with</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Contains</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Do not Contains</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>

                  <th className="f14 fw-normal black cursor-pointer py-1">
                    <Dropdown as={ButtonGroup} className="" align="end">
                      <Form.Control type="text" placeholder="search" className="py-0" />
                      <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="filter-dropdown">
                        <div className="px-0">
                          <div className="filter-dropdown-item">
                            <Row className="py-2 border-bottom">
                              <Col md={10} className="text-start pe-0">
                                All
                              </Col>
                              <Col md={2} className="text-end ps-0">
                                <Form.Check aria-label="option 1" />
                              </Col>
                            </Row>
                          </div>

                          <Row className="py-2">
                            <Col md={8} className="text-start ps-2 pe-0">
                              <Button variant="secondary" size="sm">
                                Select All
                              </Button>
                            </Col>
                            <Col md={4} className="text-end ps-0">
                              <Button variant="secondary" size="sm">
                                Ok
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>
                  <th className="f14 fw-normal black cursor-pointer py-1">
                    <Dropdown as={ButtonGroup} className="" align="end">
                      <Form.Control type="text" placeholder="search" className="py-0" />
                      <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="filter-dropdown">
                        <div className="px-0">
                          <div className="filter-dropdown-item">
                            <Row className="py-2 border-bottom">
                              <Col md={10} className="text-start pe-0">
                                All
                              </Col>
                              <Col md={2} className="text-end ps-0">
                                <Form.Check aria-label="option 1" />
                              </Col>
                            </Row>
                          </div>

                          <Row className="py-2">
                            <Col md={8} className="text-start ps-2 pe-0">
                              <Button variant="secondary" size="sm">
                                Select All
                              </Button>
                            </Col>
                            <Col md={4} className="text-end ps-0">
                              <Button variant="secondary" size="sm">
                                Ok
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>

                  <th className="f14 fw-normal black cursor-pointer"></th>

                  <th className="f14 fw-normal white cursor-pointer py-1">
                    <Dropdown as={ButtonGroup} className="" align="end">
                      <Form.Control type="text" placeholder="search" className="py-0" />
                      <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="filter-dropdown">
                        <Dropdown.Item className="filter-dropdown-item">Off</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Equal</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Not Equal</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Less than</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Less tha or Equal</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Greater than</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">greater than or Equal</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Begins With</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Do not Begins with</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Ends With</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Do not Ends with</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Contains</Dropdown.Item>
                        <Dropdown.Item className="filter-dropdown-item">Do not Contains</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-light">
                <tr>
                  <td className="f14 c4">1.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">Prakasham</td>
                  <td className="f14 c4">Prakasham Team</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 c4">02/12/2021</td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                  <td className="f14 text-primary text-center">
                    <Link to="/join-inspection-report-view" target="_blank" className="bg-light text-primary">view</Link>
                  </td>
                </tr>
              </tbody>
            </Table>
            </div>

            <Row>
              <Col className="d-flex  " style={{ background: "#f7f7f7", width: "100%" }}>
                <Button className="border-0 bg-transparent">
                  <FontAwesomeIcon
                    style={{
                      color: "green",
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    icon={faFileExcel}
                  />
                </Button>

                <Pagination className="mb-0">{items}</Pagination>
              </Col>

              <Col className="text-end" style={{ background: "#f7f7f7", width: "100%" }}>
                <input type="Checkbox" />
                <label className="ms-1 me-2 f14">
                  Group By Inspection Officer
                </label>
              </Col>
            </Row>

          </fieldset>
        </Col>
      </Row>
    </Col>
  );
};

export default JoinInspectionReport;
