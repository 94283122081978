const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.use || values.use == 0) {
    errors.use = "Required";
  }
  if (!values.sub_use || values.sub_use == 0) {
    errors.sub_use = "Required";
  }
  if (!values.type || values.type == 0) {
    errors.type = "Required";
  }
  if (!values.dwelling_units) {
    errors.dwelling_units = "Required";
  }
  if (!values.no_of_floors) {
    errors.no_of_floors = "Required";
  }

  if (!values.floors || !values.floors.length) {
    errors.floors = { _error: "At least one floor must be entered" };
  } else {
    const membersArrayErrors = [];
    values.floors.forEach((floor, memberIndex) => {
      const memberErrors = {};
      if (!floor || !floor.floor_name) {
        memberErrors.floor_name = "Required";
        membersArrayErrors[memberIndex] = memberErrors;
      }
      if (!floor || !floor.height) {
        memberErrors.height = "Required";
        membersArrayErrors[memberIndex] = memberErrors;
      }
      if (!floor || !floor.use || floor.use == 0) {
        memberErrors.use = "Required";
        membersArrayErrors[memberIndex] = memberErrors;
      }
      if (!floor || !floor.total_floor_area) {
        memberErrors.total_floor_area = "Required";
        membersArrayErrors[memberIndex] = memberErrors;
      }
    });
    if (membersArrayErrors.length) {
      errors.floors = membersArrayErrors;
    }
  }
  return errors;
};

export default validate;
