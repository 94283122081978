import React, { useEffect, useState } from "react";
import { Button, Row, Col, Pagination, Table, Dropdown, Form, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import Pdf from "../assets/images/CommonAffidavitUndertaking.pdf"
import ApplicationSubmissionService from "../services/service/ApplicationSubmissionService";
import ErrorHandler from "../services/constant/ErrorHandler";
import UserDetails from "../services/storage/UserDetails";
import { Link } from "react-router-dom";

const SubmissionPendingView = () => {
  const { userDetails } = UserDetails();
  const [pendingList, setPendingList] = useState([]);
  useEffect(() => {
    ApplicationSubmissionService.getPendingList().then(result => {
      setPendingList(result.data.user_application);
    }).catch(function (error) {
      ErrorHandler(error);
    })
  }, []);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  return (
    <>
      <Col className="rt-dashboard">
        <Header />

        <Row className="px-3 py-2">
          <Col xs={12} lg={12} xl={4} xxl={6}>
            <a
              target="_blank"
              className="f14 blink_me"
              href={Pdf}>
              Common Affidavit by Owner, Builders and LTPs
            </a>
          </Col>
          <Col xs={12} lg={12} xl={8} xxl={6} className="text-md-end"></Col>
        </Row>

        <Col className="col-12 mt-2 px-3">
          <Table striped responsive className="common-table-view">
            <thead>
              <tr className="bg5">
                <th className="f14 fw-normal white cursor-pointer">
                  <FontAwesomeIcon icon={faSort} className="mt-1" />
                </th>
                <th className="f14 fw-normal white cursor-pointer">
                  File No <FontAwesomeIcon icon={faSort} className="mt-1" />
                </th>
                <th className="f14 fw-normal white cursor-pointer">
                  Permission Type
                  <FontAwesomeIcon icon={faSort} className="mt-1" />
                </th>
                <th className="f14 fw-normal white cursor-pointer">
                  Case Type <FontAwesomeIcon icon={faSort} className="mt-1" />
                </th>
                <th className="f14 fw-normal white cursor-pointer">
                  Applicant <FontAwesomeIcon icon={faSort} className="mt-1" />
                </th>
                <th className="f14 fw-normal white cursor-pointer">
                  Architect <FontAwesomeIcon icon={faSort} className="mt-1" />
                </th>
                <th className="f14 fw-normal white cursor-pointer">
                  IALA <FontAwesomeIcon icon={faSort} className="mt-1" />
                </th>
                <th className="f14 fw-normal white cursor-pointer">
                  Submited Date <FontAwesomeIcon icon={faSort} className="mt-1" />
                </th>
              </tr>
              <tr>
                <th className="f14 fw-normal white cursor-pointer"></th>
                <th className="f14 fw-normal white cursor-pointer"></th>
                <th className="f14 fw-normal white cursor-pointer py-1">
                  <Dropdown as={ButtonGroup} className="" align="end">
                    <Form.Control type="text" placeholder="search" className="py-0" />
                    <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="filter-dropdown">
                      <div className="px-0">
                        <div className="filter-dropdown-item">
                          <Row className="py-2 border-bottom">
                            <Col md={10} className="text-start pe-0">
                              Filter On
                            </Col>
                            <Col md={2} className="text-end ps-0">
                              <Form.Check aria-label="option 1" />
                            </Col>
                          </Row>
                        </div>

                        <Row className="py-2">
                          <Col md={8} className="text-start ps-2 pe-0">
                            <Button variant="secondary" size="sm">
                              Select All
                            </Button>
                          </Col>
                          <Col md={4} className="text-end ps-0">
                            <Button variant="secondary" size="sm">
                              Ok
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th className="f14 fw-normal white cursor-pointer py-1">
                  <Dropdown as={ButtonGroup} className="" align="end">
                    <Form.Control type="text" placeholder="search" className="py-0" />
                    <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="filter-dropdown">
                      <div className="px-0">
                        <div className="filter-dropdown-item">
                          <Row className="py-2 border-bottom">
                            <Col md={10} className="text-start pe-0">
                              Filter On
                            </Col>
                            <Col md={2} className="text-end ps-0">
                              <Form.Check aria-label="option 1" />
                            </Col>
                          </Row>
                        </div>

                        <Row className="py-2">
                          <Col md={8} className="text-start ps-2 pe-0">
                            <Button variant="secondary" size="sm">
                              Select All
                            </Button>
                          </Col>
                          <Col md={4} className="text-end ps-0">
                            <Button variant="secondary" size="sm">
                              Ok
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th className="f14 fw-normal white cursor-pointer"></th>
                <th className="f14 fw-normal white cursor-pointer"></th>
                <th className="f14 fw-normal white cursor-pointer py-1">
                  <Dropdown as={ButtonGroup} className="" align="end">
                    <Form.Control type="text" placeholder="search" className="py-0" />
                    <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="filter-dropdown">
                      <div className="px-0">
                        <div className="filter-dropdown-item">
                          <Row className="py-2 border-bottom">
                            <Col md={10} className="text-start pe-0">
                              Filter On
                            </Col>
                            <Col md={2} className="text-end ps-0">
                              <Form.Check aria-label="option 1" />
                            </Col>
                          </Row>
                        </div>

                        <Row className="py-2">
                          <Col md={8} className="text-start ps-2 pe-0">
                            <Button variant="secondary" size="sm">
                              Select All
                            </Button>
                          </Col>
                          <Col md={4} className="text-end ps-0">
                            <Button variant="secondary" size="sm">
                              Ok
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </th>
                <th className="f14 fw-normal white cursor-pointer"></th>
              </tr>
            </thead>
            <tbody>
              {pendingList?.length > 0 &&
                pendingList.map((item, index) => {
                  return (
                    <tr>
                      <td className="f14 c4">{index + 1}.</td>
                      <td className="f14 c4">
                        <Link to={{ pathname: "/user-application", state: item }}>{item.file_no}</Link>
                      </td>
                      <td className="f14 c4">{item.project_type}</td>
                      <td className="f14 c4">{item.case_type}  </td>
                      <td className="f14 c4">{userDetails?.first_name}</td>
                      <td className="f14 c4">sampath kumar</td>
                      <td className="f14 c4">Bhadrachalam</td>
                      <td className="f14 c4">01-01-2021</td>
                    </tr>
                  )
                })
              }
              {/* <tr>
                <td className="f14 c4">2.</td>
                <td className="f14 c4">IIC/0758/2021</td>
                <td className="f14 c4">Building Permission</td>
                <td className="f14 c4">New </td>
                <td className="f14 c4">kumaravel</td>
                <td className="f14 c4">kumarvel</td>
                <td className="f14 c4">Arunachalam</td>
                <td className="f14 c4">01-01-2021</td>
              </tr> */}
            </tbody>
          </Table>

          <Row className="my-2">
            <Col className="col-12 col-sm-6 mb-3 mb-sm-0">
              <div className="d-flex">
                <Button className="bg-white border-0">
                  <FontAwesomeIcon style={{ color: "green" }} className="black" icon={faSyncAlt} />
                </Button>
                <Pagination className="mb-0">{items}</Pagination>
              </div>
            </Col>
            <Col className="col-12 col-sm-6 text-end">
              <p className="mb-0">Total Proposal(s) : 1</p>
            </Col>
          </Row>

        </Col>
      </Col>
    </>
  );
};

export default SubmissionPendingView;
