import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

const PrimaryView = (props) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modals, setModals] = useState(false);
  const [modalDatas, setModalDatas] = useState("");
  const hiddenFileInput = React.useRef(null);
  const hiddenDateInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (e, index, id) => {
    if (e.target.files[0].size < 26214400) {
      var temp = [...props.documents];
      temp[index].doc_file = e.target.files[0];
      temp[index].preview = URL.createObjectURL(e.target.files[0]);
      props.setDocuments(temp);
      props.save(id, e.target.files[0]);
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const handleView = (value) => {
    setModalData(value?.doc_file);
    setModal(true);
  };
  const handleReason = (value) => {
    setModalDatas(value);
    setModals(true);
  };
  const handlePreview = (index) => {
    setModalData(props.documents[index]?.preview);
    setModal(true);
  };

  return (
    <Col className="mt-3">
      {props?.data?.map((item, index) => {
        let temp = props.documents.filter(
          (list) => list.application_document_id == item.id
        );
        return (
          <Col
            key={index}
            className="ps-2 py-0 d-flex col-md-12 mb-2 align-items-center justify-content-between"
            md={12}
          >
            <Col md={7} className={"mt-3"}>
              <div className="d-flex align-items-center">
                {/* <input type="checkbox" disabled /> */}
                <label className="f14 mb-0 ms-2">{item.name}</label>
              </div>
            </Col>
            <Col md={1}>
              <div>
                {temp[0]?.doc_file != "" &&
                  typeof temp[0]?.doc_file == "string" && (
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handleView(temp[0])}
                    >
                      View
                    </Button>
                  )}
              </div>
            </Col>
            <Col md={4}>
              {temp[0]?.zm_approval == 2 && temp[0]?.status == 23 && (
                <div>
                  <input
                    type="file"
                    accept={".jpeg,.pdf,.PDF"}
                    onChange={(e) => handleChange(e, index, item?.id)}
                  />
                  <Button
                    style={{ fontSize: "14px" }}
                    className="bg-transparent border-0 text-primary"
                    onClick={() => handleReason(temp[0]?.zm_reason)}
                  >
                    Reason
                  </Button>
                </div>
              )}
              {temp[0]?.commissioner_approval == 2 && temp[0]?.status == 4 && (
                <div>
                  <input
                    type="file"
                    accept={".jpeg,.pdf,.PDF"}
                    onChange={(e) => handleChange(e, index, item?.id)}
                  />
                  <Button
                    style={{ fontSize: "14px" }}
                    className="bg-transparent border-0 text-primary"
                    onClick={() => handleReason(temp[0]?.commissioner_reason)}
                  >
                    Reason
                  </Button>
                </div>
              )}
            </Col>
          </Col>
        );
      })}
      {modal === true ? (
        <>
          <Modal
            show={modal}
            onHide={() => {
              setModalData("");
              setModal(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                File
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <iframe src={modalData} style={{ height: "500px" }}></iframe>
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : null}

      {modals === true ? (
        <>
          <Modal
            show={modals}
            onHide={() => {
              setModalDatas("");
              setModals(false);
            }}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                Reason
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <textarea
                  value={modalDatas}
                  readOnly
                  rows={4}
                  className="form-control px-2 py-1 mb-3 mt-3 rounded-0"
                />
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </Col>
  );
};

export default PrimaryView;
