import moment from "moment/moment";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";
import ApplicationService from "../../services/service/ApplictionService";
import ReportOneg from "../Report/thirdchangeReport";

const OccupancyDigitalSignatureLatter = (props) => {
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  useEffect(() => {
    let formdata = new FormData();
    formdata.append("status", 11);
    ApplicationService.get_occupancy_link(formdata)
      .then((result) => {
        setList(result?.data?.lists);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const [modalType, setModalType] = useState("");
  const viewPreCheckReport = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("error");
        setModalData(response.precheck_errors);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const occupancyUserAccount = (value) => {
    ApplicationUpdateService.occupancyUserAccountDetail({
      user_occupancy_id: value.id,
    })
      .then((result) => {
        let response = result.data;
        setModalType("account");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewDrawingPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("report");
        setModalData(response.scrutiny_report_pdf);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewDemondNoteSignaturetPDF = (value) => {
    ApplicationUpdateService.occupancyDemondNoteSignatureDownload({
      user_occupancy_id: value.id,
    })
      .then((result) => {
        let response = result.data;
        // viewReport(response);
        setModalType("fees");
        setModalData(response?.occupancy_fee_intimation_url);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewDemandNoteIssued = (value) => {
    ApplicationService.demandNoteIssued({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("fees");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const columns = [
    {
      name: "sl",
      label: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{tableMeta.rowIndex + 1}</div>;
        },
      },
    },
    {
      name: "file_no",
      label: "File No",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Link
                to={{
                  pathname: "/occupancy-view",
                  state: list[tableMeta.rowIndex],
                }}
              >
                {value}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "iala_name",
      label: "IALA",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {
                list[tableMeta.rowIndex]?.user_occupancy_detail?.iala_place
                  ?.name
              }
            </div>
          );
        },
      },
    },
    {
      name: "fee_intimation",
      label: "Fee Intimation",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status >= 11
                  ? "f14 c4 text-start  pre-pdf-btn"
                  : "f14 c4 text-start  pre-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status >= 11 ? false : true}
                onClick={() =>
                  viewDemondNoteSignaturetPDF(list[tableMeta.rowIndex])
                }
                className="view-btn"
              >
                View
              </button>
            </div>
          );
        },
      },
    },

    {
      name: "occupancy_certificate",
      label: "Occupancy Certificate",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className={"f14 c4 text-start pre-pdf-btn opacity-50"}>
              <button
                className="dwn-btn"
                // onClick={() => viewScrutinyReportPDF(list[tableMeta.rowIndex])}
                disabled
              >
                View
              </button>
            </div>
          );
        },
      },
    },

    {
      name: "user_account",
      label: "User Account Detail",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status >= 11
                  ? "f14 c4 text-start draw-pdf-btn"
                  : "f14 c4 text-start draw-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status >= 11 ? false : true}
                onClick={() => occupancyUserAccount(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },

    {
      name: "created_at",
      label: "Date Of Submission",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {list[tableMeta.rowIndex]?.created_at
                ? moment(list[tableMeta.rowIndex]?.created_at).format(
                    "YYYY-MM-DD"
                  )
                : ""}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: false,
    filter: true,
    filterType: "dropdown",
  };

  return (
    <Col className="col-12 mt-0 px-3">
      <MUIDataTable
        title={"Fee Intimation Letter Applications"}
        data={list}
        columns={columns}
        options={options}
      />
      {modal === true ? (
        <>
          <Modal
            show={modal}
            onHide={() => {
              setModal(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                {modalType == "demond_note"
                  ? "Demand Note Signature"
                  : modalType == "account"
                  ? "User Account Details"
                  : modalType == "fees"
                  ? "Fees Intimation"
                  : modalType == "msb"
                  ? "MSB Agenda"
                  : modalType == "mom"
                  ? "MOM"
                  : modalType == "ta_letter"
                  ? "TA Letter"
                  : "Errors"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {modalType == "demond_note" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "account" ? (
                  <div style={{ height: "200px" }}>
                    <Row>
                      <Col className="mt-5">
                        Account No : {modalData.account_no}
                      </Col>
                      <Col className="mt-5">
                        IFSC Code : {modalData.ifsc_code}
                      </Col>
                    </Row>
                  </div>
                ) : modalType == "fees" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "msb" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "mom" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "ta_letter" ? (
                  <div>
                    <iframe
                      src={modalData?.ta_url ? modalData.ta_url : null}
                      style={{ height: "500px", width: "100%" }}
                    ></iframe>
                  </div>
                ) : (
                  modalType == "error" && (
                    <div>
                      <Row>
                        <Col>Errors</Col>
                      </Row>
                      {modalData.map((item, index) => {
                        return (
                          <Row>
                            <Col>{item}</Col>
                          </Row>
                        );
                      })}
                    </div>
                  )
                )}
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </Col>
  );
};
export default OccupancyDigitalSignatureLatter;
