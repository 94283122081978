import { useState } from 'react';
import { USER_DETAILS } from '../constant/Constant';

export default function UserDetails() {
    const getUserDetails = () => {
        const data = JSON.parse(localStorage.getItem(USER_DETAILS));
        // const userToken = JSON.parse(tokenString);
        // return userToken?.token
        return data
    };

    const [userDetails, setUserDetails] = useState(getUserDetails());

    const saveUserDetails = data => {
        const temp = localStorage.setItem(USER_DETAILS, JSON.stringify(data));
        setUserDetails(temp);
    };
    const logout = () => {
        localStorage.clear();
    };
    return {
        saveUserDetails: saveUserDetails,
        userDetails,
        logout: logout,
    }
}