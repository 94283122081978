import { faFileAlt, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddBulidingDetails from "./AddBulidingDetails";
import { Field, FieldArray, reduxForm, change } from "redux-form";
import ApplicationService from "../../services/service/ApplictionService";
import ErrorHandler from "../../services/constant/ErrorHandler";
import validate from "./validate";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import HighRise from "../../services/constant/HighRise";
import { bin } from "../../assets/images/Index";

const BuildingDetailsTab = (props) => {
  // console.log("props", props);
  const { saveHighRiseStatus } = HighRise();
  const { handleSubmit, pristine, reset, submitting } = props;
  const [row, setRow] = useState([0]);
  const [stilt, setStilt] = useState("");
  const [cellars, setCellars] = useState("");
  const addNewRow = () => {
    var temp = [...row];
    temp.push(row.length);
    setRow(temp);
  };
  const [tempArray, setTempArray] = useState([]);
  const [tempArrays, setTempArrays] = useState([]);
  const [Id, setId] = useState([]);

  //  WITHOUT REDUX METHOD

  const [name, setName] = useState("");
  const [typeNo, setTypeNo] = useState("");
  const [uses, setUses] = useState("");
  const [subUses, setSubUses] = useState("");
  const [types, setTypes] = useState("");
  const [heights, setHeights] = useState("");
  const [parkings, setParkings] = useState("");
  const [cellar, setCellar] = useState("");

  // NO OF FLOORS

  const [floorName, setFloorName] = useState({});
  const [floorUse, setFloorUse] = useState({});
  const [floorHeight, setFloorHeight] = useState({});
  const [floorTotal, setFloorTotal] = useState({});

  const onFloorNameChange = (e, index) => {
    const value = e.target.value;
    setFloorName((pre) => ({ ...pre, [index]: value }));
  };
  const onFloorUseChange = (e, index) => {
    const value = e.target.value;
    setFloorUse((pre) => ({ ...pre, [index]: value }));
  };
  const onFloorHeightChange = (e, index) => {
    const value = e.target.value;
    setFloorHeight((pre) => ({ ...pre, [index]: value }));
  };
  const onFloorTotalChange = (e, index) => {
    const value = e.target.value;
    setFloorTotal((pre) => ({ ...pre, [index]: value }));
  };

  // const handleChange = (e, index) => {
  //   const value = e.target.value;
  //   const name = e.target.name;

  //   console.log("value", value);
  //   console.log("name", name);

  //   let temp = [...tempArray];

  //   console.log("temp", temp);

  //   // temp[index] = value;
  //   // setTempArray(temp);
  // };

  // VALIDATION

  const [nameErr, setNameErr] = useState(false);
  const [typeNoErr, setTypeNoErr] = useState(false);
  const [useErr, setUseErr] = useState(false);
  const [subUseErr, setSubuseErr] = useState(false);

  // ONCHANGE EVENT

  const [useList, setUseList] = useState([]);
  const [subUseList, setSubUseList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    ApplicationService.getUseList()
      .then((result) => {
        setUseList(result.data.building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const changeUse = (e) => {
    if (e.target.value != "" && e.target.value != 0) {
      getSubList(e.target.value);
    }
  };
  const changeSubUse = (e) => {
    if (e.target.value != "" && e.target.value != 0) {
      getTypeList(e.target.value);
    }
  };
  const getSubList = (use) => {
    ApplicationService.getSubUseList(use)
      .then((result) => {
        setSubUseList(result.data.sub_building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const getTypeList = (sub_use) => {
    ApplicationService.getSubUseList(sub_use)
      .then((result) => {
        setTypeList(result.data?.sub_building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  useEffect(() => {
    ApplicationService.getUseList()
      .then((result) => {
        setUseList(result.data.building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);

  // NO OF FLOOR

  const changeNoofFloor = (e) => {
    let temp = [];
    for (let i = 0; i < e.target.value; i++) {
      temp.push(i);
    }
    setTempArray(temp);
  };

  // SAVE DATA

  const save = (values) => {
    if (
      name !== 0 &&
      typeNo !== 0 &&
      uses !== 0 &&
      subUses !== 0 &&
      types !== 0
    ) {
      let payload = values;
      // console.log(payload);
      if (props.applicationID) {
        const formdata = new FormData();
        formdata.append("user_application_id", props.applicationID);
        formdata.append("name", name);
        formdata.append("use", uses);
        formdata.append("sub_use", subUses);
        formdata.append("type", types);
        formdata.append("dwelling_units", typeNo);
        formdata.append("no_of_floors", tempArray?.length);

        tempArray?.map((i, index) => {
          formdata.append(`floors[${index}][floor_name]`, floorName[index]);
          formdata.append(`floors[${index}][height]`, floorHeight[index]);
          formdata.append(`floors[${index}][use]`, floorUse[index]);
          formdata.append(
            `floors[${index}][total_floor_area]`,
            floorTotal[index]
          );
        });

        if (stilt) {
          formdata.append("height_excluding_slit", stilt);
        }

        if (cellars) {
          formdata.append("height_excluding_celler", cellars);
        }

        ApplicationService.saveBuildingDetails(formdata)
          .then((result) => {
            saveHighRiseStatus(
              result?.data?.is_high_rise ? result.data.is_high_rise : 0
            );
            getDetails();
            alert(result.data.message);
            setName("");
            setTypeNo("");
            setUses("");
            setSubUses("");
            setTypes("");
            setTempArray([]);
            setFloorName({});
            setFloorTotal({});
            setFloorHeight({});
            setFloorUse({});
            setCellars("");
            setStilt("");
          })
          .catch(function (error) {
            ErrorHandler(error);
          });
      } else {
        alert("please fill GeneralInformation !");
      }
    } else {
      alert("Please fill all Details");
    }
  };

  const [list, setList] = useState([]);

  const getDetails = () => {
    ApplicationService.viewApplication({
      user_application_id: props?.applicationID,
      type: 5,
    }).then((res) => {
      // console.log(res?.data?.user_application_building_details);
      setList(res?.data?.user_application_building_details);
    });
  };

  console.log("list", list);

  const deleteBuilding = (id) => {
    console.log("id", id);
    let data = {
      user_application_building_detail_id: id,
    };

    ApplicationService.deleteBuilding(data).then((res) => {
      alert(res?.data?.message);
      getDetails();
    });
  };

  // const save = (values) => {
  //   if (props.applicationID) {
  //     var payload = values;
  //     payload.user_application_id = props.applicationID;
  //     ApplicationService.saveBuildingDetails(payload)
  //       .then((result) => {
  //         saveHighRiseStatus(
  //           result?.data?.is_high_rise ? result.data.is_high_rise : 0
  //         );
  //         alert(result.data.message);
  //       })
  //       .catch(function (error) {
  //         ErrorHandler(error);
  //       });
  //   } else {
  //     alert("please fill GeneralInformation !");
  //   }
  // };

  return (
    <Tabs id="uncontrolled-tab-example" className="mb-0">
      <Tab className="userApplic" eventKey="build" title="New Building Details">
        <form>
          <Col className="rt-dashboard px-3">
            <Col className="d-md-flex justify-content-between align-items-center px-3">
              <Col className="d-flex justify-content-md-end">
                <Link
                  to="/application-permission"
                  style={{
                    color: "#2f6db5",
                    fontSize: 13,
                  }}
                ></Link>
              </Col>
            </Col>
            <Col className="customers mb-3 mt-3">
              <Col
                style={{
                  border: "1px solid #e9e9e9",
                  padding: 15,
                  marginBottom: 10,
                }}
                xs={12}
                className="d-md-flex flex-wrap"
              >
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Name*</p>
                  </Col>
                  <Col>
                    <input
                      name={"name"}
                      type="text"
                      label="Name"
                      className="form-control px-2 py-1 rounded-0"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </Col>
                </Col>

                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Use*</p>
                  </Col>
                  <Col>
                    <Form.Select
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                      value={uses}
                      onChange={(e) => {
                        changeUse(e);
                        setUses(e.target.value);
                      }}
                    >
                      <option className="f14 py-2" value="0">
                        Select
                      </option>
                      {useList.length > 0 &&
                        useList.map((item, index) => {
                          return (
                            <option
                              key={index}
                              className="f14 py-2"
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Col>
                </Col>

                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Sub Use*</p>
                  </Col>
                  <Col>
                    <Form.Select
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                      value={subUses}
                      onChange={(e) => {
                        changeSubUse(e);
                        setSubUses(e.target.value);
                      }}
                    >
                      <option className="f14 py-2" value="0">
                        Select
                      </option>
                      {subUseList.length > 0 &&
                        subUseList.map((item, index) => {
                          return (
                            <option
                              key={index}
                              className="f14 py-2"
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Col>
                </Col>

                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Type*</p>
                  </Col>
                  <Col>
                    <Form.Select
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                      value={types}
                      onChange={(e) => setTypes(e.target.value)}
                    >
                      <option className="f14 py-2" value="0">
                        Select
                      </option>
                      {typeList.length > 0 &&
                        typeList.map((item, index) => {
                          return (
                            <option
                              key={index}
                              className="f14 py-2"
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Col>
                </Col>

                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Type Number of dwelling units*</p>
                  </Col>
                  <Col>
                    <Col>
                      <input
                        name={"dwelling_units"}
                        type="number"
                        className="form-control px-2 py-1 rounded-0"
                        onChange={(e) => setTypeNo(e.target.value)}
                        value={typeNo}
                      />
                    </Col>
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">
                      Height excluding Stilt / Parking*
                    </p>
                  </Col>
                  <Col>
                    <input
                      name={"height_excluding_slit"}
                      type="number"
                      disabled={cellars ? true : false}
                      className="form-control px-2 py-1 rounded-0"
                      onChange={(e) => setStilt(e.target.value)}
                      value={stilt}
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">Height excluding Cellars*</p>
                  </Col>
                  <Col>
                    <input
                      name={"height_excluding_celler"}
                      type="number"
                      disabled={stilt ? true : false}
                      className="form-control px-2 py-1 rounded-0"
                      onChange={(e) => setCellars(e.target.value)}
                      value={cellars}
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-0">No. Of Floors*</p>
                  </Col>
                  <Col>
                    <input
                      name={"no_of_floors"}
                      type="number"
                      className="form-control px-2 py-1 rounded-0"
                      // component={renderField}
                      label="No of Floors"
                      onChange={changeNoofFloor}
                      value={tempArray?.length == 0 ? null : tempArray?.length}
                    />
                  </Col>
                </Col>
              </Col>
              {tempArray?.map((i, index) => (
                <>
                  <li key={index}>
                    <h4>Floor #{index + 1}</h4>
                    <Col className="d-md-flex flex-wrap mt-1">
                      <Col
                        md={6}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                      >
                        <Col>
                          <p className="f13 mb-0">Floor Name*</p>
                        </Col>
                        <Col>
                          <input
                            name="floor_name"
                            type="text"
                            label="Floor Name"
                            value={floorName[index]}
                            className="form-control px-2 py-1 rounded-0"
                            onChange={(e) => onFloorNameChange(e, index)}
                            // value={cellars}
                          />
                        </Col>
                      </Col>
                      <Col
                        md={6}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                      >
                        <Col>
                          <p className="f13 mb-0">Height (m)</p>
                        </Col>
                        <Col>
                          <input
                            name="height"
                            type="number"
                            label="Height"
                            value={floorHeight[index]}
                            className="form-control px-2 py-1 rounded-0"
                            onChange={(e) => onFloorHeightChange(e, index)}
                            // value={cellars}
                          />
                        </Col>
                      </Col>
                      <Col
                        md={6}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                      >
                        <Col>
                          <p className="f13 mb-0">Use</p>
                        </Col>
                        <Col>
                          <Form.Select
                            name="use"
                            // component={renderSelectField}
                            onChange={(e) => onFloorUseChange(e, index)}
                            value={floorUse[index]}
                          >
                            <option className="f14 py-2" value="0">
                              Select
                            </option>
                            {useList.length > 0 &&
                              useList.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    className="f14 py-2"
                                    value={item.id}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Form.Select>
                        </Col>
                      </Col>
                      <Col
                        md={6}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                      >
                        <Col>
                          <p className="f13 mb-0">Total Floor Area</p>
                        </Col>
                        <Col>
                          <input
                            name="total_floor_area"
                            type="number"
                            label="Total Floor Area"
                            value={floorTotal[index]}
                            className="form-control px-2 py-1 rounded-0"
                            onChange={(e) => onFloorTotalChange(e, index)}
                            // value={cellars}
                          />
                        </Col>
                      </Col>
                    </Col>
                  </li>
                </>
              ))}
              <Col className="d-flex justify-content-center mb-2">
                <Button
                  type="button"
                  className="tab-blu-back"
                  onClick={save}
                  style={{
                    color: "#fff",
                    fontSize: 15,
                    marginTop: 10,
                    padding: "6px 14px",
                    backgroundColor: "#5593db",
                  }}
                >
                  <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
                  Save
                </Button>
              </Col>
            </Col>
          </Col>
        </form>
      </Tab>
      <Tab
        className="userApplic"
        eventKey="show"
        title="Saved Building Details"
      >
        <form>
          <Col className="rt-dashboard px-3">
            <Col className="d-md-flex justify-content-between align-items-center px-3">
              <Col className="d-flex justify-content-md-end">
                <Link
                  to="/application-permission"
                  style={{
                    color: "#2f6db5",
                    fontSize: 13,
                  }}
                ></Link>
              </Col>
            </Col>
            {list?.map((i, index) => (
              <Col className="customers mb-3 mt-3">
                <li key={index}>
                  <h4>Building #{index + 1}</h4>
                  <Col
                    style={{
                      border: "1px solid #e9e9e9",
                      padding: 15,
                      marginBottom: 10,
                    }}
                    xs={12}
                    className="d-md-flex flex-wrap"
                  >
                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    >
                      <Col>
                        <p className="f13 mb-0">Name*</p>
                      </Col>
                      <Col>
                        <input
                          name={"name"}
                          type="text"
                          label="Name"
                          className="form-control px-2 py-1 rounded-0"
                          // onChange={(e) => setName(e.target.value)}
                          value={i?.name}
                        />
                      </Col>
                    </Col>

                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    >
                      <Col>
                        <p className="f13 mb-0">Use*</p>
                      </Col>
                      <Col>
                        <Form.Select
                          style={{ fontSize: "15px" }}
                          aria-label="Default select example"
                          className="form-control px-2 py-1 rounded-0"
                          value={i?.uses?.id}
                          // onChange={(e) => {
                          //   changeUse(e);
                          //   setUses(e.target.value);
                          // }}
                        >
                          <option className="f14 py-2" value="0">
                            Select
                          </option>
                          {useList.length > 0 &&
                            useList.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  className="f14 py-2"
                                  value={item.id}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Col>
                    </Col>

                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    >
                      <Col>
                        <p className="f13 mb-0">Sub Use*</p>
                      </Col>
                      <Col>
                        <Form.Select
                          style={{ fontSize: "15px" }}
                          aria-label="Default select example"
                          className="form-control px-2 py-1 rounded-0"
                          value={i?.subUses?.id}
                          // onChange={(e) => {
                          //   changeSubUse(e);
                          //   setSubUses(e.target.value);
                          // }}
                        >
                          <option className="f14 py-2" value="0">
                            Select
                          </option>
                          {subUseList.length > 0 &&
                            subUseList.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  className="f14 py-2"
                                  value={item.id}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Col>
                    </Col>

                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    >
                      <Col>
                        <p className="f13 mb-0">Type*</p>
                      </Col>
                      <Col>
                        <Form.Select
                          style={{ fontSize: "15px" }}
                          aria-label="Default select example"
                          className="form-control px-2 py-1 rounded-0"
                          value={i?.type?.id}
                          // onChange={(e) => setTypes(e.target.value)}
                        >
                          <option className="f14 py-2" value="0">
                            Select
                          </option>
                          {typeList.length > 0 &&
                            typeList.map((item, index) => {
                              return (
                                <option
                                  key={index}
                                  className="f14 py-2"
                                  value={item.id}
                                >
                                  {item.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Col>
                    </Col>

                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    >
                      <Col>
                        <p className="f13 mb-0">
                          Type Number of dwelling units*
                        </p>
                      </Col>
                      <Col>
                        <Col>
                          <input
                            name={"dwelling_units"}
                            type="number"
                            className="form-control px-2 py-1 rounded-0"
                            // onChange={(e) => setTypeNo(e.target.value)}
                            value={i?.dwelling_units}
                          />
                        </Col>
                      </Col>
                    </Col>
                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    >
                      <Col>
                        <p className="f13 mb-0">
                          Height excluding Stilt / Parking*
                        </p>
                      </Col>
                      <Col>
                        <input
                          name={"height_excluding_slit"}
                          type="number"
                          // disabled={cellars ? true : false}
                          className="form-control px-2 py-1 rounded-0"
                          // onChange={(e) => setStilt(e.target.value)}
                          value={i?.height_excluding_slit}
                        />
                      </Col>
                    </Col>
                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    >
                      <Col>
                        <p className="f13 mb-0">Height excluding Cellars*</p>
                      </Col>
                      <Col>
                        <input
                          name={"height_excluding_celler"}
                          type="number"
                          // disabled={stilt ? true : false}
                          className="form-control px-2 py-1 rounded-0"
                          // onChange={(e) => setCellars(e.target.value)}
                          value={i?.height_excluding_celler}
                        />
                      </Col>
                    </Col>
                    <Col
                      md={6}
                      className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                    >
                      <Col>
                        <p className="f13 mb-0">No. Of Floors*</p>
                      </Col>
                      <Col>
                        <input
                          name={"no_of_floors"}
                          type="number"
                          className="form-control px-2 py-1 rounded-0"
                          // component={renderField}
                          label="No of Floors"
                          // onChange={changeNoofFloor}
                          value={i?.no_of_floors}
                        />
                      </Col>
                    </Col>
                    <img
                      src={bin}
                      width={20}
                      onClick={() => deleteBuilding(i?.id)}
                    />
                  </Col>
                  {i?.user_application_building_floors?.map((f, indexs) => (
                    <li key={indexs}>
                      <h4>Floor #{indexs + 1}</h4>
                      <Col className="d-md-flex flex-wrap mt-1">
                        <Col
                          md={6}
                          className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                        >
                          <Col>
                            <p className="f13 mb-0">Floor Name*</p>
                          </Col>
                          <Col>
                            <input
                              name="floor_name"
                              type="text"
                              label="Floor Name"
                              value={f?.floor_name}
                              className="form-control px-2 py-1 rounded-0"
                              // onChange={(e) => onFloorNameChange(e, index)}
                              // value={cellars}
                            />
                          </Col>
                        </Col>
                        <Col
                          md={6}
                          className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                        >
                          <Col>
                            <p className="f13 mb-0">Height (m)</p>
                          </Col>
                          <Col>
                            <input
                              name="height"
                              type="number"
                              label="Height"
                              value={f?.height}
                              className="form-control px-2 py-1 rounded-0"
                              // onChange={(e) => onFloorHeightChange(e, index)}
                              // value={cellars}
                            />
                          </Col>
                        </Col>
                        <Col
                          md={6}
                          className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                        >
                          <Col>
                            <p className="f13 mb-0">Use</p>
                          </Col>
                          <Col>
                            <Form.Select
                              name="use"
                              // component={renderSelectField}
                              // onChange={(e) => onFloorUseChange(e, index)}
                              value={f?.use?.id}
                            >
                              <option className="f14 py-2" value="0">
                                Select
                              </option>
                              {useList.length > 0 &&
                                useList.map((item, index) => {
                                  return (
                                    <option
                                      key={index}
                                      className="f14 py-2"
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                          </Col>
                        </Col>
                        <Col
                          md={6}
                          className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                        >
                          <Col>
                            <p className="f13 mb-0">Total Floor Area</p>
                          </Col>
                          <Col>
                            <input
                              name="total_floor_area"
                              type="number"
                              label="Total Floor Area"
                              value={f?.total_floor_area}
                              className="form-control px-2 py-1 rounded-0"
                              // onChange={(e) => onFloorTotalChange(e, index)}
                              // value={cellars}
                            />
                          </Col>
                        </Col>
                      </Col>
                    </li>
                  ))}

                  {/* <Col className="d-flex justify-content-center mb-2">
              <Button
                type="button"
                className="tab-blu-back"
                onClick={save}
                style={{
                  color: "#fff",
                  fontSize: 15,
                  marginTop: 10,
                  padding: "6px 14px",
                  backgroundColor: "#5593db",
                }}
              >
                <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
                Save
              </Button>
            </Col> */}
                </li>
              </Col>
            ))}
          </Col>
        </form>
      </Tab>
    </Tabs>
  );
};

export default reduxForm({
  form: "BuildingDetailsForm", // a unique identifier for this form
  validate,
})(BuildingDetailsTab);

// export default BuildingDetailsTab;
