import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Tab, Tabs } from "react-bootstrap";
import ErrorHandler from "../../../services/constant/ErrorHandler";
import ApplicationService from "../../../services/service/ApplictionService";
import PrimaryEdit from "./Document/PrimaryEdit";
import HighRise from "../../../services/constant/HighRise";
import { HIGH_RISE } from "../../../services/constant/Constant";
import { useLocation } from "react-router-dom";
const UserDocumentEdit = (props) => {
  const location = useLocation();
  const { highRiseStatus } = HighRise();
  const [documentList, setDocumentList] = useState([]);
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    if (props.list?.id) {
      ApplicationService.occupancyDocumentList()
        .then((result) => {
          setDocumentList(result.data.user_occupancy_documents);
          let responce = result.data.user_occupancy_documents;
          let responce2 = props.list?.user_occupancy_document;
          var temp = [];

          responce?.map((item, ind) => {
            let finder = responce2?.find(
              (i) => i?.application_document_id == item?.id
            );

            if (item?.id == finder?.application_document_id) {
              temp.push({
                application_document_id: finder.application_document_id,
                doc_file: finder?.doc_file,
                preview: "",
              });
            } else {
              temp.push({
                application_document_id: item.id,
                doc_file: "",
                type: item?.type,
                preview: "",
              });
            }
          });

          setDocuments(temp);
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }
  }, [props.list]);

  const save = (id, file, index) => {
    // if (props.applicationID) {
    var count = 1;

    if (file == "") {
      count = count + 1;
    }

    if (count == 1) {
      var form_data = new FormData();
      form_data.append("user_occupancy_id", props.applicationID);
      form_data.append(`application_document_id`, id);
      form_data.append(`doc_file`, file);

      ApplicationService.occupancyDocumentAdd(form_data)
        .then((result) => {
          if (result.data.status == "success") {
            var temp = [...documents];
            temp[index].doc_file = file;
            temp[index].preview = URL.createObjectURL(file);
            setDocuments(temp);
            props.getViewOccupancy(location?.state?.id);
          } else {
            alert(result.data.message);
          }
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("please select all Files ?");
    }
  };

  return (
    <>
      <Col>
        {documents.length > 0 && (
          <PrimaryEdit
            documents={documents}
            setDocuments={setDocuments}
            data={documentList}
            save={save}
          />
        )}
      </Col>
    </>
  );
};

export default UserDocumentEdit;
