import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApplicantCheckList from "../component/ApplicantCheckList";
import ApplicantInformation from "../component/ApplicantInformation";
import GeneralInformation from "../component/GeneralInformation";
import Header from "./Header";
import ApplyNOC from "./userApplication/ApplyNOC";
import BuildingDetails from "./BuildingDetails/BuildingDetails";
import PlotDetails from "./userApplication/PlotDetails";
import SiteDetails from "./userApplication/SiteDetails";
import UserDocument from "./userApplication/UserDocument";
import UserDrawing from "./userApplication/UserDrawing";
import BuildingDetailsCopy from "./BuildingDetails/BuildingDetails copy";
import BuildingDetailsTab from "./BuildingDetails/BuildingDetailsTab";
import BuildingDetailsCopy2 from "./BuildingDetails/BuildingDetails copy 2";

const ApplicPermissionView = () => {
  const [applicationID, setApplicationID] = useState(null);

  return (
    <>
      <Col className="rt-dashboard">
        <Header />
        <Col className="d-flex justify-content-between w-100 px-2 bg-green">
          <Link
            to="/draft-application"
            className="d-flex black align-items-center p-2"
          >
            <FontAwesomeIcon icon={faArrowCircleLeft} />
            <p className="mb-0 ms-2">Back</p>
          </Link>
          <Button className="proposal-risk-back">Proposal Risk Category</Button>
        </Col>
        <Col>
          <Tabs id="uncontrolled-tab-example" className="mb-0">
            <Tab
              className="userApplic"
              eventKey="userapplicationform"
              title="Application Form"
            >
              <Col className="px-2 app-inform mt-1 bg-white">
                <Tabs
                  id="uncontrolled-tab-example aria-labelledby"
                  className="mb-0 bg-white"
                >
                  <Tab
                    eventKey="generalInformation"
                    title="General Information"
                  >
                    <GeneralInformation
                      setApplicationID={setApplicationID}
                      type="new"
                    />
                  </Tab>
                  <Tab
                    eventKey="applicationInformation"
                    title="Applicant Information"
                  >
                    <ApplicantInformation />
                  </Tab>
                  <Tab
                    eventKey="applicantCheckList"
                    title="Applicant CheckList"
                  >
                    <ApplicantCheckList
                      applicationID={applicationID}
                      type="new"
                    />
                  </Tab>
                  <Tab eventKey="plotDetails" title="Plot Details">
                    <PlotDetails applicationID={applicationID} type="new" />
                  </Tab>
                  <Tab eventKey="buildingDetails" title="Building Details">
                    {/* <BuildingDetails applicationID={applicationID} type="new" /> */}
                    {/* <BuildingDetailsCopy applicationID={applicationID} type="new" /> */}
                    <BuildingDetailsTab
                      applicationID={applicationID}
                      type="new"
                    />
                  </Tab>
                  <Tab eventKey="siteDetails" title="Site Details">
                    <SiteDetails applicationID={applicationID} type="new" />
                  </Tab>
                  <Tab eventKey="applyNOC" title="Upload NOC">
                    <ApplyNOC applicationID={applicationID} type="new" />
                  </Tab>
                </Tabs>
              </Col>
            </Tab>
            <Tab
              className="userApplic"
              eventKey="userdocument"
              title="Document"
            >
              <UserDocument applicationID={applicationID} type="new" />
            </Tab>
            <Tab eventKey="userdrawing" title="Drawing">
              <UserDrawing applicationID={applicationID} type="new" />
            </Tab>
          </Tabs>
        </Col>
      </Col>
    </>
  );
};

export default ApplicPermissionView;
