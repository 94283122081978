import React, { useState } from "react";
import { Button, Row, Col, Pagination, Table, Dropdown, Form, ButtonGroup, Modal } from "react-bootstrap";
import SideBar from "../component/Sidebar";
import IntimateWorkProgressView from "../component/workProgress/IntimateWorkProgressView"

const IntimateWorkProgress = () => {

  return (
    <Col className="d-lg-flex">
      {/* <SideBar /> */}
      <IntimateWorkProgressView />
    </Col>
  );
};

export default IntimateWorkProgress;
