import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SideBar from "../component/Sidebar";
import Header from "../component/Header";
import { NoImage } from "../assets/images/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import UserDetails from "../services/storage/UserDetails";

const Profile = () => {
  const { userDetails } = UserDetails();
  console.log("userDetails", userDetails);
  const [email, setEmail] = useState(userDetails.email);
  const [mobile, setMobile] = useState(userDetails.mobile);
  const [landline, setLandline] = useState(userDetails.user_detail.landline);
  const [idNumber, setIdNumber] = useState("");
  const [address, setAddress] = useState(userDetails.user_detail.address);
  const [city, setCity] = useState(userDetails.user_detail.city);
  const [state, setState] = useState(userDetails.user_detail.state);
  const [country, setCountry] = useState(userDetails.user_detail.nationality);
  const [pincode, setPincode] = useState(userDetails.user_detail.pin_code);
  const [shortProfile, setShortProfile] = useState(
    userDetails.user_detail.short_profile
  );
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  function editClick() {
    document.getElementById("edit").style.display = "block";
    document.getElementById("saveBtn").style.display = "block";

    if (userDetails.type != 1) {
      document.getElementById("editProfilePic").style.display = "block";
    }
    document.getElementById("static").style.display = "none";
    document.getElementById("editBtn").style.display = "none";
  }

  function cancelClick() {
    document.getElementById("static").style.display = "block";
    document.getElementById("editBtn").style.display = "block";
    document.getElementById("saveBtn").style.display = "none";
    document.getElementById("edit").style.display = "none";
    if (userDetails.type != 1) {
      document.getElementById("editProfilePic").style.display = "none";
    }
  }

  function saveClick() {
    document.getElementById("static").style.display = "block";
    document.getElementById("editBtn").style.display = "block";
    document.getElementById("saveBtn").style.display = "none";
    document.getElementById("edit").style.display = "none";
    if (userDetails.type != 1) {
      document.getElementById("editProfilePic").style.display = "none";
    }
  }

  return (
    <Col className="d-lg-flex">
      {/* <SideBar /> */}
      <Col className="px-2">
        <Header />
        <div style={{ height: "90vh", overflowY: "scroll" }}>
          <Col className="d-md-flex justify-content-between align-items-center px-2 my-2">
            <Col className="col-12 col-md-8 text-end py-2"></Col>
            {userDetails.type != 1 && (
              <Col className="col-12 col-md-2 text-center text-md-end py-2">
                <NoImage />
                &nbsp;
                <div
                  class="upload-btn-wrapper mt-2"
                  id="editProfilePic"
                  style={{ display: "none" }}
                >
                  <Button variant="outline-secondary">Upload a file</Button>
                  <input type="file" name="myfile" />
                </div>
                &nbsp;
              </Col>
            )}
            <Col className="col-12 col-md-2 text-center py-2">
              <Button
                variant="light"
                className="rounded-0 border f-11 py-1 px-2"
              >
                <div id="editBtn" onClick={editClick}>
                  <FontAwesomeIcon icon={faEdit} />
                  &nbsp; Edit
                </div>
                <div
                  id="saveBtn"
                  style={{ display: "none" }}
                  onClick={saveClick}
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Save
                </div>
              </Button>
              &nbsp;
              <Button
                variant="light"
                className="rounded-0 border f-11 py-1 px-2"
                onClick={cancelClick}
              >
                <FontAwesomeIcon icon={faTimes} />
                &nbsp; Cancel
              </Button>
            </Col>
          </Col>

          <Row>
            <Col className="col-12 col-md-12 text-start py-2">
              <div className="f-large black fw-bold px-3">
                {userDetails?.title}. {userDetails?.first_name}{" "}
                {userDetails?.middle_name} {userDetails?.last_name}
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="col-12 col-md-12 text-start">
              <div className="profile-title">
                <div className="f12 black px-3">
                  <strong>Basic Information</strong>
                </div>
              </div>
            </Col>

            <Col className="col-12 col-md-12 text-start py-2">
              <Row>
                <Col className="col-12 col-md-6 text-start py-2">
                  <Row>
                    <Col className="col-12 col-md-4 text-start py-2">
                      <div className="f14 black">Department</div>
                    </Col>
                    <Col className="col-12 col-md-8 text-start py-2">
                      <div className="f14 black">
                        <strong>Town Planning</strong>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-12 col-md-6 text-start py-2">
                  <Row>
                    <Col className="col-12 col-md-4 text-start py-2">
                      <div className="f14 black">Designation</div>
                    </Col>
                    <Col className="col-12 col-md-8 text-start py-2">
                      <div className="f14 black">
                        <strong>
                          <i>
                            {userDetails?.type == 1
                              ? "Citizen"
                              : userDetails?.type == 2
                              ? "Architect"
                              : "Structural Engineer"}
                          </i>
                        </strong>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col className="col-12 col-md-12 text-start py-2">
              <Row>
                <Col className="col-12 col-md-6 text-start py-2">
                  <Row>
                    <Col className="col-12 col-md-4 text-start py-2">
                      <div className="f14 black">Registration No</div>
                    </Col>
                    <Col className="col-12 col-md-8 text-start py-2">
                      <div className="f14 black">
                        <strong>
                          {userDetails.type != 1
                            ? userDetails.user_detail
                            : "-"}
                        </strong>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-12 col-md-6 text-start py-2">
                  <Row>
                    <Col className="col-12 col-md-4 text-start py-2">
                      <div className="f14 black">Qualification</div>
                    </Col>
                    <Col className="col-12 col-md-8 text-start py-2">
                      <div className="f14 black">
                        <strong>{userDetails.user_detail.qualification}</strong>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <div id="static">
            <Row>
              <Col className="col-12 col-md-12 text-start">
                <div className="profile-title">
                  <div className="f12 black px-3">
                    <strong>Contact Information</strong>
                  </div>
                </div>
              </Col>

              <Col className="col-12 col-md-12 text-start py-2">
                <Row>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">Email</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <div className="f14 black">
                          <strong>{email}</strong>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">Mobile No.</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <div className="f14 black">
                          <strong>{mobile}</strong>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col className="col-12 col-md-12 text-start py-2">
                <Row>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">Landline</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <div className="f14 black">
                          <strong>{landline}</strong>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">
                          Fax
                        </div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <div className="f14 black">
                          <strong>
                            -
                          </strong>
                        </div>
                      </Col>
                    </Row>
                  </Col> */}
                </Row>
              </Col>
            </Row>

            <Row>
              <Col className="col-12 col-md-12 text-start">
                <div className="profile-title">
                  <div className="f12 black px-3">
                    <strong>Basic Information</strong>
                  </div>
                </div>
              </Col>

              <Col className="col-12 col-md-12 text-start py-2">
                <Row>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">Address</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <div className="f14 black">
                          <strong>{address}</strong>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">City</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <div className="f14 black">
                          <strong>{city}</strong>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col className="col-12 col-md-12 text-start py-2">
                <Row>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">State</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <div className="f14 black">
                          <strong>{state}</strong>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {userDetails.type != 1 && (
                    <Col className="col-12 col-md-6 text-start py-2">
                      <Row>
                        <Col className="col-12 col-md-4 text-start py-2">
                          <div className="f14 black">Country</div>
                        </Col>
                        <Col className="col-12 col-md-8 text-start py-2">
                          <div className="f14 black">
                            <strong>{country}</strong>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">PinCode</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <div className="f14 black">
                          <strong>{pincode}</strong>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {userDetails.type != 1 && (
              <Row>
                <Col className="col-12 col-md-12 text-start">
                  <div className="profile-title">
                    <div className="f12 black px-3">
                      <strong>Short Profile</strong>
                    </div>
                  </div>
                </Col>

                <Col className="col-12 col-md-12 text-start py-2">
                  <Row>
                    <Col className="col-12 col-md-12 text-start py-2">
                      <Row>
                        <Col className="col-12 col-md-4 text-start py-2">
                          <div className="f14 black">Short Profile</div>
                        </Col>
                        <Col className="col-12 col-md-8 text-start py-2">
                          <div className="f14 black">
                            <strong>-</strong>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </div>

          <div id="edit" style={{ display: "none" }}>
            <Row>
              <Col className="col-12 col-md-12 text-start">
                <div className="profile-title">
                  <div className="f12 black px-3">
                    <strong>Contact Information</strong>
                  </div>
                </div>
              </Col>

              <Col className="col-12 col-md-12 text-start py-2">
                <Row>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">Email</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <InputGroup className="mb-1">
                          <FormControl
                            className="inputBox"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">Mobile No.</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <InputGroup className="mb-1">
                          <FormControl
                            className="inputBox"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col className="col-12 col-md-12 text-start py-2">
                <Row>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">Landline</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <InputGroup className="mb-1">
                          <FormControl
                            className="inputBox"
                            value={landline}
                            onChange={(e) => setLandline(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">
                          Fax
                        </div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <InputGroup className="mb-1">
                          <FormControl className="inputBox" />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col> */}
                </Row>
              </Col>
            </Row>

            <Row>
              <Col className="col-12 col-md-12 text-start">
                <div className="profile-title">
                  <div className="f12 black px-3">
                    <strong>Basic Information</strong>
                  </div>
                </div>
              </Col>

              <Col className="col-12 col-md-12 text-start py-2">
                <Row>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">Address</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <InputGroup className="mb-1">
                          <FormControl
                            className="inputBox"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">City</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <InputGroup className="mb-1">
                          <FormControl
                            className="inputBox"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col className="col-12 col-md-12 text-start py-2">
                <Row>
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">State</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <InputGroup className="mb-1">
                          <FormControl
                            className="inputBox"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                  {userDetails.type != 1 && (
                    <Col className="col-12 col-md-6 text-start py-2">
                      <Row>
                        <Col className="col-12 col-md-4 text-start py-2">
                          <div className="f14 black">Country</div>
                        </Col>
                        <Col className="col-12 col-md-8 text-start py-2">
                          <InputGroup className="mb-1">
                            <FormControl
                              className="inputBox"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  <Col className="col-12 col-md-6 text-start py-2">
                    <Row>
                      <Col className="col-12 col-md-4 text-start py-2">
                        <div className="f14 black">PinCode</div>
                      </Col>
                      <Col className="col-12 col-md-8 text-start py-2">
                        <InputGroup className="mb-1">
                          <FormControl
                            className="inputBox"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {userDetails.type != 1 && (
              <Row>
                <Col className="col-12 col-md-12 text-start">
                  <div className="profile-title">
                    <div className="f12 black px-3">
                      <strong>Short Profile</strong>
                    </div>
                  </div>
                </Col>

                <Col className="col-12 col-md-12 text-start py-2">
                  <Row>
                    <Col className="col-12 col-md-12 text-start py-2">
                      <Row>
                        <Col className="col-12 col-md-2 text-start py-2">
                          <div className="f14 black">Short Profile</div>
                        </Col>
                        <Col className="col-12 col-md-10 text-start py-2">
                          <InputGroup className="mb-1">
                            <FormControl
                              className="inputBox"
                              value={shortProfile}
                              onChange={(e) => setShortProfile(e.target.value)}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Col>
    </Col>
  );
};

export default Profile;
