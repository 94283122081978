import { useState } from 'react';
import { USER_APPLICATION_ID } from '../constant/Constant';

export default function UserApplication() {
    const getUserApplicationId = () => {
        const id = localStorage.getItem(USER_APPLICATION_ID);
        // const userToken = JSON.parse(tokenString);
        // return userToken?.token
        return id
    };

    const [userApplicationId, setUserApplicationId] = useState(getUserApplicationId());

    const saveUserApplicationId = id => {
        localStorage.setItem(USER_APPLICATION_ID, id);
        setUserApplicationId(id);
    };

    return {
        setUserApplicationId: saveUserApplicationId,
        userApplicationId
    }
}