import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Button, Col, Tab, Tabs, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import UserDetails from "../../services/storage/UserDetails";
import Header from "../Header";
import UserApplicationForm from "./ApplicationForm";
import UserDocument from "./UserDocumentEdit";
import UserDrawing from "./UserDrawingEdit";

const ViewApplication = (props) => {
  const { userDetails } = UserDetails();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.file_no) {
    } else {
      history.goBack();
    }
  }, []);
  return (
    <Col className="d-lg-flex">
      {/* <SideBar /> */}
      {location?.state?.file_no && (
        <Col className="rt-dashboard">
          <Header />
          <Row className="px-2 bg-green p-2 border-bottom">
            <Col className="col-12 col-md-3 mb-2 mb-md-0">
              <p className="f14 mb-0">
                <i>File No: {location.state.file_no}</i>
              </p>
            </Col>
            <Col className="col-12 col-md-3 text-md-center mb-2 mb-md-0">
              <h4 className="f14 mb-0">
                <i>Proposal Status: Draft Mode</i>
              </h4>
            </Col>
            <Col className="col-12 col-md-6 text-md-end mb-2 mb-md-0">
              <p className="f14 mb-0">
                <i>
                  {userDetails?.type == 1
                    ? "Citizen"
                    : userDetails?.type == 2
                    ? "Architect"
                    : "Structural Engineer"}{" "}
                  : {userDetails?.title}. {userDetails?.first_name}{" "}
                  {userDetails?.middle_name} {userDetails?.last_name}
                </i>
              </p>
            </Col>
          </Row>

          <Col className="d-flex justify-content-between w-100 px-2 bg-green border-bottom">
            <Link
              to="/draft-application"
              className="d-flex black align-items-center p-2"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} />
              <p className="mb-0 ms-2">Back</p>
            </Link>
            <Button className="proposal-risk-back">
              Proposal Risk Category
            </Button>
          </Col>

          <Col className="d-flex justify-content-between w-100 px-2 p-2 mb-2 bg-green border-bottom">
            <h4 className="f14 mb-0">Fire NOC For owner: Draft</h4>
          </Col>

          <Col className="px-2 app-inform">
            <Tabs id="uncontrolled-tab-example" className="mb-0">
              <Tab
                className="userApplic"
                eventKey="userapplicationform"
                title="Application Form"
              >
                <UserApplicationForm data={location.state} />
              </Tab>
              <Tab
                className="userApplic"
                eventKey="userdocument"
                title="Document"
              >
                <UserDocument data={location.state} />
              </Tab>
              <Tab eventKey="userdrawing" title="Drawing">
                <UserDrawing data={location.state} />
              </Tab>
            </Tabs>
          </Col>
        </Col>
      )}
    </Col>
  );
};

export default ViewApplication;
