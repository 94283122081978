import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationService from "../../services/service/ApplictionService";
import ReportOneg from "../Report/thirdchangeReport";
import pdfgenerate from "../Report/pdfgenerate";
import test1 from "../Report/test1";
import UserDetails from "../../services/storage/UserDetails";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";
import ApplicationRejectService from "../../services/service/ApplicationRejectService";

const UserDrawing = (props) => {
  const [file, setFile] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [modal, setModal] = useState(false);
  const [jsonData, setJsonData] = useState("");
  const [update, setUpdate] = useState(false);
  const [drawingID, setDrawingID] = useState("");
  const [reportData, setReportData] = useState("");
  const [status, setStatus] = useState("");
  const [drawingDetails, setDrawingDetails] = useState("");
  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFileURL("");
  };
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = () => {
    ApplicationService.viewApplication({
      user_application_id: props.data.id,
      type: 8,
    })
      .then((result) => {
        let response = result.data.user_application_drawing;
        if (response != null) {
          // setUpdate(true);
          // setFileURL(response.drawing);
          setDrawingDetails(response);
          setDrawingID(response.id);
          setStatus(response.status);
          // handleClick();
        }
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const handleClick = () => {
    ApplicationUpdateService.viewReportDetail({
      user_application_id: props.data.id,
    })
      .then((result) => {
        let response = result.data;
        setReportData(response);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewReport = async () => {
    var temp = {};
    temp.owner = {
      firstName: "test",
      lastName: "test2",
      email: "test@gmail.com",
      mobile: "9566673188",
    };
    temp.mobile = "9266673188";
    temp.architect = {
      name: "test",
      email: "test@gmail.com",
      mobile: "9566673188",
    };
    temp.report = JSON.parse(reportData.rule_engine_json_response);
    // temp.report = test1;
    let newreport = new ReportOneg(temp);
    let results = await newreport.show();
    setJsonData(results);
    setModal(true);
  };
  const viewPDF = () => {
    setJsonData(reportData.scrutiny_report_pdf);
    setModal(true);
  };
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const save = () => {
    if (file != "") {
      var form_data = new FormData();
      form_data.append("user_application_id", props.data.id);
      form_data.append("drawing", file);
      if (drawingDetails?.commissioner_approval == 2) {
        form_data.append("type", 1);
      }
      if (drawingDetails?.zm_approval == 2) {
        form_data.append("type", 2);
      }

      if (drawingDetails?.dgm_approval == 2) {
        form_data.append("type", 3);
      }

      ApplicationRejectService.drawingChange(drawingID, form_data)
        .then((result) => {
          alert(
            result.data.message
              ? result.data.message
              : "Application saved successfully"
          );
          setUpdate(true);
          getDetails();
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("Please select a Drawing File ?");
    }
  };

  return (
    <>
      <Col>
        <Col className="d-flex w-100 mt-3">
          <Col lg={12} className="d-flex align-items-center my-auto">
            {props.data?.status == 4 ||
            props.data?.status == 17 ||
            drawingDetails?.commissioner_approval == 2 ||
            drawingDetails?.zm_approval == 2 ||
            (drawingDetails?.dgm_approval == 2 && update == false) ? (
              <input
                style={{ width: "190px", fontSize: "13px", margin: "auto" }}
                type="file"
                accept=".dwg,.DWG"
                onChange={handleChange}
              />
            ) : null}
            {props.data.status == 3 && (
              <Col>
                <div className="d-flex justify-content-lg-center align-items-center">
                  Drawing Submitted Successfully
                </div>
              </Col>
            )}
          </Col>
        </Col>
        {modal === true ? (
          <>
            <Modal
              show={modal}
              onHide={() => {
                setModal(false);
              }}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                  Report
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <iframe src={jsonData} style={{ height: "500px" }}></iframe>
                </Row>
              </Modal.Body>
            </Modal>
          </>
        ) : null}
      </Col>

      {props.data?.status == 4 ||
      props.data?.status == 17 ||
      drawingDetails?.commissioner_approval == 2 ||
      drawingDetails?.zm_approval == 2 ||
      (drawingDetails?.dgm_approval == 2 && update == false) ? (
        <Col className="d-flex justify-content-center mb-2 bg-white">
          <Button
            onClick={save}
            className="tab-blu-back "
            style={{
              color: "#fff",
              fontSize: 15,
              marginTop: 30,
              padding: "6px 14px",
              backgroundColor: "#5593db",
            }}
          >
            <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
            Submit Drawing
          </Button>
        </Col>
      ) : null}
    </>
  );
};

export default UserDrawing;
