import React from "react";
import { Button, Row, Col, Pagination, Form, FormControl, InputGroup } from "react-bootstrap";
import { AutoDcrLogo, DashboardLogo } from "../assets/images/Index";

const OnlinePayment = () => {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  return (
    <Col className="d-lg-flex">
      <Col className="">
        <Col className="d-md-flex justify-content-between align-items-center px-2 mb-2 bg-light">
          <Col className="text-Start py-2">
            <DashboardLogo />
          </Col>
          <Col className="text-center py-2">
            <div className="f14 black fw-normal text-uppercase">
              Online Payments
            </div>
          </Col>
          <Col className="text-end py-2">
            <AutoDcrLogo />
          </Col>
        </Col>

        <Row className="justify-content-center">
          <Col className="col-12 col-md-8 mt-4">
            <Row className="mb-2">
              <Col className="col-12 col-md-8 ps-0 pe-0 border-end border-dark bg-light ">
                <div className="bg1 f14 text-light p-2 fw-bold">
                  File Details
                </div>
                <div className="f14 text-light py-2 px-2">
                  <Col className="col-12 col-md-12 d-flex">
                    <Form.Check type="radio" className="ps-3" aria-label="radio 1" /> &nbsp;
                    <Form.Check.Label className="black f14">File No.</Form.Check.Label>
                    <Form.Check type="radio" className="ps-3" aria-label="radio 1" /> &nbsp;
                    <Form.Check.Label className="black f14">Challan No.</Form.Check.Label>
                  </Col>

                  <Row className="mb-2">
                    <Col className="col-12 col-md-3">
                      <Form.Label className="mt-2 mb-0 black f14">Serve Number</Form.Label>
                    </Col>
                    <Col className="col-12 col-md-6">
                      <InputGroup className="mb-1">
                        <FormControl className="inputBox" />
                      </InputGroup>
                    </Col>
                    <Col className="col-12 col-md-3">
                      <Button variant="outline-secondary" className="py-0">
                        Go
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col className="col-12 col-md-3">
                      <Form.Label className="mt-2 mb-0 black f14">Architect Name</Form.Label>
                    </Col>
                    <Col className="col-12 col-md-9">
                      <Form.Label className="mt-2 mb-0 black f14">-</Form.Label>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col className="col-12 col-md-3">
                      <Form.Label className="mt-2 mb-0 black f14">Owner Name</Form.Label>
                    </Col>
                    <Col className="col-12 col-md-9">
                      <Form.Label className="mt-2 mb-0 black f14">-</Form.Label>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col className="col-12 col-md-3">
                      <Form.Label className="mt-2 mb-0 black f14">Case Type</Form.Label>
                    </Col>
                    <Col className="col-12 col-md-9">
                      <Form.Label className="mt-2 mb-0 black f14">-</Form.Label>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col className="col-12 col-md-3">
                      <Form.Label className="mt-2 mb-0 black f14">Challan No.</Form.Label>
                    </Col>
                    <Col className="col-12 col-md-9">
                      <Form.Select aria-label="Default select example">
                        <option>select </option>
                        <option value="1"></option>
                        <option value="2"></option>
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col className="col-12 col-md-3">
                      <Form.Label className="mt-2 mb-0 black f14">Challan Type</Form.Label>
                    </Col>
                    <Col className="col-12 col-md-9">
                      <Form.Label className="mt-2 mb-0 black f14">-</Form.Label>
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col className="col-12 col-md-3">
                      <Form.Label className="mt-2 mb-0 black f14">Amount to Pay(INR)</Form.Label>
                    </Col>
                    <Col className="col-12 col-md-9">
                      <InputGroup className="mb-1">
                        <FormControl className="inputBox" />
                      </InputGroup>
                    </Col>
                  </Row>

                </div>
              </Col>
              <Col className="col-12 col-md-4 ps-0 pe-0 bg-light ">
                <div className="bg1 f14 text-light p-2 fw-bold">
                  Payment Option
                </div>
                <div className="bg-light f14 text-light py-5 px-2">
                  <div className="text-center">
                    <Button variant="outline-secondary">
                      Pay
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default OnlinePayment;
