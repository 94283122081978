import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { NoImage } from "../../assets/images/Index";
import { validEmail, validMobilNo } from "../../services/constant/Constant";
import ErrorHandler from "../../services/constant/ErrorHandler";
import RegisterService from "../../services/service/RegisterService";
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

const RegistrationForm = () => {
  const [namePrefix, setNamePrefix] = useState('Mr');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [type, setType] = useState(2);
  const [qualification, setQualification] = useState('');
  const [address, setAddress] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [email, setEmail] = useState('');
  const [proofPrefix, setProofPrefix] = useState(1);
  const [proof, setProof] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [accept, setAccept] = useState(false);
  const [experence, setExperence] = useState('');
  const [firmName, setFirmName] = useState('');
  const [shortProfile, setShortProfile] = useState('');
  const [nationality, setNationality] = useState('');
  const [dob, setDob] = useState('');
  const [registrationCertificate, setRegistrationCertificate] = useState('');
  const [registrationCertificateFile, setRegistrationCertificateFile] = useState('');
  const [licenceNo, setLicenceNo] = useState('');
  const [validDate, setValidDate] = useState('');
  const [question, setQuestion] = useState('What was your childhood nickname ?');
  const [answer, setAnswer] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [proofNumber, setProofNumber] = useState('');


  const [namePrefixErr, setNamePrefixErr] = useState(null);
  const [firstNameErr, setFirstNameErr] = useState(null);
  const [middleNameErr, setMiddleNameErr] = useState(null);
  const [lastNameErr, setLastNameErr] = useState(null);
  const [qualificationErr, setQualificationErr] = useState(null);
  const [addressErr, setAddressErr] = useState(null);
  const [stateErr, setStateErr] = useState(null);
  const [cityErr, setCityErr] = useState(null);
  const [pincodeErr, setPincodeErr] = useState(null);
  const [mobileNoErr, setMobileNoErr] = useState(null);
  const [emailErr, setEmailErr] = useState(null);
  const [proofPrefixErr, setProofPrefixErr] = useState(null);
  const [proofErr, setProofErr] = useState(null);
  const [userNameErr, setUserNameErr] = useState(null);
  const [passwordErr, setPasswordErr] = useState(null);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(null);
  const [acceptErr, setAcceptErr] = useState(null);
  const [experenceErr, setExperenceErr] = useState(null);
  const [firmNameErr, setFirmNameErr] = useState(null);
  const [shortProfileErr, setShortProfileErr] = useState(null);
  const [nationalityErr, setNationalityErr] = useState(null);
  const [dobErr, setDobErr] = useState(null);
  const [registrationCertificateErr, setRegistrationCertificateErr] = useState(null);
  const [registrationCertificateFileErr, setRegistrationCertificateFileErr] = useState(null);
  const [validDateErr, setValidDateErr] = useState(null);
  const [questionErr, setQuestionErr] = useState(null);
  const [answerErr, setAnswerErr] = useState(null);
  const [profileImageErr, setProfileImageErr] = useState(null);
  const [licenceNoErr, setLicenceNoErr] = useState(null);
  const [proofNumberErr, setProofNumberErr] = useState(null);

  useEffect(() => {
    loadCaptchaEnginge(6, "grey", "blue");
  }, []);
  const doSubmit = () => {
    let user_captcha_value = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(user_captcha_value) == true) {
      handleSubmit();
    }

    else {
      alert('Captcha Does Not Match');
    }
  };
  const changeNamePrefix = (e) => {
    setNamePrefix(e.target.value);
  }
  const changeFirstName = (e) => {
    setFirstName(e.target.value);
    if (e.target.value != "") {
      setFirstNameErr(false);
    } else {
      setFirstNameErr(true);
    }
  }
  const changeMiddleName = (e) => {
    setMiddleName(e.target.value);
    if (e.target.value != "") {
      setMiddleNameErr(false);
    } else {
      setMiddleNameErr(true);
    }
  }

  const changeLastName = (e) => {
    setLastName(e.target.value);
    if (e.target.value != "") {
      setLastNameErr(false);
    } else {
      setLastNameErr(true);
    }
  }
  const changeQualification = (e) => {
    setQualification(e.target.value);
    if (e.target.value != "") {
      setQualificationErr(false);
    } else {
      setQualificationErr(true);
    }
  }
  const changeAddress = (e) => {
    setAddress(e.target.value);
    if (e.target.value != "") {
      setAddressErr(false);
    } else {
      setAddressErr(true);
    }
  }
  const changeState = (e) => {
    setState(e.target.value);
    if (e.target.value != "") {
      setStateErr(false);
    } else {
      setStateErr(true);
    }
  }
  const changeCity = (e) => {
    setCity(e.target.value);
    if (e.target.value != "") {
      setCityErr(false);
    } else {
      setCityErr(true);
    }
  }
  const changePincode = (e) => {
    setPincode(e.target.value);
    if (e.target.value != "") {
      setPincodeErr(false);
    } else {
      setPincodeErr(true);
    }
  }
  const changeMobileNo = (e) => {
    setMobileNo(e.target.value);
    if (e.target.value != "" && validMobilNo.test(e.target.value)) {
      setMobileNoErr(false);
    } else {
      setMobileNoErr(true);
    }
  }
  const changeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value != "" && validEmail.test(e.target.value)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  }
  const changeProofPrefix = (e) => {
    setProofPrefix(e.target.value);
  }
  const changeProof = (e) => {
    setProof(e.target.files[0]);
    if (e.target.files[0] != "") {
      if (e.target.files[0].size <= 26214400) {
        setProofErr(false);
      } else {
        alert("File size should below 25 MB");
        setProofErr(true);
      }
    } else {
      setProofErr(true);
    }
  };
  const changeUserName = (e) => {
    setUserName(e.target.value);
    if (e.target.value != "") {
      if (e.target.value.length >= 3) {
        RegisterService.userNameCheck({ username: e.target.value })
          .then((result) => {
            setUserNameErr(false);
          })
          .catch(function (error) {
            setUserNameErr(true);
            // ErrorHandler(error);
          });
      } else {
        setUserNameErr(true);
      }
    } else {
      setUserNameErr(true);
    }
  };
  const changePassword = (e) => {
    setPassword(e.target.value);
    if (e.target.value != "") {
      setPasswordErr(false);
      if (confirmPassword != "") {
        if (e.target.value == confirmPassword) {
          setConfirmPasswordErr(false);
        } else {
          setConfirmPasswordErr(true);
        }
      }
    } else {
      setPasswordErr(true);
    }
  };
  const changeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value != "" && e.target.value == password) {
      setConfirmPasswordErr(false);
    } else {
      setConfirmPasswordErr(true);
    }
  };
  const changeAccept = (e) => {
    setAccept(e.target.value);
  };
  const changeExperince = (e) => {
    setExperence(e.target.value);
    if (e.target.value != "") {
      setExperenceErr(false);
    } else {
      setExperenceErr(true);
    }
  };

  const changeFirmName = (e) => {
    setFirmName(e.target.value);
    if (e.target.value != "") {
      setFirmNameErr(false);
    } else {
      setFirmNameErr(true);
    }
  };

  const changeShortProfile = (e) => {
    setShortProfile(e.target.value);
    if (e.target.value != "") {
      setShortProfileErr(false);
    } else {
      setShortProfileErr(true);
    }
  };
  const changeNationality = (e) => {
    setNationality(e.target.value);
    if (e.target.value != "") {
      setNationalityErr(false);
    } else {
      setNationalityErr(true);
    }
  };
  const changeDob = (e) => {
    setDob(e.target.value);
    if (e.target.value != "") {
      setDobErr(false);
    } else {
      setDobErr(true);
    }
  };
  const [imageUrl, setImageUrl] = useState("");
  const changeProfileImage = (e) => {
    setProfileImage(e.target.files[0]);
    var file = e.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);
    reader.onloadend = function (e) {
      setImageUrl(reader.result);
    };
    if (e.target.files[0] != "") {
      if (e.target.files[0].size <= 26214400) {
        setProfileImageErr(false);
      } else {
        alert("File size should below 25 MB");
        setProfileImageErr(true);
      }
    } else {
      setProfileImageErr(true);
    }
  };
  const changeRegistrationCertificate = (e) => {
    setRegistrationCertificate(e.target.value);
    if (e.target.value != "") {
      setRegistrationCertificateErr(false);
    } else {
      setRegistrationCertificateErr(true);
    }
  };
  const changeRegistrationCertificateFile = (e) => {
    setRegistrationCertificateFile(e.target.files[0]);
    if (e.target.files[0] != "") {
      if (e.target.files[0].size <= 26214400) {
        setRegistrationCertificateFileErr(false);
      } else {
        alert("File size should below 25 MB");
        setRegistrationCertificateFileErr(true);
      }
    } else {
      setRegistrationCertificateFileErr(true);
    }
  };
  const changeLicenceNo = (e) => {
    setLicenceNo(e.target.value);
    if (e.target.value != "") {
      setLicenceNoErr(false);
    } else {
      setLicenceNoErr(true);
    }
  }
  const changeValidDate = (e) => {
    setValidDate(e.target.value);
    if (e.target.value != "") {
      setValidDateErr(false);
    } else {
      setValidDateErr(true);
    }
  }
  const changeAnswer = (e) => {
    setAnswer(e.target.value);
    if (e.target.value != "") {
      setAnswerErr(false);
    } else {
      setAnswerErr(true);
    }
  }
  const changeProofNumber = (e) => {
    setProofNumber(e.target.value);
    if (e.target.value != "") {
      setProofNumberErr(false);
    } else {
      setProofNumberErr(true);
    }
  }
  const handleSubmit = () => {
    if (firstNameErr == false && lastNameErr == false && qualificationErr == false && addressErr == false && stateErr == false && cityErr == false && pincodeErr == false && mobileNoErr == false && emailErr == false && proofErr == false && userNameErr == false && passwordErr == false && confirmPasswordErr == false && experenceErr == false && firmNameErr == false && shortProfileErr == false && profileImageErr === false && nationalityErr == false && dobErr == false && registrationCertificateErr == false && registrationCertificateFileErr == false && licenceNoErr == false && validDateErr == false && answerErr == false && proofNumberErr == false) {
      var form_data = new FormData();
      form_data.append('title', namePrefix);
      form_data.append('type', type.toString());
      form_data.append('first_name', firstName);
      form_data.append('middle_name', middleName);
      form_data.append('last_name', lastName);
      form_data.append('qualification', qualification);
      form_data.append('address', address);
      form_data.append('state', state);
      form_data.append('city', city);
      form_data.append('pin_code', pincode);
      form_data.append('mobile', mobileNo.toString());
      form_data.append('email', email);
      form_data.append('username', userName);
      form_data.append('password', password);
      form_data.append('id_type', proofPrefix.toString());
      form_data.append('id_image', proof);
      form_data.append('experience', experence);
      form_data.append('firm_name', firmName);
      form_data.append('short_profile', shortProfile);
      form_data.append('nationality', nationality);
      form_data.append('dob', dob);
      form_data.append('security_question', question);
      form_data.append('answer', answer);
      form_data.append('license_number', licenceNo);
      form_data.append('license_validity', validDate);
      form_data.append('id_number', proofNumber);
      form_data.append('certificate_type', registrationCertificate);
      form_data.append('certificate_image', registrationCertificateFile);
      form_data.append('image', profileImage);
      RegisterService.register(form_data).then(result => {
        if (result.data.status == 'success') {
          alert(result.data.message);
          window.location.href = '/';
        }
      }).catch(function (error) {
        ErrorHandler(error);
      });
    } else {
      if (firstName == '') {
        setFirstNameErr(true);
      }
      if (lastName == '') {
        setLastNameErr(true);
      }
      if (qualification == '') {
        setQualificationErr(true);
      }
      if (address == '') {
        setAddressErr(true);
      }
      if (state == '') {
        setStateErr(true);
      }
      if (city == '') {
        setCityErr(true);
      }
      if (pincode == '') {
        setPincodeErr(true);
      }
      if (mobileNo == '') {
        setMobileNoErr(true);
      }
      if (email == '') {
        setEmailErr(true);
      }
      if (proof == '') {
        setProofErr(true);
      }
      if (userName == '') {
        setUserNameErr(true);
      }

      if (password == '') {
        setPasswordErr(true);
      }
      if (confirmPassword == '') {
        setConfirmPasswordErr(true);
      }
      if (proofNumber == '') {
        setProofNumberErr(true);
      }

      if (experence == '') {
        setExperenceErr(true);
      }

      if (firmName == '') {
        setFirmNameErr(true);
      }

      if (shortProfile == '') {
        setShortProfileErr(true);
      }

      if (nationality == '') {
        setNationalityErr(true);
      }

      if (dob == '') {
        setDobErr(true);
      }
      if (registrationCertificate == '') {
        setRegistrationCertificateErr(true);
      }
      if (licenceNo == '') {
        setLicenceNoErr(true);
      }
      if (validDate == '') {
        setValidDateErr(true);
      }
      if (answer == '') {
        setAnswerErr(true);
      }
      alert('Please enter all the details ?')
    }
  }

  return (
    <div className="p-3 ">
      <div className="border1 border-color-lit-white border-solid">
        <h4 className="f15 black p-2 bg-lt-white">
          Welcome to Professional/ Consultant Registration Portal
        </h4>
        <div className="p-2">
          <h4 className="f15 black p-2 bg-lt-white border-btm1 border-color-lit-white border-solid  mb-0">
            Personal Information
          </h4>
        </div>

        <Row>
          <Col className="col-12 col-md-2 d-block d-md-none">
            <div className="ps-sm-2 mt-2 p-1 mt-lg-0 text-center bg-light">
              <NoImage />
              <br />
              <div class="upload-btn-wrapper">
                <Button variant="outline-secondary">Upload a file</Button>
                <input type="file" name="myfile" />
              </div>
            </div>
          </Col>
          <Col className="col-12 col-md-10">
            <Col className="d-lg-flex align-items-center p-2">

              <div className="reg-lab px-2 py-1 bg-lt-white">
                <p className="mb-0 f15 ">Name</p>
              </div>
              <Col className="d-sm-flex ps-lg-3">

                <Col className="ps-lg-2 mt-2 mt-lg-0">
                  <Form.Select
                    style={{ fontSize: "16px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={namePrefix}
                    onChange={changeNamePrefix}
                  >
                    <option className="f15 py-2" value="Mr">
                      Mr
                    </option>
                    <option className="f15 py-2" value="Mrs">
                      Mrs
                    </option>
                    <option className="f15 py-2" value="Ms">
                      Ms
                    </option>
                    <option className="f15 py-2" value="Miss">
                      Miss
                    </option>
                    <option className="f15 py-2" value="Messrs">
                      Messrs
                    </option>
                  </Form.Select>
                </Col>

                <Col className="ps-sm-2 mt-2 mt-lg-0">
                  <input style={{ borderColor: firstNameErr ? 'tomato' : '' }} value={firstName} onChange={changeFirstName}
                    className="form-control px-2 py-1 rounded-0"
                    placeholder="First Name"
                  />
                </Col>

                <Col className="ps-sm-2 mt-2 mt-lg-0">
                  <input value={middleName} onChange={changeMiddleName}
                    className="form-control px-2 py-1 rounded-0"
                    placeholder="Middle Name"
                  />
                </Col>

                <Col className="ps-sm-2 mt-2 mt-lg-0">
                  <input style={{ borderColor: lastNameErr ? 'tomato' : '' }} value={lastName} onChange={changeLastName}
                    className="form-control px-2 py-1 rounded-0"
                    placeholder="Last Name"
                  />
                </Col>
              </Col>

            </Col>

            <Col className="d-flex align-items-center px-2">
              <Col xs={12}>
                <Col className="d-md-flex align-items-center py-1">
                  <div className="reg-label px-2 py-1 bg-lt-white">
                    <p className="mb-0 f15">Professional Category</p>
                  </div>
                  <Col md={6} lg={5} className="d-md-flex ps-md-3 mt-2 mt-md-0">
                    <Form.Select
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option className="f15 py-2" value="2">
                        Architect
                      </option>
                      <option className="f15 py-2" value="3">
                        Structural Engineer
                      </option>
                    </Form.Select>
                  </Col>
                </Col>
                <Col className="d-md-flex align-items-center py-1 mt-3 mt-sm-0">
                  <div className="reg-label px-2 py-1 bg-lt-white">
                    <p className="mb-0 f15">Qualification</p>
                  </div>
                  <Col md={6} lg={5} className="d-md-flex ps-md-3 mt-1 mt-md-0">
                    <input style={{ borderColor: qualificationErr ? 'tomato' : '' }} value={qualification} onChange={changeQualification}
                      className="form-control px-2 py-1 rounded-0"
                      placeholder="Qualification"
                    />
                  </Col>
                </Col>
                <Col className="d-md-flex align-items-center py-1 mt-3 mt-sm-0">
                  <div className="reg-label px-2 py-1 bg-lt-white">
                    <p className="mb-0 f15">Total Experience (in years)</p>
                  </div>
                  <Col md={6} lg={5} className="d-md-flex ps-md-3 mt-1 mt-md-0">
                    <input style={{ borderColor: experenceErr ? 'tomato' : '' }} value={experence} onChange={changeExperince}
                      className="form-control px-2 py-1 rounded-0"
                      placeholder="Total Experience"
                    />
                  </Col>
                </Col>
                <Col className="d-md-flex align-items-center py-1 mt-3 mt-sm-0">
                  <div className="reg-label px-2 py-1 bg-lt-white">
                    <p className="mb-0 f15">
                      Firm Name (Employed or Self Registered)
                    </p>
                  </div>
                  <Col md={6} lg={5} className="d-md-flex ps-md-3 mt-1 mt-md-0">
                    <input style={{ borderColor: firstNameErr ? 'tomato' : '' }} value={firmName} onChange={changeFirmName}
                      className="form-control px-2 py-1 rounded-0"
                      placeholder="Firm Name"
                    />
                  </Col>
                </Col>
                <Col className="d-md-flex align-items-center py-1 mt-3 mt-sm-0">
                  <div className="reg-label px-2 py-1 bg-lt-white">
                    <p className="mb-0 f15">Short Profile (Experience Summary)</p>
                  </div>
                  <Col md={6} lg={5} className="d-md-flex ps-md-3 mt-1 mt-md-0">
                    <textarea style={{ borderColor: shortProfileErr ? 'tomato' : '' }} value={shortProfile} onChange={changeShortProfile}
                      className="form-control px-2 py-1 rounded-0"
                      placeholder="Short Profile"></textarea>
                  </Col>
                </Col>
              </Col>
              <Col style={{ width: "150px" }}></Col>
            </Col>
          </Col>
          <Col className="col-12 col-md-2 d-none d-md-block">
            <div className="ps-sm-2 mt-2 p-1 mt-lg-0 text-center bg-light">
              {profileImage != "" ?
                (
                  <img style={{ width: "125px " }} src={imageUrl} alt="" />
                )
                :
                <NoImage />
              }
              <br />
              <div class="upload-btn-wrapper">
                <Button variant="outline-secondary">Upload a file</Button>
                <input onChange={changeProfileImage} type="file" accept=".jpg,.jpeg,.png,.PNG,.JPG,.JEPG" name="myfile" />
              </div>
            </div>
          </Col>
        </Row>

        <Col className="d-md-flex">
          <Col className="d-md-flex align-items-center p-2">
            <div className="reg-label px-2 py-1 bg-lt-white">
              <p className="mb-0 f15">Nationality</p>
            </div>
            <Col className="ps-md-3">
              <input style={{ borderColor: nationalityErr ? 'tomato' : '' }} value={nationality} onChange={changeNationality}
                className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                placeholder="Nationality"
              />
            </Col>
          </Col>
          <Col className="d-md-flex align-items-center p-2">
            <div className="reg-label px-2 py-1 bg-lt-white">
              <p className="mb-0 f15">Date Of Birth</p>
            </div>
            <Col className="ps-md-3">
              <input style={{ borderColor: dobErr ? 'tomato' : '' }} value={dob} onChange={changeDob}
                type="date"
                className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                placeholder="Date Of Birth"
              />
            </Col>
          </Col>
        </Col>
        <Col className="p-2">
          <h4 className="f15 black p-2 bg-lt-white border-btm1 border-color-lit-white border-solid  mb-0">
            Contact Information
          </h4>
          <Col className="d-md-flex align-items-center py-1 mt-2">
            <div className="reg-label px-2 py-1 bg-lt-white">
              <p className="mb-0 f15">Postal Address</p>
            </div>
            <Col md={6} lg={5} className="d-md-flex ps-md-3 mt-md-0">
              <textarea style={{ borderColor: addressErr ? 'tomato' : '' }} value={address} onChange={changeAddress}
                className="form-control px-2 py-1 rounded-0"
                placeholder="Postal Address"></textarea>
            </Col>
          </Col>
          <Col className="d-md-flex">
            <Col className="d-md-flex align-items-center p-2">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">State</p>
              </div>
              <Col className="ps-md-3">
                <input style={{ borderColor: stateErr ? 'tomato' : '' }} value={state} onChange={changeState}
                  className="form-control px-2 py-1 rounded-0"
                  placeholder="State"
                />
              </Col>
            </Col>
            <Col className="d-md-flex align-items-center p-2">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">City</p>
              </div>
              <Col className="ps-md-3">
                <input style={{ borderColor: cityErr ? 'tomato' : '' }}
                  value={city} onChange={changeCity}
                  type="text"
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                  placeholder="City"
                />
              </Col>
            </Col>
          </Col>
          <Col className="d-md-flex">
            <Col className="d-md-flex align-items-center p-2">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">Pin code</p>
              </div>
              <Col className="ps-md-3">
                <input style={{ borderColor: pincodeErr ? 'tomato' : '' }} value={pincode} onChange={changePincode}
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                  placeholder="Pin code"
                />
              </Col>
            </Col>
            <Col className="d-md-flex align-items-center p-2">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">Mobile No</p>
              </div>
              <Col className="ps-md-3">
                <input style={{ borderColor: mobileNoErr ? 'tomato' : '' }} value={mobileNo} onChange={changeMobileNo}
                  type="number"
                  maxLength={10}
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                  placeholder=""
                />
              </Col>
            </Col>
          </Col>
          <Col className="d-md-flex">
            <Col md={6} className="d-md-flex align-items-center p-2">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">E-mail</p>
              </div>
              <Col className="ps-md-3">
                <input style={{ borderColor: emailErr ? 'tomato' : '' }} value={email} onChange={changeEmail}
                  type="email"
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                  placeholder=""
                />
              </Col>
            </Col>
          </Col>
        </Col>
        <Col className="p-2">
          <h4 className="f15 black p-2 bg-lt-white border-btm1 border-color-lit-white border-solid  mb-0">
            Registration Details
          </h4>
          <Col md={8} className="d-flex align-items-center py-1 mt-2">
            <Col xs={8} className="d-md-flex">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">Id Proof</p>
              </div>
              <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
                <Form.Select
                  style={{ fontSize: "15px" }}
                  aria-label="Default select example"
                  className="form-control px-2 py-1 rounded-0"
                  value={proofPrefix}
                  onChange={changeProofPrefix}
                >
                  <option className="f15 py-2" value="1">
                    Pan Card
                  </option>
                  <option className="f15 py-2" value="2">
                    Passport
                  </option>
                  <option className="f15 py-2" value="3">
                    Aadhar Card
                  </option>
                  <option className="f15 py-2" value="4">
                    Driving Licence
                  </option>
                  <option className="f15 py-2" value="5">
                    DOB Certificate
                  </option>
                  <option className="f15 py-2" value="6">
                    Voter ID
                  </option>
                  <option className="f15 py-2" value="7">
                    Bank Passbook
                  </option>
                  <option className="f15 py-2" value="8">
                    Self Declaration
                  </option>
                </Form.Select>
              </Col>
            </Col>
            <Col xs={4} className="d-md-flex ps-3 mt-md-0">
              <input onChange={changeProof}
                type="file" accept=".pdf,.jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0 input-files border-0"
              />
            </Col>
          </Col>
          <Col className="d-flex align-items-center py-1 mt-2">
            <Col xs={8} className="d-md-flex">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">ID Proof Number</p>
              </div>
              <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
                <input style={{ borderColor: proofNumberErr ? 'tomato' : '' }} value={proofNumber} onChange={changeProofNumber}
                  type="text"
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                  placeholder="ID Proof Number *"
                />
              </Col>
            </Col>
          </Col>
          <Col md={8} className="d-flex align-items-center py-1 mt-2">
            <Col xs={8} className="d-md-flex">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">
                  Registration certificate scanned copy
                </p>
              </div>
              <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
                <input style={{ borderColor: registrationCertificateErr ? 'tomato' : '' }} value={registrationCertificate} onChange={changeRegistrationCertificate}
                  type="text"
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                />
              </Col>
            </Col>
            <Col xs={4} className="d-md-flex ps-3 mt-md-0">
              <input onChange={changeRegistrationCertificateFile}
                type="file" accept=".pdf,.jpg,.jpeg,.png,.PNG,.JPG,.JEPG"
                className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0 border-0  border-0"
              />
            </Col>
          </Col>
          <Col className="d-flex align-items-center py-1 mt-2">
            <Col xs={8} className="d-md-flex">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">Registration No./ License No.</p>
              </div>
              <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
                <input style={{ borderColor: licenceNoErr ? 'tomato' : '' }} value={licenceNo} onChange={changeLicenceNo}
                  type="text"
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                  placeholder="Registration No./ License No"
                />
              </Col>
            </Col>
          </Col>
          <Col className="d-flex align-items-center py-1 mt-2">
            <Col xs={8} className="d-md-flex">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">Valid Up To</p>
              </div>
              <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
                <input style={{ borderColor: validDateErr ? 'tomato' : '' }}
                  value={validDate}
                  onChange={changeValidDate}
                  type="date"
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                  placeholder="Valid Up To"
                />
              </Col>
            </Col>
          </Col>
        </Col>{" "}
        <Col className="p-2">
          <h4 className="f15 black p-2 bg-lt-white border-btm1 border-color-lit-white border-solid  mb-0">
            Login Details
          </h4>
          <Col className="d-flex align-items-center py-1 mt-2">
            <Col md={8} className="d-md-flex">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">Login Name</p>
              </div>
              <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
                <input style={{ borderColor: userNameErr ? 'tomato' : '' }} value={userName} onChange={changeUserName}
                  type="text"
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                />
              </Col>
            </Col>
          </Col>
          <Col md={8} className="d-flex align-items-center py-1 mt-2">
            <Col className="d-md-flex">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">Password</p>
              </div>
              <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
                <input style={{ borderColor: passwordErr ? 'tomato' : '' }} value={password} onChange={changePassword}
                  type="password"
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                />
              </Col>
            </Col>
          </Col>
          <Col md={8} className="d-flex align-items-center py-1 mt-2">
            <Col className="d-md-flex">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">Re-Enter Password</p>
              </div>
              <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
                <input style={{ borderColor: confirmPasswordErr ? 'tomato' : '' }} value={confirmPassword} onChange={changeConfirmPassword}
                  type="password"
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                />
              </Col>
            </Col>
          </Col>
          <Col className="d-flex align-items-center py-1 mt-2">
            <Col md={8} className="d-md-flex">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">Security Question</p>
              </div>
              <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
                <Form.Select
                  style={{ fontSize: "15px" }}
                  aria-label="Default select example"
                  className="form-control px-2 py-1 rounded-0"
                  value={question} onChange={(e) => setQuestion(e.target.value)}
                >
                  <option className="f15 py-2" value="What was your childhood nickname ?">
                    What was your childhood nickname ?
                  </option>
                  <option className="f15 py-2" value="What is the name of your favorite childhood friend ?">
                    What is the name of your favorite childhood friend ?
                  </option>
                  <option className="f15 py-2" value="In what city or town did your mother and father meet ?">
                    In what city or town did your mother and father meet ?
                  </option>
                  <option className="f15 py-2" value="What is the middle name of your oldest child ?">
                    What is the middle name of your oldest child ?
                  </option>
                  <option className="f15 py-2" value="What is your favorite team ?">
                    What is your favorite team ?
                  </option>
                  <option className="f15 py-2" value="What is your favorite movie ?">
                    What is your favorite movie ?
                  </option>
                  <option className="f15 py-2" value="What is your favorite sport in high school ?">
                    What is your favorite sport in high school ?
                  </option>
                  <option className="f15 py-2" value="What was your favorite food as a child ?">
                    What was your favorite food as a child ?
                  </option>
                  <option className="f15 py-2" value="What was the make and model of your first car ?">
                    What was the make and model of your first car ?
                  </option>
                  <option className="f15 py-2" value="What was the name of the hospital where you were born ?">
                    What was the name of the hospital where you were born ?
                  </option>
                </Form.Select>
              </Col>
            </Col>
          </Col>
          <Col md={8} className="d-flex align-items-center py-1 mt-2">
            <Col className="d-md-flex">
              <div className="reg-label px-2 py-1 bg-lt-white">
                <p className="mb-0 f15">Answer</p>
              </div>
              <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
                <input style={{ borderColor: answerErr ? 'tomato' : '' }} value={answer} onChange={changeAnswer}
                  type="text"
                  className="form-control px-2 py-1 rounded-0 mt-2 mt-md-0"
                />
              </Col>
            </Col>
          </Col>
        </Col>
        <Col>
          <div className="container">
            <div className="form-group">
              <div className="col mt-3">
                <LoadCanvasTemplate />
              </div>
              <div className="col mt-3">
                <div><input placeholder="Enter Captcha Value" id="user_captcha_input" autocomplete="off" name="user_captcha_input" type="text"></input></div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={8} className="d-flex align-items-center py-1 mt-2">
          <Col className="d-md-flex">
            <Col className="d-md-flex ps-md-3 mt-2 mt-md-0">
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check value={accept} onClick={() => setAccept(!accept)}
                  type="checkbox"
                  label="I hereby declare that above information is correct."
                />
              </Form.Group>
            </Col>
          </Col>
        </Col>
        <Col className="btn-sub-mit">
          <Button onClick={doSubmit} disabled={accept ? false : true} className="bg-">Submit</Button>
        </Col>
      </div>
    </div>
  );
};

export default RegistrationForm;
