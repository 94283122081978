import httpClient from "../constant/httpClient"
import { URL } from "../constant/URL"

const payment_initiate=(payload)=>{
    return httpClient.post(URL.PAYMENT_INITIATE,payload);
}
const payment_receipt = (payload) => {
  return httpClient.post(URL.PAYMENT_RECEIPT, payload);
};

const PaymentService = {
  payment_initiate,
  payment_receipt,
};
export default PaymentService