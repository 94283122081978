import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Tab, Tabs } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";
import ApplicationService from "../../services/service/ApplictionService";
import Primary from "./Primary";
import Additional from "./Additional";
import HighRiseDocuments from "./HighRiseDocuments";

const UserDocument = (props) => {
  const [documentList, setDocumentList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [update, setUpdate] = useState(false);
  const [list, setList] = useState([]);
  let test = 1;
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = () => {
    ApplicationService.viewApplication({
      user_application_id: props.data.id,
      type: 7,
    })
      .then((result) => {
        let response = result.data.user_application_document;
        if (response.length > 0) {
          setList(response);
          test = test + 1;
          setUpdate(true);
          var temp = [];
          response.map((item, index) => {
            temp.push({
              application_document_id: item.application_document_id?.id,
              doc_file: item.doc_file,
              type: item.application_document_id?.type,
              preview: "",
            });
          });
          setDocuments(temp);
        }
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
    ApplicationService.getDocumentsList()
      .then((result) => {
        setDocumentList(result.data.applicationdocumentlist);
        if (test == 1) {
          var temp = [];
          result.data?.applicationdocumentlist.map((item, index) => {
            temp.push({
              application_document_id: item.id,
              doc_file: "",
              type: item.type,
              preview: "",
            });
          });
          setDocuments(temp);
        }
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const save = () => {
    if (update) {
      var count = 1;
      let payload = [];
      documents.map((item, index) => {
        if (typeof item.doc_file == "object") {
          payload.push(item);
          count = count + 1;
        }
      });
      if (count == 1) {
        alert("Please change file to update");
      } else {
        var form_data = new FormData();
        form_data.append("user_application_id", props.data.id);
        payload.map((item, index) => {
          form_data.append(
            `application_documents[${index}][application_document_id]`,
            item.application_document_id
          );
          form_data.append(
            `application_documents[${index}][doc_file]`,
            item.doc_file
          );
        });
        ApplicationUpdateService.updateDocument(form_data)
          .then((result) => {
            alert(
              result.data.message
                ? result.data.message
                : "Application saved successfully"
            );
            getDetails();
          })
          .catch(function (error) {
            ErrorHandler(error);
          });
      }
    } else {
      var count = 1;
      documents.map((item, index) => {
        if (item.doc_file == "" && item.type == 1) {
          count = count + 1;
        }
      });
      if (count == 1) {
        var form_data = new FormData();
        form_data.append("user_application_id", props.data.id);
        documents.map((item, index) => {
          if (item.doc_file != "") {
            form_data.append(
              `application_documents[${index}][application_document_id]`,
              item.application_document_id
            );
            form_data.append(
              `application_documents[${index}][doc_file]`,
              item.doc_file
            );
          }
        });
        ApplicationService.saveDocuments(form_data)
          .then((result) => {
            alert(
              result.data.message
                ? result.data.message
                : "Application saved successfully"
            );
            getDetails();
          })
          .catch(function (error) {
            ErrorHandler(error);
          });
      } else {
        alert("please select all Files ?");
      }
    }
  };
  return (
    <Col>
      {documents.length > 0 && (
        <Tabs id="uncontrolled-tab-example" className="mb-1 in-user-app">
          <Tab eventKey="generalinformation" title="Primary" className="py-0">
            <Primary
              getDetails={getDetails}
              list={list}
              documents={documents}
              setDocuments={setDocuments}
              data={documentList}
            />
          </Tab>
          <Tab
            eventKey="applicationinformation"
            title="Additional"
            className=" py-0"
          >
            <Additional
              getDetails={getDetails}
              list={list}
              documents={documents}
              setDocuments={setDocuments}
              data={documentList}
            />
          </Tab>
          <Tab eventKey="highrise" title="MSB HR" className=" py-0">
            <HighRiseDocuments
              getDetails={getDetails}
              list={list}
              documents={documents}
              setDocuments={setDocuments}
              data={documentList}
            />
          </Tab>
        </Tabs>
      )}
    </Col>
  );
};

export default UserDocument;
