import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationRejectService from "../../services/service/ApplicationRejectService";
const HighRiseDocuments = (props) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState('');
  const hiddenFileInput = React.useRef(null);
  const hiddenDateInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();

  }
  const handleChange = (e, value) => {
    if (e.target.files[0].size < 26214400) {
      var temp = [...props.documents];
      let index = temp.findIndex(
        (x) => x.application_document_id == value?.application_document_id?.id
      );
      if (index >= 0) {
        temp[index].doc_file = e.target.files[0];
        temp[index].preview = URL.createObjectURL(e.target.files[0]);
        props.setDocuments(temp);
      }
    } else {
      alert("File size should be less than 25MB ");
    }
  }
  const handleView = (value) => {
    setModalData(value?.doc_file);
    setModal(true);
  }
  const handlePreview = (value) => {
    setModalData(value?.preview);
    setModal(true);
  }
  const save = (value) => {
    let index = props.list.findIndex(x => x.application_document_id?.id == value?.application_document_id);
    if (value?.doc_file != "" && typeof value?.doc_file != "string") {
      var form_data = new FormData();
      form_data.append('user_application_id', props.list[index]?.user_application_id);
      form_data.append('application_document_id', props.list[index].application_document_id?.id);
      form_data.append('doc_file', value.doc_file);
      if (props.list[index]?.commissioner_approval == 2) {
        form_data.append('type', 1);
      }
      if (props.list[index]?.zm_approval == 2) {
        form_data.append('type', 2);
      }
      ApplicationRejectService.documentChange(form_data).then(result => {
        alert(result.data.message);
        props.getDetails();
      }).catch(function (error) {
        ErrorHandler(error);
      })
    } else {
      alert("Please select file !");
    }
  }
  return (
    <Col>
      {props.data.map((item, index) => {
        if (item.type == 3) {
          let temp = props.documents.filter(list => list.application_document_id
            == item.id);
          let temp1 = props.list.filter(lis => lis.application_document_id.id
            == item.id);
          return (
            <Col key={index} className="ps-2 py-0 d-flex col-md-12 mb-2 align-items-center justify-content-between">
              <Col md={6}>
                <div className="d-flex align-items-center">
                  {/* <input type="checkbox" disabled /> */}
                  <label className="f14 mb-0 ms-2">{item.name}</label>
                </div>
              </Col>
              <Col md={6}>
                <Row>
                  <Col>
                    {temp[0]?.doc_file != "" && typeof temp[0]?.doc_file == "string" &&
                      <Button
                        style={{ fontSize: "14px" }}
                        className="bg-transparent border-0 text-primary ms-auto me-0"
                        onClick={() => handleView(temp[0])}
                      >
                        View
                      </Button>
                    }
                  </Col>
                  <Col>
                    {temp1[0]?.commissioner_approval == 2 || temp1[0]?.zm_approval == 2 ?
                      <input
                        type="file"
                        accept=".pdf,.PDF"
                        onChange={(e) => handleChange(e, temp1[0])}
                      />
                      : null}
                  </Col>
                  <Col>
                    {temp1[0]?.commissioner_approval == 2 || temp1[0]?.zm_approval == 2 ?
                      <Button
                        onClick={() => save(temp[0])}
                        className="bg-white px-2 py-1 me-1 mb-1 border-1 border-solid border-color-lit-white rounded-2"
                        style={{
                          color: "#2f6db5",
                          fontSize: 13,
                        }}>
                        {/* <FontAwesomeIcon className="f15 me-2" icon={faFileAlt} /> */}
                        Upload
                      </Button>
                      : null}
                  </Col>
                  <Col>
                    {temp[0]?.preview && temp[0]?.preview != "" ?
                      <Button
                        style={{ fontSize: "14px" }}
                        className="bg-transparent border-0 text-primary ms-auto me-0"
                        onClick={() => handlePreview(temp[0])}
                      >
                        Preview
                      </Button>
                      : null}
                  </Col>
                </Row>
              </Col>
            </Col>
          )
        }
      })
      }
      {modal === true ?
        <>
          <Modal
            show={modal}
            onHide={() => { setModalData(''); setModal(false); }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16" >File</Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <Row>
                <iframe src={modalData} style={{ height: '500px' }} ></iframe>
              </Row>
            </Modal.Body>
          </Modal>

        </> : null
      }
    </Col>
  );
};

export default HighRiseDocuments;
