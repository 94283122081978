import { PDFExport } from "@progress/kendo-react-pdf";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import BuildingPermissionOrder from "../../screens/form/BuildingPermissionOrder";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";
import ApplicationService from "../../services/service/ApplictionService";
import ReportOneg from "../Report/thirdchangeReport";

const SanctionedFiles = (props) => {
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [accept, setAccept] = useState("");
  const [reason, setReason] = useState("");
  const pdfExportComponent = React.useRef(null);

  const getList = () => {
    ApplicationService.getApplicationList({ type: "sanction_files" })
      .then((result) => {
        setList(result.data.user_application_sanction_files);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  useEffect(() => {
    getList();
  }, []);
  const [modalType, setModalType] = useState("");
  const viewPreCheckReport = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("error");
        setModalData(response.precheck_errors);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewDrawingPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("report");
        setModalData(response?.scrutiny_report_pdf);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewScrutinyReportPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        // viewReport(response);
        setModalType("report");
        setModalData(response?.scrutiny_report);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewSanctionLetter = (value) => {
    // type=1->msb_letter,type=2->mom_letter,type=3->ca_letter,type=4->sanction_letter
    // ApplicationUpdateService.pdfLink(value.id, 4)
    ApplicationUpdateService.sanctionPdfLink({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("sanction_letter");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
    // ApplicationUpdateService.viewSanctionLetter({ user_application_id: value.id }).then(result => {
    //     let response = result.data;
    //     setModalType('sanction_letter');
    //     setModalData(response);
    //     setModal(true);
    // }).catch(function (error) {
    //     ErrorHandler(error);
    // });
  };
  const sanctionLetterAccept = (values) => {
    var payload = {};
    // payload.user_application_id = values?.id ? values.id : 0;
    payload.user_application_id = values ? values : 0;
    payload.status = accept == "accept" ? 1 : accept == "reject" ? 0 : null;
    if (accept == "reject") {
      payload.sanction_reject_reason = reason;
    }
    let count = 1;
    if (accept == "reject" && reason == "") {
      count = count + 1;
    }
    if (count == 1) {
      ApplicationUpdateService.sanctionLetterAccept(payload)
        .then((result) => {
          let response = result.data;
          alert(response.message);
          setAccept("");
          setReason("");
          setModal(false);
          getList();
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("Please enter reason to proceed");
    }
  };
  const viewReport = async (values) => {
    let temp = {};
    let drawing = JSON.parse(values.rule_engine_json_response);
    let details = values.scrutiny_report_datas;
    let anpdf = values.scrutiny_report_pdf;
    temp.owner = {
      firstName: details.applicant_name,
      lastName: details.applicant_name,
      email: details.email,
      address: details.address,
    };
    temp.mobile = details.mobile;
    temp.surveyNo = details.sector_number;
    temp.doorNo = details.plot_number;
    temp.locality = details.mandal_name;
    temp.village = details.village_name;
    temp.siteaddress = details.site_address;
    temp.useOfTheBuildings = details.land_use_category;
    temp.subuseOfTheBuildings = details.land_subuse_category;
    temp.existingRoadType = details.road_type;
    temp.proposedRoadType = details.land_subuse_category;
    temp.architect = {
      name: details.applicant_name,
      email: details.email,
      mobile: details.mobile,
    };
    temp.appliedOn = details.receipt_date;
    temp.applicationId = details.application_number;
    temp.report = drawing;
    let newreport = new ReportOneg(temp);
    let results = await newreport.show();
    // setModalType("report");
    // setModalData(results);
    // setModal(true);
  };

  const viewDemandNoteIssued = (value) => {
    ApplicationService.demandNoteIssued({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("fees");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewCELetter = (value) => {
    // type=1->msb_letter,type=2->mom_letter,type=3->ca_letter,type=4->sanction_letter
    // ApplicationUpdateService.pdfLink(value.id, 3)
    ApplicationUpdateService.taPdfDownload({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        // setModalType("ce_letter");
        setModalType("ta_letter");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewMOMPDF = (value) => {
    ApplicationUpdateService.momPdfDownload({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        console.log(response);
        // viewReport(response);
        setModalType("mom");
        setModalData(response?.mom_url);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const viewMsbAgengaPDF = (value) => {
    ApplicationUpdateService.msbPdfDownload({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        // console.log(response);
        // viewReport(response);
        setModalType("msb");
        setModalData(response?.agenda_url);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const columns = [
    {
      name: "sl",
      label: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{tableMeta.rowIndex + 1}</div>;
        },
      },
    },
    {
      name: "file_no",
      label: "File No",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Link
                to={{
                  pathname: "/application-view",
                  state: list[tableMeta.rowIndex],
                }}
              >
                {value}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "application_type",
      label: "Building Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "case_type",
      label: "Case Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "iala_name",
      label: "IALA",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pre_check_report",
      label: "Pre-Check Report",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? "f14 c4 text-center pre-pdf-btn"
                  : "f14 c4 text-center pre-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewPreCheckReport(list[tableMeta.rowIndex])}
                className="view-btn"
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "drawing_pdf",
      label: "Drawing PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? "f14 c4 text-center draw-pdf-btn"
                  : "f14 c4 text-center draw-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewDrawingPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "secrutiny_report_pdf",
      label: "Scrutiny Report PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? "f14 c4 text-center sec-pdf-btn"
                  : "f14 c4 text-center sec-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewScrutinyReportPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },

    // NEW

    {
      name: "demand_note_issued",
      label: "Fee Intimation ",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 0 &&
                list[tableMeta.rowIndex].status <= 11
                  ? "f14 c4 text-center pre-pdf-btn opacity-50"
                  : list[tableMeta.rowIndex].status == 14
                  ? "f14 c4 text-center pre-pdf-btn opacity-50"
                  : list[tableMeta.rowIndex].status >= 16 &&
                    list[tableMeta.rowIndex].status <= 20
                  ? "f14 c4 text-center pre-pdf-btn opacity-50"
                  : "f14 c4 text-center pre-pdf-btn"
              }
            >
              <button
                className="dwn-btn"
                disabled={
                  list[tableMeta.rowIndex].status > 0 &&
                  list[tableMeta.rowIndex].status <= 11
                    ? true
                    : list[tableMeta.rowIndex].status == 14
                    ? true
                    : list[tableMeta.rowIndex].status >= 16 &&
                      list[tableMeta.rowIndex].status <= 20
                    ? true
                    : false
                }
                onClick={() => viewDemandNoteIssued(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },

    // NEW

    {
      name: "msb_agenda",
      label: "MSB Agenda",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(list[tableMeta.rowIndex].status);
          return (
            <>
              {list[tableMeta.rowIndex].is_high_rise == 1 ? (
                <div
                  className={
                    list[tableMeta.rowIndex].status > 0 &&
                    list[tableMeta.rowIndex].status <= 14
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status == 15
                      ? "f14 c4 text-center msb-pdf-btn "
                      : list[tableMeta.rowIndex].status >= 16 &&
                        list[tableMeta.rowIndex].status <= 20
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status >= 21 &&
                        list[tableMeta.rowIndex].status <= 24
                      ? "f14 c4 text-center msb-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 25 &&
                        list[tableMeta.rowIndex].status <= 29
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status >= 30 &&
                        list[tableMeta.rowIndex].status <= 37
                      ? "f14 c4 text-center msb-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 38 &&
                        list[tableMeta.rowIndex].status <= 40
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status > 40
                      ? "f14 c4 text-center msb-pdf-btn"
                      : "f14 c4 text-center msb-pdf-btn opacity-50"
                  }
                >
                  <button
                    disabled={
                      list[tableMeta.rowIndex].status > 0 &&
                      list[tableMeta.rowIndex].status <= 14
                        ? true
                        : list[tableMeta.rowIndex].status == 15
                        ? false
                        : list[tableMeta.rowIndex].status >= 16 &&
                          list[tableMeta.rowIndex].status <= 20
                        ? true
                        : list[tableMeta.rowIndex].status >= 21 &&
                          list[tableMeta.rowIndex].status <= 24
                        ? false
                        : list[tableMeta.rowIndex].status >= 25 &&
                          list[tableMeta.rowIndex].status <= 29
                        ? true
                        : list[tableMeta.rowIndex].status >= 30 &&
                          list[tableMeta.rowIndex].status <= 37
                        ? false
                        : list[tableMeta.rowIndex].status >= 38 &&
                          list[tableMeta.rowIndex].status <= 40
                        ? true
                        : list[tableMeta.rowIndex].status > 40
                        ? false
                        : true
                    }
                    onClick={() => viewMsbAgengaPDF(list[tableMeta.rowIndex])}
                  >
                    View
                  </button>
                </div>
              ) : (
                " - "
              )}
            </>
          );
        },
      },
    },

    // NEW

    {
      name: "mom",
      label: "MOM",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {list[tableMeta.rowIndex].is_high_rise == 1 ? (
                <div
                  className={
                    list[tableMeta.rowIndex].status > 0 &&
                    list[tableMeta.rowIndex].status <= 14
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status == 15
                      ? "f14 c4 text-center msb-pdf-btn "
                      : list[tableMeta.rowIndex].status >= 16 &&
                        list[tableMeta.rowIndex].status <= 20
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status >= 21 &&
                        list[tableMeta.rowIndex].status <= 24
                      ? "f14 c4 text-center msb-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 25 &&
                        list[tableMeta.rowIndex].status <= 29
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status >= 30 &&
                        list[tableMeta.rowIndex].status <= 37
                      ? "f14 c4 text-center msb-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 38 &&
                        list[tableMeta.rowIndex].status <= 40
                      ? "f14 c4 text-center msb-pdf-btn opacity-50"
                      : list[tableMeta.rowIndex].status > 40
                      ? "f14 c4 text-center msb-pdf-btn"
                      : "f14 c4 text-center msb-pdf-btn opacity-50"
                  }
                >
                  <button
                    disabled={
                      list[tableMeta.rowIndex].status > 0 &&
                      list[tableMeta.rowIndex].status <= 14
                        ? true
                        : list[tableMeta.rowIndex].status == 15
                        ? false
                        : list[tableMeta.rowIndex].status >= 16 &&
                          list[tableMeta.rowIndex].status <= 20
                        ? true
                        : list[tableMeta.rowIndex].status >= 21 &&
                          list[tableMeta.rowIndex].status <= 24
                        ? false
                        : list[tableMeta.rowIndex].status >= 25 &&
                          list[tableMeta.rowIndex].status <= 29
                        ? true
                        : list[tableMeta.rowIndex].status >= 30 &&
                          list[tableMeta.rowIndex].status <= 37
                        ? false
                        : list[tableMeta.rowIndex].status >= 38 &&
                          list[tableMeta.rowIndex].status <= 40
                        ? true
                        : list[tableMeta.rowIndex].status > 40
                        ? false
                        : true
                    }
                    onClick={() => viewMOMPDF(list[tableMeta.rowIndex])}
                  >
                    View
                  </button>
                </div>
              ) : (
                " - "
              )}{" "}
            </>
          );
        },
      },
    },
    {
      name: "ta_letter",
      label: "TA Letter",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {list[tableMeta.rowIndex].is_high_rise == 1 ? (
                <div
                  className={
                    list[tableMeta.rowIndex].status == 15
                      ? "f14 c4 text-center sec-pdf-btn"
                      : list[tableMeta.rowIndex].status >= 22 &&
                        list[tableMeta.rowIndex].status <= 24
                      ? "f14 c4 text-center sec-pdf-btn"
                      : list[tableMeta.rowIndex].status == 37
                      ? "f14 c4 text-center sec-pdf-btn"
                      : "f14 c4 text-center sec-pdf-btn opacity-50"
                  }
                >
                  <button
                    onClick={() => viewCELetter(list[tableMeta.rowIndex])}
                    disabled={
                      list[tableMeta.rowIndex].status == 15
                        ? false
                        : list[tableMeta.rowIndex].status >= 22 &&
                          list[tableMeta.rowIndex].status <= 24
                        ? false
                        : list[tableMeta.rowIndex].status == 37
                        ? false
                        : true
                    }
                  >
                    View
                  </button>
                </div>
              ) : (
                " - "
              )}{" "}
            </>
          );
        },
      },
    },
    {
      name: "sanctioned_letter",
      label: "Sanction Letter",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="f14 c4 text-center pre-pdf-btn">
              {/* {list[tableMeta.rowIndex].status == 15 || list[tableMeta.rowIndex].status == 23 ? "f14 c4 text-center pre-pdf-btn" : "f14 c4 text-center pre-pdf-btn opacity-50"}> */}
              <button
                className={
                  list[tableMeta.rowIndex].status == 23
                    ? "dwn-btn opacity-50"
                    : "dwn-btn"
                }
                onClick={() => viewSanctionLetter(list[tableMeta.rowIndex])}
                disabled={list[tableMeta.rowIndex].status == 23 ? true : false}
              >
                View
              </button>
            </div>
          );
        },
      },
    },

    {
      name: "created_at",
      label: "Date Of Submission",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    selectableRows: false,
    filter: true,
    filterType: "dropdown",
  };

  return (
    <Col className="rt-dashboard">
      <MUIDataTable
        title={"Sanctioned Applications"}
        data={list}
        columns={columns}
        options={options}
      />
      {modal === true ? (
        <>
          <Modal
            show={modal}
            onHide={() => {
              setModal(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                {modalType == "report"
                  ? "Report"
                  : modalType == "sanction_letter"
                  ? "Sanction Letter"
                  : modalType == "msb"
                  ? "MSB Agenda"
                  : modalType == "mom"
                  ? "MOM"
                  : modalType == "ta_letter"
                  ? "TA Latter"
                  : modalType == "fees"
                  ? "Fees Intimation"
                  : "Errors"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {modalType == "report" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "error" ? (
                  <div>
                    <Row>
                      <Col>Errors</Col>
                    </Row>
                    {modalData.map((item, index) => {
                      return (
                        <Row>
                          <Col>{item}</Col>
                        </Row>
                      );
                    })}
                  </div>
                ) : modalType == "msb" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "mom" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "ta_letter" ? (
                  <div>
                    <iframe
                      src={modalData?.ta_url ? modalData.ta_url : null}
                      style={{ height: "500px", width: "100%" }}
                    ></iframe>
                  </div>
                ) : modalType == "fees" ? (
                  <iframe
                    src={modalData?.fee_intimation_url}
                    style={{ height: "500px" }}
                  ></iframe>
                ) : modalType == "sanction_letter" ? (
                  <div className="draw-pdf-btn">
                    {/* <PDFExport
                                                    ref={pdfExportComponent}
                                                    paperSize="A4"
                                                    fileName="Sanction-Letter"
                                                > */}
                    <div>
                      <iframe
                        // src={modalData.download_url}
                        src={modalData.sanction_url}
                        style={{ height: "500px", width: "100%" }}
                      ></iframe>
                      {/* <BuildingPermissionOrder data={modalData} /> */}
                    </div>
                    {/* </PDFExport>
                                                <div style={{ marginLeft: '45%' }}>
                                                    <button className="mt-2" onClick={() => {
                                                        if (pdfExportComponent.current) {
                                                            pdfExportComponent.current.save();
                                                        }
                                                    }}>Download</button>
                                                </div> */}
                    {modalData?.user_application?.status != 22 && (
                      <div style={{ textAlign: "center", marginTop: "10px" }}>
                        <div style={{ marginBottom: "10px" }}>
                          <input
                            type={"radio"}
                            checked={accept == "accept" ? true : false}
                            name="userAction"
                            onClick={() => setAccept("accept")}
                          />{" "}
                          Accept
                          <input
                            type={"radio"}
                            checked={accept == "reject" ? true : false}
                            name="userAction"
                            onClick={() => setAccept("reject")}
                            style={{ marginLeft: "10px" }}
                          />{" "}
                          Reject
                        </div>

                        {accept == "reject" && (
                          <div style={{ marginBottom: "10px" }}>
                            <textarea
                              placeholder="Why your are rejecting ...."
                              value={reason}
                              onChange={(e) => setReason(e.target.value)}
                            />
                          </div>
                        )}
                        {accept != "" && (
                          <div>
                            <button
                              onClick={() =>
                                sanctionLetterAccept(
                                  // modalData?.user_application
                                  modalData?.user_application_id
                                )
                              }
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </Col>
  );
};
export default SanctionedFiles;
