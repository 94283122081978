import { faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { bin } from "../../assets/images/Index";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationService from "../../services/service/ApplictionService";
import UserApplication from "../../services/storage/UserApplication";

const UserDrawingSaved = (props) => {
  const history = useHistory();
  const { setUserApplicationId } = UserApplication();
  const [building_usage_plan, setbuilding_usage_plan] = useState("");
  const [building_usage_construction, setbuilding_usage_construction] =
    useState("");
  const [buildingCount, setBuildingCount] = useState([
    {
      building_plan: "",
      building_construction: "",
      front: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "7",
      },
      slide_1: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "8",
      },
      slide_2: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "9",
      },
      rear_setback: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "10",
      },
      total_building_height: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "11",
      },
      total_habitable_height: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "12",
      },
      no_upper_floors: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "2",
      },
      no_parking_floors: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "3",
      },
      built_ip_area: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "4",
      },
      // built_ip_area: [
      //   {
      //     as_per_plan: "",
      //     as_per_construction: "",
      //     as_per_official: "",
      //     type: "4",
      //   },
      // ],
      upper_floor_area: [
        {
          as_per_plan: "",
          as_per_construction: "",
          as_per_official: "",
          type: "5",
        },
      ],
      parking_floor_area: [
        {
          as_per_plan: "",
          as_per_construction: "",
          as_per_official: "",
          type: "6",
        },
      ],
    },
  ]);

  const addBuilding = () => {
    let temp = [...buildingCount];
    temp.push({
      building_plan: "",
      building_construction: "",
      front: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "7",
      },
      slide_1: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "8",
      },
      slide_2: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "9",
      },
      rear_setback: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "10",
      },
      total_building_height: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "11",
      },
      total_habitable_height: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "12",
      },
      no_upper_floors: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "2",
      },
      no_parking_floors: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "3",
      },
      built_ip_area: {
        as_per_plan: "",
        as_per_construction: "",
        as_per_official: "",
        type: "4",
      },

      upper_floor_area: [
        {
          as_per_plan: "",
          as_per_construction: "",
          as_per_official: "",
          type: "5",
        },
      ],
      parking_floor_area: [
        {
          as_per_plan: "",
          as_per_construction: "",
          as_per_official: "",
          type: "6",
        },
      ],
    });
    setBuildingCount(temp);
  };

  const deleteBuilding = (ind) => {
    let temp = [...buildingCount];
    temp.splice(ind, 1);
    setBuildingCount(temp);
  };

  const addBuildingDetails = (ind, indx, type) => {
    let temp = [...buildingCount];
    let tempx = [...temp[ind][type]];
    let obj = {
      as_per_plan: "",
      as_per_construction: "",
      as_per_official: "",
    };

    if (type == "built_ip_area") {
      obj.type = "4";
    }

    if (type == "upper_floor_area") {
      obj.type = "5";
    }

    if (type == "parking_floor_area") {
      obj.type = "6";
    }

    tempx.push(obj);
    temp[ind][type] = tempx;
    setBuildingCount(temp);
  };

  const deleteBuildingDetails = (ind, indx, type) => {
    let temp = [...buildingCount];
    let tempx = [...temp[ind][type]];
    tempx.splice(indx, 1);
    temp[ind][type] = tempx;
    console.log(ind, indx, type, temp);
    setBuildingCount(temp);
  };

  const handleChange = (event, type, type_2, ind, index, type_3) => {
    let temp = [...buildingCount];

    if (type_3 == "array") {
      let temX = temp[ind][type][index];

      let obj = {
        ...temX,
        [type_2]: event,
      };

      temp[ind][type][index] = obj;
    } else {
      if (type_2) {
        temp[ind] = {
          ...temp[ind],
          [type]: {
            ...temp[ind][type],
            [type_2]: event,
          },
        };
      } else {
        temp[ind] = {
          ...temp[ind],
          [type]: event,
        };
      }
    }

    setBuildingCount(temp);
  };

  const save = () => {
    let formdata = new FormData();

    let count = 1;

    if (building_usage_plan == "") {
      count = count + 1;
    }

    if (building_usage_construction == "") {
      count = count + 1;
    }

    formdata.append("building_usage_plan", building_usage_plan);
    formdata.append("building_usage_construction", building_usage_construction);

    buildingCount?.map((item, ind) => {
      if (item?.building_plan == "") {
        count = count + 1;
      }

      if (item?.building_construction == "") {
        count = count + 1;
      }

      if (item?.no_upper_floors?.as_per_plan == "") {
        count = count + 1;
      }

      if (item?.no_upper_floors?.as_per_construction == "") {
        count = count + 1;
      }

      if (item?.no_parking_floors?.as_per_plan == "") {
        count = count + 1;
      }

      if (item?.no_parking_floors?.as_per_construction == "") {
        count = count + 1;
      }

      if (item?.front?.as_per_plan == "") {
        count = count + 1;
      }

      if (item?.front?.as_per_construction == "") {
        count = count + 1;
      }

      if (item?.slide_1?.as_per_plan == "") {
        count = count + 1;
      }

      if (item?.slide_1?.as_per_construction == "") {
        count = count + 1;
      }

      if (item?.slide_2?.as_per_plan == "") {
        count = count + 1;
      }

      if (item?.slide_2?.as_per_construction == "") {
        count = count + 1;
      }

      if (item?.rear_setback?.as_per_plan == "") {
        count = count + 1;
      }

      if (item?.rear_setback?.as_per_construction == "") {
        count = count + 1;
      }

      if (item?.total_building_height?.as_per_plan == "") {
        count = count + 1;
      }

      if (item?.total_building_height?.as_per_construction == "") {
        count = count + 1;
      }

      if (item?.total_habitable_height?.as_per_plan == "") {
        count = count + 1;
      }

      if (item?.total_habitable_height?.as_per_construction == "") {
        count = count + 1;
      }

      if (item?.built_ip_area?.as_per_plan == "") {
        count = count + 1;
      }

      if (item?.built_ip_area?.as_per_construction == "") {
        count = count + 1;
      }

      formdata.append(
        `buildings[${ind}][user_occupancy_id]`,
        props.applicationID
      );
      formdata.append(`buildings[${ind}][building_plan]`, item?.building_plan);
      formdata.append(
        `buildings[${ind}][building_construction]`,
        item?.building_construction
      );

      // TYPE 2
      formdata.append(
        `buildings[${ind}][building_details][${0}][as_per_plan]`,
        item?.no_upper_floors?.as_per_plan
      );
      formdata.append(
        `buildings[${ind}][building_details][${0}][as_per_construction]`,
        item?.no_upper_floors?.as_per_construction
      );
      formdata.append(
        `buildings[${ind}][building_details][${0}][type]`,
        item?.no_upper_floors?.type
      );

      // TYPE 3
      formdata.append(
        `buildings[${ind}][building_details][${1}][as_per_plan]`,
        item?.no_parking_floors?.as_per_plan
      );
      formdata.append(
        `buildings[${ind}][building_details][${1}][as_per_construction]`,
        item?.no_parking_floors?.as_per_construction
      );
      formdata.append(
        `buildings[${ind}][building_details][${1}][type]`,
        item?.no_parking_floors?.type
      );

      // TYPE 4

      formdata.append(
        `buildings[${ind}][building_details][${2}][as_per_plan]`,
        item?.built_ip_area?.as_per_plan
      );
      formdata.append(
        `buildings[${ind}][building_details][${2}][as_per_construction]`,
        item?.built_ip_area?.as_per_construction
      );
      formdata.append(
        `buildings[${ind}][building_details][${2}][type]`,
        item?.built_ip_area?.type
      );

      let type_4_ind = 3;

      // TYPE 5
      item?.upper_floor_area?.map((upper_area, upperInd) => {
        if (upper_area?.as_per_construction == "") {
          count = count + 1;
        }

        if (upper_area?.as_per_plan == "") {
          count = count + 1;
        }

        formdata.append(
          `buildings[${ind}][building_details][${
            upperInd + type_4_ind
          }][as_per_plan]`,
          upper_area?.as_per_plan
        );
        formdata.append(
          `buildings[${ind}][building_details][${
            upperInd + type_4_ind
          }][as_per_construction]`,
          upper_area?.as_per_construction
        );
        formdata.append(
          `buildings[${ind}][building_details][${upperInd + type_4_ind}][type]`,
          upper_area?.type
        );
      });

      let type_5_ind = item?.upper_floor_area?.length + type_4_ind;

      // TYPE 6
      item?.parking_floor_area?.map((parking_area, parkInd) => {
        if (parking_area?.as_per_construction == "") {
          count = count + 1;
        }

        if (parking_area?.as_per_plan == "") {
          count = count + 1;
        }

        formdata.append(
          `buildings[${ind}][building_details][${
            parkInd + type_5_ind
          }][as_per_plan]`,
          parking_area?.as_per_plan
        );
        formdata.append(
          `buildings[${ind}][building_details][${
            parkInd + type_5_ind
          }][as_per_construction]`,
          parking_area?.as_per_construction
        );
        formdata.append(
          `buildings[${ind}][building_details][${parkInd + type_5_ind}][type]`,
          parking_area?.type
        );
      });

      let type_6_ind = type_5_ind + item?.parking_floor_area?.length - 1;

      // TYPE 7
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 1}][as_per_plan]`,
        item?.front?.as_per_plan
      );
      formdata.append(
        `buildings[${ind}][building_details][${
          type_6_ind + 1
        }][as_per_construction]`,
        item?.front?.as_per_construction
      );
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 1}][type]`,
        item?.front?.type
      );

      // TYPE 8
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 2}][as_per_plan]`,
        item?.slide_1?.as_per_plan
      );
      formdata.append(
        `buildings[${ind}][building_details][${
          type_6_ind + 2
        }][as_per_construction]`,
        item?.slide_1?.as_per_construction
      );
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 2}][type]`,
        item?.slide_1?.type
      );

      // TYPE 9
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 3}][as_per_plan]`,
        item?.slide_2?.as_per_plan
      );
      formdata.append(
        `buildings[${ind}][building_details][${
          type_6_ind + 3
        }][as_per_construction]`,
        item?.slide_2?.as_per_construction
      );
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 3}][type]`,
        item?.slide_2?.type
      );

      // TYPE 10
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 4}][as_per_plan]`,
        item?.rear_setback?.as_per_plan
      );
      formdata.append(
        `buildings[${ind}][building_details][${
          type_6_ind + 4
        }][as_per_construction]`,
        item?.rear_setback?.as_per_construction
      );
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 4}][type]`,
        item?.rear_setback?.type
      );

      // TYPE 11
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 5}][as_per_plan]`,
        item?.total_building_height?.as_per_plan
      );
      formdata.append(
        `buildings[${ind}][building_details][${
          type_6_ind + 5
        }][as_per_construction]`,
        item?.total_building_height?.as_per_construction
      );
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 5}][type]`,
        item?.total_building_height?.type
      );

      // TYPE 12
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 6}][as_per_plan]`,
        item?.total_habitable_height?.as_per_plan
      );
      formdata.append(
        `buildings[${ind}][building_details][${
          type_6_ind + 6
        }][as_per_construction]`,
        item?.total_habitable_height?.as_per_construction
      );
      formdata.append(
        `buildings[${ind}][building_details][${type_6_ind + 6}][type]`,
        item?.total_habitable_height?.type
      );
    });

    if (count == 1) {
      ApplicationService.occupancy_site_store(formdata)
        .then((result) => {
          setUserApplicationId(result.data.user_application_id);
          alert(result.data.message);
          history.push("/");
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("Please fill all details ?");
    }
  };

  useEffect(() => {
    if (props?.list?.id) {
      let data = props?.list?.user_occupancy_site;
      setbuilding_usage_plan(data?.[0]?.building_usage_plan);
      setbuilding_usage_construction(data?.[0]?.building_usage_construction);
      let temp = [];
      data?.map((item, ind) => {
        let obj = {};

        obj.building_plan = item?.building_plan;
        obj.building_construction = item?.building_construction;
        obj.upper_floor_area = [];
        obj.parking_floor_area = [];

        item?.user_occupancy_site_detail?.map((site, inx) => {
          if (site?.type == 2) {
            obj.no_upper_floors = {
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "2",
              id: site?.id,
            };
          }

          if (site?.type == 3) {
            obj.no_parking_floors = {
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "3",
              id: site?.id,
            };
          }

          if (site?.type == 4) {
            obj.built_ip_area = {
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "4",
              id: site?.id,
            };
          }

          if (site?.type == 5) {
            obj.upper_floor_area.push({
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "5",
              id: site?.id,
            });
          }

          if (site?.type == 6) {
            obj.parking_floor_area.push({
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "6",
              id: site?.id,
            });
          }

          if (site?.type == 7) {
            obj.front = {
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "7",
              id: site?.id,
            };
          }

          if (site?.type == 8) {
            obj.slide_1 = {
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "8",
              id: site?.id,
            };
          }

          if (site?.type == 9) {
            obj.slide_2 = {
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "9",
              id: site?.id,
            };
          }

          if (site?.type == 10) {
            obj.rear_setback = {
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "10",
              id: site?.id,
            };
          }

          if (site?.type == 11) {
            obj.total_building_height = {
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "11",
              id: site?.id,
            };
          }

          if (site?.type == 12) {
            obj.total_habitable_height = {
              as_per_plan: site?.as_per_plan,
              as_per_construction: site?.as_per_construction,
              as_per_official: site?.as_per_official,
              type: "12",
              id: site?.id,
            };
          }
        });

        temp.push(obj);
      });

      setBuildingCount(temp);
    }
  }, [props?.list]);

  // console.log("buildingCount", buildingCount);

  return (
    <>
      <Col className="prop-norm">
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>
              <h5 className="f14 mb-0">Usage of the Building</h5>
            </Accordion.Header>
            <Accordion.Body className="px-2 py-1">
              <Col
                lg={12}
                md={12}
                className="d-flex flex-wrap mt-3 mb-3 justify-content-center"
              >
                <Col className="mx-2">
                  <h5 className="f14 mb-0" style={{ color: "transparent" }}>
                    {" "}
                    As per Submitted Plan
                  </h5>
                </Col>
                <Col>
                  <h5 className="f14 mb-0 "> As per Submitted Plan</h5>
                </Col>
                <Col>
                  {" "}
                  <h5 className="f14 mb-0 "> As per Construction</h5>
                </Col>
                {/* <Col>
                  {" "}
                  <h5 className="f14 mb-0 mx-3">Input by Official</h5>
                </Col> */}
              </Col>
              <Col lg={12} className="d-md-flex flex-wrap mt-2">
                <Col
                  md={12}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                >
                  <Col>
                    <p className="f13 mb-0">Usage of the Building*</p>
                  </Col>
                  <Col>
                    <Form.Select
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                      value={building_usage_plan}
                      onChange={(e) => setbuilding_usage_plan(e.target.value)}
                      disabled
                    >
                      <option value="0">Select Usage of the Building</option>
                      <option className="f14 py-2" value="Commercial">
                        Commercial
                      </option>
                      <option className="f14 py-2" value="IT/ITES">
                        IT/ITES
                      </option>
                      <option className="f14 py-2" value="Residential">
                        Residential
                      </option>
                      <option className="f14 py-2" value="Mixed">
                        Mixed
                      </option>
                      <option className="f14 py-2" value="Industrial">
                        Industrial
                      </option>
                    </Form.Select>
                  </Col>
                  <Col>
                    <Form.Select
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                      value={building_usage_construction}
                      onChange={(e) =>
                        setbuilding_usage_construction(e.target.value)
                      }
                      disabled
                    >
                      <option value="0">Select Usage of the Building</option>
                      <option className="f14 py-2" value="Commercial">
                        Commercial
                      </option>
                      <option className="f14 py-2" value="IT/ITES">
                        IT/ITES
                      </option>
                      <option className="f14 py-2" value="Residential">
                        Residential
                      </option>
                      <option className="f14 py-2" value="Mixed">
                        Mixed
                      </option>
                      <option className="f14 py-2" value="Industrial">
                        Industrial
                      </option>
                    </Form.Select>
                  </Col>
                  {/* <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      // value={road}
                      // onChange={changeRoad}
                      disabled
                    />
                  </Col> */}
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Header>
              <h5 className="f14 mb-0">Details of Building</h5>
            </Accordion.Header>
            <Accordion.Body className="px-2 py-1">
              {buildingCount?.map((item, ind) => {
                return (
                  <>
                    <Col lg={12} className="d-md-flex flex-wrap ">
                      <Col md={3} lg={3} className="mt-3 mb-3">
                        <h3 className="f18 mb-0 mx-2">Building {ind + 1} </h3>
                      </Col>
                      <Col
                        md={12}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                      >
                        <Col>
                          <p className="f13 mb-0">Building Name*</p>
                        </Col>
                        <Col className="d-flex justify-content-between align-items-center">
                          <input
                            value={item?.building_plan}
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                "building_plan",
                                "",
                                ind
                              )
                            }
                            disabled
                            className="form-control px-2 py-1 rounded-0"
                          />
                        </Col>
                        <Col>
                          <input
                            className="form-control px-2 py-1 rounded-0"
                            value={item?.building_construction}
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                "building_construction",
                                "",
                                ind
                              )
                            }
                            disabled
                          />
                        </Col>
                        {/* <Col>
                          <input
                            className="form-control px-2 py-1 rounded-0"
                            // value={road}
                            // onChange={changeRoad}
                            disabled
                          />
                        </Col> */}
                      </Col>
                      <Col
                        md={12}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                      >
                        <Col className="d-flex justify-content-between">
                          <p className="f13 mb-0">Number of Upper Floors*</p>
                        </Col>
                        <Col>
                          <input
                            className="form-control px-2 py-1 rounded-0"
                            value={item?.no_upper_floors?.as_per_plan}
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                "no_upper_floors",
                                "as_per_plan",
                                ind
                              )
                            }
                            disabled
                          />
                        </Col>
                        <Col>
                          <input
                            className="form-control px-2 py-1 rounded-0"
                            value={item?.no_upper_floors?.as_per_construction}
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                "no_upper_floors",
                                "as_per_construction",
                                ind
                              )
                            }
                            disabled
                          />
                        </Col>
                        {/* <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                // value={road}
                                // onChange={changeRoad}
                                disabled
                              />
                            </Col> */}
                      </Col>

                      <Col
                        md={12}
                        className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                      >
                        <Col className="d-flex justify-content-between">
                          <p className="f13 mb-0">Number of Parking Floors*</p>
                        </Col>
                        <Col>
                          <input
                            className="form-control px-2 py-1 rounded-0"
                            value={item?.no_parking_floors?.as_per_plan}
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                "no_parking_floors",
                                "as_per_plan",
                                ind
                              )
                            }
                            disabled
                          />
                        </Col>
                        <Col>
                          <input
                            className="form-control px-2 py-1 rounded-0"
                            value={item?.no_parking_floors?.as_per_construction}
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                "no_parking_floors",
                                "as_per_construction",
                                ind
                              )
                            }
                            disabled
                          />
                        </Col>
                        {/* <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                // value={road}
                                // onChange={changeRoad}
                                disabled
                              />
                            </Col> */}
                      </Col>
                    </Col>

                    <Accordion.Item>
                      <Accordion.Header>
                        <h5 className="f14 mb-0">Floor Area (Sq.m)</h5>
                      </Accordion.Header>
                      <Accordion.Body className="px-2 py-1">
                        <Col md={12} className="d-md-flex flex-wrap">
                          <Col
                            md={12}
                            className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                          >
                            <Col className="d-flex justify-content-between">
                              <p className="f13 mb-0">
                                Built-Up Area (Floor Wise and Building Wise)*
                              </p>
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.built_ip_area?.as_per_plan}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "built_ip_area",
                                    "as_per_plan",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.built_ip_area?.as_per_construction}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "built_ip_area",
                                    "as_per_construction",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            {/* <Col>
                                  <input
                                    className="form-control px-2 py-1 rounded-0"
                                    // value={road}
                                    // onChange={changeRoad}
                                    disabled
                                  />
                                </Col> */}
                          </Col>

                          {item?.upper_floor_area?.map((uparea, upindx) => {
                            return (
                              <Col
                                md={12}
                                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                              >
                                <Col className="d-flex justify-content-between">
                                  <p className="f13 mb-0">
                                    Upper Floor Area* {upindx + 1}
                                  </p>
                                </Col>
                                <Col>
                                  <input
                                    className="form-control px-2 py-1 rounded-0"
                                    value={uparea?.as_per_plan}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        "upper_floor_area",
                                        "as_per_plan",
                                        ind,
                                        upindx,
                                        "array"
                                      )
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col>
                                  <input
                                    className="form-control px-2 py-1 rounded-0"
                                    value={uparea?.as_per_construction}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        "upper_floor_area",
                                        "as_per_construction",
                                        ind,
                                        upindx,
                                        "array"
                                      )
                                    }
                                    disabled
                                  />
                                </Col>
                                {/* <Col>
                                  <input
                                    className="form-control px-2 py-1 rounded-0"
                                    // value={road}
                                    // onChange={changeRoad}
                                    disabled
                                  />
                                </Col> */}
                              </Col>
                            );
                          })}

                          {item?.parking_floor_area?.map((pkarea, pkindx) => {
                            return (
                              <Col
                                md={12}
                                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                              >
                                <Col className="d-flex justify-content-between">
                                  <p className="f13 mb-0">
                                    Parking Floor Area* {pkindx + 1}
                                  </p>
                                </Col>
                                <Col>
                                  <input
                                    className="form-control px-2 py-1 rounded-0"
                                    value={pkarea?.as_per_plan}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        "parking_floor_area",
                                        "as_per_plan",
                                        ind,
                                        pkindx,
                                        "array"
                                      )
                                    }
                                    disabled
                                  />
                                </Col>
                                <Col>
                                  <input
                                    className="form-control px-2 py-1 rounded-0"
                                    value={pkarea?.as_per_construction}
                                    onChange={(e) =>
                                      handleChange(
                                        e.target.value,
                                        "parking_floor_area",
                                        "as_per_construction",
                                        ind,
                                        pkindx,
                                        "array"
                                      )
                                    }
                                    disabled
                                  />
                                </Col>
                                {/* <Col>
                                  <input
                                    className="form-control px-2 py-1 rounded-0"
                                    // value={road}
                                    // onChange={changeRoad}
                                    disabled
                                  />
                                </Col> */}
                              </Col>
                            );
                          })}
                        </Col>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item>
                      <Accordion.Header>
                        <h5 className="f14 mb-0">External Setbacks</h5>
                      </Accordion.Header>
                      <Accordion.Body className="px-2 py-1">
                        <Col md={12} className="d-md-flex flex-wrap">
                          <Col
                            md={12}
                            className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                          >
                            <Col>
                              <p className="f13 mb-0">Front*</p>
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.front?.as_per_plan}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "front",
                                    "as_per_plan",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.front?.as_per_construction}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "front",
                                    "as_per_construction",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            {/* <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                // value={road}
                                // onChange={changeRoad}
                                disabled
                              />
                            </Col> */}
                          </Col>
                          <Col
                            md={12}
                            className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                          >
                            <Col>
                              <p className="f13 mb-0">Side1*</p>
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.slide_1?.as_per_plan}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "slide_1",
                                    "as_per_plan",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.slide_1?.as_per_construction}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "slide_1",
                                    "as_per_construction",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            {/* <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                // value={road}
                                // onChange={changeRoad}
                                disabled
                              />
                            </Col> */}
                          </Col>
                          <Col
                            md={12}
                            className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                          >
                            <Col>
                              <p className="f13 mb-0">Side2*</p>
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.slide_2?.as_per_plan}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "slide_2",
                                    "as_per_plan",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.slide_2?.as_per_construction}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "slide_2",
                                    "as_per_construction",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            {/* <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                // value={road}
                                // onChange={changeRoad}
                                disabled
                              />
                            </Col> */}
                          </Col>
                          <Col
                            md={12}
                            className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                          >
                            <Col>
                              <p className="f13 mb-0">Rear Setback values*</p>
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.rear_setback?.as_per_plan}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "rear_setback",
                                    "as_per_plan",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.rear_setback?.as_per_construction}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "rear_setback",
                                    "as_per_construction",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            {/* <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                // value={road}
                                // onChange={changeRoad}
                                disabled
                              />
                            </Col> */}
                          </Col>
                        </Col>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item>
                      <Accordion.Header>
                        <h5 className="f14 mb-0">Height (Mtrs)</h5>
                      </Accordion.Header>
                      <Accordion.Body className="px-2 py-1">
                        <Col md={12} className="d-md-flex flex-wrap">
                          <Col
                            md={12}
                            className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                          >
                            <Col>
                              <p className="f13 mb-0">Total Building Height*</p>
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={item?.total_building_height?.as_per_plan}
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "total_building_height",
                                    "as_per_plan",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={
                                  item?.total_building_height
                                    ?.as_per_construction
                                }
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "total_building_height",
                                    "as_per_construction",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            {/* <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                // value={road}
                                // onChange={changeRoad}
                                disabled
                              />
                            </Col> */}
                          </Col>

                          <Col
                            md={12}
                            className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center gap-3"
                          >
                            <Col>
                              <p className="f13 mb-0">
                                Habitable Building Height*
                              </p>
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={
                                  item?.total_habitable_height?.as_per_plan
                                }
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "total_habitable_height",
                                    "as_per_plan",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                value={
                                  item?.total_habitable_height
                                    ?.as_per_construction
                                }
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    "total_habitable_height",
                                    "as_per_construction",
                                    ind
                                  )
                                }
                                disabled
                              />
                            </Col>
                            {/* <Col>
                              <input
                                className="form-control px-2 py-1 rounded-0"
                                // value={road}
                                // onChange={changeRoad}
                                disabled
                              />
                            </Col> */}
                          </Col>
                        </Col>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </>
  );
};

export default UserDrawingSaved;
