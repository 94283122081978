import React from "react";
import { Button, Row, Col, Pagination, Table, Dropdown, Form, FormControl, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import SideBar from "../component/Sidebar";
import Header from "../component/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faLock, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

const ChangePassword = () => {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Col className="d-lg-flex">
      {/* <SideBar /> */}
      <Col className="">
        <Header />

        <Col className="col-12 col-md-12 text-center">
          <div className="f20 black px-3 py-3">
            <strong>
              Change your Account Password Here
            </strong>
          </div>
        </Col>

        <Row className="justify-content-center">
          <Col className="col-12 col-md-8 col-lg-6 col-xl-4 col-xxl-3 text-center">
            <Row className="justify-content-center">

              <Col className="col-12 col-md-12 text-center">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faLock} />
                  </InputGroup.Text>
                  <FormControl
                    placeholder="Old Password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col className="col-12 col-md-12 text-center">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faLock} />
                  </InputGroup.Text>
                  <FormControl
                    placeholder="New Password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col className="col-12 col-md-12 text-center">
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faLock} />
                  </InputGroup.Text>
                  <FormControl
                    placeholder="Confirm Password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col className="col-12 col-md-12 text-center">
                <div className="d-flex justify-content-center">
                  <Button variant="light" className="border f-14">
                    Submit
                  </Button>
                  &nbsp;
                  &nbsp;
                  <Button variant="light" className="border f-14">
                    Clear
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>



      </Col>
    </Col>
  );
};

export default ChangePassword;
