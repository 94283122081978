import React from "react";
import httpClient from "../constant/httpClient";
import { URL } from '../constant/URL';

const register = (payload) => {
    return httpClient.post(URL.REGISTER, payload);
}
const userNameCheck = (payload) => {
    return httpClient.post(URL.USER_NAME_CHECK, payload);
}
const login = (payload) => {
    return httpClient.post(URL.LOGIN, payload);
}
const RegisterService = {
    register,
    userNameCheck,
    login,
}
export default RegisterService;