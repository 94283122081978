import React from "react";
import { Button, Row, Col, Pagination, Table, Dropdown, Form, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import SidebarSearch from "../component/SidebarSearch";
import Header from "../component/Header";
import { AutoDcrLogo, } from "../assets/images/Index";

const Applicationsearch = () => {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  return (
    <Col className="d-lg-flex">
      <SidebarSearch />
      <Col className="px-2">
        <Col className="d-md-flex justify-content-between align-items-center px-2 mb-2 bg-light">
          <Col className="text-end py-2">
           <AutoDcrLogo />
          </Col>
        </Col>
        <Table striped>
          <thead>
            <tr className="bg5">
              <th className="f14 fw-normal white cursor-pointer">
                <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
              File No <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Temporary No
                <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Name of Applicant <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
              Architect / LE / SE  <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Proceeding Letter <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Drawing PDF <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Inspection Report <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
            </tr>
            <tr>
              <th className="f14 fw-normal white cursor-pointer"></th>
              <th className="f14 fw-normal white cursor-pointer"></th>
              <th className="f14 fw-normal white cursor-pointer py-1"></th>
              <th className="f14 fw-normal white cursor-pointer"></th>
              <th className="f14 fw-normal white cursor-pointer py-1">
                <Dropdown as={ButtonGroup} className="" align="end">
                  <Form.Control type="text" placeholder="search" className="py-0" />
                  <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="filter-dropdown">
                    <div className="px-0">
                      <div className="filter-dropdown-item">
                        <Row className="py-2 border-bottom">
                          <Col md={10} className="text-start pe-0">
                            Filter On
                          </Col>
                          <Col md={2} className="text-end ps-0">
                            <Form.Check aria-label="option 1" />
                          </Col>
                        </Row>
                      </div>

                      <Row className="py-2">
                        <Col md={8} className="text-start ps-2 pe-0">
                          <Button variant="secondary" size="sm">
                            Select All
                          </Button>
                        </Col>
                        <Col md={4} className="text-end ps-0">
                          <Button variant="secondary" size="sm">
                            Ok
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </th>
              <th className="f14 fw-normal white cursor-pointer"></th>
              <th className="f14 fw-normal white cursor-pointer py-1">
                <Dropdown as={ButtonGroup} className="" align="end">
                  <Form.Control type="text" placeholder="search" className="py-0" />
                  <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="filter-dropdown">
                    <div className="px-0">
                      <div className="filter-dropdown-item">
                        <Row className="py-2 border-bottom">
                          <Col md={10} className="text-start pe-0">
                            Filter On
                          </Col>
                          <Col md={2} className="text-end ps-0">
                            <Form.Check aria-label="option 1" />
                          </Col>
                        </Row>
                      </div>

                      <Row className="py-2">
                        <Col md={8} className="text-start ps-2 pe-0">
                          <Button variant="secondary" size="sm">
                            Select All
                          </Button>
                        </Col>
                        <Col md={4} className="text-end ps-0">
                          <Button variant="secondary" size="sm">
                            Ok
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </th>
              <th className="f14 fw-normal white cursor-pointer"></th>
            </tr>
          </thead>
          <tbody>
            
            <tr>
              <td className="f14 c4">2.</td>
              <td className="f14 c4">IIC/0758/2021</td>
              <td className="f14 c4">IIC/0758GH009</td>
              <td className="f14 c4">kumaravel </td>
              <td className="f14 c4">kumarvel</td>
              <td className="f14 c4">....</td>
              <td className="f14 c4">BluePrint</td>
              <td className="f14 c4">....</td>
            </tr>
          </tbody>
        </Table>
        <Col className="d-sm-flex justify-content-between align-items-center">
          <Col className="d-flex">
            <Button className="bg-white border-0">
              <FontAwesomeIcon className="black" icon={faSyncAlt} />
            </Button>
            <Pagination className="mb-0">{items}</Pagination>
          </Col>
          <Col className="d-flex  justify-content-end">
            <p className="mb-0">Total Proposal(s) : 1</p>
          </Col>
        </Col>
      </Col>
    </Col>
  );
};

export default Applicationsearch;
