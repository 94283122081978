import { faFileAlt, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddBulidingDetails from "./AddBulidingDetails";
import { Field, FieldArray, reduxForm } from "redux-form";
import ApplicationService from "../../services/service/ApplictionService";
import ErrorHandler from "../../services/constant/ErrorHandler";
import validate from "./validate";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import HighRise from "../../services/constant/HighRise";

const renderField = ({
  input,
  label,
  type,
  value,
  readOnly,
  position,
  register,
  helperInfo,
  startAdorment,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <FormControl
      error={touched && error}
      style={{
        width: "100%",
      }}
    >
      <TextField
        style={{
          width: "100%",
          border: "1px solid #c5c5c5",
          padding: "3px",
        }}
        type={type}
        innerRef={input}
        disabled={readOnly}
        error={touched && error}
        value={input.value}
        {...input}
        {...custom}
        InputProps={{ disableUnderline: true }}
      />
      <FormHelperText>
        {touched && error ? label + " " + error : touched && error ? error : ""}
      </FormHelperText>
    </FormControl>
  );
};
const BuildingDetails2 = (props) => {
  const { saveHighRiseStatus } = HighRise();
  const { handleSubmit, pristine, reset, submitting } = props;
  const [row, setRow] = useState([0]);
  const [stilt, setStilt] = useState("");
  const [cellars, setCellars] = useState("");
  const addNewRow = () => {
    var temp = [...row];
    temp.push(row.length);
    setRow(temp);
  };
  const [tempArray, setTempArray] = useState([]);
  const [useList, setUseList] = useState([]);
  const [subUseList, setSubUseList] = useState([]);
  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    ApplicationService.getUseList()
      .then((result) => {
        setUseList(result.data.building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const changeUse = (e) => {
    if (e.target.value != "" && e.target.value != 0) {
      getSubList(e.target.value);
    }
  };
  const changeSubUse = (e) => {
    if (e.target.value != "" && e.target.value != 0) {
      getTypeList(e.target.value);
    }
  };
  const getSubList = (use) => {
    ApplicationService.getSubUseList(use)
      .then((result) => {
        setSubUseList(result.data.sub_building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const getTypeList = (sub_use) => {
    ApplicationService.getSubUseList(sub_use)
      .then((result) => {
        setTypeList(result.data?.sub_building_detail);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };

  const changeNoofFloor = (e) => {
    let temp = [];
    for (let i = 0; i < e.target.value; i++) {
      temp.push(i);
    }
    setTempArray(temp);
  };
  const save = (values) => {
    if (props.applicationID) {
      var payload = values;
      payload.user_application_id = props.applicationID;
      ApplicationService.saveBuildingDetails(payload)
        .then((result) => {
          saveHighRiseStatus(
            result?.data?.is_high_rise ? result.data.is_high_rise : 0
          );
          alert(result.data.message);
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("please fill GeneralInformation !");
    }
  };

  const renderSelectField = ({
    disabled,
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <div>
      {/* <label>{label}</label> */}
      <div>
        <select
          style={{
            width: "100%",
            border: "1px solid #c5c5c5",
            padding: "3px",
            backgroundColor: "#fff",
          }}
          disabled={disabled}
          {...input}
        >
          {children}
        </select>
        {touched && error && (
          <span style={{ color: "tomato" }}>{label + " " + error}</span>
        )}
      </div>
    </div>
  );
  const renderMembers = ({
    fields,
    meta: { touched, error, submitFailed },
  }) => (
    <ul>
      <li>
        {/* <button type="button" onClick={() => fields.push({})}>
          Add Member
        </button>
        {(touched || submitFailed) && error && <span>{error}</span>} */}
      </li>
      {tempArray.map((member, index) => (
        <li key={index}>
          {/* <button
            type="button"
            title="Remove Member"
            onClick={() => fields.remove(index)}
          /> */}
          <h4>Floor #{index + 1}</h4>
          <Col className="d-md-flex flex-wrap mt-1">
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Floor Name*</p>
              </Col>
              <Col>
                <Field
                  name={`floors[${member}].floor_name`}
                  type="text"
                  component={renderField}
                  label="Floor Name"
                />
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Height (m)</p>
              </Col>
              <Col>
                <Field
                  name={`floors[${member}].height`}
                  type="number"
                  component={renderField}
                  label="Height"
                />
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Use</p>
              </Col>
              <Col>
                <Field
                  name={`floors[${member}].use`}
                  component={renderSelectField}
                >
                  <option className="f14 py-2" value="0">
                    Select
                  </option>
                  {useList.length > 0 &&
                    useList.map((item, index) => {
                      return (
                        <option
                          key={index}
                          className="f14 py-2"
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </Field>
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Total Floor Area</p>
              </Col>
              <Col>
                <Field
                  name={`floors[${member}].total_floor_area`}
                  type="number"
                  component={renderField}
                  label="Total Floor Area"
                />
              </Col>
            </Col>
          </Col>
        </li>
      ))}
    </ul>
  );
  return (
    <form onSubmit={handleSubmit(save)}>
      <Col className="rt-dashboard px-3">
        <Col className="d-md-flex justify-content-between align-items-center px-3">
          <Col className="d-flex justify-content-md-end">
            {/* <div className="bg-white ">
            <button
              className="bg-white px-2 py-1 me-1 border-1 border-solid border-color-lit-white rounded-2"
              onClick={addNewRow}
              style={{
                color: "#2f6db5",
                fontSize: 13,
              }}>
              <span>+</span>Add
            </button>
          </div> */}
            <Link
              to="/application-permission"
              style={{
                color: "#2f6db5",
                fontSize: 13,
              }}
            ></Link>

            {/* <Link
            to="/application-occupancy"
            className="bg-white px-2 py-1 me-1 border-1 border-solid border-color-lit-white rounded-2"
            style={{
              color: "#2f6db5",
              fontSize: 13,
            }}>
            Delete
          </Link> */}
          </Col>
        </Col>
        <Col className="customers mb-3 mt-3">
          <Col
            style={{
              border: "1px solid #e9e9e9",
              padding: 15,
              marginBottom: 10,
            }}
            xs={12}
            className="d-md-flex flex-wrap"
          >
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Name*</p>
              </Col>
              <Col>
                <Field
                  name={"name"}
                  type="text"
                  component={renderField}
                  label="Name"
                />
              </Col>
            </Col>

            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Use*</p>
              </Col>
              <Col>
                <Field
                  name="use"
                  label="Use"
                  component={renderSelectField}
                  onChange={changeUse}
                >
                  <option className="f14 py-2" value="0">
                    Select
                  </option>
                  {useList.length > 0 &&
                    useList.map((item, index) => {
                      return (
                        <option
                          key={index}
                          className="f14 py-2"
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </Field>
              </Col>
            </Col>

            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Sub Use*</p>
              </Col>
              <Col>
                <Field
                  name="sub_use"
                  label="Sub Use"
                  component={renderSelectField}
                  onChange={changeSubUse}
                >
                  <option className="f14 py-2" value="0">
                    Select
                  </option>
                  {subUseList.length > 0 &&
                    subUseList.map((item, index) => {
                      return (
                        <option
                          key={index}
                          className="f14 py-2"
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </Field>
              </Col>
            </Col>

            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Type*</p>
              </Col>
              <Col>
                <Field name="type" label="Type" component={renderSelectField}>
                  <option className="f14 py-2" value="0">
                    Select
                  </option>
                  {typeList.length > 0 &&
                    typeList.map((item, index) => {
                      return (
                        <option
                          key={index}
                          className="f14 py-2"
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </Field>
              </Col>
            </Col>

            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Type Number of dwelling units*</p>
              </Col>
              <Col>
                <Col>
                  <Field
                    name={"dwelling_units"}
                    type="number"
                    component={renderField}
                    label="Dwelling Units"
                  />
                </Col>
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Height excluding Stilt / Parking*</p>
              </Col>
              <Col>
                <Field
                  disabled={cellars ? true : false}
                  name={"height_excluding_slit"}
                  type="number"
                  component={renderField}
                  label="Height Excluding Slit"
                  onChange={(e) => setStilt(e.target.value)}
                />
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">Height excluding Cellars*</p>
              </Col>
              <Col>
                <Field
                  disabled={stilt ? true : false}
                  name={"height_excluding_celler"}
                  type="number"
                  component={renderField}
                  label="Height Excluding Cellars"
                  onChange={(e) => setCellars(e.target.value)}
                />
              </Col>
            </Col>
            <Col
              md={6}
              className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
            >
              <Col>
                <p className="f13 mb-0">No. Of Floors*</p>
              </Col>
              <Col>
                <Field
                  name={"no_of_floors"}
                  type="number"
                  component={renderField}
                  label="No of Floors"
                  onChange={changeNoofFloor}
                />
              </Col>
            </Col>
          </Col>
          <FieldArray name="floors" component={renderMembers} />
          <Col className="d-flex justify-content-center mb-2">
            <Button
              type="submit"
              className="tab-blu-back"
              style={{
                color: "#fff",
                fontSize: 15,
                marginTop: 10,
                padding: "6px 14px",
                backgroundColor: "#5593db",
              }}
            >
              <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
              Save
            </Button>
          </Col>
        </Col>
      </Col>
    </form>
  );
};

export default reduxForm({
  form: "BuildingDetailsForm", // a unique identifier for this form
  validate,
})(BuildingDetails2);
