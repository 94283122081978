import React from "react";
import { Col, Tab, Tabs, Row } from "react-bootstrap";
import { Logo, DPMS } from "../assets/images/Index";
import GeneralInspectionReport from "../component/dashboard/GeneralInspectionReport";
import JoinInspectionReport from "../component/dashboard/JoinInspectionReport";
import PendencyDetails from "../component/dashboard/PendencyDetails";
import StatusWise from "../component/dashboard/StatusWise";
import StructuralStabilityReport from "../component/dashboard/StructuralStabilityReport";
import Header from "../component/Header";
import BuildingDetails from "../component/userApplication/BuildingDetails";

const DashBoard = () => {
  return (
    <Col className="px-3">
      <Col className="py-1 justify-content-center">
        <div className="align-items-center justify-content-between one-edge-shadow">
          <Row>
            <Col className="col-12 col-sm-12 col-md-12 col-lg-9 mb-2 mb-sm-0">
              <div className="d-flex align-items-center">
                &nbsp;
                <Logo />
                <div className="mx-2 ps-2 d-block d-sm-none">
                  <h5 className="f-x-large fw-bold mx-0 mt-1 ms-4">TGIIC</h5>
                </div>
                <div className="mx-2 ps-2 d-none d-sm-block">
                  <h4 className="f-x-large m-0">
                    <strong>
                      Telangana Industrial Infrastructure Corporation Ltd.
                    </strong>
                  </h4>
                  <h5 className="f13 mx-0 mt-1 ms-4">
                    (A GOVT. OF TELANGANA UNDERTAKING)
                  </h5>
                </div>
              </div>
            </Col>
            <Col className="col-12 col-sm-12 col-md-3 text-center text-md-end d-none d-lg-block">
              <DPMS />
            </Col>
          </Row>
        </div>
      </Col>

      <Tabs id="uncontrolled-tab-example" className="mb-3 dashboard-tab">
        <Tab
          eventKey="statuswise"
          title="Status Wise"
          className="py-0 bg-white f-14"
        >
          <StatusWise />
        </Tab>

        <Tab
          eventKey="pendencydetails"
          title="Pendency Details"
          className="py-0 bg-white"
        >
          <PendencyDetails />
        </Tab>

        <Tab
          eventKey="generaiInspectionreport"
          title="General Inspection Report"
          className="py-0 bg-white"
        >
          <GeneralInspectionReport />
        </Tab>

        <Tab
          eventKey="jointinspectionreport"
          title="Joint Inspection Report"
          className="py-0 bg-white"
        >
          <JoinInspectionReport />
        </Tab>

        <Tab
          eventKey="structuralstabilityreport"
          title="Structural Stability Report"
          className="py-0 bg-white"
        >
          <StructuralStabilityReport />
        </Tab>
      </Tabs>
    </Col>
  );
};

export default DashBoard;
