import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Tab, Tabs } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationService from "../../services/service/ApplictionService";
import Additional from "./Document/Additional";
import HighRiseDocuments from "./Document/HighRiseDocuments";
import Primary from "./Document/Primary";
import HighRise from "../../services/constant/HighRise";
import { HIGH_RISE } from "../../services/constant/Constant";
const UserDocument = (props) => {
  const { highRiseStatus } = HighRise();
  const [documentList, setDocumentList] = useState([]);
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    ApplicationService.occupancyDocumentList()
      .then((result) => {
        setDocumentList(result.data.user_occupancy_documents);
        var temp = [];
        result.data?.user_occupancy_documents.map((item, index) => {
          temp.push({
            application_document_id: item.id,
            doc_file: "",
            type: item.type,
            preview: "",
          });
        });
        setDocuments(temp);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);

  const save = (id, file, index) => {
    if (props.applicationID) {
      var count = 1;
      // documents.map((item, index) => {
      //   if (item.doc_file == "") {
      //     count = count + 1;
      //   }
      // });

      if (file == "") {
        count = count + 1;
      }

      if (count == 1) {
        var form_data = new FormData();
        form_data.append("user_occupancy_id", props.applicationID);
        form_data.append(`application_document_id`, id);
        form_data.append(`doc_file`, file);
        // documents.map((item, index) => {
        //   if (item.doc_file != "") {
        //     form_data.append(
        //       `application_documents[${index}][id]`,
        //       item.application_document_id
        //     );
        //     form_data.append(
        //       `application_documents[${index}][doc_file]`,
        //       item.doc_file
        //     );
        //   }
        // });

        ApplicationService.occupancyDocumentAdd(form_data)
          .then((result) => {
            if (result.data.status == "success") {
              var temp = [...documents];
              temp[index].doc_file = file;
              temp[index].preview = URL.createObjectURL(file);
              setDocuments(temp);
            } else {
              alert(result.data.message);
            }
          })
          .catch(function (error) {
            ErrorHandler(error);
          });
      } else {
        alert("please select all Files ?");
      }
    } else {
      alert("please fill GeneralInformation !");
    }
  };
  return (
    <>
      <Col>
        {documents.length > 0 && (
          <Primary
            documents={documents}
            setDocuments={setDocuments}
            data={documentList}
            save={save}
          />
        )}
      </Col>
    </>
  );
};

export default UserDocument;
