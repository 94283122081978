import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationService from "../../services/service/ApplictionService";
import ReportOneg from "../Report/thirdchangeReport";
import pdfgenerate from "../Report/pdfgenerate";
import PaymentService from "../../services/service/PaymentService";

const UserDrawing = (props) => {
  const [file, setFile] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [modal, setModal] = useState(false);
  const [jsonData, setJsonData] = useState("");
  const [update, setUpdate] = useState(false);

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const save = () => {
    if (props.applicationID) {
      ApplicationService.viewApplication({ user_application_id: props.applicationID, type: 8 }).then(result => {
        if (file != "") {
          var form_data = new FormData();
          form_data.append("user_application_id", props.applicationID);
          form_data.append("drawing", file);
          ApplicationService.saveDrawing(form_data)
            .then((result) => {
              alert(
                result.data.message
                  ? result.data.message
                  : "Application saved successfully"
              );
              paymentInitiate();
            })
            .catch(function (error) {
              ErrorHandler(error);
            });
        } else {
          alert("please select a Drawing File ?");
        }
        // let response = result.data.user_application_drawing;
        // if (response != null) {
        // }
      }).catch(function (error) {
        ErrorHandler(error);
      });

    } else {
      alert("please fill GeneralInformation !");
    }
  };
  const paymentInitiate = () => {
    PaymentService.payment_initiate({ user_application_id: props.applicationID }).then(result => {
      console.log(result.data);
      setJsonData(result.data);
      setModal(true);
    }).catch(function (error) {
      ErrorHandler(error);
    });
  }
  return (
    <>
      <Col className=" px-2">
        <Col className="d-flex w-100 mt-3">
          <Col lg={12} className="d-flex align-items-center my-auto">
            {/* <Col className="px-2 py-1 bg-lt-white-f7 mx-2 my-2">
              <label className="c1 fw-bold f14">Upload your Drawing file:</label>
            </Col> */}
            {/* {fileURL != "" &&
            <Button
              style={{ fontSize: "14px" }}
              className="bg-transparent border-0 text-primary ms-auto me-0"
              onClick={handleClick}
            >
              View
            </Button>
          } */}
            <input
              style={{ width: "190px", fontSize: "13px", margin: 'auto' }}
              type="file"
              accept=".dwg,.DWG"
              onChange={handleChange}
            />
          </Col>
        </Col>
        {modal === true ? (
          <>
            <Modal
              show={modal}
              onHide={() => {
                setModal(false);
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                  Payment Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul>
                  <li>
                    Account Number : {jsonData?.virtual_account_number}
                  </li>
                  <li>
                    IFSC code : {jsonData?.ifsc_code}
                  </li>
                  <li>
                    Processing fees : {jsonData?.processing_fees}
                  </li>
                </ul>
              </Modal.Body>
            </Modal>
          </>
        ) : null}
      </Col>

      <Col className="d-flex justify-content-center mb-2 bg-white">
        <Button
          onClick={save}
          className="tab-blu-back "
          style={{
            color: "#fff",
            fontSize: 15,
            marginTop: 30,
            padding: "6px 14px",
            backgroundColor: "#5593db",
          }}
        >
          <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
          Submit Drawing
        </Button>
      </Col>
    </>
  );
};

export default UserDrawing;
