import httpClient from "../constant/httpClient"
import { URL } from "../constant/URL"

const drawingChange = (drawing_id, payload) => {
    return httpClient.post(URL.DRAWING_FILE_CHANGE + drawing_id, payload);
}
const documentChange = (payload) => {
    return httpClient.post(URL.DOCUMENT_CHANGE, payload);
}
const nocChange = (id, payload) => {
    return httpClient.post(URL.NOC_CHANGE + id, payload);
}
const siteDetailsChange = (id, payload) => {
    return httpClient.post(URL.SITE_DETAILS_CHANGE + id, payload);
}
const ApplicationRejectService = {
    drawingChange,
    documentChange,
    nocChange,
    siteDetailsChange,
}
export default ApplicationRejectService