import { faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Accordion, Button, Col, Form } from "react-bootstrap";
import UserDetails from "../../services/storage/UserDetails";

const ApplicantInformation = () => {
  const { userDetails } = UserDetails();
  const [type, setType] = useState(userDetails.type);
  return (
    <Col className="prop-norm">
      {/* <Col className="d-flex justify-content-end mb-2">
        <Button
          // onClick={save}
          className="bg-white px-2 py-1 me-1 border-1 border-solid border-color-lit-white rounded-2"
          style={{
            color: "#2f6db5",
            fontSize: 13,
          }}>
          <FontAwesomeIcon className="f15 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col> */}

      <Accordion defaultActiveKey={type == 1 ? "1" : "0"}>
        {type != 1 &&
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">
                Architect/ Structural Engineer/ Surveyor Information
              </h5>
            </Accordion.Header>
            <Accordion.Body className="px-2 py-1">
              <Col lg={12} className="d-md-flex flex-wrap">
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">Consultant Type*</p>
                  </Col>
                  <Col>
                    <Form.Select
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                      value={type != 1 ? type : null}
                      disabled
                    >
                      <option className="f14 py-2" value="0">
                        Select
                      </option>
                      <option className="f14 py-2" value="2">
                        Architect
                      </option>
                      <option className="f14 py-2" value="3">
                        Structural Engineer
                      </option>
                    </Form.Select>
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">Name*</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails.first_name}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">Address*</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.address}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">License Number*</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.license_number}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">Validity*</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.license_validity}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2
              align-items-center">
                  <Col>
                    <p className="f13 mb-1">Mobile Number*</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.mobile}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">E-mail ID*</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.email}
                      disabled
                    />
                  </Col>
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        }
        {type == 1 &&
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="f14 mb-0">Applicant's Information</h5>
            </Accordion.Header>
            <Accordion.Body className="px-2 py-1">
              <Col lg={12} className="d-md-flex flex-wrap">
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">First Name *</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails.first_name}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">Last Name *</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails.last_name}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">ID Proof Number *</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_file?.id_number}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1"> Address *</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.address}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">City *</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.city}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1"> State/Province/Region *</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.state}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">PinCode *</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.pin_code}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1"> Email *</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails.email}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1">Landline Number *</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.landline}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                  <Col>
                    <p className="f13 mb-1"> Mobile Number *</p>
                  </Col>
                  <Col>
                    <input className="form-control px-2 py-1 rounded-0"
                      value={userDetails.mobile}
                      disabled
                    />
                  </Col>
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        }
      </Accordion>
      <Col className="d-flex justify-content-center mb-2">
        <Button
          // onClick={save}
          className="tab-blu-back"
          style={{
            color: "#fff",
            fontSize: 15,
            marginTop: 10,
            padding: "6px 14px",
            backgroundColor: "#5593db",
          }}
        >
          <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col>
    </Col>
  );
};

export default ApplicantInformation;
