import React, { useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  FormControl,
  Button,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Banner,
  LOCK,
  Logo,
  DPMS,
  LTP,
  CIT,
  Arrow,
  OnlinePay,
  AppliBtn,
  Newgif,
  FooterBack,
  FooterLogo,
} from "../assets/images/Index";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import RegisterService from "../services/service/RegisterService";
import ErrorHandler from "../services/constant/ErrorHandler";
import { useHistory } from "react-router-dom";
import UserDetails from "../services/storage/UserDetails";
import RitFixed from "./RitFixed";

const Login = ({ setToken }) => {
  const { saveUserDetails } = UserDetails();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [userNameErr, setUserNameErr] = useState(null);
  const [passwordErr, setPasswordErr] = useState(null);
  const changeUserName = (e) => {
    setUserName(e.target.value);
    if (e.target.value != "") {
      setUserNameErr(false);
    } else {
      setUserNameErr(true);
    }
  };
  const changePassword = (e) => {
    setPassword(e.target.value);
    if (e.target.value != "") {
      setPasswordErr(false);
    } else {
      setUserNameErr(true);
    }
  };
  useEffect(() => {
    loadCaptchaEnginge(6, "grey", "blue");
  }, []);
  const doSubmit = () => {
    let user_captcha_value =
      document.getElementById("user_captcha_input").value;

    if (validateCaptcha(user_captcha_value) == true) {
      handleLogin();
    } else {
      alert("Captcha Does Not Match");
    }
  };
  const handleLogin = () => {
    if (userNameErr == false && passwordErr == false) {
      RegisterService.login({ username: userName, password: password })
        .then((result) => {
          let response = result.data;
          if (response.status == "success") {
            setToken(response.token);
            saveUserDetails(response.user);
            window.location.href = "/#/select-apply-for";
            // window.location.href = "/";

            // history.push({
            //   pathname: '/draft-application'
            // });
          }
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("Please enter your username and password");
    }
  };
  return (
    <>
      <RitFixed />
      {/* <marquee width="100%" direction="left" className="marq-box bg-danger">
        <p className="text-white mb-0 marq-p">
          Currently, this Portal is for testing purpose for architects under
          TSIIC to submit their drawings and to check on scrutiny reports
        </p>
      </marquee> */}
      <div className="top-banner-img">
        <Banner />
      </div>
      <Col className="banner-img d-md-flex justify-content-between">
        <Col md={6} xl={6} className="my-2">
          <div className="ltp-cit">
            <div className="box-ltp">
              <div className="d-flex align-items-center mb-2">
                <LTP />
                <h4 className="ps-3 f16 c7 mb-0 text-uppercase">
                  <strong>LTP Registration</strong>
                </h4>
              </div>
              <p className="d-flex justify-content-end mb-1 p-1 border-bottom border-dark">
                <Link to="/professional/registration" className="c8 f18">
                  <strong>
                    <i> Register Now</i>
                  </strong>
                  <Arrow />
                </Link>
              </p>
            </div>
            <div className="box-cit mt-2">
              <div className="d-flex align-items-center mb-2">
                <CIT />
                <h4 className="ps-3 f16 c7 mb-0 text-uppercase">
                  <strong>Citizen Registration</strong>
                </h4>
              </div>
              <p className="d-flex justify-content-end mb-0 p-1 border-bottom border-dark">
                <Link to="/citizen/registration" className="c8 f18">
                  <strong>
                    <i> Register Now</i>
                  </strong>
                  <Arrow />
                </Link>
              </p>
            </div>

            {/* <Row className="justify-content-center">
              <Col
                xs={12}
                sm={6}
                className="mb-2 mb-sm-0 text-center text-sm-end"
              >
                <Link to="/application-search">
                  <AppliBtn />
                </Link>
              </Col>
              <Col xs={12} sm={6} className="text-center text-sm-start">
                <Link to="/online-payment">
                  <OnlinePay />
                </Link>
              </Col>
            </Row> */}

            {/* <p
                      className="text-white mt-2 mb-0 p-1 text-center m-auto f13 bg3"
                      style={{ width: "180px", cursor: "pointer" }}
                      onClick={handleShow}>
                      Provide Inspection Checklist
                    </p> */}
          </div>
        </Col>
        <div className="bg-white form-box my-2">
          <Row>
            <Col xs={12} md={3} className=" d-none d-lg-block m-auto">
              <LOCK />
            </Col>
            <Col className="border border-black-50" xs={12} lg={9}>
              <Form.Label className="mt-3 mb-0 f14">User Name</Form.Label>
              <InputGroup className="mb-1">
                <FormControl
                  className="inputBox"
                  value={userName}
                  onChange={changeUserName}
                />
              </InputGroup>
              <Form.Label className="mt-2 mb-0 f14">Password</Form.Label>
              <InputGroup className="mb-1">
                <FormControl
                  type="password"
                  className="inputBox"
                  value={password}
                  onChange={changePassword}
                />
              </InputGroup>
              {/* <Button
          className="btn-login f13"
          as="input"
          type="button"
          value="Login"
        /> */}
              <LoadCanvasTemplate />
              <input
                placeholder="Enter Captcha Value"
                autocomplete="off"
                id="user_captcha_input"
                name="user_captcha_input"
                type="text"
              ></input>

              {/* <Link
              to="/draft-application"
              className="btn-login f11 w-100 d-flex align-items-center justify-content-center">
              Login
            </Link> */}
              <div className="mt-2">
                <Button onClick={doSubmit} className="bg-">
                  Login
                </Button>

                <a
                  // href="http://tsiiccontrol.abpas.co.in/#/staff/signin" // live
                  // href="https://tsiiccontrol.abpas.co.in/#/staff/signin" // live
                  href="http://autometrycontrol.autometrybpas.co.in/#/staff/signin" // develop
                  // href="https://autometrycontrol.autometrybpas.co.in/#/staff/signin" // develop
                  target="blank"
                >
                  <Button className="bg- mx-3">
                    For Officers Login Click Here
                  </Button>
                </a>
              </div>
              <p
                className="f13 mt-1 text-primary"
                style={{ cursor: "pointer" }}
                onClick={handleShow}
              >
                Forgot Password?
              </p>
            </Col>
          </Row>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="justify-content-center">
              <div className="f14 text-center">Forget password</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label className="mt-3 mb-0 f14">Login Name</Form.Label>
            <InputGroup className="mb-1">
              <FormControl className="inputBox" placeholder="Login Name" />
            </InputGroup>
            <Form.Label className="mt-2 mb-0 f14">E-mail ID</Form.Label>
            <InputGroup className="mb-1">
              <FormControl className="inputBox" placeholder="E-mail ID" />
            </InputGroup>
            <Form.Label className="mt-2 mb-0 f14">Mobile No</Form.Label>
            <InputGroup className="mb-1">
              <FormControl className="inputBox" placeholder="Mobile No" />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button variant="outline-secondary" onClick={handleClose}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    </>
  );
};

export default Login;
