import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Tab, Tabs } from "react-bootstrap";
import SideBar from "../component/Sidebar";
import ApplicPermissionView from "../component/ApplicPermissionView";

const ApplicPermission = () => {
  const [applicationID, setApplicationID] = useState(null);
  return (
    <Col className="d-lg-flex">
      <ApplicPermissionView />
      {/* <Col className="rt-dashboard">
        <Header />
        <Col className="d-flex justify-content-between w-100 px-2 bg-green">
          <Link to="/dashboard" className="d-flex black align-items-center p-2">
            <FontAwesomeIcon icon={faArrowCircleLeft} />
            <p className="mb-0 ms-2">Back</p>
          </Link>
          <Button className="proposal-risk-back">Proposal Risk Category</Button>
        </Col>
        <Col className="px-2 app-inform mt-1">
          <Tabs id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="generalInformation" title="General Information">
              <GeneralInformation setApplicationID={setApplicationID} />
            </Tab>
            <Tab
              eventKey="applicationInformation"
              title="Applicant Information">
              <ApplicantInformation applicationID={applicationID} />
            </Tab>
            <Tab eventKey="applicantCheckList" title="Applicant CheckList">
              <ApplicantCheckList applicationID={applicationID} />
            </Tab>
          </Tabs>
        </Col>
      </Col> */}
    </Col>
  );
};

export default ApplicPermission;
