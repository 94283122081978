import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationService from "../../services/service/ApplictionService";
import ReportOneg from "../Report/thirdchangeReport";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import rjson4 from "../Report/rjson4";
import { PDFExport } from "@progress/kendo-react-pdf";
import PaymentService from "../../services/service/PaymentService.js";
import moment from "moment";
import UserDetails from "../../services/storage/UserDetails.js";
const ScrutinyReportGenerated = (props) => {
  const { userDetails } = UserDetails();
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const pdfExportComponent = React.useRef(null);
  const [addres, setAddres] = useState("");
  let address = "";
  useEffect(() => {
    address = userDetails?.user_detail?.address
      ? userDetails?.user_detail?.address
      : null;

    address += userDetails?.user_detail?.city
      ? ", " + userDetails?.user_detail?.city
      : null;
    address += userDetails?.user_detail?.state
      ? ", " + userDetails?.user_detail?.state
      : null;
    address += userDetails?.user_detail?.pin_code
      ? ", " + userDetails?.user_detail?.pin_code + "."
      : null;
    setAddres(address);
  }, []);

  useEffect(() => {
    ApplicationService.getApplicationList({ type: "report_generated" })
      .then((result) => {
        setList(result.data.user_application_report_generated);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const [modalType, setModalType] = useState("");
  const viewPreCheckReport = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("error");
        setModalData(response.precheck_errors);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewDrawingPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("report");
        setModalData(response.scrutiny_report_pdf);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewScrutinyReportPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        // viewReport(response);
        setModalType("report");
        setModalData(response.scrutiny_report);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewRecepit = (value) => {
    PaymentService.payment_receipt({
      user_application_id: value.id,
      type: 1,
    })
      .then((result) => {
        let response = result.data;
        setModalType("receipt");
        setModalData(response);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewReport = async (values) => {
    let temp = {};
    let drawing = JSON.parse(values.rule_engine_json_response);
    let details = values.scrutiny_report_datas;
    let anpdf = values.scrutiny_report_pdf;
    temp.owner = {
      firstName: details.applicant_name,
      lastName: details.applicant_name,
      email: details.email,
      address: details.address,
    };
    temp.mobile = details.mobile;
    temp.surveyNo = details.sector_number;
    temp.doorNo = details.plot_number;
    temp.locality = details.mandal_name;
    temp.village = details.village_name;
    temp.siteaddress = details.site_address;
    temp.useOfTheBuildings = details.land_use_category;
    temp.subuseOfTheBuildings = details.land_subuse_category;
    temp.existingRoadType = details.road_type;
    temp.proposedRoadType = details.land_subuse_category;
    temp.architect = {
      name: details.applicant_name,
      email: details.email,
      mobile: details.mobile,
    };
    temp.appliedOn = details.receipt_date;
    temp.applicationId = details.application_number;
    temp.report = drawing;
    // temp.owner = { firstName: 'test', lastName: 'test2', email: 'test@gmail.com', mobile: '9566673188' };
    // temp.mobile = "9266673188";
    // temp.architect = { name: 'test', email: 'test@gmail.com', mobile: '9566673188' };
    // temp.report = rjson4;
    let newreport = new ReportOneg(temp);
    let results = await newreport.show();
    setModalType("report");
    setModalData(results);
    setModal(true);
  };
  const columns = [
    {
      name: "sl",
      label: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{tableMeta.rowIndex + 1}</div>;
        },
      },
    },
    {
      name: "file_no",
      label: "File No",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Link
                to={{
                  pathname: "/application-view",
                  state: list[tableMeta.rowIndex],
                }}
              >
                {value}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "application_type",
      label: "Building Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "case_type",
      label: "Case Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "iala_name",
      label: "IALA",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pre_check_report",
      label: "Pre-Check Report",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? "f14 c4 text-center pre-pdf-btn"
                  : "f14 c4 text-center pre-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewPreCheckReport(list[tableMeta.rowIndex])}
                className="view-btn"
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "drawing_pdf",
      label: "Drawing PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 4
                  ? "f14 c4 text-center draw-pdf-btn"
                  : "f14 c4 text-center draw-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewDrawingPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "secrutiny_report_pdf",
      label: "Scrutiny Report PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status > 5
                  ? "f14 c4 text-center sec-pdf-btn"
                  : "f14 c4 text-center sec-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status > 4 ? false : true}
                onClick={() => viewScrutinyReportPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "recepit",
      label: "Recepit",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="f14 c4 text-center date-pdf-btn">
              <button onClick={() => viewRecepit(list[tableMeta.rowIndex])}>
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Date Of Submission",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const options = {
    selectableRows: false,
    filter: true,
    filterType: "dropdown",
  };

  return (
    <Col className="rt-dashboard">
      <MUIDataTable
        title={"Scrutiny Report Generated Applications"}
        data={list}
        columns={columns}
        options={options}
      />
      {modal === true ? (
        <>
          <Modal
            show={modal}
            onHide={() => {
              setModal(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                {modalType == "report"
                  ? "Report"
                  : modalType == "error"
                  ? "Errors"
                  : "Payment Receipt"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {modalType == "report" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : modalType == "error" ? (
                  <div>
                    <Row>
                      <Col>Errors</Col>
                    </Row>
                    {modalData.map((item, index) => {
                      return (
                        <Row>
                          <Col>{item}</Col>
                        </Row>
                      );
                    })}
                  </div>
                ) : (
                  modalType == "receipt" && (
                    <div className="draw-pdf-btn">
                      <PDFExport
                        ref={pdfExportComponent}
                        paperSize="A4"
                        fileName="Payment Receipt"
                      >
                        <div
                          style={{
                            width: "95%",
                            margin: "auto",
                            fontFamily: "Verdana, sans-serif",
                          }}
                        >
                          <div class="hed" style={{ textAlign: "center" }}>
                            <h5
                              style={{
                                fontWeight: "bold",
                                fontSize: "25px",
                                marginBottom: 0,
                              }}
                            >
                              TGIIC
                            </h5>
                            <h6
                              style={{
                                fontSize: "18px",
                                marginBottom: 0,
                                marginTop: "10px",
                                backgroundColor: "#00000012",
                                padding: "2px",
                              }}
                            >
                              {" "}
                              Payment Receipt
                            </h6>
                          </div>
                          <table
                            border="0"
                            cellspacing="0"
                            width="100%"
                            style={{
                              fontSize: "13px",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              borderBottom: "1px solid #000",

                              borderTop: "1px solid #000",
                            }}
                          >
                            <tbody>
                              <tr>
                                <th
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                  }}
                                >
                                  Receipt No
                                </th>
                                <td style={{ width: " 2%" }}>:</td>
                                <td style={{ textAlgin: "start" }}>
                                  {modalData?.receipt_details?.receipt_no
                                    ? modalData?.receipt_details?.receipt_no
                                    : ""}
                                </td>

                                <th
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                  }}
                                >
                                  Receipt Date
                                </th>
                                <td style={{ width: "2%" }}>:</td>
                                <td>
                                  {moment(new Date()).format("D MMMM, YYYY")}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                  }}
                                >
                                  Demand Note No
                                </th>

                                <td style={{ width: "2%" }}>:</td>
                                <td>
                                  {modalData?.receipt_details?.demand_note_no
                                    ? modalData?.receipt_details?.demand_note_no
                                    : ""}
                                </td>
                                <th
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                  }}
                                >
                                  BA No
                                </th>
                                <td style={{ width: "2%" }}>:</td>
                                <td>
                                  {modalData?.receipt_details?.ba_no
                                    ? modalData?.receipt_details?.ba_no
                                    : ""}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table
                            border="0"
                            cellspacing="0"
                            width="100%"
                            style={{
                              fontSize: "13px",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ padding: "10px", width: "30%" }}>
                                  {" "}
                                  Applicant Name
                                </td>
                                <td style={{ width: "2%" }}>:</td>
                                <td>
                                  <b style={{ fontSize: "13px" }}>
                                    {" "}
                                    {userDetails?.first_name
                                      ? userDetails?.first_name
                                      : ""}
                                    {userDetails?.last_name
                                      ? " " + userDetails?.last_name
                                      : ""}
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "10px", width: "30%" }}>
                                  {" "}
                                  Address of Communication
                                </td>
                                <td>:</td>
                                <td>
                                  {" "}
                                  {addres}
                                  {/* {userDetails?.user_detail?.address
                                ? userDetails?.user_detail?.address
                                : ""}
                              {userDetails?.user_detail?.city
                                ? ", " + userDetails?.user_detail?.city
                                : ""}
                              {userDetails?.user_detail?.state
                                ? ", " + userDetails?.user_detail?.state
                                : ""}
                              {userDetails?.user_detail?.pin_code
                                ? ", " +
                                  userDetails?.user_detail?.pin_code +
                                  "."
                                : ""} */}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <table
                            border="1"
                            cellspacing="0"
                            width="100%"
                            style={{
                              fontSize: "13px",
                              padding: "10px",
                              fontStyle: "normal",
                              fontWeight: "normal",
                              borderWidth: "1px",
                              borderColor: "#000",
                              marginBottom: "10px",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                    width: "20%",
                                    fontSize: "8px",
                                  }}
                                >
                                  Amount (INR){" "}
                                </td>
                                <td style={{ width: "2%" }}>:</td>
                                <td style={{ width: "50%", fontWeight: 700 }}>
                                  {" "}
                                  {modalData?.receipt_details?.amount
                                    ? parseFloat(
                                        modalData?.receipt_details?.amount
                                      ).toFixed(2)
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                    width: "20%",
                                    fontSize: "8px",
                                  }}
                                >
                                  Amount (In Words)
                                </td>
                                <td style={{ width: "2%" }}>:</td>
                                <td style={{ fontWeight: 700 }}>
                                  {" "}
                                  {modalData?.receipt_details?.amount_in_words
                                    ? modalData?.receipt_details
                                        ?.amount_in_words
                                    : ""}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                    fontSize: "8px",
                                  }}
                                >
                                  Transaction Type
                                </td>
                                <td style={{ width: "2%" }}>:</td>
                                <td
                                  style={{ fontSize: "10px", fontWeight: 700 }}
                                >
                                  Net Banking / Credit Card / Debit Card
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    textAlign: "start",
                                    padding: "10px",
                                    width: "20%",
                                    fontSize: "8px",
                                  }}
                                >
                                  Payment Made At
                                </td>
                                <td style={{ width: "2%" }}>:</td>
                                <td style={{ fontWeight: 700 }}>Online</td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    padding: "10px 0px 10px 10px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Payment Details
                                </td>
                              </tr>
                              {modalData?.transactions.length > 0 &&
                                modalData?.transactions.map((list, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        padding: "10px",
                                        fontSize: "9px",
                                      }}
                                    >
                                      Transaction No
                                    </td>
                                    <td style={{ width: "2%" }}>:</td>
                                    <td
                                      style={{
                                        textAlign: "start",

                                        fontSize: "9px ",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {list.txn_ref_no}
                                    </td>

                                    <td
                                      style={{
                                        textAlign: "end",
                                        padding: "10px",

                                        fontSize: "9px",
                                      }}
                                    >
                                      Date :{" "}
                                      {moment(new Date(list.created_at)).format(
                                        "D MMMM, YYYY"
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {/* <table style={{ width: "100%" }}>
                            <tr
                              style={{
                                fontSize: "18px",
                                backgroundColor: "#00000012",
                              }}
                            >
                              <th
                                style={{
                                  textAlign: "start",
                                  padding: "10px",
                                }}
                                colSpan="6"
                              >
                                Payment Details
                              </th>
                            </tr>
                            <tbody>
                              {modalData?.transactions.length > 0 &&
                                modalData?.transactions.map((list, index) => (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        padding: "10px",
                                        width: "25%",
                                        fontSize: "9px",
                                      }}
                                    >
                                      Transaction No
                                    </td>
                                    <td style={{ width: "2%" }}>:</td>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        width: "15%",
                                        fontSize: "9px ",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {list.txn_ref_no}
                                    </td>

                                    <td
                                      style={{
                                        textAlign: "end",
                                        padding: "10px",
                                        width: "20%",
                                        fontSize: "9px",
                                      }}
                                    >
                                      Date{" "}
                                    </td>
                                    <td style={{ width: "2%" }}>:</td>
                                    <td
                                      style={{
                                        textAlign: "start",
                                        width: "15%",
                                        fontSize: "9px",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {moment(new Date(list.created_at)).format(
                                        "D MMMM, YYYY"
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table> */}
                          <br></br>
                          <br></br>
                          <div class="sig_fs" style={{ float: "right" }}>
                            <h5
                              style={{
                                textAlign: "center",
                                fontSize: "14px",
                                fontWeight: 700,
                              }}
                            >
                              SIGNATURE OF THE CFC CLERK TGIIC
                            </h5>
                          </div>
                        </div>
                      </PDFExport>
                      <div style={{ marginLeft: "45%" }}>
                        <button
                          className="mt-5"
                          onClick={() => {
                            if (pdfExportComponent.current) {
                              pdfExportComponent.current.save();
                            }
                          }}
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  )
                )}
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </Col>
  );
};
export default ScrutinyReportGenerated;
