import React from "react";
import httpClient from "../constant/httpClient";
import { URL } from "../constant/URL";
const getDraftList = () => {
    return httpClient.post(URL.DRAFT_LIST);
}
const getSubmittedList = () => {
    return httpClient.post(URL.SUBMITTED_LIST);
}
const getPendingList = () => {
    return httpClient.post(URL.PENDING_LIST);
}
const ApplicationSubmissionService = {
    getDraftList,
    getSubmittedList,
    getPendingList,
}
export default ApplicationSubmissionService