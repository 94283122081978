import React, { useState } from "react";
import { Button, Col, Form, Modal, Pagination, Table, Row, Dropdown, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faFileExcel,
  faSort,
  faSyncAlt,
  faTh,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { components } from "react-select";

const StructuralStabilityReport = () => {
  const [optionSelected, setOptionSelected] = useState(null);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const [show, setShow] = useState(false);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const colourOptions = [
    { value: "ocean", label: "Ocean", color: "#00B8D9" },
    { value: "blue", label: "Blue", color: "#0052CC" },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630" },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
  ];
  const MultiValue = (props) => (
    <components.MultiValue {...props}></components.MultiValue>
  );
  const handleChange = (selected) => {
    setOptionSelected(selected);
  };

  return (
    <Col className="rt-dashboard  mt-3">
    <Row className="justify-content-md-center justify-content-lg-start">
      <Col className="col-12 col-md-8 col-lg-5">
          <fieldset>
            <legend>Summary</legend>
            <Col className="mt-2">
              <Col className="overflow-scroll mb-3">

                <div style={{ maxHeight: "220px", Height: "220px", overflowY: 'auto' }}>
                  <Table hover className="mb-0">
                    <thead className="sticky-top" style={{ verticalAlign: "middle" }}>
                      <tr className="bg-lt-white-f7">
                        <th className="f13 fw-bold black cursor-pointer ps-2 pe-0">
                          
                        </th>
                        <th className="f13 fw-bold black cursor-pointer px-0">
                          
                        </th>
                        <th className="f13 fw-bold black cursor-pointer ps-0 pe-2 text-end">
                        Status
                        </th>
                        <th className="f13 fw-bold black cursor-pointer ps-0 pe-2 text-end">
                        
                        </th>
                      </tr>
                    </thead>
                    <tbody className="" style={{ verticalAlign: "middle" }}>
                      <tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0 sr-No"></td>
                        <td className="f14 black p-0 text-start"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                      </tr>
                      <tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0 sr-No"></td>
                        <td className="f14 black p-0 text-start"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                      </tr>
                      <tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0 sr-No"></td>
                        <td className="f14 black p-0 text-start"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                      </tr>
                      <tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0 sr-No"></td>
                        <td className="f14 black p-0 text-start"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                      </tr>
                      <tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0 sr-No"></td>
                        <td className="f14 black p-0 text-start"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                      </tr>
                      <tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0 sr-No"></td>
                        <td className="f14 black p-0 text-start"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                      </tr>
                      <tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0 sr-No"></td>
                        <td className="f14 black p-0 text-start"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end"></td>
                      </tr>
                      <tr
                        style={{ height: "30px" }}
                        className=" border-color-lit-white">
                        <td className="f14 black ps-2 pe-0 py-0 sr-No">Total</td>
                        <td className="f14 black p-0 text-start">0</td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end">0</td>
                        <td className="f14 black ps-0 pe-2 py-0 text-end">0</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              
            </Col>
          </fieldset>
        </Col>
        
      </Row>

      <Row>
        <Col className="col-12 col-md-12 mt-3">
          <fieldset>
            <legend>Structural Stability Report</legend>
            <div style={{ maxHeight: "350px", Height: "320px", overflowY: 'auto' }}>
            <Table striped>
              <thead className="sticky-top">
                <tr className="bg-light">
                  <th className="f14 fw-normal black cursor-pointer">
                    sr.No
                    <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                    File No <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                  Structural Stability Certification Done  By(OU/JNTU/Others)<FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                  Status <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                  Verification  Requested Date <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                  Certification Received Date <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                  <th className="f14 fw-normal black cursor-pointer">
                  Attachment <FontAwesomeIcon icon={faSort} className="mt-1" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="f14 c4">1.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
                <tr>
                  <td className="f14 c4">2.</td>
                  <td className="f14 c4">IIC/0758/2021</td>
                  <td className="f14 c4">sampath kumar</td>
                  <td className="f14 c4">Status</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">22/12/2021</td>
                  <td className="f14 c4">File.pdf</td>
                </tr>
              </tbody>
            </Table>
            </div>

            <Col className="d-flex" style={{ background: "#f7f7f7", width: "100%" }}>
              <Button className="border-0 bg-transparent">
                <FontAwesomeIcon
                  style={{
                    color: "green",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  icon={faSyncAlt}
                />
              </Button>
              <Button className="border-0 bg-transparent">
                <FontAwesomeIcon
                  style={{
                    color: "green",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  icon={faFileExcel}
                />
              </Button>

<Pagination className="mb-0">{items}</Pagination>
            </Col>

          </fieldset>
        </Col>
      </Row>
    </Col>
  );
};

export default StructuralStabilityReport;
