import { faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import ErrorHandler from "../services/constant/ErrorHandler";
import ApplicationService from "../services/service/ApplictionService";
import UserApplication from "../services/storage/UserApplication";

const GeneralInformation = (props) => {
  const { setUserApplicationId } = UserApplication();
  const [projectType, setProjectType] = useState("Buiding Permission");
  const [applicationType, setApplicationType] = useState("Residential");
  const [caseType, setCaseType] = useState("New");
  const [applicationFor, setApplicationFor] = useState("TGIIC");
  const [zone, setZone] = useState(0);
  const [zoneList, setZoneList] = useState([]);
  const [district, setDistrict] = useState(0);
  const [districtList, setDistrictList] = useState([]);
  const [iala, setIala] = useState(0);
  const [ialaList, setIalaList] = useState([]);
  const [industrialPark, setIndustrialPark] = useState(0);
  const [industrialParkList, setIndustrialParkList] = useState([]);
  const [mandal, setMandal] = useState(0);
  const [mandalList, setMandalList] = useState([]);
  const [village, setVillage] = useState(0);
  const [villageList, setVillageList] = useState([]);
  const [siteNumberType, setSiteNumberType] = useState(0);
  const [siteNumber, setSiteNumber] = useState("");
  const [siteLocation, setSiteLocation] = useState("");
  const [siteLocationType, setSiteLocationType] = useState(0);
  const [road, setRoad] = useState("");
  const [partialPayment, setPartialPayment] = useState(0);
  const [amaligamation, setAmaligamation] = useState(0);
  const [fireNOC, setFireNOC] = useState(0);

  const [mandalErr, setMandalErr] = useState(null);
  const [villageErr, setVillageErr] = useState(null);
  const [siteNumberErr, setSiteNumberErr] = useState(null);
  const [siteLocationErr, setSiteLocationErr] = useState(null);
  const [roadErr, setRoadErr] = useState(null);

  const changeMandal = (e) => {
    setMandal(e.target.value);
    if (e.target.value != 0) {
      getSubList(e.target.value, "village");
    }
  };
  const changeVillage = (e) => {
    setVillage(e.target.value);
    if (e.target.value != "") {
      setVillageErr(false);
    } else {
      setVillageErr(true);
    }
  };
  const changeSiteNumber = (e) => {
    setSiteNumber(e.target.value);
    if (e.target.value != "") {
      setSiteNumberErr(false);
    } else {
      setSiteNumberErr(true);
    }
  };
  const changeSiteLocation = (e) => {
    setSiteLocation(e.target.value);
    if (e.target.value != "") {
      setSiteLocationErr(false);
    } else {
      setSiteLocationErr(true);
    }
  };
  const changeRoad = (e) => {
    setRoad(e.target.value);
    if (e.target.value != "") {
      setRoadErr(false);
    } else {
      setRoadErr(true);
    }
  };
  useEffect(() => {
    ApplicationService.getZoneList()
      .then((result) => {
        var response = result.data;
        setZoneList(response.place);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const changeZone = (e) => {
    setZone(e.target.value);
    if (e.target.value != 0) {
      getSubList(e.target.value, "zone");
    }
  };
  const changeDistrict = (e) => {
    setDistrict(e.target.value);
    if (e.target.value != 0) {
      getSubList(e.target.value, "district");
    }
  };
  const changeIala = (e) => {
    setIala(e.target.value);
    if (e.target.value != 0) {
      getSubList(e.target.value, "iala");
    }
  };
  const changeIndustrialPark = (e) => {
    setIndustrialPark(e.target.value);
    if (e.target.value != 0) {
      getSubList(e.target.value, "mandal");
    }
  };
  const getSubList = (value, type) => {
    if (value != null && value != "") {
      ApplicationService.getZoneSubList(value)
        .then((result) => {
          var response = result.data;
          if (type == "zone") {
            setDistrictList(response.sub_place);
          } else if (type == "district") {
            setIalaList(response.sub_place);
          } else if (type == "iala") {
            setIndustrialParkList(response.sub_place);
          } else if (type == "mandal") {
            setMandalList(response.sub_place);
          } else if (type == "village") {
            setVillageList(response.sub_place);
          }
          // setIalaList(response);
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }
  };
  const save = () => {
    if (
      projectType != 0 &&
      applicationType != 0 &&
      caseType != 0 &&
      zone != 0 &&
      district != 0 &&
      iala != 0 &&
      industrialPark != 0 &&
      mandal != 0 &&
      village != 0 &&
      partialPayment != 0 &&
      siteNumberType != 0 &&
      siteNumberErr == false &&
      siteLocationType != 0 &&
      siteLocationErr == false &&
      roadErr == false &&
      amaligamation != 0 &&
      fireNOC != 0
    ) {
      var payload = {
        project_type: projectType,
        application_type: applicationType,
        case_type: caseType,
        zone: zone,
        district: district,
        iala: iala,
        industrial_park: industrialPark,
        mandal: mandal,
        village: village,
        partial_payment: partialPayment,
        site_number_type: siteNumberType,
        site_number: siteNumber,
        site_location_type: siteLocationType,
        site_location: siteLocation,
        road_street: road,
        amalgamation_plot: amaligamation,
        fire_noc: fireNOC,

        // For Develop Only

        application_for: applicationFor,
      };
      ApplicationService.userApplication(payload)
        .then((result) => {
          setUserApplicationId(result.data.user_application_id);
          props.setApplicationID(result.data.user_application_id);
          alert(result.data.message);
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("Please fill all details ?");
    }
  };
  return (
    <Col className="prop-norm">
      <Accordion>
        <Accordion.Item>
          <Accordion.Header>
            <h5 className="f14 mb-0">Proposal Information</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col lg={12} className="d-md-flex flex-wrap">
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Project Type*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    disabled
                  >
                    <option className="f14 py-2" value="Buiding Permission">
                      Buiding Permission
                    </option>
                    <option className="f15 py-2" value="Structural Engineer">
                      Structural Engineer
                    </option>
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Application Type*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={applicationType}
                    onChange={(e) => setApplicationType(e.target.value)}
                  >
                    <option className="f14 py-2" value="Residential">
                      Residential
                    </option>
                    <option className="f15 py-2" value="Commercial">
                      Commercial
                    </option>
                    <option
                      className="f15 py-2"
                      value="Commercial-IT Buildings"
                    >
                      Commercial - IT Buildings
                    </option>
                    <option className="f15 py-2" value="Commercial-Industrial">
                      Commercial - Industrial
                    </option>
                    <option className="f15 py-2" value="Shopping-Mall">
                      Shopping Mall
                    </option>
                    <option className="f15 py-2" value="Mixed">
                      Mixed
                    </option>
                  </Form.Select>
                </Col>
              </Col>

              {/* FOR ONLY DEVELOPERS START */}

              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Application For*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={applicationFor}
                    onChange={(e) => setApplicationFor(e.target.value)}
                  >
                    <option className="f14 py-2" value="TGIIC">
                      TGIIC
                    </option>
                    <option className="f15 py-2" value="assam">
                      Assam
                    </option>
                  </Form.Select>
                </Col>
              </Col>

              {/* FOR ONLY DEVELOPERS END */}

              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Case Type*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={caseType}
                    onChange={(e) => setCaseType(e.target.value)}
                  >
                    <option className="f14 py-2" value="New">
                      New
                    </option>
                    <option className="f15 py-2" value="Revision">
                      Revision
                    </option>
                    <option className="f15 py-2" value="Addition or Alteration">
                      Addition or Alteration
                    </option>
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Zone*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={zone}
                    onChange={changeZone}
                  >
                    <option selected="selected" value="0">
                      Select
                    </option>
                    {zoneList.length > 0 &&
                      zoneList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">District*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={district}
                    onChange={changeDistrict}
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    {districtList.length > 0 &&
                      districtList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">IALA*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={iala}
                    onChange={changeIala}
                  >
                    <option value="0">Select</option>
                    {ialaList.length > 0 &&
                      ialaList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Industrial Park*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={industrialPark}
                    onChange={changeIndustrialPark}
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    {industrialParkList.length > 0 &&
                      industrialParkList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Mandal*</p>
                </Col>
                <Col>
                  {/* <FormControl className="inputBox" value={mandal} onChange={changeMandal} /> */}
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={mandal}
                    onChange={changeMandal}
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    {mandalList.length > 0 &&
                      mandalList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1"> Village*</p>
                </Col>
                <Col>
                  {/* <FormControl className="inputBox" value={village} onChange={changeVillage} /> */}
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={village}
                    onChange={(e) => setVillage(e.target.value)}
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    {villageList.length > 0 &&
                      villageList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">
                    City Level Infra Impact Fees on instalment basis *
                  </p>
                </Col>
                <Col>
                  {/* <FormControl className="inputBox" value={village} onChange={changeVillage} /> */}
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={partialPayment}
                    onChange={(e) => setPartialPayment(e.target.value)}
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    <option className="f14 py-2" value="yes">
                      Yes
                    </option>
                    <option className="f14 py-2" value="no">
                      No
                    </option>
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">
                    Fire NOC is obtained for a Refuge Area Non Compliance
                  </p>
                </Col>
                <Col>
                  {/* <FormControl className="inputBox" value={village} onChange={changeVillage} /> */}
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={fireNOC}
                    onChange={(e) => setFireNOC(e.target.value)}
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    <option className="f14 py-2" value="yes">
                      Yes
                    </option>
                    <option className="f14 py-2" value="no">
                      No
                    </option>
                  </Form.Select>
                </Col>
              </Col>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item>
          <Accordion.Header>
            <h5 className="f14 mb-0">Site Details</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col className="d-md-flex flex-wrap">
              <Col
                lg={3}
                md={6}
                className="ps-0 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Form.Select
                  style={{ fontSize: "15px" }}
                  aria-label="Default select example"
                  className="form-control px-2 py-1 rounded-0"
                  value={siteNumberType}
                  onChange={(e) => setSiteNumberType(e.target.value)}
                >
                  <option value="0">Select Plot/House No.</option>
                  <option className="f14 py-2" value="1">
                    NA
                  </option>
                  <option className="f14 py-2" value="2">
                    Plot No.
                  </option>
                  <option className="f14 py-2" value="3 ">
                    House No./ Door No.
                  </option>
                  <option className="f14 py-2" value="4">
                    Shed Number
                  </option>
                </Form.Select>
              </Col>
              <Col
                lg={3}
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <input
                  className="form-control px-2 py-1 rounded-0"
                  value={siteNumber}
                  onChange={changeSiteNumber}
                />
              </Col>
              <Col
                lg={3}
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Form.Select
                  style={{ fontSize: "15px" }}
                  aria-label="Default select example"
                  className="form-control px-2 py-1 rounded-0"
                  value={siteLocationType}
                  onChange={(e) => setSiteLocationType(e.target.value)}
                >
                  <option className="f14 py-2" value="0">
                    Select Location By
                  </option>
                  <option className="f14 py-2" value="1">
                    Survey Number
                  </option>
                  <option className="f14 py-2" value="2">
                    TS Number
                  </option>
                  <option className="f14 py-2" value="3">
                    Gram khantam
                  </option>
                  <option className="f14 py-2" value="4">
                    Abadi
                  </option>
                </Form.Select>
              </Col>
              <Col
                lg={3}
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <input
                  className="form-control px-2 py-1 rounded-0"
                  value={siteLocation}
                  onChange={changeSiteLocation}
                />
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <label className="f13 mb-1">Road/Street</label>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    value={road}
                    onChange={changeRoad}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Amalgamation Plot </p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={amaligamation}
                    onChange={(e) => setAmaligamation(e.target.value)}
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    <option className="f14 py-2" value="yes">
                      Yes
                    </option>
                    <option className="f14 py-2" value="no">
                      No
                    </option>
                  </Form.Select>
                </Col>
              </Col>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Col className="d-flex justify-content-center mb-2">
        <Button
          onClick={save}
          className="tab-blu-back"
          style={{
            color: "#fff",
            fontSize: 15,
            marginTop: 10,
            padding: "6px 14px",
            backgroundColor: "#5593db",
          }}
        >
          <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col>
    </Col>
  );
};

export default GeneralInformation;
