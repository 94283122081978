import { faEllipsisH, faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Button, Col, Form, Row, FormControl, InputGroup } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationService from "../../services/service/ApplictionService";

const SiteDetails = (props) => {
  const [update, setUpdate] = useState(false);
  const [siteDetailsList, setSiteDetailsList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = () => {
    let test = 1;
    ApplicationService.viewApplication({ user_application_id: props.data.id, type: 9 }).then(result => {
      let response = result.data.user_application_site_detail;
      if (response.length > 0) {
        test = test + 1;
        setUpdate(true);
        var temp = [];
        for (let i = 0; i < response.length; i++) {
          temp.push({ site_detail_question_id: response[i].site_detail_question_id, architect_input: response[i].architect_input, });
        }
        setAnswerList(temp);
      }
    }).catch(function (error) {
      ErrorHandler(error);
    });
    ApplicationService.getSiteDetailsList().then(result => {
      setSiteDetailsList(result.data.site_detail_question);
      if (test == 1) {
        var ans = [];
        result.data.site_detail_question.map((item, index) => {
          ans.push({ site_detail_question_id: item.id, architect_input: "" });
        });
        setAnswerList(ans);
      }
    }).catch(function (error) {
      ErrorHandler(error);
    })
  }
  const changeInput = (e, index) => {
    var temp = [...answerList];
    temp[index].architect_input = e.target.value;
    setAnswerList(temp);
  }
  const save = () => {
    if (update) {
      var valid = 1;
      answerList.map((item, index) => {
        if (item.architect_input == "") {
          valid = valid + 1;
        }
      });
      if (valid == 1) {
        var payload = {
          user_application_id: props.data.id,
          site_detail_questions: answerList,
        }
        ApplicationService.saveSiteDetails(payload).then(result => {
          alert(result.data.message);
          getDetails();
        }).catch(function (error) {
          ErrorHandler(error);
        })
      } else {
        alert("Please fill all details");
      }
    } else {
      var valid = 1;
      answerList.map((item, index) => {
        if (item.architect_input == "") {
          valid = valid + 1;
        }
      });
      if (valid == 1) {
        var payload = {
          user_application_id: props.data.id,
          site_detail_questions: answerList,
        }
        ApplicationService.saveSiteDetails(payload).then(result => {
          alert(result.data.message);
          getDetails();
        }).catch(function (error) {
          ErrorHandler(error);
        })
      } else {
        alert("Please fill all details");
      }
    }
  }
  return (
    <Col className="prop-norm">
      {/* <Col className="d-flex justify-content-end mb-2">
        <Button
          onClick={save}
          className="bg-white px-2 py-1 me-1 mb-1 border-1 border-solid border-color-lit-white rounded-2"
          style={{
            color: "#2f6db5",
            fontSize: 13,
          }}>
          <FontAwesomeIcon className="f15 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col> */}

      <Container>

        <Row className="justify-content-center">
          <Col className="col-12 col-md-11 my-2 ps-0 pe-0">
            <div className="text-start mb-3">
              <div className="title-section rounded">
                <h5 className="f15 mb-0">
                  Site Details
                </h5>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          {siteDetailsList.map((item, index) => {
            return (
              <Col key={index} className="col-12 col-md-11 my-2 bg-white shadow py-3">
                <Row>
                  <Col className="col-12 col-md-8 mb-2">
                    <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                      {index + 1}. {item.question} ?
                    </Form.Label>
                    <div class="ps-3" style={{ color: "#999999" }}>
                      <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                    </div>
                  </Col>
                  <Col className="col-12 col-md-4 mb-2=">
                    <Form.Label className="mt-3 f13 px-4">
                      Input by Architect
                    </Form.Label>
                    <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                      <FormControl value={answerList[index]?.architect_input} className="inputBox" placeholder="..." onChange={(e) => changeInput(e, index)} />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
            )
          })
          }
          {/* <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  2. Whether approach road connected with an existing public road
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  3. Nature of approach road
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  4. Width of approach road in meters
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  5.Services over approach road
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  6. Whether approach road side drain exists
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  7. Whether the site is vaccant
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  7 a) FEES PAID
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  7 b) Work(Commenced/Not Commenced)
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  7 c) If Commenced, the status of construction at the time of Inspection
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  7 d) No.Of Existing structures
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  7 e) Type of Structure
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  7 f) Does area of existing structure tallies with the drawing?
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  7 g)Whether existing structure to be demolished?
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  8. Does plot boundaries tallies with the site?
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  9. Whether the site is affected by/ in the vicinity of
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  10.Distance from water body?
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  11. Whether high tension line passing over the site
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-3 ps-0 pe-0">
            <div className="text-start mb-3">
              <div className="title-section rounded">
                <h5 className="f14 mb-0">
                  12. Site surrounded by (Physical features)
                </h5>
              </div>
            </div>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  12 a) EAST
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  12 b) SOUTH
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  12 c) WEST
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  12 d) NORTH
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  13. Site terrain
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  14. Proposed site falling in
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  15. Site enclosed with
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  16. Whether the site is covered in?
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  17. Existing Compound
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-md-11 my-2 bg-white shadow py-3">
            <Row>
              <Col className="col-12 col-md-8 mb-2">
                <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                  17 a) FEES PAID
                </Form.Label>
                <div class="ps-3" style={{ color: "#999999" }}>
                  <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                </div>
              </Col>
              <Col className="col-12 col-md-4 mb-2=">
                <Form.Label className="mt-3 f13 px-4">
                  Input by Architect
                </Form.Label>
                <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                  <FormControl className="inputBox" placeholder="..." />
                </InputGroup>
              </Col>
            </Row>
          </Col> */}

        </Row>
      </Container>
      <Col className="d-flex justify-content-center mb-2">
        <Button
          onClick={save}
          className="tab-blu-back"
          style={{
            color: "#fff",
            fontSize: 15,
            marginTop: 10,
            padding: "6px 14px",
            backgroundColor: "#5593db",
          }}
        >
          <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col>
    </Col>
  );
};

export default SiteDetails;
