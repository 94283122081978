import React, { useState } from "react";
import { Button, Col, Form, Modal, Pagination, Table, Row, Dropdown, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faFileExcel,
  faSort,
  faSyncAlt,
  faTh,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { components } from "react-select";

import Pdf from "../../assets/images/report.pdf"

const JoinInspectionReportView = () => {
  const [optionSelected, setOptionSelected] = useState(null);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const [show, setShow] = useState(false);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const colourOptions = [
    { value: "ocean", label: "Ocean", color: "#00B8D9" },
    { value: "blue", label: "Blue", color: "#0052CC" },
    { value: "purple", label: "Purple", color: "#5243AA" },
    { value: "red", label: "Red", color: "#FF5630" },
    { value: "orange", label: "Orange", color: "#FF8B00" },
    { value: "yellow", label: "Yellow", color: "#FFC400" },
    { value: "green", label: "Green", color: "#36B37E" },
    { value: "forest", label: "Forest", color: "#00875A" },
    { value: "slate", label: "Slate", color: "#253858" },
    { value: "silver", label: "Silver", color: "#666666" },
  ];
  const MultiValue = (props) => (
    <components.MultiValue {...props}></components.MultiValue>
  );
  const handleChange = (selected) => {
    setOptionSelected(selected);
  };


  return (
    <Col className="rt-dashboard  pt-3 bg-light">
      <Row>
        <Col className="col-12 col-md-12 mt-3">

          <Table>
            <thead>
              <tr className="bg-light">
                <th className="f14 fw-normal black cursor-pointer">
                  sr.No
                </th>
                <th className="f14 fw-normal black cursor-pointer">
                  Officer Name
                </th>
                <th className="f14 fw-normal black cursor-pointer">
                  Designation
                </th>
                <th className="f14 fw-normal black cursor-pointer">
                  Site Inspection on
                </th>
                <th className="f14 fw-normal black cursor-pointer">

                </th>
              </tr>
            </thead>
            <tbody className="bg-light">
              <tr>
                <td className="f14 c4">1.</td>
                <td className="f14 c4">Raju</td>
                <td className="f14 c4">Executive Officer</td>
                <td className="f14 c4">22/12/2021</td>
                <td className="f14 c4">Report</td>
              </tr>
            </tbody>
          </Table>

          <Row className="border-bottom">
            <Col style={{ background: "#f7f7f7", width: "100%" }}>
              <Button className="border-0 bg-transparent">
                <FontAwesomeIcon
                  style={{
                    color: "green",
                    paddingTop: 0,
                    paddingBottom: 0,
                  }}
                  icon={faFileExcel}
                />
              </Button>
            </Col>
          </Row>
        </Col>

        <Col className="bg-white">
          <div className="mt-2" id="IframeSiteVisitPanel" style={{display: "block;"}}>
            <iframe id="IframeSiteVisit" frameborder="0" marginheight="0" marginwidth="0" width="100%" scrolling="auto" height="500px" 
            src={Pdf}>
            </iframe>
          </div>
        </Col>
        
      </Row>
    </Col>
  );
};

export default JoinInspectionReportView;
