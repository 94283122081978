import { faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form } from "react-bootstrap";
import {
  validAadharNumber,
  validEmail,
  validMobilNo,
} from "../../services/constant/Constant";
import UserDetails from "../../services/storage/UserDetails";
import ApplicationService from "../../services/service/ApplictionService";
import ErrorHandler from "../../services/constant/ErrorHandler";

const ApplicantInformation = (props) => {
  const { userDetails } = UserDetails();
  const [type, setType] = useState(userDetails.type);
  const [nameList, setNameList] = useState([]);
  const [name, setName] = useState(0);
  const [nameDetails, setNameDetails] = useState("");

  const [ownerName, setOwnerName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [email, setEmail] = useState("");
  const [landlineNumber, setLandlineNumber] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  const [typeNameList, setTypeNameList] = useState([]);
  const [typeNameId, setTypeNameId] = useState(0);
  const [typeNameDetails, setTypeNameDetails] = useState("");

  const [ownerNameErr, setOwnerNameErr] = useState(null);
  const [fatherNameErr, setFatherNameErr] = useState(null);
  const [aadharNumberErr, setAadharNumberErr] = useState(null);
  const [address1Err, setAddress1Err] = useState(null);
  const [address2Err, setAddress2Err] = useState(null);
  const [cityErr, setCityErr] = useState(null);
  const [stateErr, setStateErr] = useState(null);
  const [pincodeErr, setPincodeErr] = useState(null);
  const [emailErr, setEmailErr] = useState(null);
  const [landlineNumberErr, setLandlineNumberErr] = useState(null);
  const [mobileNoErr, setMobileNoErr] = useState(null);
  const changeType = (e) => {
    setType(e.target.value);
    if (e.target.value != 0) {
      getNameList(e.target.value);
    }
  };
  const changeName = (e) => {
    setName(e.target.value);
    setNameDetails("");
    if (e.target.value != 0) {
      getTypeNameDetails(e.target.value, 1);
    }
  };
  const changeOwnerName = (e) => {
    setOwnerName(e.target.value);
    if (e.target.value != "") {
      setOwnerNameErr(false);
    } else {
      setOwnerNameErr(true);
    }
  };
  const changeFatherName = (e) => {
    setFatherName(e.target.value);
    if (e.target.value != "") {
      setFatherNameErr(false);
    } else {
      setFatherNameErr(true);
    }
  };
  const changeAadharNumber = (e) => {
    setAadharNumber(e.target.value);
    if (e.target.value != "" && validAadharNumber.test(e.target.value)) {
      console.log("valid aahar");
      setAadharNumberErr(false);
    } else {
      setAadharNumberErr(true);
    }
  };
  const changeAddress1 = (e) => {
    setAddress1(e.target.value);
    if (e.target.value != "") {
      setAddress1Err(false);
    } else {
      setAddress1Err(true);
    }
  };
  const changeAddress2 = (e) => {
    setAddress2(e.target.value);
    if (e.target.value != "") {
      setAddress2Err(false);
    } else {
      setAddress2Err(true);
    }
  };
  const changeCity = (e) => {
    setCity(e.target.value);
    if (e.target.value != "") {
      setCityErr(false);
    } else {
      setCityErr(true);
    }
  };
  const changeState = (e) => {
    setState(e.target.value);
    if (e.target.value != "") {
      setStateErr(false);
    } else {
      setStateErr(true);
    }
  };
  const changePincode = (e) => {
    setPincode(e.target.value);
    if (e.target.value != "") {
      setPincodeErr(false);
    } else {
      setPincodeErr(true);
    }
  };
  const changeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value != "" && validEmail.test(e.target.value)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  };
  const changeLandlineNumber = (e) => {
    setLandlineNumber(e.target.value);
    if (e.target.value != "") {
      setLandlineNumberErr(false);
    } else {
      setLandlineNumberErr(true);
    }
  };
  const changeMobileNo = (e) => {
    setMobileNo(e.target.value);
    if (e.target.value != "" && validMobilNo.test(e.target.value)) {
      setMobileNoErr(false);
    } else {
      setMobileNoErr(true);
    }
  };
  const changeTypeNameId = (e) => {
    setTypeNameId(e.target.value);
    setTypeNameDetails("");
    if (e.target.value != 0) {
      getTypeNameDetails(e.target.value, 2);
    }
  };
  const getNameList = (type_id) => {
    ApplicationService.getTypeNameList({ user_type: type_id })
      .then((result) => {
        setNameList(result.data.users);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  // useEffect(() => {
  //   ApplicationService.getTypeNameList({ user_type: 3 }).then(result => {
  //     console.log(result.data);
  //     setTypeNameList(result.data.users);
  //   }).catch(function (error) {
  //     ErrorHandler(error);
  //   });
  // }, []);
  const getTypeNameDetails = (id, type) => {
    // ApplicationService.getTypeNameDetails({ user_id: id }).then(result => {
    //   console.log(result.data);
    //   if (type == 1) {
    //     setNameDetails(result.data.user);
    //   } else {
    //     setTypeNameDetails(result.data.user);
    //   }
    // }).catch(function (error) {
    //   ErrorHandler(error);
    // })
  };
  const save = () => {
    // if (props.applicationID) {
    //   if (type != 0 && name != 0 && ownerNameErr == false && fatherNameErr == false && aadharNumberErr == false && address1Err == false && address2Err == false && cityErr == false && stateErr == false && pincodeErr == false && emailErr == false && landlineNumberErr == false && mobileNoErr == false && typeNameId != 0) {
    //     var payload = {
    //       "owner_name": ownerName,
    //       "father_name": fatherName,
    //       "aadhar_card_no": aadharNumber,
    //       "address1": address1,
    //       "address2": address2,
    //       "city": city,
    //       "state": state,
    //       "pincode": pincode,
    //       "email": email,
    //       "landline_no": landlineNumber,
    //       "mobile": mobileNo,
    //       "structural_enginner_id": typeNameId,
    //       "consultant_id": name,
    //       "user_application_id": props.applicationID
    //     };
    //     ApplicationService.userApplicantInfo(payload).then(result => {
    //       console.log(result.data);
    //       alert(result.data.message);
    //     }).catch(function (error) {
    //       ErrorHandler(error);
    //     });
    //   } else {
    //     alert("Please check all inputs are properly ?");
    //   }
    // } else {
    //   alert("please fill GeneralInformation !");
    // }
  };
  return (
    <Col className="prop-norm">
      <Accordion defaultActiveKey={type == 1 ? "1" : "0"}>
        {type != 1 && (
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">
                Architect/ Structural Engineer/ Surveyor Information
              </h5>
            </Accordion.Header>
            <Accordion.Body className="px-2 py-1">
              <Col lg={12} className="d-md-flex flex-wrap">
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">Consultant Type*</p>
                  </Col>
                  <Col>
                    <Form.Select
                      style={{ fontSize: "15px" }}
                      aria-label="Default select example"
                      className="form-control px-2 py-1 rounded-0"
                      value={type != 1 ? type : null}
                      // onChange={changeType}
                      disabled
                    >
                      <option className="f14 py-2" value="0">
                        Select
                      </option>
                      <option className="f14 py-2" value="2">
                        Architect
                      </option>
                      <option className="f14 py-2" value="3">
                        Structural Engineer
                      </option>
                    </Form.Select>
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">Name*</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails.first_name}
                      disabled
                    />
                    {/* <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={name}
                    onChange={changeName}
                  >
                    <option selected="selected" value="0">
                      Select
                    </option>
                    {nameList.length > 0 &&
                      nameList.map((item, index) => {
                        return (
                          <option key={index} className="f14 py-2" value={item.id}>
                            {item.first_name} - {item.username}
                          </option>
                        )
                      })
                    }
                  </Form.Select> */}
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">Address*</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.address}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">License Number*</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.license_number}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">Validity*</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.license_validity}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2
              align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">Mobile Number*</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.mobile}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">E-mail ID*</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.email}
                      disabled
                    />
                  </Col>
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {type == 1 && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <h5 className="f14 mb-0">Applicant's Information</h5>
            </Accordion.Header>
            <Accordion.Body className="px-2 py-1">
              <Col lg={12} className="d-md-flex flex-wrap">
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">First Name *</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails.first_name}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">Last Name *</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails.last_name}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">ID Proof Number *</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_file?.id_number}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1"> Address *</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.address}
                      disabled
                    />
                  </Col>
                </Col>
                {/* <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                <Col>
                  <p className="f13 mb-1">Address Line 2</p>
                </Col>
                <Col>
                  <input className="form-control px-2 py-1 rounded-0"
                    value={address2}
                    onChange={changeAddress2}
                  />
                </Col>
              </Col> */}
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">City *</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.city}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1"> State/Province/Region *</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.state}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">PinCode *</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.pin_code}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1"> Email *</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails.email}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1">Landline Number *</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails?.user_detail?.landline}
                      disabled
                    />
                  </Col>
                </Col>
                <Col
                  md={6}
                  className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
                >
                  <Col>
                    <p className="f13 mb-1"> Mobile Number *</p>
                  </Col>
                  <Col>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      value={userDetails.mobile}
                      disabled
                    />
                  </Col>
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        )}
        {/* <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5 className="f14 mb-0">Structural Engineer Information</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col lg={12} className="d-md-flex flex-wrap">
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                <Col>
                  <p className="f13 mb-1">Consultant Type*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={typeNameId}
                    onChange={changeTypeNameId}
                  >
                    <option value="0">Select</option>
                    {typeNameList.length > 0 &&
                      typeNameList.map((item, index) => {
                        return (
                          <option key={index} className="f14 py-2" value={item.id}>
                            {item.first_name} - {item.username}
                          </option>
                        )
                      })
                    }
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                <Col>
                  <p className="f13 mb-1">Address*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    value={typeNameDetails?.user_detail?.address}
                    disabled
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                <Col>
                  <p className="f13 mb-1">Validity*</p>
                </Col>
                <Col>
                  <input
                    type="date"
                    className="form-control px-2 py-1 rounded-0"
                    value={typeNameDetails?.user_detail?.license_validity}
                    disabled
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center">
                <Col>
                  <p className="f13 mb-1">License Number*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    value={typeNameDetails?.user_detail?.license_number}
                    disabled
                  />
                </Col>
              </Col>
            </Col>
          </Accordion.Body>
        </Accordion.Item> */}
      </Accordion>
      <Col className="d-flex justify-content-center mb-2">
        <Button
          onClick={save}
          className="tab-blu-back"
          style={{
            color: "#fff",
            fontSize: 15,
            marginTop: 10,
            padding: "6px 14px",
            backgroundColor: "#5593db",
          }}
        >
          <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col>
    </Col>
  );
};

export default ApplicantInformation;
