import React, { useState } from "react";
import {
  faFile,
  faFileAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Switch from "react-switch";
import {
  Accordion,
  Button,
  Col,
  Form,
  ToggleButton,
  ToggleButtonGroup,
  Modal,
  Row,
} from "react-bootstrap";
import ApplicationService from "../../services/service/ApplictionService";
import ErrorHandler from "../../services/constant/ErrorHandler";

const ApplyNOC = (props) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState('');
  const [togglebtnround, setTogglebtnround] = useState(false);
  const [textClickHandle, setTextClickHandle] = useState(false);
  const handleChange = () => {
    setTogglebtnround(!togglebtnround);
    setTextClickHandle(!textClickHandle);
  };
  const clickTaggleHandle = () => {
    setTextClickHandle(!textClickHandle);
  };

  const [modalOneshow, setModalOneShow] = useState(false);
  const [modalTwoshow, setModalTwoShow] = useState(false);

  const modalOneClose = () => setModalOneShow(false);
  const modalOneShow = () => setModalOneShow(true);

  const modalTwoClose = () => setModalTwoShow(false);
  const modalTwoShow = () => setModalTwoShow(true);

  const [fireFile, setFireFile] = useState("");
  const [fireFile2, setFireFile2] = useState("");
  const [fireFile3, setFireFile3] = useState("");
  const [fireFile4, setFireFile4] = useState("");
  const [files, setFiles] = useState([
    {
      type: 1,
      noc_file: "",
      preview: "",
    },
    {
      type: 2,
      noc_file: "",
      preview: "",
    },
    {
      type: 3,
      noc_file: "",
      preview: "",
    },
    {
      type: 4,
      noc_file: "",
      preview: "",
    },
    {
      type: 5,
      noc_file: "",
      preview: "",
    },
  ]);
  const changeFile = (e, type) => {
    if (e.target.files[0].size < 26214400) {
      let temp = [...files];
      const index = temp.findIndex((fruit) => fruit.type == type);
      temp[index].noc_file = e.target.files[0];
      temp[index].preview = URL.createObjectURL(e.target.files[0]);
      setFiles(temp);
      // setFireFile(e.target.files[0]);
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const handlePreview = (index) => {
    setModalData(files[index].preview);
    setModal(true);
  };
  const changeFile2 = (e) => {
    if (e.target.files[0].size < 26214400) {
      setFireFile2(e.target.files[0]);
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const changeFile3 = (e) => {
    if (e.target.files[0].size < 26214400) {
      setFireFile3(e.target.files[0]);
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const changeFile4 = (e) => {
    if (e.target.files[0].size < 26214400) {
      setFireFile4(e.target.files[0]);
    } else {
      alert("File size should be less than 25MB ");
    }
  };
  const save = () => {
    if (props.applicationID) {
      const index = files.findIndex((fruit) => fruit.type == 1);
      if (files[index].noc_file != "") {
        var form_data = new FormData();
        form_data.append("user_application_id", props.applicationID);
        files.map((item, index) => {
          if (item.noc_file != "") {
            form_data.append(`nocs[${index}][type]`, item.type);
            form_data.append(`nocs[${index}][noc_file]`, item.noc_file);
          }
        });
        // form_data.append('nocs[0][type]', 1);
        // form_data.append('nocs[0][noc_file]', fireFile);
        // form_data.append('nocs[1][type]', 2);
        // form_data.append('nocs[1][noc_file]', fireFile2);
        // form_data.append('nocs[2][type]', 3);
        // form_data.append('nocs[2][noc_file]', fireFile3);
        ApplicationService.saveApplyNOC(form_data)
          .then((result) => {
            alert(
              result.data.message
                ? result.data.message
                : "Application saved successfully"
            );
          })
          .catch(function (error) {
            ErrorHandler(error);
          });
      } else {
        alert("please fill all Details ?");
      }
    } else {
      alert("please fill GeneralInformation !");
    }
  };
  return (
    <>
      <Col className="prop-norm">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">Fire NOC </h5>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-1 pb-0">
              <Col className="d-flex w-100">
                <Col lg={6} className="d-flex align-items-center">
                  <Col className="px-2 py-1 bg-lt-white-f7 me-2 mt-2">
                    <label className="c1 fw-bold f14">Attachment:</label>
                  </Col>
                  {/* <Button
                    style={{ fontSize: "14px" }}
                    className="bg-transparent border-0 text-primary ms-auto me-0"
                    onClick={handleClick}
                  >
                    View
                  </Button> */}
                  <input
                    type="file"
                    onChange={(e) => changeFile(e, 1)}
                    accept=".pdf,.PDF"
                  />
                  {files[0].preview != "" &&
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handlePreview(0)}
                    >
                      Preview
                    </Button>
                  }

                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">Environmental clearance </h5>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-1 pb-0">
              <Col className="d-flex w-100">
                <Col lg={6} className="d-flex align-items-center">
                  <Col className="px-2 py-1 bg-lt-white-f7 me-2 mt-2">
                    <label className="c1 fw-bold f14">Attachment:</label>
                  </Col>
                  {/* <Button
                    style={{ fontSize: "14px" }}
                    className="bg-transparent border-0 text-primary ms-auto me-0"
                    onClick={handleClick}
                  >
                    View
                  </Button> */}
                  <input
                    type="file"
                    onChange={(e) => changeFile(e, 2)}
                    accept=".pdf,.PDF"
                  />
                  {files[1].preview != "" &&
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handlePreview(1)}
                    >
                      Preview
                    </Button>
                  }
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">Traffic NOC ( If Applicable ) </h5>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-1 pb-0">
              <Col className="d-flex w-100">
                <Col lg={6} className="d-flex align-items-center">
                  <Col className="px-2 py-1 bg-lt-white-f7 me-2 mt-2">
                    <label className="c1 fw-bold f14">Attachment:</label>
                  </Col>
                  {/* <Button
                    style={{ fontSize: "14px" }}
                    className="bg-transparent border-0 text-primary ms-auto me-0"
                    onClick={handleClick}
                  >
                    View
                  </Button> */}
                  <input
                    type="file"
                    onChange={(e) => changeFile(e, 3)}
                    accept=".pdf,.PDF"
                  />
                  {files[2].preview != "" &&
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handlePreview(2)}
                    >
                      Preview
                    </Button>
                  }
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">
                Airport Authority of India( If Applicable){" "}
              </h5>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-1 pb-0">
              <Col className="d-flex w-100">
                <Col lg={6} className="d-flex align-items-center">
                  <Col className="px-2 py-1 bg-lt-white-f7 me-2 mt-2">
                    <label className="c1 fw-bold f14">Attachment:</label>
                  </Col>
                  {/* <Button
                    style={{ fontSize: "14px" }}
                    className="bg-transparent border-0 text-primary ms-auto me-0"
                    onClick={handleClick}
                  >
                    View
                  </Button> */}
                  <input
                    type="file"
                    onChange={(e) => changeFile(e, 4)}
                    accept=".pdf,.PDF"
                  />
                  {files[3].preview != "" &&
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handlePreview(3)}
                    >
                      Preview
                    </Button>
                  }
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h5 className="f14 mb-0">
                High Rise Document (If Applicable){" "}
              </h5>
            </Accordion.Header>
            <Accordion.Body className="px-0 pt-1 pb-0">
              <Col className="d-flex w-100">
                <Col lg={6} className="d-flex align-items-center">
                  <Col className="px-2 py-1 bg-lt-white-f7 me-2 mt-2">
                    <label className="c1 fw-bold f14">Attachment:</label>
                  </Col>
                  {/* <Button
                    style={{ fontSize: "14px" }}
                    className="bg-transparent border-0 text-primary ms-auto me-0"
                    onClick={handleClick}
                  >
                    View
                  </Button> */}
                  <input
                    type="file"
                    onChange={(e) => changeFile(e, 5)}
                    accept=".pdf,.PDF"
                  />
                  {files[4].preview != "" &&
                    <Button
                      style={{ fontSize: "14px" }}
                      className="bg-transparent border-0 text-primary ms-auto me-0"
                      onClick={() => handlePreview(4)}
                    >
                      Preview
                    </Button>
                  }
                </Col>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* <Col>
          <p className="f14 mb-0 text-danger bg-lt-white p-2 mt-2">
            Yes - I do not have Applied NOC and want to obtain NOC from
            respective office (Online procedure).
          </p>
          <p className="f14 mb-0 text-danger bg-lt-white p-2 mt-2">
            No - I have Applied NOC.
          </p>
        </Col> */}
        <Col className="d-flex justify-content-center mb-2">
          <Button
            onClick={save}
            className="tab-blu-back"
            style={{
              color: "#fff",
              fontSize: 15,
              marginTop: 10,
              padding: "6px 14px",
              backgroundColor: "#5593db",
            }}
          >
            <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
            Save
          </Button>
        </Col>
      </Col>

      <Modal show={modalOneshow} onHide={modalOneClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="justify-content-center">
            <div className="f14 text-center">
              <strong>Fire NOC Condition</strong>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="f14 text-start">
            <strong>Note :</strong>
          </div>
          <div className="f12 text-start">
            1 : All Types of Residential Buildings with height more than 18m. or
            Non Residential Buildings with height more than 15m.
          </div>
          <br />
          <div className="f12 text-start">
            2 : Public Congregation Places like Schools, Auditoriums , Function
            Halls. Height between 6m. to 15m. and area more than 500sq.m.
          </div>
          <div className="f14 text-start">
            <strong>For this application :</strong>
            Type of Building : Residential Building, Height : 30.5m.
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-secondary" onClick={modalOneClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalTwoshow} onHide={modalTwoClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="justify-content-center">
            <div className="f14 text-center">
              <strong>AAI NOC Condition</strong>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="f14 text-start">
            <strong>Note :</strong>
            Whether proposed site falls whith in the Air funnel Zone? If
            selected Yes, the proposal requires NOC from AAI Department.
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="outline-secondary" onClick={modalTwoClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {modal === true ?
        <>
          <Modal
            show={modal}
            onHide={() => { setModalData(''); setModal(false); }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16" >File</Modal.Title>
            </Modal.Header>
            <Modal.Body >
              <Row>
                <iframe src={modalData} style={{ height: '500px' }} ></iframe>
              </Row>
            </Modal.Body>
          </Modal>

        </> : null
      }
    </>
  );
};

export default ApplyNOC;
