import React from "react";
import { Link } from "react-router-dom";
import { DashboardLogo } from "../assets/images/Index";
import Header from "../component/Header";
import HeaderCopy from "../component/HeaderCopy";

const SelectTab = () => {
  const navigator = (type) => {
    if (type == "occupancy") {
      localStorage.setItem("selectFor", 2);
      window.location.href = "/";
    } else {
      localStorage.setItem("selectFor", 1);
      window.location.href = "/";
    }
  };
  return (
    <>
      <HeaderCopy />
      <div className="alert-popup">
        <div className="customPopupDesign">
          <h6 className="text-center">
            <DashboardLogo />
          </h6>
          <h6 className="text-center">{"Select Apply For"}</h6>
          <div className="d-flex justify-content-center pt-2">
            <button
              className="bg-transparent border-0"
              onClick={() => navigator("building")}
            >
              <Link
                //   to="/application-permission"
                className="bg3 white f13 px-3 py-3 me-2 text-nowrap rounded"
              >
                Apply For Building Permission
              </Link>
            </button>
            <button
              className="bg-transparent border-0"
              onClick={() => navigator("occupancy")}
            >
              <Link
                //   to="/application-occupancy"
                className="bg3 white f13 px-3 py-3 text-nowrap rounded"
              >
                {/* Apply For Occupancy */}
                Apply for Occupancy Certificate
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectTab;
