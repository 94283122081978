import jsPDF from "jspdf";
import "jspdf-autotable";
import { logoImage } from "../../assets/images/Index.js";
//import DateFormat from "../utils/dateFormat";
export default class ReportOneg {
  constructor(app) {
    this.app = app;
    this.pageWidth = 210;
    this.pageHeight = 297;
    this.doc = null;
    this.bodyFontSize = 8;
    this.headerFontSize = 9;
    this.firstTableInPage = 35;
    this.tableStart = 7;
    this.bottom = 10;
    this.top = 3;
    this.horizontal = 5;
    this.lineWidth = 0.25;
    this.lineColor = [128, 128, 128];
    this.textColor = "black";
    this.nonComplaintColor = "red";
    this.halign = "center";
    this.valign = "middle";
    this.bodyfillColor = "white";
    this.tableHeaderColor = "#dea4df";
    this.theme = "grid";
    this.rowPageBreak = "avoid";
    this.tableNameFontSize = 10;
    this.headerStyles = {
      centerAndMiddle: {
        valign: "middle",
        fontSize: this.headerFontSize,
        halign: "center",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
      onlyCenter: {
        fontSize: this.headerFontSize,
        halign: "center",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
      onlyMiddle: {
        fontSize: this.headerFontSize,
        valign: "middle",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
    };
    this.bodyStyles = {
      centerAndMiddle: {
        fontSize: this.bodyFontSize,
        lineColor: [128, 128, 128],
        overflow: "linebreak",
        lineWidth: 0.25,
        valign: "middle",
        halign: "center",
      },
      onlyCenter: {
        fontSize: this.bodyFontSize,
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        overflow: "linebreak",
        halign: "center",
      },
      onlyMiddle: {
        fontSize: this.bodyFontSize,
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        valign: "middle",
        overflow: "linebreak",
      },
      noAlign: {
        fontSize: this.bodyFontSize,
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        overflow: "linebreak",
      },
    };
    this.margin = {
      horizontal: this.horizontal,
      top: this.firstTableInPage + this.tableStart,
      bottom: this.bottom,
    };
  }

  checkRelativeSpanLength = (start, currSpan) => {
    return start + this.tableStart + 7 * currSpan <= this.pageHeight - 45;
  };

  mergeCellsUtil = (start, data, paramIndex) => {
    let map = {},
      mapPage = {},
      setBody = [],
      curTotalRowSpan = 0;
    let tempStart = start,
      idxFind = 0;
    for (let i = 0; i < data?.length; i++) {
      if (map[data[i][paramIndex]]) {
        if (this.checkRelativeSpanLength(tempStart, curTotalRowSpan + 1)) {
          setBody[idxFind][paramIndex] = {
            ...setBody[idxFind][paramIndex],
            rowSpan: setBody[idxFind][paramIndex].rowSpan + 1,
          };
          //  setBody[idxFind][paramIndex] = {...setBody[idxFind][paramIndex],rowSpan:1}
          setBody.push([...data[i].filter((ele, idx) => idx != paramIndex)]);
          curTotalRowSpan += 1;
        } else {
          tempStart = this.firstTableInPage;
          curTotalRowSpan = 1;
          setBody.push([
            {
              content: data[i][paramIndex],
              rowSpan: 1,
              check: 2,
              styles: { halign: "center" },
            },
            ...data[i].filter((ele, idx) => idx != paramIndex),
          ]);
          idxFind = setBody.length - 1;
        }
      } else {
        map[data[i][paramIndex]] = true;
        curTotalRowSpan = curTotalRowSpan + 1;

        setBody.push([
          {
            content: data[i][paramIndex],
            rowSpan: 1,
            check: 3,
            styles: { halign: "center" },
          },
          ...data[i].filter((ele, idx) => idx != paramIndex),
        ]);
        idxFind = setBody.length - 1;
      }
    }

    return setBody;
  };
  getParkingAreaUtil = () => {
    let totalArea = 0;
    this.app.report.VisitorsParkingBasement &&
      this.app.report.VisitorsParkingBasement.map((i, index) => {
        totalArea = Number(Number(totalArea) + Number(i.parkingArea)).toFixed(
          2
        );
      });

    totalArea = Number(totalArea).toFixed(2);

    return totalArea;
  };
  isResidential = (val) => {
    return ["residential", "group Housing", "grouphousing"].includes(
      String(val).toLowerCase()
    );
  };
  notProviedTable(start, message, tableHeader) {
    let pg = 0,
      end = start;
    this.doc.autoTable({
      body: [[message]],
      theme: "grid",
      rowPageBreak: this.rowPageBreak,
      startY: start + this.tableStart,
      margin: this.margin,
      bodyStyles: this.bodyStyles.centerAndMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(tableHeader, this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    return end;
  }
  pageHeader() {
    this.doc.setFontSize(15);
    this.doc.text(85, 20, "Scrutiny Report");
    this.doc.addImage(logoImage, "JPEG", this.horizontal, this.top, 25, 28);
  }
  checkNOandNA = (val) => {
    return val === "NO" || val === "NA" ? true : false;
  };
  firstFiveTableHeader(name, start) {
    this.doc.autoTable({
      head: [[name]],
      theme: this.theme,
      startY: start,
      margin: { horizontal: this.horizontal },
      headStyles: {
        ...this.headerStyles.onlyCenter,
        fillColor: this.tableHeaderColor,
      },
    });
  }
  tableHeader(name, start) {
    this.doc.autoTable({
      head: [[name]],
      theme: "grid",
      startY: start,
      margin: { horizontal: this.horizontal },
      headStyles: {
        ...this.headerStyles.onlyCenter,
        fillColor: this.tableHeaderColor,
      },
    });
  }
  footer() {
    let pages = this.doc.internal.getNumberOfPages();
    let pageWidth = this.doc.internal.pageSize.width; //Optional
    let pageHeight = this.doc.internal.pageSize.height; //Optional
    this.doc.setFontSize(8); //Optional

    for (let j = 1; j < pages + 1; j++) {
      let horizontalPos = pageWidth - 15; //Can be fixed number
      let verticalPos = pageHeight - 5; //Can be fixed number
      this.doc.setPage(j);
      this.doc.text(`page ${j} of ${pages}`, horizontalPos, verticalPos, {
        align: "center",
      });
      this.doc.text(
        "Note: All Linear measurement values are in Metres and Area values in Square Metres",
        5,
        verticalPos - 5
      );
      this.doc.text(
        "Abbreviation : L = Length, W = Width, A = Area",
        5,
        verticalPos
      );
    }
  }
  checkSpace(start) {
    if (start > this.pageHeight - 45) {
      this.doc.addPage();
      this.pageHeader();
      start = this.firstTableInPage;
    }
    return start;
  }
  getCellWidth(body) {
    return (this.pageWidth - 2 * this.horizontal) / body.length;
  }
  getColumnStyles(body) {
    let columnStyles = {};
    for (let i = 0; i < body.length; i++) {
      columnStyles = {
        ...columnStyles,
        i: {
          cellWidth: this.getCellWidth(body),
        },
      };
    }
    return columnStyles;
  }
  ProposalTable(start, sno) {
    let end = 0,
      pg = 0;
    const setBody = [
      ["Date of receipt of application", this.app.appliedOn],
      ["Application Number", this.app.applicationId],
    ];
    this.doc.autoTable({
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: this.rowPageBreak,
      theme: this.theme,
      startY: start + this.tableStart,
      margin: {
        horizontal: this.horizontal,
        top: this.firstTableInPage + this.tableStart,
        bottom: this.bottom,
      },
      headStyles: {
        valign: this.valign,
        fontSize: this.headerFontSize,
        halign: this.halign,
        fillColor: this.fillColor,
        textColor: this.textColor,
        lineWidth: this.lineWidth,
        lineColor: this.lineColor,
      },
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: {
        fontSize: this.bodyFontSize,
        // halign: "center",
        lineColor: this.lineColor,
        lineWidth: this.lineWidth,
        valign: this.valign,
      },
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(sno + ". Proposal Details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  }
  FirstAppApplicantTable(start, sno) {
    let end = 0,
      pg = 0;
    const setBody = [
      [
        "Applicant Name",
        this.app.owner.firstName + " " + this.app.owner.lastName,
      ],
      [
        "Address",
        this.app.owner.addressLine1 +
          "," +
          " " +
          this.app.owner.addressLine2 +
          "," +
          " " +
          this.app.owner.district +
          "," +
          " " +
          "Pincode -" +
          " " +
          this.app.owner.pinCode,
      ],
      ["Mobile number", this.app.owner.mobile.toString()],
      ["e-mail address", this.app.owner.email],
      ["PAN number", this.app.owner.pan],
    ];
    this.doc.autoTable({
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + ". Applicant Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  }
  FirstAppArchitectTable(start, sno) {
    let end = 0,
      pg = 0;
    const setBody = [
      ["Architect Name", this.app.architect.name],
      [
        "Mobile number",
        this.app.architect.mobile ? this.app.architect.mobile : "8795634123",
      ],
      ["e-mail address", this.app.architect.email],
    ];
    this.doc.autoTable({
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + ". Architect Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  }
  FirstAppSiteRequirementTable(start, sno) {
    let end = 0,
      pg = 0,
      loacation = "",
      AmalgamationPlot = "",
      solar = "",
      septic = "";
    if (this.app.report.LocationPlan.length > 0) {
      loacation = this.app.report.LocationPlan[0].Value;
    } else {
      loacation = "NO";
    }
    /*  if (this.app.report.AmalgamationPlot.length > 0) {
            AmalgamationPlot = this.app.report.AmalgamationPlot[0].Value
        }
        else {
            AmalgamationPlot = "NO"
        }*/

    AmalgamationPlot = "NO";

    if ("SolarWaterHeaterTotalArea" in this.app.report) {
      solar = this.app.report.SolarWaterHeaterTotalArea;
    } else {
      solar = "No";
    }

    if ("SepticTank" in this.app.report) {
      septic = this.app.report.SepticTank[0].Value;
    } else {
      septic = "No";
    }

    const setBody = [
      ["Location Plan", loacation],
      ["Solar Water Heater", solar],
      ["Septic Tank", septic],
    ];
    this.doc.autoTable({
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + " Site Requirement Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;

        end = data.cursor.y;
      },
    });
    return end;
  }
  FirstAppSiteTable(start, sno) {
    let end = 0,
      pg = 0;
    const setBody = [
      ["Sector Number", this.app.surveyNumber],
      ["Plot Number", this.app.surveyNumber],
      ["Taluka Name", this.app.talukaName],
      ["Village Name", this.app.villageName],
      [
        "Site Address",
        this.app.addressLine1 +
          "," +
          " " +
          this.app.addressLine2 +
          "," +
          " " +
          this.app.district +
          "," +
          " " +
          "Pincode -" +
          " " +
          this.app.pinCode,
      ],
      ["Building use category", this.app.buildingType],
      ["Building use Sub-category", this.app.buildingType],
      [
        "Number of Structures/Buildings",
        this.app.noOfBuildings ? this.app.noOfBuildings : "1",
      ],
    ];

    this.doc.autoTable({
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + ". Site Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  }
  FirstAppexternalMeansTable = (start, sno) => {
    let end = 0,
      pg = 0;
    const setBody = [
      ["Road type", this.app.existingRoadType],
      ["Proposed Road type", this.app.proposedRoadType],
    ];
    this.doc.autoTable({
      head: [["Parameter", "Provided"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          // if (data.column.dataKey === 0) {
          //     data.cell.styles.halign = "center"
          // }
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyFontSize.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + ". External Means of Access",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  FirstAppexternalMeansTable1 = (start, sno) => {
    let end = 0,
      pg = 0;
    var setBody = [];
    this.app.report.ExistingRoad.map((i, index) => {
      if (this.app.report.ExistingRoad.length === 1) {
        setBody.push(["Existing Road Width", i.min, i.Width, i.result]);
      } else {
        setBody.push([
          "Existing Road Width " + (index + 1),
          i.min,
          i.Width,
          i.result,
        ]);
      }
    });
    setBody.push([
      "Proposed Road Width",
      "N.A",
      this.app.report.ProposedRoad[0].Width === null
        ? "N.A"
        : this.app.report.ProposedRoad[0].Width,
      "N.A",
    ]);
    setBody.push([
      "Number of Roads abutting plot",
      "1",
      this.app.report.ExistingRoad.length,
      this.app.report.ExistingRoad.length >= 1 ? "Compliant" : "Non-Compliant",
    ]);
    this.doc.autoTable({
      head: [["Parameters", "Minimum", "Provided", "Result"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            // console.log("hiii", data.cell.styles, data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 4; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              //data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles,
      columnStyles: {
        0: { cellWidth: 60 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + "External Means of Access",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  ApplicantTable(start, sno) {
    let end = 0,
      pg = 0;
    const setBody = [
      ["Applicant Name", this.app.owner.firstName],
      ["Address", this.app.owner.address],
      ["Mobile number", this.app.mobile.toString()],
      ["e-mail address", this.app.owner.email],
    ];
    this.doc.autoTable({
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + ". Applicant Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  }
  ArchitectTable(start, sno) {
    let end = 0,
      pg = 0;
    const setBody = [
      ["Architect Name", this.app.architect.name],
      [
        "Mobile number",
        this.app.architect.mobile ? this.app.architect.mobile : "8795634123",
      ],
      ["e-mail address", this.app.architect.email],
    ];
    this.doc.autoTable({
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + ". Architect Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  }
  SiteRequirementTable(start, sno) {
    let end = 0,
      pg = 0,
      loacation = "",
      AmalgamationPlot = "";
    if (this.app.report.LocationPlan.length > 0) {
      loacation = this.app.report.LocationPlan[0].Value;
    } else {
      loacation = "NO";
    }
    /*  if (this.app.report.AmalgamationPlot.length > 0) {
            AmalgamationPlot = this.app.report.AmalgamationPlot[0].Value
        }
        else {
            AmalgamationPlot = "NO"
        }*/

    AmalgamationPlot = "NO";
    const setBody = [["Location Plan", loacation]];
    this.doc.autoTable({
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + " Site Requirement Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;

        end = data.cursor.y;
      },
    });
    return end;
  }
  SiteTable(start, sno) {
    let end = 0,
      pg = 0;
    const setBody = [
      ["Sector Number", this.app.surveyNo],
      ["Plot Number", this.app.doorNo],
      ["Mandal Name", this.app.locality],
      ["Village Name", this.app.village],
      ["Site Address", this.app.siteaddress],
      ["Building use category", this.app.useOfTheBuildings],
      ["Building use Sub-category", this.app.subuseOfTheBuildings],
      [
        "Number of Structures/Buildings",
        this.app.cellar ? this.app.noOfBuildings : "1",
      ],
    ];

    this.doc.autoTable({
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + ". Site Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  }
  externalMeansTable = (start, sno) => {
    let end = 0,
      pg = 0;
    const setBody = [
      ["Road type", this.app.existingRoadType],
      ["Proposed Road type", this.app.existingRoadType],
    ];
    this.doc.autoTable({
      head: [["Parameter", "Provided"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          // if (data.column.dataKey === 0) {
          //     data.cell.styles.halign = "center"
          // }
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + ". External Means of Access",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  getProjectionTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 1;
    var setBody = this.app.report.ChajjaProjection.map((i) => {
      let temp = [sno + "." + subsno, i.BuildingName, [i.Width, i.result]];
      subsno = subsno + 1;
      return temp;
    });
    this.doc.autoTable({
      head: [["S.NO", "Building name", "Chajja Projection"]],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 2 && data.cell.section === "body") {
          this.doc.autoTable({
            head: [["Maximum", "Provided", "Result"]],
            body: [["0.75", data.cell.raw[0], data.cell.raw[1]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            styles: {
              fontSize: data.table.body[0].fontSize,
              cellPadding: 1,
              halign: "center",
              valign: "middle",
            },
            columnStyles: {
              0: { cellWidth: 30 },
              1: { cellWidth: 30 },
              2: { cellWidth: 30 },
            },
            theme: "grid",
            headStyles: {
              valign: "middle",
              fontSize: data.table.body[0].fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
            bodyStyles: {
              minCellHeight: data.table.body[0].height - 6,
              fontSize: data.table.body[0].fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body" && data.cell.raw !== "") {
                if (data.cell.raw === "Non-Compliant") {
                  // console.log("hiii",data.row.cells)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 2; i++) {
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                  }
                } else {
                  //console.log("bye",data.cell.raw[1])
                  data.cell.styles.textColor = "black";
                  // data.cell.styles.lineColor = [128,128,128];
                  // data.cell.styles.lineWidth = 0.25
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 1) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw[1] === "Non-Compliant") {
            // console.log("oppo",data.cell.raw)
            data.row.cells[0].styles.textColor = "red";
            data.row.cells[1].styles.textColor = "red";
            // data.row.cells[0].styles.lineColor = "red";
            // data.row.cells[0].styles.lineWidth = 0.75
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 20 },
        2: { cellWidth: 90 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log(this.doc,"projection")
        if (pg > 0) {
          this.tableHeader(sno + ". Projection Details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  externalMeansTable1 = (start, sno) => {
    let end = 0,
      pg = 0;
    var setBody = [];
    this.app.report.ExistingRoad.map((i, index) => {
      if (this.app.report.ExistingRoad.length === 1) {
        setBody.push(["Existing Road Width", i.min, i.Width, i.result]);
      } else {
        setBody.push([
          "Existing Road Width " + (index + 1),
          i.min,
          i.Width,
          i.result,
        ]);
      }
    });
    if (this.app.report.ProposedRoad.length > 1) {
      setBody.push([
        "Proposed Road Width",
        "N.A",
        this.app.report.ProposedRoad[0].Width === null
          ? "N.A"
          : this.app.report.ProposedRoad[0].Width,
        "N.A",
      ]);
    }

    setBody.push([
      "Number of Roads abutting plot",
      "1",
      this.app.report.ExistingRoad.length,
      this.app.report.ExistingRoad.length >= 1 ? "Compliant" : "Non-Compliant",
    ]);
    this.doc.autoTable({
      head: [["Parameters", "Minimum", "Provided", "Result"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            // console.log("hiii", data.cell.styles, data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 4; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              //data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.firstFiveTableHeader(
            sno + "External Means of Access",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  AreaStatementTableTSIIC = (start, sno) => {
    let end = 0,
      pg = 0;
    var setBody = [];

    let existingBuildingArea = 0,
      accessoryBuilding = 0;
    let parkingArea = this.getParkingAreaUtil();

    if (
      "ExistingBuilding" in this.app.report &&
      this.app.report.ExistingBuilding.length > 0
    ) {
      this.app.report.ExistingBuilding &&
        this.app.report.ExistingBuilding.map((i) => {
          i.Area && (existingBuildingArea += i.Area);
        });
    }

    existingBuildingArea = Number(Number(existingBuildingArea).toFixed(2));

    if (
      "AccessoryBuilding" in this.app.report &&
      this.app.report.AccessoryBuilding.length > 0
    ) {
      this.app.report.AccessoryBuilding &&
        this.app.report.AccessoryBuilding.map((i) => {
          i.Area && (accessoryBuilding += i.Area);
        });
    }

    accessoryBuilding = Number(Number(accessoryBuilding).toFixed(2));

    let plotCoveragePercentage = 0;
    if (existingBuildingArea > 0) {
      plotCoveragePercentage = Number(
        Number(
          100 *
            (existingBuildingArea /
              this.app.report.AreaStatement[0].GrossPlotArea)
        ).toFixed(2)
      );
    }
    setBody.push([
      1,
      "Gross Plot Area",
      this.app.report.AreaStatement[0].GrossPlotArea,
    ]);
    //setBody.push([null])
    setBody.push([2, "Deduction", " "]);
    setBody.push([
      "a",
      "Road Widening",
      this.app.report.AreaStatement[0].RoadWidening,
    ]);

    setBody.push(["b", "Nala Buffer", this.app.report.AreaStatement[0].Nala]);

    setBody.push([
      "c",
      "Total Deduction (2a)",
      this.app.report.AreaStatement[0].TotalDeduction,
    ]);
    //setBody.push([null])
    setBody.push([
      3,
      "Net Plot Area (1-2b)",
      this.app.report.AreaStatement[0].NetPlotArea,
    ]);
    //setBody.push([null])
    setBody.push([
      4,
      "Recreational Open space",
      this.app.report.AreaStatement[0].ROSArea,
    ]);
    setBody.push([
      5,
      "Vacant Plot Area ",
      this.app.report.AreaStatement[0].VacantPlotArea,
    ]);
    //setBody.push([null])
    setBody.push([6, "Building Coverage Area", existingBuildingArea]);
    //setBody.push([null])
    setBody.push([
      7,
      "Building Coverage Area Percentage (6/1)",
      `${plotCoveragePercentage} %`,
    ]);
    setBody.push([8, "Parking ", this.app.report.AreaStatement[0].Parking]);
    setBody.push([
      9,
      "Proposed built up area",
      this.app.report.AreaStatement[0].ProposedBuiltUpArea,
    ]);
    //setBody.push([null])
    // setBody.push([9, "Permissible Premium Built up Area (6 x 0.20)", this.app.report.AreaStatement[0].PermissiblePremiumBuiltupArea])
    // setBody.push(["a", "Premium FSI   0.20", "-"])
    // setBody.push([10, "Proposed Premium Built up Area", this.app.report.AreaStatement[0].ProposedPremiumBuiltupArea])
    // //setBody.push([null])
    // setBody.push([11, "Maximum Permissible built up area (7 + 9)", this.app.report.AreaStatement[0].MaximumPermissibleBuiltupArea])
    // setBody.push([12, "Proposed built up area (8+10)", this.app.report.AreaStatement[0].ProposedBuiltUpArea])
    //setBody.push([null])
    //setBody.push([13, "Consumed FSI (12 / 6)",])
    setBody.push([10, "Deductions in Built up Areas", " "]);
    //setBody.push(["a", "Excess Balcony", this.app.report.AreaStatement[0].Balcony])
    // setBody.push(["a", "Service Areas/ Accessory Uses", accessoryBuilding])
    //setBody.push(["b", "Staircase", this.app.report.AreaStatement[0].Staircase])
    //setBody.push(["c", "Fire Staircase", this.app.report.AreaStatement[0].FireStairecase])
    //  setBody.push(["d", "Corridor", "-"])
    // setBody.push(["a", "Service Areas/ Accessory Buildings", accessoryBuilding])
    setBody.push([
      "a",
      "Electrical Sub Station",
      this.app.report.AreaStatement[0].ElectricalSubStation,
    ]);

    // setBody.push(["g", "Garage", "-"])
    //setBody.push(["c", "Lift", this.app.report.AreaStatement[0].Lift])
    //setBody.push(["f", "Fire Lift", this.app.report.AreaStatement[0].FireLift])
    setBody.push(["b", "Duct", this.app.report.AreaStatement[0].Duct]);
    let Total_Non_FSI_BuitupArea =
      this.app.report.AreaStatement[0].Duct +
      this.app.report.AreaStatement[0].ElectricalSubStation;

    Total_Non_FSI_BuitupArea = Number(
      Number(Total_Non_FSI_BuitupArea).toFixed(2)
    );
    setBody.push([
      "c",
      "Total of Deductions in Built up Areas",
      Total_Non_FSI_BuitupArea,
    ]);
    // setBody.push([null])
    // let totalexcess = this.app.report.AreaStatementExcess.Balcony + this.app.report.AreaStatementExcess.DoubleTerrace + this.app.report.AreaStatementExcess.Refugee
    // totalexcess = Number(Number(totalexcess).toFixed(2))
    let totatbuiltup =
      this.app.report.AreaStatement[0].TotalProposedBuiltupArea;
    totatbuiltup = Number(Number(totatbuiltup).toFixed(2));

    //setBody.push([14, "Excess Area", "-"])
    //setBody.push(["a", "Excess Balcony", Number(Number(this.app.report.AreaStatementExcess.Balcony).toFixed(2))])
    //setBody.push(["b", "Excess Double Height Terrace", Number(Number(this.app.report.AreaStatementExcess.DoubleTerrace).toFixed(2))])
    //setBody.push(["c", "Excess Refuge Area", Number(Number(this.app.report.AreaStatementExcess.Refugee).toFixed(2))])
    //setBody.push(["d", "Total Excess Area", totalexcess])
    //setBody.push([15, "Total Proposed built up area", totatbuiltup])
    setBody.push([
      11,
      "Total Proposed built up area",
      this.app.report.AreaStatement[0].TotalProposedBuiltupArea,
    ]);

    this.doc.autoTable({
      head: [["S.NO", "Description", "Area in sq.m."]],
      body: setBody,
      didParseCell: function (data) {
        if (data.column.dataKey === 2 || data.column.dataKey === 0) {
          data.cell.styles.halign = "center";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (
            data.column.dataKey === 0 &&
            data.cell.raw !== 4 &&
            data.cell.raw !== 5 &&
            typeof data.cell.raw === "number"
          ) {
            data.row.cells[1].styles.fontStyle = "bold";
          }

          if (data.cell.raw === "Total Deduction") {
            data.cell.styles.fontStyle = "bold";
          }

          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 150 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(sno + ". Area Statement ", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  plotExternalelementsTable = (start, sno) => {
    var setBody = [],
      end = 0,
      pg = 0,
      subsno = 0;

    this.app.report.EntranceWidth.map((i, index) => {
      if (this.app.report.EntranceWidth.length === 1) {
        subsno = subsno + 1;
        setBody.push(["Entrance Gate Width", i.min, i.Value, i.result]);
      } else {
        subsno = subsno + 1;
        setBody.push([
          "Entrance Gate - " + (index + 1).toString() + " Width",
          i.min,
          i.Value,
          i.result,
        ]);
      }
    });

    if (
      "EntranceHeight" in this.app.report &&
      this.app.report.EntranceHeight.length > 0
    ) {
      this.app.report.EntranceHeight.map((i, index) => {
        if (this.app.report.EntranceHeight.length === 1) {
          subsno = subsno + 1;
          setBody.push(["Entrance Gate Height", i.min, i.Value, i.result]);
        } else {
          subsno = subsno + 1;
          setBody.push([
            "Entrance Gate - " + (index + 1).toString() + " Height",
            i.min,
            i.Value,
            i.result,
          ]);
        }
      });
    }

    this.app.report.CompoundWallLength.map((i, index) => {
      if (this.app.report.CompoundWallLength.length === 1) {
        subsno = subsno + 1;
        setBody.push(["Compound wall Length", "N.A", i.CompoundLength, "N.A"]);
      } else {
        subsno = subsno + 1;
        setBody.push([
          "Boundary wall - " + (index + 1).toString() + "Length",
          "N.A",
          i.CompoundLength,
          "N.A",
        ]);
      }
    });

    /*  this.app.report.CompoundWallSolidPortionHeight.map((i, index) => {
            if (this.app.report.CompoundWallSolidPortionHeight.length === 1) {
                subsno = subsno + 1
                setBody.push(["Boundary wall Height", "N.A", i.max, i.Value, i.result])
            }
            else {
                subsno = subsno + 1
                setBody.push(["Boundary wall - " + (index + 1).toString() + " Height", "N.A", i.max, i.Value, i.result])
            }
        })
        this.app.report.CompoundWallRailingHeight.map((i, index) => {
            if (this.app.report.CompoundWallRailingHeight.length === 1) {
                subsno = subsno + 1
                setBody.push(["Boundary wall Railing Height", "N.A", i.max, i.Value, i.result])
            }
            else {
                subsno = subsno + 1
                setBody.push(["Boundary wall - " + (index + 1).toString() + "Railing Height", "N.A", i.max, i.Value, i.result])
            }
        })*/

    /*  if ('HighTensionLine' in this.app.report) {
             
            this.app.report.HighTensionLine.map((i, index) => {
                 subsno = subsno + 1
                 setBody.push(["High Tension Line",i.min, i.provided, i.result])
             })
 
         }
 
         if ('LowTensionLine' in this.app.report) {
              
             this.app.report.LowTensionLine.map((i, index) => {
                  subsno = subsno + 1
                  setBody.push(["Low Tension Line ",i.min, i.provided, i.result])
              })
  
          }*/

    if ("BufferZone" in this.app.report) {
      subsno = subsno + 1;
      setBody.push([
        "Buffer Zone Width",
        this.app.report.BufferZone.min,
        this.app.report.BufferZone.provided,
        this.app.report.BufferZone.result,
      ]);
    }

    /*  if ('Nala' in this.app.report) {
              
                  subsno = subsno + 1
                  setBody.push(["Nala Width","N.A", this.app.report.Nala.Width,"N.A"])
                  setBody.push(["Nala Area","N.A", this.app.report.Nala.Area,"N.A"])
                  setBody.push(["Nala Distance","N.A", this.app.report.Nala.Distance,"N.A"])
  
          }*/

    if (setBody.length > 0)
      this.doc.autoTable({
        head: [["Parameters", "Minimum", "Provided", "Result"]],
        body: setBody,
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey != 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 5; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else data.cell.styles.textColor = "black";
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: this.headerStyles.centerAndMiddle,
        columnStyles: {
          // 0: { cellWidth: 20 },
          0: { cellWidth: 100 },
        },
        bodyStyles: this.bodyStyles.onlyMiddle,
        didDrawPage: (data) => {
          this.pageHeader();
          //console.log("secondary",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + ". Plot External Elements",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    else
      end = this.notProviedTable(
        start,
        "Entrance gate and Compound details not Provided",
        sno + ". Plot External Elements"
      );

    return end;
  };

  plotExternalelementsSubTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];

    this.app.report.HighTensionLine.map((i, index) => {
      setBody.push([
        i.BuildingName,
        "High Tension Line Distance",
        [i.min, i.provided, i.result],
      ]);
    });

    this.app.report.LowTensionLine.map((i, index) => {
      setBody.push([
        i.BuildingName,
        "Low Tension Line Distance",
        [i.min, i.provided, i.result],
      ]);
    });

    this.doc.autoTable({
      head: [["Building Name", "Parameter", "Length"]],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 2 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum Area", "Provided", "Result"]],
            startY: data.cell.y + 9,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 40,
              fontSize: data.table.head[0].cells[0].styles.fontSize,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              //  minCellHeight:10,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 2 && data.cell.section === "body") {
          //console.log("po",data.table.body!

          data.doc.autoTable({
            body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 40 },
              1: { cellWidth: 40 },
              2: { cellWidth: 40 },
            },
            theme: "grid",
            bodyStyles: {
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body") {
                if (
                  data.column.dataKey !== 1 &&
                  data.column.dataKey !== 2 &&
                  data.column.dataKey !== 0
                ) {
                  //console.log("jgjgjg",data.cell.styles)
                  data.cell.styles.halign = "center";
                }
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "head" && data.column.dataKey !== 3) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.halign = "center";

          if (
            data.cell.raw &&
            data.cell.raw.result &&
            data.cell.raw.result === "Non-Compliant"
          ) {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 2; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 120 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + " Plot external sub Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  //new table
  BuildingDetailelementsTable = (start, sno) => {
    var setBody = [],
      end = 0,
      pg = 0,
      subsno = 0;
    subsno = subsno + 1;
    setBody.push([
      this.app.report.existing_building_details.building_type,
      this.app.report.existing_building_details.existing_building_name,
      this.app.report.existing_building_details.existing_built_up_area,
    ]);

    this.doc.autoTable({
      head: [["Building Type", "Building Name", "Builtup Area"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 5; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 100 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Existing Building Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    if ("building_total_area" in this.app) {
      if (end > this.pageHeight - 45) {
        this.doc.addPage();
        this.pageHeader();
        this.tableHeader(
          sno + "Existing Building Details Table",
          this.firstTableInPage
        );
        end = this.firstTableInPage + this.tableStart;
      }
      var totalsetBody = [];
      totalsetBody.push(["Total Floor Area", this.app.building_total_area]);
      // totalsetBody.push(["Total FSI ", Number(industryFsi + commercialFsi - netIncident).toFixed(2)])
      this.doc.autoTable({
        body: totalsetBody,
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 0) {
              //console.log("jgjgjg",data.cell.styles)
              data.cell.styles.halign = "center";
            }
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.textColor = "black";
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: end,
        margin: { horizontal: this.horizontal },
        columnStyles: {
          0: { cellWidth: 80 },
          2: { cellWidth: 30 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          //console.log("chowk",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "Existing Building Details Table",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }

    return end;
  };

  //new table
  ExistingBuildingDetailelementsTable = (start, sno) => {
    var setBody = [],
      end = 0,
      pg = 0,
      subsno = 0;
    subsno = subsno + 1;
    setBody.push([
      1,
      "Existing built up area",
      this.app.report.existing_proposed_building_details.existing_built_up,
    ]);
    setBody.push([
      2,
      "Proposed built up area",
      this.app.report.existing_proposed_building_details.proposed_buit_up,
    ]);
    setBody.push([
      3,
      "Total built up area",
      this.app.report.existing_proposed_building_details.total_builtup_area,
    ]);

    this.doc.autoTable({
      head: [["S.No", "Description", "Area in sq.m"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 5; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 60 },
        1: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Existing Building Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    return end;
  };

  //new table parking details

  ParkingDetailsNewTable = (start, sno) => {
    let end = start,
      pg = 0,
      total_built_up = 0,
      mech_area = 0,
      mech_area_count = 0,
      parking_area = 0,
      deductions = 0,
      total_parking_area = 0;
    var setBody = [];

    this.app.report.ParkingArea.map((i) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.BuiltUpArea == 0 ? "-" : i.BuiltUpArea,
        [i.MechanicalParkingAreaPerSlot, i.Count, i.MechanicalParkingArea],
        i.ParkingArea,
        i.Deduction,
        i.CalculatedArea,
      ]);
      total_built_up += i.BuiltUpArea;
      parking_area += i.TotalParkingArea;
      deductions += i.Deduction;
      total_parking_area += i.CalculatedArea;
      mech_area += i.MechanicalParkingAreaPerSlot;
      mech_area_count += i.MechanicalParkingArea;
    });

    setBody.push([
      "Total",
      "",
      total_built_up.toFixed(2),
      [mech_area.toFixed(2), "-", mech_area_count.toFixed(2)],
      parking_area.toFixed(2),
      deductions.toFixed(2),
      total_parking_area.toFixed(2),
    ]);
    // console.log("wc - Area",setBody)
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.ParkingArea.length > 0) {
      this.doc.autoTable({
        head: [
          [
            "Building Name",
            "Floor Name",
            "Basement Area",
            "Mech Parking",
            "Total Parking Area",
            "Deductions (Duct, AccessoryBuilding, VentilationShaft)",
            "Parking Area",
          ],
        ],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 3 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Area            ", "Count           ", "Area x Count"]],
              startY: data.cell.y + 9,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 20,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 3 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 20 },
                2: { cellWidth: 20 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body") {
                  if (
                    data.column.dataKey !== 1 &&
                    data.column.dataKey !== 2 &&
                    data.column.dataKey !== 0
                  ) {
                    //console.log("jgjgjg",data.cell.styles)
                    data.cell.styles.halign = "center";
                  }
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 3; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      // data.row.cells[i].styles.lineColor = "red";
                      // data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
            if (
              data.column.dataKey === 3 &&
              data.cell.section === "body" &&
              data.row.cells[2].raw[2] === "Non-Compliant"
            ) {
              data.cell.styles.textColor = "red";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 30 },
          2: { cellWidth: 20 },
          3: { cellWidth: 60 },
          4: { cellWidth: 22 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + ".  Parking Information Details Table",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }

    return end;
  };

  ParkingDetailsVisitorsTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [
      [
        this.app.report.visitors_parking_details.totalParkingArea,
        this.app.report.visitors_parking_details.min,
        this.app.report.visitors_parking_details.provided.toFixed(2),
        this.app.report.visitors_parking_details.result,
      ],
    ];

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Parking Area (40 %)", "Minimum(10 %)", "Provided", "Result"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        5: { cellWidth: 30 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(
            sno + "Visitors Parking Information",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  ParkingDetailsDTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [
      [
        this.app.report.parking_details.netBuiltUpArea,
        this.app.report.parking_details.min_parking_net_built_value,
        this.app.report.parking_details.requiredParking,
        this.app.report.parking_details.provided,
        this.app.report.parking_details.result,
      ],
    ];

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Net Builtup Area",
          "Minimum (40 %)",
          "Required Parking (40% + 10%)",
          "Provided",
          "Result",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        5: { cellWidth: 30 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(
            sno + "Visitors Parking Information",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  //new canopy details
  CanopyDetailsTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];

    this.app.report.CanopyDetails.map((i, index) => {
      setBody.push([
        i.BuildingName,
        i.CanopyLength,
        i.CanopyWidth,
        i.CanopyHeight,
      ]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Building Name", "Length", "Width", "Height"]],
      body: setBody,
      didDrawCell: function (data) {
        if (
          (data.column.dataKey === 1 && data.cell.section === "head") ||
          (data.column.dataKey === 2 && data.cell.section === "head") ||
          (data.column.dataKey === 3 && data.cell.section === "head")
        ) {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 16.6,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (
          (data.column.dataKey === 1 && data.cell.section === "body") ||
          (data.column.dataKey === 2 && data.cell.section === "body") ||
          (data.column.dataKey === 3 && data.cell.section === "body")
        ) {
          //console.log("po",data.cell.y)
          data.doc.autoTable({
            body: [
              [data.cell.raw.min, data.cell.raw.provided, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 16.6 },
              1: { cellWidth: 16.6 },
              2: { cellWidth: 16.6 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }

          if (data.column.dataKey !== 0 && data.column.dataKey !== 1) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }

          if (
            data.column.dataKey !== 0 &&
            data.column.dataKey !== 1 &&
            data.column.dataKey !== 4
          ) {
            if (data.cell.raw.result === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)

              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }

          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 50 },
        2: { cellWidth: 50 },
        3: { cellWidth: 50 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(sno + ". Canopy Details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    return end;
  };

  NormalStairDetails = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;

    var setBody = [];

    this.app.report.NormalStairDetails.map((i, index) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.Parameter,
        i.min,
        i.provided,
        i.result,
        i.count,
      ]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Parameter",
          "Minimum",
          "Provided",
          "Result",
          "No. of Units",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 5; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Normal Staircase Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  NormalStairFlightDetails = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;

    var setBody = [];

    this.app.report.NormalStairsFlight.map((i, index) => {
      setBody.push([i.BuildingName, i.FloorName, i.max, i.provided, i.result]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        ["Building Name", "Floor Name", "Maximum No.s", "Provided", "Result"],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 4; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 50 },
        2: { cellWidth: 50 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Normal Staircase Flight Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  FireStairFlightDetails = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;

    var setBody = [];

    this.app.report.FireStairsFlight.map((i, index) => {
      setBody.push([i.BuildingName, i.FloorName, i.max, i.provided, i.result]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        ["Building Name", "Floor Name", "Maximum No.s", "Provided", "Result"],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 4; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 50 },
        2: { cellWidth: 50 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Normal Staircase Flight Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  FireStairDetails = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;

    var setBody = [];

    this.app.report.FireStairDetails.map((i, index) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.Parameter,
        i.min,
        i.provided,
        i.result,
        i.count,
      ]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Parameter",
          "Minimum",
          "Provided",
          "Result",
          "No. of Units",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 7; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Fire Staircase Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  StairCaseCountDetailsTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;

    var setBody = [];

    this.app.report.StairCaseCount.map((i, index) => {
      setBody.push([i.BuildingName, "Normal Staircase", i.Parameter.normal]);
      setBody.push([i.BuildingName, "Fire Staircase", i.Parameter.fire]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Building Name", "Parameter", "No of Umits"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 3; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 70 },
        2: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(
            sno + ". StairCase Count Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  LiftCountDetailsTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;

    var setBody = [];

    this.app.report.LiftCount.map((i, index) => {
      setBody.push([i.BuildingName, "Normal Lift", i.Parameter.normal]);
      setBody.push([i.BuildingName, "Fire Lift", i.Parameter.fire]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Building Name", "Parameter", "No of Umits"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 3; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 60 },
        1: { cellWidth: 70 },
        2: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(sno + ". Lift Count Details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  DrivewayDetailsTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];

    this.app.report.DriveWayDetails.map((i, index) => {
      setBody.push([i.BuildingName, i.FloorName, i.buildingHeight, i.width]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Building Name", "Floor Name", "Building Height", "Width"]],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 30,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          //console.log("po",data.cell.y)
          data.doc.autoTable({
            body: [
              [
                data.cell.raw.minWidth,
                data.cell.raw.provided
                  ? data.cell.raw.provided
                  : data.cell.raw.Width,
                data.cell.raw.result,
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 30 },
              1: { cellWidth: 30 },
              2: { cellWidth: 30 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 1,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }

          if (data.column.dataKey !== 0 && data.column.dataKey !== 1) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }

          if (
            data.column.dataKey !== 0 &&
            data.column.dataKey !== 1 &&
            data.column.dataKey !== 4
          ) {
            if (data.cell.raw.result === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)

              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 5; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }

          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40 },
        3: { cellWidth: 90 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + ". Parking Floor Driveway Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    return end;
  };

  MarginalOpenSpaceNewTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [];

    this.app.report.MarginDetails.map((i, index) => {
      if ("FrontMargin" in i) {
        setBody.push([
          i.BuildingName,
          "Front Margin",
          i.FrontMargin.min,
          i.FrontMargin.Value,
          i.FrontMargin.result,
        ]);
      }

      if ("Side1Margin" in i) {
        setBody.push([
          i.BuildingName,
          "Side 1 Margin",
          i.Side1Margin.min,
          i.Side1Margin.Value,
          i.Side1Margin.result,
        ]);
      }

      if ("Side2Margin" in i) {
        setBody.push([
          i.BuildingName,
          "Side 2 Margin",
          i.Side2Margin.min,
          i.Side2Margin.Value,
          i.Side2Margin.result,
        ]);
      }

      if ("RearMargin" in i) {
        setBody.push([
          i.BuildingName,
          "Rear Margin",
          i.RearMargin.min,
          i.RearMargin.Value,
          i.RearMargin.result,
        ]);
      }
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Building Name", "Parameter", "Minimum", "Provided", "Result"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 5; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        2: { cellWidth: 30 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Marginal Open Spaces",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  MarginalOpenSpaceTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [];
    this.app.report.MarginDetails.map((i, index) => {
      subsno = subsno + 1;
      setBody.push([
        i.BuildingName,
        i.FrontMargin,
        i.Side1Margin,
        i.Side2Margin,
        i.RearMargin,
      ]);
    });

    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Front Margin",
          "Side One Margin",
          "Side Two Margin",
          "Rear Margin",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        // console.log("deg", data)
        if (data.column.dataKey !== 0 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 8,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              cellWidth: 14,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey !== 0 && data.cell.section === "body") {
          //console.log("po",data.table.body)
          data.doc.autoTable({
            body: [
              [data.cell.raw.min, data.cell.raw.Value, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 14 },
              1: { cellWidth: 14 },
              2: { cellWidth: 14 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.column.dataKey === 0 && data.cell.section === "head") {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 1) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          if (
            data.column.dataKey === 3 ||
            data.column.dataKey === 4 ||
            data.column.dataKey === 1 ||
            data.column.dataKey === 2
          ) {
            if (data.cell.raw.result === "Non-Compliant") {
              data.row.cells[0].styles.textColor = "red";
            } else {
              data.cell.styles.textColor = "black";
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 15,
      },
      columnStyles: {
        // 0: { cellWidth: 20 },
        1: { cellWidth: 42 },
        2: { cellWidth: 42 },
        3: { cellWidth: 42 },
        4: { cellWidth: 42 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Marginal Open Spaces",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  BasementSetback = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [];
    var Basement = this.app.report.BasementSetback;

    if ("FrontSetback" in this.app.report.BasementSetback) {
      setBody.push([
        "Front Setback",
        Basement.FrontSetback.min,
        Basement.FrontSetback.provided,
        Basement.FrontSetback.result,
      ]);
    }

    if ("Side1Setback" in this.app.report.BasementSetback) {
      setBody.push([
        "Side 1 Setback",
        Basement.Side1Setback.min,
        Basement.Side1Setback.provided,
        Basement.Side1Setback.result,
      ]);
    }

    if ("Side2Setback" in this.app.report.BasementSetback) {
      setBody.push([
        "Side 2 Setback",
        Basement.Side2Setback.min,
        Basement.Side2Setback.provided,
        Basement.Side2Setback.result,
      ]);
    }

    if ("RearSetback" in this.app.report.BasementSetback) {
      setBody.push([
        "Rear Setback",
        Basement.RearSetback.min,
        Basement.RearSetback.provided,
        Basement.RearSetback.result,
      ]);
    }

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Parameter", "Minimum", "Provided", "Result"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 4; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 50 },
        2: { cellWidth: 50 },
        3: { cellWidth: 50 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(sno + " Basement Setback", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  ProjectionIntoMarginalOpenSpaceTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 1;
    var setBody = this.app.report.ChajjaProjection.map((i) => {
      let temp = [
        i.BuildingName,
        "Chajja Projections",
        i.max,
        i.Width,
        i.result,
      ];
      subsno = subsno + 1;
      return temp;
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Building Name", "Parameter", "Maximum", "Provided", "Result"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 1 && data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 70 },
        1: { cellWidth: 50 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + "  Projection into Marginal Open Spaces",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  MarginalOpenSpaceForStructuresTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [];
    this.app.report.CanopyMargins.map((i, index) => {
      setBody.push([
        i.BuildingName,
        "Canopy",
        i.Front,
        i.Rear,
        i.Side1,
        i.Side2,
      ]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Paramter",
          "Front Margin",
          "Side One Margin",
          "Side Two Margin",
          "Rear Margin",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (
          data.column.dataKey !== 0 &&
          data.cell.section === "head" &&
          data.column.dataKey !== 1 &&
          data.cell.section === "head"
        ) {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              cellWidth: 14,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (
          data.column.dataKey !== 0 &&
          data.cell.section === "body" &&
          data.column.dataKey !== 1 &&
          data.cell.section === "body"
        ) {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            // head: [["Minimum", "Provided", "Result"]],
            body: [
              [data.cell.raw.min, data.cell.raw.provided, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 14 },
              1: { cellWidth: 14 },
              2: { cellWidth: 14 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 2; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (
          (data.column.dataKey === 0 && data.cell.section === "head") ||
          (data.column.dataKey === 1 && data.cell.section === "head")
        ) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          if (data.column.dataKey !== 0 && data.column.dataKey !== 1) {
            if (data.cell.raw.result === "Non-Compliant") {
              data.row.cells[0].styles.textColor = "red";
              data.row.cells[1].styles.textColor = "red";
            } else {
              data.cell.styles.textColor = "black";
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 14,
      },
      columnStyles: {
        1: { cellWidth: 10 },
        2: { cellWidth: 42 },
        3: { cellWidth: 42 },
        4: { cellWidth: 42 },
        5: { cellWidth: 42 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + " Marginal Open Spaces for Structures",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  getBuildingHeightTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subno = 1;
    var setBody = [];
    var max = [
      [
        sno + "." + subno,
        this.app.report.BuildingTotalHeight[0],
        { min: 0, max: 0, Value: 0, result: "" },
      ],
    ];
    this.app.report.BuildingTotalHeight.map((i) => {
      let check = true;
      max.map((p) => {
        if (p[1].BuildingName === i.BuildingName) {
          check = false;
          if (p.Value > i.Value) {
            i.Value = p.Value;
          }
        }
      });
      if (check) {
        subno = subno + 1;
        max.push([
          sno + "." + subno,
          i,
          { min: 0, max: 0, Value: 0, result: "" },
        ]);
      }
    });

    max.map((p) => {
      this.app.report.BuildingHabitableHeight.map((i) => {
        if (i.BuildingName === p[1].BuildingName) {
          if (i.Value > p[2].Value) {
            p[2].Value = i.Value;
            p[2].min = i.min;
            p[2].max = i.max;
            p[2].result = i.result;
          }
        }
      });
    });

    var setBody = max.map((i) => {
      return [
        i[0],
        i[1].BuildingName,
        i[1].Value,
        [i[2].min, i[2].max, i[2].Value, i[2].result],
      ];
    });
    this.doc.autoTable({
      head: [
        [
          "S.NO",
          "Building name",
          "Building height",
          "Total Habitable Building height",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          //console.log("po",data.table.body)
          data.doc.autoTable({
            head: [["Minimum", "Maximum", "Provided", "Result"]],
            body: [
              [
                data.cell.raw[0],
                data.cell.raw[1],
                data.cell.raw[2],
                data.cell.raw[3],
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            styles: {
              fontSize: 10,
              cellPadding: 1,
              halign: "center",
              valign: "middle",
            },
            columnStyles: {
              0: { cellWidth: 30 },
              1: { cellWidth: 30 },
              2: { cellWidth: 30 },
              3: { cellWidth: 30 },
            },
            theme: "grid",
            headStyles: {
              valign: "middle",
              fontSize: 10,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
            bodyStyles: {
              minCellHeight: data.table.body[0].height - 6,
              fontSize: 10,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
                // data.cell.styles.lineColor = [128, 128, 128];
                // data.cell.styles.lineWidth = 0.25
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 1) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw[3] === "Non-Compliant") {
            // data.row.cells[0].styles.lineColor = "red";
            // data.row.cells[0].styles.lineWidth = 0.75;
            // data.row.cells[1].styles.lineColor = "red";
            // data.row.cells[1].styles.lineWidth = 0.75
            data.row.cells[0].styles.textColor = "red";
            data.row.cells[1].styles.textColor = "red";
            data.row.cells[2].styles.textColor = "red";
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + 9,
      margin: { horizontal: 5, top: 59, bottom: 20 },
      headStyles: {
        valign: "middle",
        fontSize: 11,
        halign: "center",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 100 },
        3: { cellWidth: 120 },
      },
      bodyStyles: {
        fontSize: 10,
        minCellHeight: 15,
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        valign: "middle",
      },
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + ". Building  height Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  WatchmanCabinTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [];
    this.app.report.WatchmanCabin.map((i, index) => {
      subsno = subsno + 1;
      setBody.push(["Security Guard", i.AreaRow]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (setBody.length > 0)
      this.doc.autoTable({
        head: [["Parameter", "Area"]],
        body: setBody,
        didDrawCell: function (data) {
          // console.log("watchamncabin",data);
          if (data.column.dataKey == 1 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                fontSize: data.table.head[0].cells[0].styles.fontSize - 1,
                cellWidth: 30,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          } /*else if(data.column.dataKey == 2 && data.cell.section === 'head'){
                    data.doc.autoTable({
                        head: [[ "Maximum", "Provided", "Result"]],
                        startY: data.cell.y + 7,
                        margin: { left: data.cell.x },
                        tableWidth: 'wrap',
                        theme: "grid",
                        headStyles: {
                            valign: 'middle',
                            fontSize: data.table.head[0].cells[0].styles.fontSize-1,
                            cellWidth:25,
                            minCellHeight:data.table.head[0].cells[0].styles.minCellHeight/2,
                            halign: 'center',
                            fillColor: "white",
                            textColor: "black",
                            lineWidth: 0.1,
                            lineColor: [128, 128, 128]
                        },
                    });

                }*/

          if (data.column.dataKey === 1 && data.cell.section === "body") {
            //console.log("po",data.table.body)
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [
                [
                  data.cell.raw.min,
                  data.cell.raw.provided,
                  data.cell.raw.result,
                ],
              ],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize - 1,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.25,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                //console.log("hiii",data.cell.raw)
                if (data.cell.raw === "Non-Compliant") {
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 2; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              },
            });
          } /*else if(data.column.dataKey === 2 && data.cell.section === 'body'){

                    data.doc.autoTable({
                        // head: [["Minimum", "Provided", "Result"]],
                        body: [
                            [data.cell.raw.max, data.cell.raw.provided, data.cell.raw.result],
                        ],
                        startY: data.cell.y,
                        margin: { left: data.cell.x },
                        tableWidth: 'wrap',
                        columnStyles: {
                            0: { cellWidth: 25 },
                            1: { cellWidth: 25 },
                            2: { cellWidth: 25 }
                        },
                        theme: "grid",
                        bodyStyles: {
                            minCellHeight: data.table.body[data.row.index].height,
                            fontSize: data.table.body[0].cells[0].styles.fontSize-1,
                            halign: "center",
                            fillColor: "white",
                            textColor: "black",
                            lineWidth: 0.25,
                            lineColor: [128, 128, 128],
                            // lineColor: data.table.body[0].cells[0].styles.lineColor,
                            // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                            valign: "middle"
                        },
                        didParseCell: function (data) {
                            //console.log("hiii",data.cell.raw)
                            if (data.cell.raw === "Non-Compliant") {
                                data.cell.styles.textColor = "red";
                                // data.cell.styles.lineColor = "red";
                                // data.cell.styles.lineWidth = 0.75
                                for (let i = 0; i < 2; i++) {
                                    if (data.row.cells[i])
data.row.cells[i].styles.textColor = "red";
                                    // data.row.cells[i].styles.lineColor = "red";
                                    // data.row.cells[i].styles.lineWidth = 0.75
                                }
                            }
                            else {
                                data.cell.styles.textColor = "black";
                            }
                        }
                    });

                }*/
        },
        didParseCell: function (data) {
          if (data.column.dataKey === 0 && data.cell.section === "head") {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 0) {
              //console.log("jgjgjg",data.cell.styles)
              data.cell.styles.halign = "center";
            }
            if (data.column.dataKey === 1 || data.column.dataKey === 2) {
              if (data.cell.raw.result === "Non-Compliant") {
                // data.row.cells[0].styles.lineColor = "red";
                // data.row.cells[0].styles.lineWidth = 0.75
                /* data.row.cells[0].styles.textColor = "red";
                            data.row.cells[1].styles.textColor = "red";
                            data.row.cells[2].styles.textColor = "red";*/
                data.cell.styles.textColor = "red";
              } else {
                data.cell.styles.textColor = "black";
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
        columnStyles: {
          0: { cellWidth: 110 },
          1: { cellWidth: 90 },
        },

        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          //console.log("chowk",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + " Accessory Building in Marginal Open Spaces",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    else
      end = this.notProviedTable(
        start,
        "Watchman cabin details not provided",
        sno + " Accessory Building in Marginal Open Spaces"
      );
    return end;
  };
  AccessoryTableinMarginalopenSpaceTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [];
    var setBody = this.app.report.ElectricalSubStation.map((value, index) => {
      if (this.app.report.ElectricalSubStation.length === 1) {
        subsno = subsno + 1;
        return [
          "Electrical Sub Station (L x W)",
          value.min,
          value.provided,
          value.result,
        ];
      }
      subsno = subsno + 1;
      return [
        "Transformer Dimension - " + (index + 1).toString() + " Width (L x W)",
        value.min,
        value.provided,
        value.result,
      ];
    });

    /*   this.app.report.ElectricalSubStationHeight.map((i, index) => {
            if (this.app.report.ElectricalSubStationHeight.length === 1) {
                subsno = subsno + 1
                setBody.push(["Transformer Height", "N.A", i.max, i.Value, i.result])
            }
            else {
                subsno = subsno + 1
                setBody.push(["Transformer Height - " + (index + 1).toString(), "N.A", 5, i.Value, i.result])
            }
        })*/

    /* this.app.report.Canopy.map((value, index) => {
            if (this.app.report.Canopy.length === 1) {
                subsno = subsno + 1
                setBody.push(["Canopy Dimension (L x W)", value.min, "N.A", value.Length + " x " + value.Width, value.result])
            }
            subsno = subsno + 1
            setBody.push(["Canopy Dimension - " + (index + 1) + "  (L x W) ", value.min, "N.A", value.Length + " x " + value.Width, value.result])
        })
        this.app.report.CanopyHeight.map((value, index) => {
            if (this.app.report.Canopy.length === 1) {
                subsno = subsno + 1
                setBody.push(["Canopy Height", value.min, "N.A", value.Value, value.result])
            }
            subsno = subsno + 1
            setBody.push(["Canopy Height - " + (index + 1), value.min, "N.A", value.Value, value.result])
        })*/
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (setBody.length > 0)
      this.doc.autoTable({
        head: [["Parameter", "Minimum", "Provided", "Result"]],
        body: setBody,
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey != 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw === "Non-Compliant") {
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 5; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: this.headerStyles.centerAndMiddle,
        columnStyles: {
          0: { cellWidth: 70 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          //console.log("secondary",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + " Accessory Building in Marginal Open Spaces",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    else
      end = this.notProviedTable(
        start,
        "Electrical substation and canopy details not provided",
        sno + " Accessory Building in Marginal Open Spaces"
      );
    return end;
  };
  RecreationalOpenSpaceTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [];

    this.app.report.ROS.map((i) => {
      setBody.push([i.GPA, i.min, Number(i.Area).toFixed(2), i.result]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);

    this.doc.autoTable({
      head: [["Net Plot Area (NPA)", "Minimum (10%)", "Provided", "Result"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (i === 2) {
                data.cell.styles.textColor = "black";
              } else if (data.row.cells[i])
                data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 40 },
        6: { cellWidth: 30 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Recreational Open Spaces",
            this.firstTableInPage
          );
        }
        pg = pg + 1;

        end = data.cursor.y;
      },
    });
    return end;
  };

  RecreationalOpenSpaceSubTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0,
      totarea = 0,
      totwidth = 0;
    var setBody = [];

    var ros_details = this.app.report.ros_details;

    this.app.report.ros_details.map((i, index) => {
      totarea += i.Area.provided;
      totwidth += i.Width.provided;
      setBody.push([index + 1, i.Area, i.Width]);
    });

    this.doc.autoTable({
      head: [["S.No", "Area", "Width"]],
      body: setBody,
      didDrawCell: function (data) {
        if (
          (data.column.dataKey === 1 && data.cell.section === "head") ||
          (data.column.dataKey === 2 && data.cell.section === "head")
        ) {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 9,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 28.3,
              fontSize: data.table.head[0].cells[0].styles.fontSize,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (
          (data.column.dataKey === 1 && data.cell.section === "body") ||
          (data.column.dataKey === 2 && data.cell.section === "body")
        ) {
          //console.log("po",data.table.body!

          data.doc.autoTable({
            body: [
              [data.cell.raw.min, data.cell.raw.provided, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 28.3 },
              1: { cellWidth: 28.3 },
              2: { cellWidth: 28.3 },
            },
            theme: "grid",
            bodyStyles: {
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body") {
                if (data.column.dataKey !== 0) {
                  //console.log("jgjgjg",data.cell.styles)
                  data.cell.styles.halign = "center";
                }
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "head" && data.column.dataKey !== 3) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.halign = "center";

          if (
            data.cell.raw &&
            data.cell.raw.result &&
            data.cell.raw.result === "Non-Compliant"
          ) {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 2; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 85 },
        2: { cellWidth: 85 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(sno + "  Ros Sub Details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    if (end > this.pageHeight - 45) {
      this.doc.addPage();
      this.pageHeader();
      this.tableHeader(
        sno + ". Recreational Open Spaces",
        this.firstTableInPage
      );
      end = this.tableStart + this.firstTableInPage;
    }
    var totalsetBody = [];
    totalsetBody.push([
      "Total Provided",
      totarea.toFixed(2),
      totwidth.toFixed(2),
    ]);
    this.doc.autoTable({
      body: totalsetBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: end,
      margin: { horizontal: this.horizontal },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 85 },
        2: { cellWidth: 85 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        /* if (pg > 0) {
                    this.tableHeader(sno + ". Floorwise Deduction", this.firstTableInPage)
                }*/
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    return end;
  };

  AmenitySpaceTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [
      [
        "Amenity Space Area",
        this.app.report.AmenitySpace[0].GPA,
        this.app.report.AmenitySpace[0].min,
        this.app.report.AmenitySpace[0].Area,
        this.app.report.AmenitySpace[0].excess,
        this.app.report.AmenitySpace[0].result,
      ],
    ];

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Parameter",
          "Net Plot Area (NPA)",
          "Minimum (5%)",
          "Provided",
          "Excess Area",
          "Result",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 40 },
        5: { cellWidth: 30 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("function",sno)
        if (pg > 0) {
          this.tableHeader(sno + ". Amenity Space c", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  InternalMeansOfAccessTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [
      ["Internal Means of Access Width/ Driveway", "", "", "", ""],
      ["Driveway Turning Radius", "", "", "", ""],
    ];

    this.doc.autoTable({
      head: [["Parameter", "Minimum", "Maximum", "Provided", "Result"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + 9,
      margin: { horizontal: 5, top: 59, bottom: 20 },
      headStyles: {
        valign: "middle",
        fontSize: 12,
        halign: "center",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 90 },
      },
      bodyStyles: {
        fontSize: 10,
        // halign: "center",
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        valign: "middle",
      },
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Internal means of Access",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  DetailsOfstructuresTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    // this.app.report.Pantry.map((i, index) => {
    //     subsno = subsno + 1
    //     setBody.push([sno + "." + subsno, i.BuildingName, i.FloorName, i.AreaRow, i.WidthRow])
    // })

    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Number of Floors",
          "Number of Basement",
          "Building Plinth Height",
          "Covered Parking/ Stilt Height",
          "Habitable Building Height",
          "Total Building height",
        ],
      ],
      body: [["", "", "", "", "", "", ["", "", "", ""]]],
      didDrawCell: function (data) {
        if (data.column.dataKey === 6 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Maximum", "Provided", "Result"]],
            startY: data.cell.y + 8,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              minCellHeight: 7,
              cellWidth: 20,
              fontSize: 9,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 6 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            //  head: [["Minimum", "Maximum", "Provided", "Result"]],
            body: [
              [
                data.cell.raw[0],
                data.cell.raw[1],
                data.cell.raw[2],
                data.cell.raw[3],
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
              2: { cellWidth: 20 },
              3: { cellWidth: 20 },
            },
            theme: "grid",
            headStyles: {
              valign: "middle",
              fontSize: 7,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: 7,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.column.dataKey !== 6 && data.cell.section === "head") {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          // if (data.column.dataKey === 3 || data.column.dataKey === 4) {
          //     if (data.cell.raw.result === "Non-Compliant") {
          //         data.row.cells[0].styles.textColor = "red";
          //         data.row.cells[1].styles.textColor = "red";
          //         data.row.cells[2].styles.textColor = "red";
          //     }
          //     else {
          //         data.cell.styles.textColor = "black"
          //     }
          // }
          // else {
          //
          // }
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + 9,
      margin: { horizontal: 5, top: 59, bottom: 20 },
      headStyles: {
        // valign: 'middle',
        fontSize: 11,
        minCellHeight: 15,
        halign: "center",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
      columnStyles: {
        0: { cellWidth: 35 },
        6: { cellWidth: 80 },
      },
      bodyStyles: {
        fontSize: 9,
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        valign: "middle",
      },
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Details of Structures/Buildings",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  FloorWiseDetailsTableTsiic = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0,
      sizeHead = 3,
      sizeBody = 7;
    var setBody = [],
      totalBuiltup = 0,
      totalIndustrialNetFsi = 0,
      netIncident = 0,
      totalduct = 0;
    this.app.report.FloorWiseDeductions.map((i, index) => {
      if (i.floorNo >= 0 && i.floorNo !== 99999) {
        // console.log("floor no :",i.BuiltUp)
        totalBuiltup += i.BuiltupArea;
        totalIndustrialNetFsi += i.NetBuiltupArea;
        totalduct += i.Duct;
      }
      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.BuiltupArea,
        [i.Duct],
        i.NetBuiltupArea,
      ]);
    });

    if ("TempIncidentDetails" in this.app.report) {
      netIncident = Number(
        Number(
          this.app.report.TempIncidentDetails.ElectricalSubStation
        ).toFixed(2)
      );
    }

    if (
      "MeterRoom" in this.app.report &&
      this.app.report.MeterRoom.length > 0
    ) {
      this.app.report.MeterRoom.map((i) => {
        if (!this.checkNOandNA(i.Area)) {
          netIncident += i.Area;
        }
      });
    }

    /* this.app.report.EntranceLobby.map(i => {
            if (!this.checkNOandNA(i.Area)) {
                netIncident += i.Area
            }

        })*/

    if ("AHU_Unit" in this.app.report && this.app.report.MeterRoom.length > 0) {
      this.app.report.AHU_Unit.map((i) => {
        if (!this.checkNOandNA(i.Area)) {
          netIncident += i.Area;
        }
      });
    }

    netIncident = Number(netIncident.toFixed(2));

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Built up Area",
          "Deductions",
          (this.isResidential(this.app.useOfTheBuildings)
            ? "Residential"
            : "Industrial") + " Net Builtup Area",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Duct"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 40,
              fontSize: data.table.head[0].cells[0].styles.fontSize,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            body: [
              [
                data.cell.raw[0],
                data.cell.raw[1],
                data.cell.raw[2],
                data.cell.raw[3],
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 40 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },

            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)

              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 1; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (
          (data.column.dataKey === 0 && data.cell.section === "head") ||
          (data.column.dataKey === 1 && data.cell.section === "head") ||
          (data.column.dataKey === 2 && data.cell.section === "head") ||
          (data.column.dataKey === 4 && data.cell.section === "head")
        ) {
          data.cell.styles.valign = "middle";
        }

        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0 && data.column.dataKey !== 1) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 14,
      },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40 },
        3: { cellWidth: 40 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Floorwise Deduction",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    if (end > this.pageHeight - 45) {
      this.doc.addPage();
      this.pageHeader();
      this.tableHeader(sno + ". Floorwise Deduction", this.firstTableInPage);
      end = this.tableStart + this.firstTableInPage;
    }
    var totalsetBody = [];
    let industryFsi = Number(Number(totalIndustrialNetFsi).toFixed(2));
    let commercialFsi = Number(
      Number(this.app.report.ExcessDetailsRow.NetCFSI).toFixed(2)
    );
    totalBuiltup = Number(totalBuiltup.toFixed(2));
    totalduct = Number(totalduct.toFixed(2));
    totalsetBody.push([
      "Total Builtup Area in sq.m",
      totalBuiltup,
      totalduct,
      industryFsi,
    ]);
    this.doc.autoTable({
      body: totalsetBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: end,
      margin: { horizontal: this.horizontal },
      columnStyles: {
        0: { cellWidth: 95 },
        1: { cellWidth: 30 },
        2: { cellWidth: 40 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        /* if (pg > 0) {
                    this.tableHeader(sno + ". Floorwise Deduction", this.firstTableInPage)
                }*/
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    /* if (end > this.pageHeight-45) {
            this.doc.addPage();
            this.pageHeader();
            this.tableHeader(sno + ". Floorwise Deduction", this.firstTableInPage)
            end = this.tableStart+this.firstTableInPage
        }
        var totalsetBody = [];
        totalsetBody.push(["Total Accessory Buildings Area in sq.m", netIncident])
        totalsetBody.push(["Total FSI ", Number(industryFsi - netIncident).toFixed(2)])
        this.doc.autoTable({
            body: totalsetBody,
            didParseCell: function (data) {
                if (data.section === 'body' && data.cell.raw !== "") {
                    if (data.column.dataKey !== 0) {
                        //console.log("jgjgjg",data.cell.styles)
                        data.cell.styles.halign = "center"
                    }
                    data.cell.styles.fontStyle = "bold";
                    data.cell.styles.textColor = "black";
                }
            },
            rowPageBreak: 'avoid',
            theme: "grid",
            startY: end,
            margin: { horizontal: this.horizontal },
            columnStyles: {
                0: { cellWidth: 165 },
            },
            bodyStyles:this.bodyStyles.noAlign,
            didDrawPage: (data) => {
                this.pageHeader()
               // console.log("chowk",sno)
                pg = pg + 1
                end = data.cursor.y
            }
        })*/
    return end;
  };
  FloorWiseDetailsTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0,
      sizeHead = 3,
      sizeBody = 7;
    var setBody = [],
      totalBuiltup = 0,
      totalIndustrialNetFsi = 0;
    this.app.report.ExcessDetails.map((i, index) => {
      if (i.floorNo >= 0 && i.floorNo !== 99999) {
        // console.log("floor no :",i.floorNo)
        totalBuiltup += i.BuiltUp;
        totalIndustrialNetFsi += i.NET;
      }

      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.BuiltUp,
        i.CoveredParking,
        [i.Duct, Number(i.Staircase).toFixed(2), i.Lift],
        [i.Balcony.max, i.Balcony.provided, i.Balcony.excess],
        [
          i.DoubleHeightTerraces.max,
          i.DoubleHeightTerraces.provided,
          i.DoubleHeightTerraces.excess,
        ],
        i.Refugee,
        i.NET,
        i.CFSI,
      ]);
    });
    var netIncident =
      Number(Number(this.app.report.TempIncidentDetails.ClubHouse).toFixed(2)) +
      Number(
        Number(
          this.app.report.TempIncidentDetails.ElectricalSubStation
        ).toFixed(2)
      ) +
      Number(
        Number(this.app.report.TempIncidentDetails.WatchmanCabin).toFixed(2)
      );
    this.app.report.MeterRoom.map((i) => {
      if (!this.checkNOandNA(i.Area)) {
        netIncident += i.Area;
      }
    });
    /*  this.app.report.EntranceLobby.map(i => {
            if (!this.checkNOandNA(i.Area)) {
                netIncident += i.Area
            }

        })*/

    this.app.report.AHU_Unit.map((i) => {
      if (!this.checkNOandNA(i.Area)) {
        netIncident += i.Area;
      }
    });
    netIncident = Number(netIncident.toFixed(2));
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Built up Area",
          "Non FSI Deductions",
          "Balcony Details",
          "Double Height Terrace Details",
          "Excess Refuge Area",
          (this.isResidential(this.app.useOfTheBuildings)
            ? "Residential"
            : "Industrial") + " Net FSI Area",
          "Commercial Net FSI Area",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 4 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Duct", "Staircase", "Lifts"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 15,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 4 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            // head: [["Duct", "Incidental Structures", "Staircase", "Lifts"]],
            body: [
              [
                data.cell.raw[0],
                data.cell.raw[1],
                data.cell.raw[2],
                data.cell.raw[3],
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 15 },
              1: { cellWidth: 15 },
              2: { cellWidth: 15 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },

            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)

              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
        if (data.column.dataKey === 5 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Maximum 15% Area", "Provided", "Excess Area"]],
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            startY: data.cell.y + 7,
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 15,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 5 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            //  head: [["Minimum 15% Area", "Provided", "Excess Area"]],
            body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 15 },
              1: { cellWidth: 15 },
              2: { cellWidth: 15 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)

              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
        if (data.column.dataKey === 6 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Maximum 20% Area", "Provided", "Excess Area"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 15,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 6 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            // head: [["Minimum 20% Area", "Provided", "Excess Area"]],
            body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 15 },
              1: { cellWidth: 15 },
              2: { cellWidth: 15 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (
          (data.column.dataKey === 0 && data.cell.section === "head") ||
          (data.column.dataKey === 1 && data.cell.section === "head") ||
          (data.column.dataKey === 2 && data.cell.section === "head") ||
          (data.column.dataKey === 3 && data.cell.section === "head") ||
          (data.column.dataKey === 7 && data.cell.section === "head")
        ) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          // if (data.column.dataKey === 3 || data.column.dataKey === 4) {
          //     if (data.cell.raw.result === "Non-Compliant") {
          //         data.row.cells[0].styles.textColor = "red";
          //         data.row.cells[1].styles.textColor = "red";
          //         data.row.cells[2].styles.textColor = "red";
          //     }
          //     else {
          //         data.cell.styles.textColor = "black"
          //     }
          // }
          // else {
          //
          // }
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 14,
      },
      columnStyles: {
        0: { cellWidth: 15 },
        1: { cellWidth: 15 },
        2: { cellWidth: 20 },
        4: { cellWidth: 45 },
        5: { cellWidth: 45 },
        6: { cellWidth: 45 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Floorwise Deduction/ Excess Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    if (this.pageHeight - 45) {
      this.doc.addPage();
      this.pageHeader();
      this.tableHeader(
        sno + ". Floorwise Deduction/ Excess Details",
        this.firstTableInPage
      );
      end = this.tableStart + this.firstTableInPage;
    }
    var totalsetBody = [];
    let industryFsi = Number(Number(totalIndustrialNetFsi).toFixed(2));
    let commercialFsi = Number(
      Number(this.app.report.ExcessDetailsRow.NetCFSI).toFixed(2)
    );
    totalBuiltup = Number(totalBuiltup.toFixed(2));
    totalsetBody.push([
      "Total Area in sq.m",
      totalBuiltup,
      "-",
      industryFsi,
      commercialFsi,
    ]);
    this.doc.autoTable({
      body: totalsetBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: end,
      margin: { horizontal: this.horizontal },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 20 },
        2: { cellWidth: 135 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Floorwise Deduction/ Excess Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    if (this.pageHeight - 45) {
      this.doc.addPage();
      this.pageHeader();
      this.tableHeader(
        sno + ". Floorwise Deduction/ Excess Details",
        this.firstTableInPage
      );
      end = this.tableStart + this.firstTableInPage;
    }
    var totalsetBody = [];
    totalsetBody.push(["Total Accessory Buildings Area in sq.m", netIncident]);
    totalsetBody.push([
      "Total FSI ",
      Number(industryFsi - netIncident).toFixed(2),
    ]);
    this.doc.autoTable({
      body: totalsetBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: end,
      margin: { horizontal: this.horizontal },
      columnStyles: {
        0: { cellWidth: 185 },
        //1: { cellWidth: 44 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Floorwise Deduction/ Excess Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  IncidentStructureTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [],
      previousFloor = -9999;
    // setBody.push(["-", "Ground Floor","Club house",  Number(Number(this.app.report.TempIncidentDetails.ClubHouse).toFixed(2))])
    if (this.app.report.TempIncidentDetails.ElectricSubStation >= 1) {
      setBody.push([
        "-",
        "Ground Floor",
        "Electric Sub Station",
        Number(
          Number(
            this.app.report.TempIncidentDetails.ElectricalSubStation
          ).toFixed(2)
        ),
      ]);
    }
    //  setBody.push(["-", "Ground Floor","Watchman Cabin",  Number(Number(this.app.report.TempIncidentDetails.WatchmanCabin).toFixed(2))])
    var netIncident =
      Number(Number(this.app.report.TempIncidentDetails.ClubHouse).toFixed(2)) +
      Number(
        Number(
          this.app.report.TempIncidentDetails.ElectricalSubStation
        ).toFixed(2)
      ) +
      Number(
        Number(this.app.report.TempIncidentDetails.WatchmanCabin).toFixed(2)
      );
    this.app.report.MeterRoom.map((i) => {
      netIncident += i.Area;
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Area === existing[3] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
          }
        });
        if (notFound) {
          setBody.push([i.BuildingName, i.FloorName, "Meter Room", i.Area, 1]);
        }
      } else {
        setBody.push([i.BuildingName, i.FloorName, "Meter Room", i.Area, 1]);
      }
    });

    /* this.app.report.EntranceLobby.map(i => {
            netIncident += i.Area
                if (setBody.length > 0) {
                    let notFound = true
                    setBody.map(existing => {
                        if (i.BuildingName === existing[0] && i.FloorName === existing[1] && i.Area === existing[3] && notFound) {
                            notFound = false              
                            existing[4] += 1
                        }
                    })
                    if (notFound) {
                        setBody.push([i.BuildingName, i.FloorName,"Entrance Lobby", i.Area,1])
                    }
                }
                else {
                    setBody.push([i.BuildingName, i.FloorName,"Entrance Lobby", i.Area,1])
                }
        })*/

    this.app.report.AHU_Unit.map((i) => {
      netIncident += i.Area;
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Area === existing[3] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
          }
        });
        if (notFound) {
          setBody.push([i.BuildingName, i.FloorName, "AHU unit", i.Area, 1]);
        }
      } else {
        setBody.push([i.BuildingName, i.FloorName, "AHU unit", i.Area, 1]);
      }
    });

    netIncident = Number(netIncident.toFixed(2));

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building name",
          "Floor Name",
          "Parameter",
          "Area in sq.m",
          "No of units",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey === 3 || data.column.dataKey === 4) {
            data.cell.styles.halign = "center";
          }
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 55 },
        2: { cellWidth: 45 },
        4: { cellWidth: 25 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Details of Accessory buildings",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    if (end > this.pageHeight - 45) {
      this.doc.addPage();
      this.pageHeader();
      this.tableHeader(
        sno + " Details  of Accessory buildings",
        this.firstTableInPage
      );
      end = this.firstTableInPage + this.tableStart;
    }
    var totalsetBody = [];
    totalsetBody.push([
      "Total Accessory building Area in sq.m",
      netIncident,
      "",
    ]);
    // totalsetBody.push(["Total FSI ", Number(industryFsi + commercialFsi - netIncident).toFixed(2)])
    this.doc.autoTable({
      body: totalsetBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: end,
      margin: { horizontal: this.horizontal },
      columnStyles: {
        0: { cellWidth: 140 },
        2: { cellWidth: 25 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Details of Accessory buildings",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    return end;
  };
  FloorSpaceAreaBreakUp = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [["", "", "", ""]];

    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Total Gross Built Up Area",
          "Total Deduction",
          "Total Net FSI Area",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0 && data.column.dataKey != 1) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 5; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + 9,
      margin: { horizontal: 5, top: 59, bottom: 20 },
      headStyles: {
        valign: "middle",
        fontSize: 12,
        halign: "center",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 70 },
        1: { cellWidth: 60 },
      },
      bodyStyles: {
        fontSize: 10,
        // halign: "center",
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        valign: "middle",
      },
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + "  Floor Space Area Break Up",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  terraceTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = this.app.report.TerraceFloor.map((i) => {
      subsno = subsno + 1;
      let temp = [sno + "." + subsno, i.BuildingName, i.Area];
      let check = true;
      this.app.report.SolarWaterHeater.map((k) => {
        if (k.BuildingName === i.BuildingName) {
          check = false;
          temp.push(k.Area);
        }
      });
      if (check) {
        temp.push("N.A");
      }
      return temp;
    });
    this.doc.autoTable({
      head: [
        ["S.NO", "Building Name", "Terrace Area", "Solar Water Heater Area"],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 1) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            // console.log("hiii", data.cell.styles, data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 4; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              //data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + 9,
      margin: { horizontal: 5, top: 59, bottom: 20 },
      headStyles: {
        valign: "middle",
        fontSize: 12,
        halign: "center",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 100 },
        2: { cellWidth: 50 },
      },
      bodyStyles: {
        fontSize: 10,
        // halign: "center",
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        valign: "middle",
      },
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(sno + ". Details of Terrace", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  parkingTableFourWheeler = (setBody, start, total, headStr, stalls) => {
    let end = 0;
    let pg = 0;
    var setBody1 = [];
    let sub = 0;
    setBody.map((i) => {
      if (!i[3]) {
        sub = sub + 1;
        setBody1.push([sub, i[1], i[2], "-"]);
      }
    });
    setBody.map((i) => {
      if (i[3]) {
        sub = sub + 1;
        setBody1.push([sub, i[1], ["-", "-"], i[4]]);
      }
    });
    if (setBody1.length > 0) {
      this.doc.autoTable({
        head: [
          [
            "S.NO",
            "Dimension",
            "No of stalls",
            " No of stacked parking",
            "Result",
          ],
        ],
        body: setBody1,
        didDrawCell: function (data) {
          if (data.column.dataKey === 1 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 8,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                minCellHeight: 7,
                cellWidth: 40,
                fontSize: 10,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.2,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 1 && data.cell.section === "body") {
            //console.log("po",data.table.body!
            data.doc.autoTable({
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 40 },
                1: { cellWidth: 40 },
                2: { cellWidth: 40 },
              },
              theme: "grid",
              headStyles: {
                valign: "middle",
                fontSize: 7,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.2,
                lineColor: [128, 128, 128],
              },
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: 9,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.25,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                //console.log("hiii",data.cell.raw)
                if (data.cell.raw === "Non-Compliant") {
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided"]],
              startY: data.cell.y + 8,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                minCellHeight: 7,
                cellWidth: 30,
                fontSize: 10,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.2,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            //console.log("po",data.table.body!
            data.doc.autoTable({
              body: [[data.cell.raw[0], data.cell.raw[1]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
              },
              theme: "grid",
              headStyles: {
                valign: "middle",
                fontSize: 7,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.2,
                lineColor: [128, 128, 128],
              },
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: 9,
                halign: "center",
                fillColor: "white",
                textColor:
                  data.table.body[data.row.index].cells[data.row.index].styles
                    .textColor,
                lineWidth: 0.25,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
            });
          }
          if (data.column.dataKey === 3 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Provided"]],
              startY: data.cell.y + 8,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                minCellHeight: 7,
                cellWidth: 40,
                fontSize: 10,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.2,
                lineColor: [128, 128, 128],
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 4 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (
              data.column.dataKey === 1 &&
              data.cell.raw[2] === "Non-Compliant"
            ) {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 5; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            }
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + 9,
        margin: { horizontal: 5, top: 59, bottom: 20 },
        headStyles: {
          minCellHeight: 15,
          fontSize: 9,
          halign: "center",
          fillColor: "white",
          textColor: "black",
          lineWidth: 0.25,
          lineColor: [128, 128, 128],
        },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 120 },
          2: { cellWidth: 60 },
          3: { cellWidth: 40 },
        },
        bodyStyles: {
          fontSize: 10,
          halign: "center",
          textColor: "black",
          lineColor: [128, 128, 128],
          lineWidth: 0.25,
          valign: "middle",
        },
        didDrawPage: (data) => {
          if (pg > 0) {
            this.pageHeader();
            this.tableHeader(headStr, this.firstTableInPage);
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
      if (end > 190) {
        this.doc.addPage();
        this.pageHeader();
        this.tableHeader(headStr, this.firstTableInPage);
        end = 59;
      }
      var totalDisplay = "";
      if (total <= 9) {
        totalDisplay = " " + total.toString() + " ";
      } else {
        totalDisplay = total.toString();
      }
      this.doc.autoTable({
        body: [["Total Stalls", stalls.min, stalls.provided, stalls.result]],
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            data.cell.styles.fontStyle = "bold";
            if (data.cell.raw === "Non-Compliant") {
              data.row.cells[0].styles.textColor = "red";
              data.row.cells[1].styles.textColor = "red";
              data.row.cells[2].styles.textColor = "red";
              data.row.cells[3].styles.textColor = "red";
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: end,
        margin: { horizontal: 5 },
        columnStyles: {
          0: { cellWidth: 140 },
          1: { cellWidth: 30 },
          2: { cellWidth: 70 },
        },
        headStyles: {
          fillColor: "white",
          lineColor: [128, 128, 128],
          lineWidth: 0.1,
        },
        bodyStyles: {
          fontSize: 10,
          halign: "center",
          fillColor: "white",
          textColor: "black",
          lineWidth: 0.25,
          lineColor: [128, 128, 128],
        },
        didDrawPage: (data) => {
          //this.pageHeader(this.doc)
          //  console.log(headStr,"total",this.doc)
          //this.tableHeader(this.doc,headStr, this.firstTableInPage)
          end = data.cursor.y;
        },
      });
    } else {
      end = start + 9;
      if (end > 190) {
        this.doc.addPage();
        this.pageHeader();
        this.tableHeader(headStr, this.firstTableInPage);
        end = 59;
      }
      var totalDisplay = "";
      if (total <= 9) {
        totalDisplay = " " + total.toString() + " ";
      } else {
        totalDisplay = total.toString();
      }
      this.doc.autoTable({
        head: [["Parameter", "Minimum", "Provided", "Result"]],
        body: [["Total Stalls", stalls.min, 0, stalls.result]],
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            data.cell.styles.fontStyle = "bold";
            if (data.cell.raw === "Non-Compliant") {
              data.row.cells[0].styles.textColor = "red";
              data.row.cells[1].styles.textColor = "red";
              data.row.cells[2].styles.textColor = "red";
              data.row.cells[3].styles.textColor = "red";
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: end,
        margin: { horizontal: 5 },
        columnStyles: {
          0: { cellWidth: 155 },
          1: { cellWidth: 44 },
          3: { cellWidth: 44 },
        },
        headStyles: {
          fontSize: 12,
          halign: "center",
          fillColor: "white",
          textColor: "black",
          lineWidth: 0.25,
          lineColor: [128, 128, 128],
        },
        bodyStyles: {
          fontSize: 10,
          halign: "center",
          fillColor: "white",
          textColor: "black",
          lineWidth: 0.25,
          lineColor: [128, 128, 128],
        },
        didDrawPage: (data) => {
          //this.pageHeader(this.doc)
          //  console.log(headStr,"total",this.doc)
          //this.tableHeader(this.doc,headStr, this.firstTableInPage)
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  parkingTable = (setBody, start, total, headStr, stalls) => {
    let end = 0;
    let pg = 0;
    if (setBody.length > 0) {
      this.doc.autoTable({
        head: [["S.NO", "Dimension", "Number of stalls"]],
        body: setBody,
        didDrawCell: function (data) {
          if (
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 2 && data.cell.section === "head")
          ) {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 8,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                minCellHeight: 7,
                cellWidth: data.column.dataKey === 1 ? 45 : 44,
                fontSize: 10,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.2,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (
            (data.column.dataKey === 1 && data.cell.section === "body") ||
            (data.column.dataKey === 2 && data.cell.section === "body")
          ) {
            //console.log("po",data.table.body!
            data.doc.autoTable({
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: data.column.dataKey === 1 ? 45 : 44 },
                1: { cellWidth: data.column.dataKey === 1 ? 45 : 44 },
                2: { cellWidth: data.column.dataKey === 1 ? 45 : 44 },
              },
              theme: "grid",
              headStyles: {
                valign: "middle",
                fontSize: 7,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.2,
                lineColor: [128, 128, 128],
              },
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: 9,
                halign: "center",
                fillColor: "white",
                textColor:
                  data.table.body[data.row.index].cells[data.row.index].styles
                    .textColor,
                lineWidth: 0.25,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
            });
          }
        },
        didParseCell: function (data) {
          if (data.column.dataKey === 0 && data.cell.section === "head") {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (
              data.column.dataKey === 1 &&
              data.cell.raw[2] === "Non-Compliant"
            ) {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 3; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            }
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + 9,
        margin: { horizontal: 5, top: 59, bottom: 20 },
        headStyles: {
          minCellHeight: 15,
          fontSize: 12,
          halign: "center",
          fillColor: "white",
          textColor: "black",
          lineWidth: 0.25,
          lineColor: [128, 128, 128],
        },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 135 },
        },
        bodyStyles: {
          fontSize: 10,
          halign: "center",
          textColor: "black",
          lineColor: [128, 128, 128],
          lineWidth: 0.25,
          valign: "middle",
        },
        didDrawPage: (data) => {
          if (pg > 0) {
            this.pageHeader();
            this.tableHeader(headStr, this.firstTableInPage);
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
      if (end > 190) {
        this.doc.addPage();
        this.pageHeader();
        this.tableHeader(headStr, this.firstTableInPage);
        end = 59;
      }
      var totalDisplay = "";
      if (total <= 9) {
        totalDisplay = " " + total.toString() + " ";
      } else {
        totalDisplay = total.toString();
      }
      this.doc.autoTable({
        body: [["Total Stalls", stalls.min, stalls.provided, stalls.result]],
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            data.cell.styles.fontStyle = "bold";
            if (data.cell.raw === "Non-Compliant") {
              data.row.cells[0].styles.textColor = "red";
              data.row.cells[1].styles.textColor = "red";
              data.row.cells[2].styles.textColor = "red";
              data.row.cells[3].styles.textColor = "red";
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: end,
        margin: { horizontal: 5 },
        columnStyles: {
          0: { cellWidth: 155 },
          1: { cellWidth: 44 },
          3: { cellWidth: 44 },
        },
        headStyles: {
          fillColor: "white",
          lineColor: [128, 128, 128],
          lineWidth: 0.1,
        },
        bodyStyles: {
          fontSize: 10,
          halign: "center",
          fillColor: "white",
          textColor: "black",
          lineWidth: 0.25,
          lineColor: [128, 128, 128],
        },
        didDrawPage: (data) => {
          //this.pageHeader(this.doc)
          //  console.log(headStr,"total",this.doc)
          //this.tableHeader(this.doc,headStr, this.firstTableInPage)
          end = data.cursor.y;
        },
      });
    } else {
      end = start + 9;
      if (end > 190) {
        this.doc.addPage();
        this.pageHeader();
        this.tableHeader(headStr, this.firstTableInPage);
        end = 59;
      }
      var totalDisplay = "";
      if (total <= 9) {
        totalDisplay = " " + total.toString() + " ";
      } else {
        totalDisplay = total.toString();
      }
      this.doc.autoTable({
        head: [["Parameter", "Minimum", "Provided", "Result"]],
        body: [["Total Stalls", stalls.min, 0, stalls.result]],
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            data.cell.styles.fontStyle = "bold";
            if (data.cell.raw === "Non-Compliant") {
              data.row.cells[0].styles.textColor = "red";
              data.row.cells[1].styles.textColor = "red";
              data.row.cells[2].styles.textColor = "red";
              data.row.cells[3].styles.textColor = "red";
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: end,
        margin: { horizontal: 5 },
        columnStyles: {
          0: { cellWidth: 155 },
          1: { cellWidth: 44 },
          3: { cellWidth: 44 },
        },
        headStyles: {
          fontSize: 12,
          halign: "center",
          fillColor: "white",
          textColor: "black",
          lineWidth: 0.25,
          lineColor: [128, 128, 128],
        },
        bodyStyles: {
          fontSize: 10,
          halign: "center",
          fillColor: "white",
          textColor: "black",
          lineWidth: 0.25,
          lineColor: [128, 128, 128],
        },
        didDrawPage: (data) => {
          //this.pageHeader(this.doc)
          //  console.log(headStr,"total",this.doc)
          //this.tableHeader(this.doc,headStr, this.firstTableInPage)
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  visitorParkingTable = (start, headStr, area, headerName, bodyName) => {
    let end = 0;
    let pg = 0;
    // console.log(area,"area-point2")
    this.doc.autoTable({
      head: [["Parameter", headerName]],
      body: [
        [
          "Area",
          [
            area.fourWheelerParking,
            area.twoWheelerParking,
            area.bicycleParking,
          ],
        ],
      ],
      didDrawCell: function (data) {
        if (data.column.dataKey === 1 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Four Wheeler", "Two Wheeler", "Bicycle"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 50,
              fontSize: data.table.head[0].cells[0].styles.fontSize,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 1 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 50 },
              1: { cellWidth: 50 },
              2: { cellWidth: 50 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.column.dataKey === 0 && data.cell.section === "head") {
          data.cell.styles.valign = "middle";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        minCellHeight: 14,
        ...this.headerStyles.onlyCenter,
      },
      columnStyles: {
        1: { cellWidth: 150 },
      },
      bodyStyles: this.bodyStyles.centerAndMiddle,
      didDrawPage: (data) => {
        if (pg > 0) {
          this.pageHeader();
          this.tableHeader(headStr, this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    pg = 0;
    if (end > this.pageHeight - 45) {
      this.doc.addPage();
      this.pageHeader();
      this.tableHeader(headStr, this.firstTableInPage);
      end = this.tableStart + this.firstTableInPage;
    }

    this.doc.autoTable({
      body: [
        ["Total Area", area.totalArea],
        [bodyName, area.builtupArea],
        ["Parking Percentage", area.percentage],
      ],
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          //data.cell.styles.fontStyle = "bold";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: end,
      margin: { horizontal: this.horizontal },
      columnStyles: {
        1: { cellWidth: 150 },
      },
      bodyStyles: this.bodyStyles.onlyCenter,
      didDrawPage: (data) => {
        if (pg > 0) {
          this.pageHeader();
          this.tableHeader(headStr, this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    return end;
  };
  PantryAreaTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];
    // this.app.report.WC.map((i, index) => {
    //     subsno = subsno + 1
    //     setBody.push([i.BuildingName, i.FloorName, i.AreaRow, i.WidthRow])
    // })
    this.app.report.Pantry.map((i) => {
      if (i.AreaRow.provided !== "NO" && i.AreaRow.provided !== null) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.AreaRow.provided === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              existing[3] += 1;
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
              1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
            1,
          ]);
        }
      }
    });
    // console.log("wc - Area",setBody)
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.Pantry.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Area", "No of Pantry"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
            if (
              data.column.dataKey === 3 &&
              data.cell.section === "body" &&
              data.row.cells[2].raw[2] === "Non-Compliant"
            ) {
              data.cell.styles.textColor = "red";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
          3: { cellWidth: 25 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + ".  Details of Pantry - Area",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  PantryWidthTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];
    this.app.report.Pantry.map((i) => {
      if (i.WidthRow.provided !== "NO" && i.WidthRow.provided !== null) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.WidthRow.provided === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              existing[3] += 1;
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
              1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
            1,
          ]);
        }
      }
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.Pantry.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Width", "No of pantry"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
            if (
              data.column.dataKey === 3 &&
              data.cell.section === "body" &&
              data.row.cells[2].raw[2] === "Non-Compliant"
            ) {
              data.cell.styles.textColor = "red";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
          3: { cellWidth: 25 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Pantry - width",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  getParkingDetailsTsiic = (start, sno) => {
    var setBody = [];
    var end = start;
    let subSno = 1;
    var totalArea = 0;
    var twoWheelerParking = 0;
    var fourWheelerParking = 0;
    var bicycleParking = 0;
    this.app.report.Parking2Wheeler.map((i) => {
      if (i.provided !== null && i.provided !== "NO") {
        if (setBody.length === 0) {
          //console.log("area boy",i.Width,i.Length*i.Width);
          setBody.push([
            1,
            i.provided,
            1,
            Number(Number(i.Length * i.Width).toFixed(2)),
          ]);
        } else {
          let notExists = true;
          setBody.map((k) => {
            if (k[1] === i.provided) {
              notExists = false;
              k[2] = k[2] + 1;
              k[3] = k[3] + Number(Number(i.Length * i.Width).toFixed(2));
              k[3] = Number(Number(k[3]).toFixed(2));
            }
          });
          if (notExists)
            setBody.push([
              setBody.length + 1,
              i.provided,
              1,
              Number(Number(i.Length * i.Width).toFixed(2)),
            ]);
        }
      }
    });

    // if (this.app.report.Parking2Wheeler.length > 0) {
    //     start = this.checkSpace(start)
    //     this.tableHeader(sno + "." + subSno + " Parking Details of Two Wheeler stalls", start)
    //     let output = this.parkingTableTsiic(setBody, start, sno + "." + subSno + " Parking Details of Two Wheeler stalls")
    //     subSno = subSno + 1
    //     end = output.end;
    //     twoWheelerParking = Number(output.totalArea)
    // }

    setBody = [];
    this.app.report.ParkingBicycle.map((i) => {
      if (i.provided !== null && i.provided !== "NO") {
        if (setBody.length === 0) {
          //console.log("area boy",i.Width,i.Length*i.Width);
          setBody.push([
            1,
            i.provided,
            1,
            Number(Number(i.Length * i.Width).toFixed(2)),
          ]);
        } else {
          let notExists = true;
          setBody.map((k) => {
            if (k[1] === i.provided) {
              notExists = false;
              k[2] = k[2] + 1;
              k[3] = k[3] + Number(Number(i.Length * i.Width).toFixed(2));
              k[3] = Number(Number(k[3]).toFixed(2));
            }
          });
          if (notExists)
            setBody.push([
              setBody.length + 1,
              i.provided,
              1,
              Number(Number(i.Length * i.Width).toFixed(2)),
            ]);
        }
      }
    });
    // if (this.app.report.ParkingBicycle.length > 0) {
    //     start = this.checkSpace(end + 25)
    //     this.tableHeader(sno + "." + subSno + " Parking Details of Bicycle stalls", start)
    //     let output = this.parkingTableTsiic(setBody, start, sno + "." + subSno + " Parking Details of Bicycle stalls")
    //     subSno = subSno + 1
    //     end = output.end;
    //     bicycleParking = Number(output.totalArea)
    // }
    var setBody = [];
    this.app.report.Parking4Wheeler.map((i) => {
      if (i.provided !== null && i.provided !== "NO") {
        if (setBody.length === 0) {
          //console.log("area boy",i.Width,i.Length*i.Width);
          setBody.push([
            1,
            i.provided,
            1,
            Number(Number(i.Length * i.Width).toFixed(2)),
          ]);
        } else {
          let notExists = true;
          setBody.map((k) => {
            if (k[1] === i.provided) {
              notExists = false;
              k[2] = k[2] + 1;
              k[3] = k[3] + Number(Number(i.Length * i.Width).toFixed(2));
              k[3] = Number(Number(k[3]).toFixed(2));
            }
          });
          if (notExists)
            setBody.push([
              setBody.length + 1,
              i.provided,
              1,
              Number(Number(i.Length * i.Width).toFixed(2)),
            ]);
        }
      }
    });
    // if (this.app.report.Parking4Wheeler.length > 0) {
    //     start = this.checkSpace(end + 25)
    //     this.tableHeader(sno + "." + subSno + " Parking Details of four Wheeler stalls", start)
    //     let output = this.parkingTableTsiic(setBody, start, sno + "." + subSno + " Parking Details of four Wheeler stalls")
    //     subSno = subSno + 1
    //     end = output.end;
    //     fourWheelerParking = Number(output.totalArea)
    // }

    totalArea =
      Number(bicycleParking) +
      Number(twoWheelerParking) +
      Number(fourWheelerParking);
    totalArea = Number(Number(totalArea).toFixed(2));
    // var builtupArea = Number(Number(this.app.report.AreaStatement[0].TotalProposedBuiltupArea).toFixed(2))
    // var percentage = Number(Number((totalArea / builtupArea) * 100).toFixed(2))
    // start = this.checkSpace(end + 25)
    // this.tableHeader(sno + "." + subSno + " Covered Parking", start)
    // end = this.visitorParkingTable(start, sno + "." + subSno + " Covered Parking", { totalArea, percentage, builtupArea, bicycleParking, twoWheelerParking, fourWheelerParking }, "Parking Area", "Builtup Area")
    // subSno = subSno + 1

    let totalAreaVisitors = 0;
    twoWheelerParking = 0;
    fourWheelerParking = 0;
    bicycleParking = 0;

    this.app.report.VisitorsParking.BiCycle.map((i) => {
      bicycleParking += i.Length * i.Width;
    });
    this.app.report.VisitorsParking.TwoWheeler.map((i) => {
      twoWheelerParking += i.Length * i.Width;
    });
    this.app.report.VisitorsParking.FourWheeler.map((i) => {
      fourWheelerParking += i.Length * i.Width;
    });
    totalAreaVisitors =
      Number(bicycleParking) +
      Number(twoWheelerParking) +
      Number(fourWheelerParking);
    totalAreaVisitors = Number(Number(totalAreaVisitors).toFixed(2));
    // percentage = Number(Number((totalAreaVisitors / totalArea) * 100).toFixed(2))
    // start = this.checkSpace(end + 25)
    // this.tableHeader(sno + "." + subSno + " Visitors Parking", start)
    // end = this.visitorParkingTable(start, sno + "." + subSno + " Visitors Parking", { totalArea: totalAreaVisitors,percentage:percentage, builtupArea: totalArea, bicycleParking, twoWheelerParking, fourWheelerParking }, "Visitors Parking Area", "Parking Area")
    // subSno = subSno + 1

    /* if(this.app.report.VisitorsParkingBasement&&this.app.report.VisitorsParkingBasement.length>0)
        {
        start = this.checkSpace(end + this.tableStart)
        this.tableHeader(sno +".  Parking Area Details", start)
        end = this.ParkingAreaTable(start, sno)
        subSno = subSno + 1
        }

        if(this.app.report.AccessoryBuildingArea&&this.app.report.AccessoryBuildingArea.length>0)
        {

            start = this.checkSpace(end + this.tableStart)
            this.tableHeader(sno +". Accessory Building Area Table ", start)
            end = this.AccessoryBuildingAreaTable(start, sno)
            subSno = subSno + 1
        }*/
    return { end, subSno };
  };

  AccessoryBuildingAreaTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];

    this.app.report.AccessoryBuildingArea.map((i) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.FloorArea,
        [i.maxArea, i.AccessoryBuildingArea, i.result],
      ]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Floor Area",
          "Accessory Building Area (Sq.m)",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Maximum (10%)", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 25,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 1,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          //console.log("po",data.table.body!

          data.doc.autoTable({
            body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 25 },
              1: { cellWidth: 25 },
              2: { cellWidth: 25 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 1,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body") {
                if (
                  data.column.dataKey !== 1 &&
                  data.column.dataKey !== 2 &&
                  data.column.dataKey !== 0
                ) {
                  //console.log("jgjgjg",data.cell.styles)
                  data.cell.styles.halign = "center";
                }
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "head" && data.column.dataKey !== 3) {
          data.cell.styles.valign = "middle";
        }
        if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
          //console.log("jgjgjg",data.cell.styles)
          data.cell.styles.halign = "center";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (
            data.cell.raw[data.column.dataKey] &&
            data.cell.raw[data.column.dataKey] === "Non-Compliant"
          ) {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 3; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 14,
      },
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 40 },
        1: { cellWidth: 45 },
        3: { cellWidth: 75 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + "  Accessory Building Area Table",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  parkingTableTsiic = (setBody, start, headStr) => {
    let end = 0;
    let pg = 0;
    var totalArea = 0;
    if (setBody.length > 0) {
      this.doc.autoTable({
        head: [
          [
            "S.NO",
            "Parking sizes Provided",
            "Number of stalls Provided",
            "Parking Area",
          ],
        ],
        body: setBody,
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.centerAndMiddle,
        },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 90 },
          2: { cellWidth: 50 },
        },
        bodyStyles: this.bodyStyles.centerAndMiddle,
        didDrawPage: (data) => {
          if (pg > 0) {
            this.pageHeader();
            this.tableHeader(headStr, this.firstTableInPage);
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
      if (end > this.pageHeight - 45) {
        this.doc.addPage();
        this.pageHeader();
        this.tableHeader(headStr, this.firstTableInPage);
        end = this.tableStart + this.firstTableInPage;
      }

      setBody.map((i) => {
        //console.log("area boy ",i)
        totalArea += i[3];
      });
      totalArea = Number(totalArea).toFixed(2);
      this.doc.autoTable({
        body: [["Total Area", totalArea]],
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            data.cell.styles.fontStyle = "bold";
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: end,
        margin: { horizontal: 5 },
        columnStyles: {
          0: { cellWidth: 160 },
        },
        headStyles: {
          fillColor: "white",
          lineColor: [128, 128, 128],
          lineWidth: 0.1,
        },
        bodyStyles: this.bodyStyles.onlyCenter,
        didDrawPage: (data) => {
          end = data.cursor.y;
        },
      });
    } else {
      this.tableHeader(headStr, start);

      this.doc.autoTable({
        body: [["Total Area", totalArea]],
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            data.cell.styles.fontStyle = "bold";
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + this.tableStart,
        margin: { horizontal: this.horizontal },
        columnStyles: {
          0: { cellWidth: 160 },
        },
        headStyles: {
          fillColor: "white",
          lineColor: [128, 128, 128],
          lineWidth: 0.1,
        },
        bodyStyles: this.bodyStyles.onlyCenter,
        didDrawPage: (data) => {
          end = data.cursor.y;
        },
      });
    }

    return { end, totalArea };
  };
  balconyTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];
    this.app.report.Balcony.map((i, index) => {
      let isadd = true;
      setBody.map((row, ind) => {
        if (
          isadd &&
          row[0] === i.BuildingName &&
          row[1] === i.FloorName &&
          row[2][1] === i.Width
        ) {
          isadd = false;
          row[3] = Number(row[3]) + 1;
        }
      });

      isadd &&
        setBody.push([
          i.BuildingName,
          i.FloorName,
          [i.max, i.Width, i.result],
          1,
        ]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.Balcony.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Projection", "No of units"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Maximum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                cellWidth: 30,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              //  head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (data.column.dataKey !== 2 && data.cell.section === "head") {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey === 2 || data.column.dataKey === 3) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 3; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        theme: "grid",
        startY: start + this.tableStart,
        rowPageBreak: "avoid",
        tableWidth: "wrap",
        margin: this.margin,
        headStyles: {
          ...this.headerStyles.onlyCenter,
          minCellHeight: 14,
        },
        columnStyles: {
          // 0: { cellWidth: 20 },
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
          3: { cellWidth: 43 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(sno + ". Balcony Details", this.firstTableInPage);
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  balconyAreaTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [];
    this.app.report.BalconyArea.map((i) => {
      let isadd = true;
      setBody.map((row, ind) => {
        if (
          isadd &&
          row[0] === i.BuildingName &&
          row[1] === i.FloorName &&
          row[4] === i.TotalArea
        ) {
          isadd = false;
          row[7] = Number(row[7]) + 1;
        }
      });

      isadd &&
        setBody.push([
          i.BuildingName,
          i.FloorName,
          i.RespFloorArea,
          Number(i.max).toFixed(2),
          Number(i.TotalArea).toFixed(2),
          i.result,
          i.no_of_units,
        ]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Floor area",
          "Maximum (15% Floor Area)",
          "Area Provided",
          "Result",
          "No of units",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            // console.log("hiii", data.cell.styles, data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              //data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 40 },
        4: { cellWidth: 25 },
        6: { cellWidth: 30 },
        7: { cellWidth: 20 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + ". Balcony FSI Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  tenementTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];

    this.app.report.Tenet.map((i, index) => {
      let isadd = true;
      setBody.map((row, ind) => {
        if (
          isadd &&
          row[0] === i.BuildingName &&
          row[1] === i.FloorName &&
          row[2] === i.ObjectClassification &&
          row[3] === i.Area
        ) {
          isadd = false;
          row[4] += 1;
        }
      });

      isadd &&
        setBody.push([
          i.BuildingName,
          i.FloorName,
          i.ObjectClassification,
          i.Area,
          1,
        ]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (setBody.length > 0)
      this.doc.autoTable({
        head: [
          ["Building Name", "Floor Name", "Unit Name", "Area", "No of Units"],
        ],
        body: setBody,

        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 0 && data.column.dataKey !== 1) {
              //console.log("jgjgjg",data.cell.styles)
              data.cell.styles.halign = "center";
            }

            data.cell.styles.textColor = "black";
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: this.headerStyles.centerAndMiddle,
        columnStyles: {
          0: { cellWidth: 50 },
          1: { cellWidth: 55 },
          2: { cellWidth: 45 },
          4: { cellWidth: 25 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          if (pg > 0) {
            this.tableHeader(
              sno + ". Details of Tenement",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    else
      end = this.notProviedTable(
        start,
        "Tenement Details not provied",
        sno + ". Details of Tenement"
      );

    return end;
  };
  kitchenTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];

    this.app.report.KitchenDetails.map((i, index) => {
      let isadd = true;
      setBody.map((row, ind) => {
        if (
          isadd &&
          row[0] === i.BuildingName &&
          row[1] === i.FloorName &&
          row[2] === i.ObjectClassification &&
          row[3].Area === i.AreaRow.Area &&
          row[4].Value === i.HeightRow.Value
        ) {
          isadd = false;
          row[5] += 1;
        }
      });

      isadd &&
        setBody.push([
          i.BuildingName,
          i.FloorName,
          i.ObjectClassification,
          i.AreaRow,
          i.HeightRow,
          1,
        ]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (setBody.length > 0)
      this.doc.autoTable({
        head: [
          [
            "Building Name",
            "Floor Name",
            "Unit Name",
            "Area",
            "Height",
            "No of units",
          ],
        ],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 3 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 14,
                fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 4 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Maximum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 14,
                fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 3 && data.cell.section === "body") {
            data.doc.autoTable({
              body: [
                [
                  data.cell.raw.min,
                  data.column.dataKey === 3
                    ? data.cell.raw.Area
                    : data.cell.raw.Value,
                  data.cell.raw.result,
                ],
              ],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 14 },
                1: { cellWidth: 14 },
                2: { cellWidth: 14 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                //console.log("hiii",data.cell.raw)
                if (data.cell.raw === "Non-Compliant") {
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              },
            });
          }
          if (data.column.dataKey === 4 && data.cell.section === "body") {
            //console.log("po",data.cell.y)
            data.doc.autoTable({
              body: [
                [
                  data.cell.raw.min,
                  data.cell.raw.max,
                  data.column.dataKey === 3
                    ? data.cell.raw.Area
                    : data.cell.raw.Value,
                  data.cell.raw.result,
                ],
              ],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 14 },
                1: { cellWidth: 14 },
                2: { cellWidth: 14 },
                3: { cellWidth: 14 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                //console.log("hiii",data.cell.raw)
                if (data.cell.raw === "Non-Compliant") {
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            data.column.dataKey !== 3 &&
            data.column.dataKey !== 4 &&
            data.cell.section === "head"
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 0 && data.column.dataKey !== 1) {
              //console.log("jgjgjg",data.cell.styles)
              data.cell.styles.halign = "center";
            }

            if (
              data.column.dataKey !== 0 &&
              data.column.dataKey !== 1 &&
              data.column.dataKey !== 2 &&
              data.column.dataKey !== 5
            ) {
              if (data.cell.raw.result === "Non-Compliant") {
                //console.log("hiii",data.cell.styles,data)

                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 2; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            }

            data.cell.styles.textColor = "black";
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + this.tableStart,
        columnWidth: "wrap",
        margin: this.margin,
        headStyles: {
          ...this.headerStyles.onlyCenter,
          minCellHeight: 14,
          fontSize: 8,
        },
        columnStyles: {
          0: { cellWidth: 25 },
          3: { cellWidth: 42 },
          4: { cellWidth: 56 },
        },
        bodyStyles: { ...this.bodyStyles.noAlign, fontSize: 7 },
        didDrawPage: (data) => {
          this.pageHeader();
          if (pg > 0) {
            this.tableHeader(
              sno + ".1 Kitchen Room Details",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    else
      end = this.notProviedTable(
        start,
        "Kitchen Room details not provied",
        sno + ".1 Kitchen Room Details"
      );

    return end;
  };
  HabitableRoomTable = (start, sno) => {
    console.log(this.app.useOfTheBuildings);

    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    /*if(this.isResidential(this.app.useOfTheBuildings))
        {
            console.log("residential");
            this.app.report.HabitableDetails.map((i, index) => {
                
                let isadd = true
                setBody.map((row,ind)=>{
                    if(isadd&&row[0]===i.BuildingName&&
                        row[1]===i.FloorName&&
                        row[2]===i.doubt&&
                        row[3]===i.ObjectClassification&&
                        row[4].Area===i.AreaRow.Area)
                        {
                            isadd = false
                            row[5]= Number(row[5])+1;
                       
                        }
                })
            
                isadd&&setBody.push([i.BuildingName, i.FloorName,i.FloorName,i.ObjectClassification,i.AreaRow,1])
                
            })
            setBody = this.mergeCellsUtil(start,setBody,0)
            if(setBody.length>0)
                this.doc.autoTable({
                    head: [["Building Name", "Floor Name","Room Name","Unit Name" ,"Area","No of rooms"]],
                    body: setBody,
                    didDrawCell: function (data) {
                        if (data.column.dataKey === 4 && data.cell.section === 'head') {
                            data.doc.autoTable({
                                head: [["Minimum", "Maximum", "Provided", "Result"]],
                                startY: data.cell.y + 7,
                                margin: { left: data.cell.x },
                                tableWidth: 'wrap',
                                theme: "grid",
                                headStyles: {
                                    valign: 'middle',
                                    cellWidth: 14,
                                    fontSize: data.table.head[0].cells[0].styles.fontSize-3,
                                    minCellHeight:data.table.head[0].cells[0].styles.minCellHeight/2,
                                    halign: 'center',
                                    fillColor: "white",
                                    textColor: "black",
                                    lineWidth: 0.2,
                                    lineColor: [128, 128, 128]
                                },
        
                            });
                        }
                        if (data.column.dataKey === 4 && data.cell.section === 'body') {
                            //console.log("po",data.cell.y)
                            data.doc.autoTable({
                                body: [[data.cell.raw.min,
                                    data.cell.raw.max, 
                                    data.column.dataKey === 3 ? 
                                    data.cell.raw.Area : data.cell.raw.Value , 
                                data.cell.raw.result]],
                                startY: data.cell.y,
                                margin: { left: data.cell.x },
                                tableWidth: 'wrap',
                                columnStyles: {
                                    0: { cellWidth: 14 },
                                    1: { cellWidth: 14 },
                                    2: { cellWidth: 14 },
                                    3: { cellWidth: 14 },
                                },
                                theme: "grid",
                                bodyStyles: {
                                    minCellHeight: data.table.body[data.row.index].height,
                                    fontSize: data.table.body[0].cells[0].styles.fontSize-4,
                                    halign: "center",
                                    fillColor: "white",
                                    textColor: "black",
                                    lineWidth: 0.1,
                                    lineColor: [128, 128, 128],
                                    // lineColor: data.table.body[0].cells[0].styles.lineColor,
                                    // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                                    valign: "middle"
                                },
                                didParseCell: function (data) {
                                    //console.log("hiii",data.cell.raw)
                                    if (data.cell.raw === "Non-Compliant") {
                                        data.cell.styles.textColor = "red";
                                        // data.cell.styles.lineColor = "red";
                                        // data.cell.styles.lineWidth = 0.75
                                        for (let i = 0; i < 3; i++) {
                                            if (data.row.cells[i])
data.row.cells[i].styles.textColor = "red";
                                            // data.row.cells[i].styles.lineColor = "red";
                                            // data.row.cells[i].styles.lineWidth = 0.75
                                        }
                                    }
                                    else {
                                        data.cell.styles.textColor = "black";
                                    }
        
                                }
        
                            });
                        }
                    },
                    didParseCell: function (data) {
                        if (data.column.dataKey !== 4 && data.cell.section === 'head') {
                            data.cell.styles.valign = "middle"
                        }
                        if (data.section === 'body' && data.cell.raw !== "") {

        
                            if (data.column.dataKey !== 0 && data.column.dataKey !== 1) {
                                //console.log("jgjgjg",data.cell.styles)
                                data.cell.styles.halign = "center"
                            }
        
                            if (data.column.dataKey !== 0 && data.column.dataKey !== 1 && data.column.dataKey !== 2&& data.column.dataKey !== 5) {
                                if (data.cell.raw.result === "Non-Compliant") { //console.log("hiii",data.cell.styles,data)
        
                                    data.cell.styles.textColor = "red";
                                    // data.cell.styles.lineColor = "red";
                                    // data.cell.styles.lineWidth = 0.75
                                    for (let i = 0; i < 2; i++) {
                                        if (data.row.cells[i])
data.row.cells[i].styles.textColor = "red";
                                        // data.row.cells[i].styles.lineColor = "red";
                                        // data.row.cells[i].styles.lineWidth = 0.75
                                    }
        
                                }
                                else {
        
                                    data.cell.styles.textColor = "black"
                                }
                            }
        
                            data.cell.styles.textColor = "black"
                        }
                    },
                    rowPageBreak: 'avoid',
                    theme: "grid",
                    startY: start + this.tableStart,
                    margin:this.margin,
                    headStyles:{...this.headerStyles.onlyCenter,
                        minCellHeight: 14,
                        fontSize: 8
                    },
                    columnStyles: {
                        0: { cellWidth: 25 },
                        3: { cellWidth: 56 },
                        4: { cellWidth: 56 },
                        5: { cellWidth: 15 }
                    },
                    bodyStyles: {...this.bodyStyles.noAlign,fontSize:7},
                    didDrawPage: (data) => {
                        this.pageHeader()
                        if (pg > 0) {
                            this.tableHeader(sno + ". Habitation Room Details", this.firstTableInPage)
                        }
                        pg = pg + 1
                        end = data.cursor.y
                    }
                   })
        
        else
            end = this.notProviedTable(start,"Habitable room details not provided",sno + ". Habitation Room Details")
        start = this.checkSpace(end + this.tableStart)
        this.tableHeader(sno +".1 Kitchen Room details", start)
        end = this.kitchenTable(start, sno+".1")
    
       
        }
        else
        {*/
    console.log("efsdfdf");

    this.app.report.HabitableDetails.map((i, index) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.ObjectClassification == null ? "-" : i.ObjectClassification,
        i.AreaRow,
      ]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Building Name", "Floor Name", "Room Name", "Area"]],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 20,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          //console.log("po",data.cell.y)
          data.doc.autoTable({
            body: [
              [data.cell.raw.min, data.cell.raw.Area, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
              2: { cellWidth: 20 },
            },
            theme: "grid",
            bodyStyles: {
              // minCellHeight: data.table.body[data.row.index].height,
              // minCellHeight:data.table.head[0].cells[0].styles.minCellHeight/2,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 1,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }

          if (data.column.dataKey !== 0 && data.column.dataKey !== 1) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }

          if (
            data.column.dataKey !== 0 &&
            data.column.dataKey !== 1 &&
            data.column.dataKey !== 4
          ) {
            if (data.cell.raw.result === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)

              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }

          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 30 },
        2: { cellWidth: 80 },
        3: { cellWidth: 60 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + ". Habitation Room Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    /* }*/
    return end;
  };

  HabitableRoomTable1 = (start, sno) => {
    console.log(this.app.useOfTheBuildings);

    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    console.log("efsdfdf");

    this.app.report.HabitableDetails.map((i, index) => {
      if (i.Ventilation.Area != 0) {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          i.ObjectClassification == null ? "-" : i.ObjectClassification,
          i.Ventilation,
        ]);
      }
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        ["Building Name", "Floor Name", "Room Name", "Ventilation (1/10th)"],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 20,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          //console.log("po",data.cell.y)
          data.doc.autoTable({
            body: [
              [data.cell.raw.min, data.cell.raw.Area, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
              2: { cellWidth: 20 },
            },
            theme: "grid",
            bodyStyles: {
              // minCellHeight: data.table.body[data.row.index].height,
              // minCellHeight:data.table.head[0].cells[0].styles.minCellHeight/2,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 1,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }

          if (data.column.dataKey !== 0 && data.column.dataKey !== 1) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }

          if (
            data.column.dataKey !== 0 &&
            data.column.dataKey !== 1 &&
            data.column.dataKey !== 4
          ) {
            if (data.cell.raw.result === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)

              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }

          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 30 },
        2: { cellWidth: 80 },
        3: { cellWidth: 60 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + ". Habitation Room Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    /* }*/
    return end;
  };

  DoubleHeightterraceTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = this.app.report.DoubleHeightTerraces.map((i) => {
      subsno = subsno + 1;
      return [
        i.BuildingName,
        i.FloorName,
        i.RespFloorArea,
        i.max,
        i.Area,
        i.excess,
        i.result,
      ];
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name ",
          "Floor area",
          "Maximum (20% Floor Area)",
          "Area Provided",
          "Excess Area",
          "Result",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            // console.log("hiii", data.cell.styles, data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              //data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 45 },
        4: { cellWidth: 30 },
        6: { cellWidth: 30 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + ".  Double Height Terrace FSI Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  detailTable = (start, sno) => {
    let end = 0,
      pg = 0;
    var setBody = [],
      subsno = 0;

    this.app.report.SepticTank.map((i, index) => {
      /* subsno = subsno + 1
            if (i.Width !== "NO" && i.Width !== null) {
                setBody.push(["Septic tank",i.Width])
            }*/
      subsno = subsno + 1;
      setBody.push(["STP", i.Value]);
    });
    this.app.report.RainWaterHarvesting.map((i) => {
      subsno = subsno + 1;
      setBody.push(["Rain water Harvesting", i.Value]);
    });

    if ("RainWaterHarvestingPits" in this.app.report) {
      subsno = subsno + 1;
      setBody.push([
        "Rain Water Harvesting Pits",
        this.app.report.RainWaterHarvestingPits,
      ]);
    }

    if ("SolarWaterHeaterTotalArea" in this.app.report) {
      subsno = subsno + 1;
      setBody.push([
        "Solar Water Heater",
        this.app.report.SolarWaterHeaterTotalArea,
      ]);
    }

    if (setBody.length > 0) {
      this.doc.autoTable({
        head: [["Parameters", "Provided"]],
        body: setBody,
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 0) {
              //console.log("jgjgjg",data.cell.styles)
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 2; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: this.headerStyles.centerAndMiddle,
        columnStyles: {
          0: { cellWidth: 100 },
        },
        bodyStyles: this.bodyStyles.onlyMiddle,
        didDrawPage: (data) => {
          this.pageHeader();
          if (pg > 0) {
            this.tableHeader(
              sno + ". Other Requirements",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    } else
      end = this.notProviedTable(
        start,
        "Rain water harvesting / septic tank detail not provided",
        sno + ". Other Requirements"
      );
    return end;
  };
  typicalFloorInformationTable = (start, sno) => {
    let end = 0,
      pg = 0;
    var setBody = [],
      subsno = 0;

    Object.keys(this.app.report.TypicalFloors).map((i) => {
      let BuildingName = i;
      let typicalFloorType = "";
      let floors = "";
      Object.keys(this.app.report.TypicalFloors[i]).map((j) => {
        typicalFloorType = j;
        if (this.app.report.TypicalFloors[i][j].length > 1) {
          floors = this.app.report.TypicalFloors[i][j].join(",");
        } else floors = this.app.report.TypicalFloors[i][j][0];
        setBody.push([BuildingName, typicalFloorType, floors]);
      });
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Building Name", "Typical Floor Type", "Floors"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 50 },
        2: { cellWidth: 90 },
      },
      bodyStyles: { ...this.bodyStyles.noAlign, halign: "left" },
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + ". Typical Floor Information Table ",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    return end;
  };
  getChowkTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = [];
    this.app.report.InteriorChowk.map((i) => {
      if (i.Length !== null && i.Width !== null) {
        subsno = subsno + 1;
        let temp = [
          i.BuildingName,
          i.height,
          "Interior Chowk",
          "-",
          [i.minLength, i.Length],
          [i.minWidth, i.Width],
          i.result,
        ];
        setBody.push(temp);
      }
    });

    /*  this.app.report.ExteriorChowk.map(i => {
            if (i.Length !== null && i.Width !== null) {
                subsno = subsno + 1
                let temp = [ i.BuildingName, i.height,"Exterior Chowk", "-", [i.minLength, i.Length], [i.minWidth, i.Width], i.result]
                setBody.push(temp)
            }
        })*/
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Building Height",
          "Parameter",
          "Floor Name",
          "Length",
          "Width",
          "Result",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (
          (data.column.dataKey === 4 && data.cell.section === "head") ||
          (data.column.dataKey === 5 && data.cell.section === "head")
        ) {
          data.doc.autoTable({
            head: [["Minimum", "Provided"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 20,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 1,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (
          (data.column.dataKey === 4 && data.cell.section === "body") ||
          (data.column.dataKey === 5 && data.cell.section === "body")
        ) {
          //console.log("po",data.table.body)
          data.doc.autoTable({
            // head: [["Minimum", "Provided"]],
            body: [[data.cell.raw[0], data.cell.raw[1]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 1,
              halign: "center",
              fillColor: "white",
              textColor:
                data.table.body[data.row.index].cells[0].styles.textColor,
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            // didParseCell: function (data) {
            //     if (data.cell.raw === "Non-Compliant") {
            //         data.cell.styles.textColor = "red";
            //         for (let i = 0; i < 2; i++) {
            //             if (data.row.cells[i])
            //         }
            //     }
            //     else {
            //         data.cell.styles.textColor = "black";
            //     }
            // }
          });
        }
      },
      didParseCell: function (data) {
        if (
          (data.column.dataKey === 0 && data.cell.section === "head") ||
          (data.column.dataKey === 1 && data.cell.section === "head") ||
          (data.column.dataKey === 2 && data.cell.section === "head") ||
          (data.column.dataKey === 3 && data.cell.section === "head") ||
          (data.column.dataKey === 6 && data.cell.section === "head")
        ) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            // data.row.cells[0].styles.lineColor = "red";
            // data.row.cells[0].styles.lineWidth = 0.75
            data.cell.styles.textColor = "red";
            data.row.cells[0].styles.textColor = "red";
            data.row.cells[1].styles.textColor = "red";
            data.row.cells[2].styles.textColor = "red";
            data.row.cells[3].styles.textColor = "red";
            data.row.cells[4].styles.textColor = "red";
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        // valign: 'middle',
        minCellHeight: 14,
        ...this.headerStyles.onlyCenter,
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 35 },
        4: { cellWidth: 40 },
        5: { cellWidth: 40 },
        6: { cellWidth: 30 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Interior Chowk/ Exterior Chowk Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  BathroomAreaTable = (start, sno) => {
    let end = start,
      pg = 0;
    let setBody = [];
    // this.app.report.WC.map((i, index) => {
    //     subsno = subsno + 1
    //     setBody.push([i.BuildingName, i.FloorName, i.AreaRow, i.WidthRow])
    // })
    this.app.report.BathRoom.map((i) => {
      if (i.AreaRow.provided !== "NO" && i.AreaRow.provided !== null) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.AreaRow.provided === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              existing[3] += 1;
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
              1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
            1,
          ]);
        }
      }
    });
    // console.log("wc - Area",setBody)
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (setBody.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Area", "No of Bathroom"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                cellWidth: 35,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 35 },
                1: { cellWidth: 35 },
                2: { cellWidth: 35 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 65 },
          2: { cellWidth: 105 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Bathroom - Area",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  BathroomWidthTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];
    this.app.report.BathRoom.map((i) => {
      if (i.WidthRow.provided !== "NO" && i.WidthRow.provided !== null) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.WidthRow.provided === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              existing[3] += 1;
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
              1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
            1,
          ]);
        }
      }
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (setBody.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Width", "No of BathRoom"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 35,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 35 },
                1: { cellWidth: 35 },
                2: { cellWidth: 35 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                ffontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 65 },
          2: { cellWidth: 105 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of BathRoom - width",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  BathroomHeightTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = this.app.report.BathRoomWCToilet_Height.map((i) => {
      return [i.BuildingName, i.min, i.Value, i.result];
    });
    this.doc.autoTable({
      head: [["Building Name", "Minimum", "Height", "Result"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            // console.log("hiii", data.cell.styles, data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 3; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              //data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        3: { cellWidth: 40 },
      },
      bodyStyles: this.bodyStyles.centerAndMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + ". Details of Bathroom Height",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  WcAreaTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];

    this.app.report.WC.map((i) => {
      if (
        !this.checkNOandNA(i.AreaRow.provided) &&
        i.AreaRow.provided !== null
      ) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.AreaRow.provided === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              if (!this.checkNOandNA(i.AreaRow.provided)) {
                existing[3] += 1;
              }
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
              this.checkNOandNA(i.AreaRow.provided) ? 0 : 1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
            this.checkNOandNA(i.AreaRow.provided) ? 0 : 1,
          ]);
        }
      }
    });
    if (setBody.length === 0) {
      let flag = true;
      this.app.report.WC.map((i) => {
        if (this.checkNOandNA(i.AreaRow.provided) && flag) {
          flag = false;
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
            this.checkNOandNA(i.AreaRow.provided) ? 0 : 1,
          ]);
        }
      });
    }
    setBody = this.mergeCellsUtil(start, setBody, 0);
    // console.log("wc - Area",setBody)
    if (this.app.report.WC.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Area", "No of water closet"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
            if (
              data.column.dataKey === 3 &&
              data.cell.section === "body" &&
              data.row.cells[2].raw[2] === "Non-Compliant"
            ) {
              data.cell.styles.textColor = "red";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
          3: { cellWidth: 25 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Water Closet - Area",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  WcWidthTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];
    this.app.report.WC.map((i) => {
      if (
        !this.checkNOandNA(i.WidthRow.provided) &&
        i.WidthRow.provided !== null
      ) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.WidthRow.provided === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              if (!this.checkNOandNA(i.WidthRow.provided)) {
                existing[3] += 1;
              }
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
              this.checkNOandNA(i.WidthRow.provided) ? 0 : 1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
            this.checkNOandNA(i.WidthRow.provided) ? 0 : 1,
          ]);
        }
      }
    });
    if (setBody.length === 0) {
      let flag = true;
      this.app.report.WC.map((i) => {
        if (this.checkNOandNA(i.WidthRow.provided) && flag) {
          flag = false;
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
            this.checkNOandNA(i.WidthRow.provided) ? 0 : 1,
          ]);
        }
      });
    }
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.WC.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Width", "No of water closet"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
            if (
              data.column.dataKey === 3 &&
              data.cell.section === "body" &&
              data.row.cells[2].raw[2] === "Non-Compliant"
            ) {
              data.cell.styles.textColor = "red";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
          3: { cellWidth: 25 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Water Closet - width",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  wcCountTable = (start, sno) => {
    let end = 0,
      pg = 0,
      cnt = 1,
      subsno = 0;
    var setBody = this.app.report["WC Count"].map((i, index) => {
      subsno = subsno + 1;
      return [sno + "." + subsno, i.Male, i.Female];
    });
    this.doc.autoTable({
      head: [["S.NO", "Male", "Female"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          // if(data.column.dataKey != 1)
          // {
          //     data.cell.styles.halign = "center"
          // }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 5; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 20 },
      },
      bodyStyles: this.bodyStyles.centerAndMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + "Details of Water Closet Count",
            this.firstTableInPage
          );
        }
        pg = pg + 1;

        end = data.cursor.y;
      },
    });
    return end;
  };
  ToiletAreaTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];

    this.app.report.Toilet.map((i) => {
      if (
        !this.checkNOandNA(i.AreaRow.provided) &&
        i.AreaRow.provided !== null
      ) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.AreaRow.provided === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              if (!this.checkNOandNA(i.AreaRow.provided)) {
                existing[3] += 1;
              }
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
              this.checkNOandNA(i.AreaRow.provided) ? 0 : 1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
            this.checkNOandNA(i.AreaRow.provided) ? 0 : 1,
          ]);
        }
      }
    });
    if (setBody.length === 0) {
      let flag = true;
      this.app.report.Toilet.map((i) => {
        if (this.checkNOandNA(i.AreaRow.provided) && flag) {
          flag = false;
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
            this.checkNOandNA(i.AreaRow.provided) ? 0 : 1,
          ]);
        }
      });
    }
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.Toilet.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Area", "No of Toilet"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            // console.log(data.cell.raw)
            if (data.cell.raw[2] === "Non-Compliant") {
              // console.log("hiii", data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
            if (
              data.column.dataKey === 3 &&
              data.cell.section === "body" &&
              data.row.cells[2].raw[2] === "Non-Compliant"
            ) {
              data.cell.styles.textColor = "red";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
          3: { cellWidth: 25 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Toilet - Area",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  ToiletWidthTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];
    this.app.report.Toilet.map((i) => {
      if (
        !this.checkNOandNA(i.WidthRow.provided) &&
        i.WidthRow.provided !== null
      ) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.WidthRow.provided === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              existing[3] += 1;
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
              1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
            1,
          ]);
        }
      }
    });
    if (setBody.length === 0) {
      let flag = true;
      this.app.report.Toilet.map((i) => {
        if (this.checkNOandNA(i.WidthRow.provided) && flag) {
          flag = false;
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
            this.checkNOandNA(i.WidthRow.provided) ? 0 : 1,
          ]);
        }
      });
    }
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.Toilet.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Width", "No of toilet"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.25,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
          if (data.column.dataKey === 3 && data.cell.section === "body") {
            //console.log(data.cell.raw, data.row.cells, data, data.row.cells[2].raw[2])
            if (data.row.cells[2].raw[2] === "Non-Compliant") {
              data.cell.styles.textColor = "red";
            }
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
            if (
              data.column.dataKey === 3 &&
              data.cell.section === "body" &&
              data.row.cells[2].raw[2] === "Non-Compliant"
            ) {
              data.cell.styles.textColor = "red";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
          3: { cellWidth: 25 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Toilet - width",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  UrinalAreaTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];
    // this.app.report.WC.map((i, index) => {
    //     subsno = subsno + 1
    //     setBody.push([i.BuildingName, i.FloorName, i.AreaRow, i.WidthRow])
    // })
    this.app.report.Urinal.map((i) => {
      if (
        !this.checkNOandNA(i.AreaRow.provided) &&
        i.AreaRow.provided !== null
      ) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.AreaRow.provided === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              existing[3] += 1;
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
              1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
            1,
          ]);
        }
      }
    });
    if (setBody.length === 0) {
      let flag = true;
      this.app.report.Urinal.map((i) => {
        if (this.checkNOandNA(i.AreaRow.provided) && flag) {
          flag = false;
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.AreaRow.min, i.AreaRow.provided, i.AreaRow.result],
            this.checkNOandNA(i.AreaRow.provided) ? 0 : 1,
          ]);
        }
      });
    }
    // console.log("wc - Area",setBody)
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.Urinal.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Area", "No of Urinal"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
          3: { cellWidth: 25 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Urinal - Area",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  UrinalWidthTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];
    this.app.report.Urinal.map((i) => {
      if (
        !this.checkNOandNA(i.WidthRow.provided) &&
        i.WidthRow.provided !== null
      ) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.WidthRow.provided === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              existing[3] += 1;
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
              1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
            1,
          ]);
        }
      }
    });
    if (setBody.length === 0) {
      let flag = true;
      this.app.report.Urinal.map((i) => {
        if (this.checkNOandNA(i.WidthRow.provided) && flag) {
          flag = false;
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.WidthRow.min, i.WidthRow.provided, i.WidthRow.result],
            this.checkNOandNA(i.WidthRow.provided) ? 0 : 1,
          ]);
        }
      });
    }
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.Urinal.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Width", "No of Urinal"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
          3: { cellWidth: 25 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Urinal - width",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  buildupTable = (start, sno) => {
    let end = 0,
      pg = 0,
      cnt = 1,
      subsno = 0;
    var setBody = this.app.report.BuiltUp.map((i, index) => {
      subsno = subsno + 1;
      return [sno + "." + subsno, i.BuildingName, i.FloorName, i.Area];
    });

    this.doc.autoTable({
      head: [["S.NO", "Building Name", "Floor Name", "Area"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 1 && data.column.dataKey != 2) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 5; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + 9,
      margin: { horizontal: 5, top: 59, bottom: 20 },
      headStyles: {
        valign: "middle",
        fontSize: 12,
        halign: "center",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 100 },
      },
      bodyStyles: {
        fontSize: 10,
        // halign: "center",
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        valign: "middle",
      },
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(sno + ". Built up details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  loftTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    this.app.report.loftDetails.map((i, index) => {
      setBody.push([
        i.BuildingName,
        "- ",
        i.BuiltUpArea,
        i.Percentage,
        i.Area,
        i.ClearHeight,
        i.RoomHeight,
      ]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Built Up Area",
          "Loft %",
          "Loft Area",
          "Loft Clear Height",
          "Loft Headroom Height",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 4 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Maximum", "Provided", "Excess Area", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 14,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 4 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            body: [
              [
                data.cell.raw.max,
                data.cell.raw.Area,
                data.cell.raw.excess,
                data.cell.raw.result,
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 14 },
              1: { cellWidth: 14 },
              2: { cellWidth: 14 },
              3: { cellWidth: 14 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 4; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
        if (data.column.dataKey === 5 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 10,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 14,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 5 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            // head: [["Maximum", "Provided", "Result"]],
            body: [
              [data.cell.raw.min, data.cell.raw.Value, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 14 },
              1: { cellWidth: 14 },
              2: { cellWidth: 14 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
        if (data.column.dataKey === 6 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 14,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 6 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            // head: [["Minimum", "Provided", "Result"]],
            body: [
              [data.cell.raw.min, data.cell.raw.Value, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 14 },
              1: { cellWidth: 14 },
              2: { cellWidth: 14 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (
          (data.column.dataKey === 0 && data.cell.section === "head") ||
          (data.column.dataKey === 1 && data.cell.section === "head") ||
          (data.column.dataKey === 2 && data.cell.section === "head") ||
          (data.column.dataKey === 3 && data.cell.section === "head")
        ) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          data.cell.styles.textColor = "black";
          if (
            data.column.dataKey === 4 ||
            data.column.dataKey === 5 ||
            data.column.dataKey === 6
          ) {
            if (data.cell.raw.result === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        minCellHeight: 14,
        ...this.headerStyles.onlyCenter,
      },
      columnStyles: {
        0: { cellWidth: 15 },
        1: { cellWidth: 15 },
        2: { cellWidth: 15 },
        4: { cellWidth: 56 },
        6: { cellWidth: 42 },
        5: { cellWidth: 42 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(sno + ".  Loft Details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  MezzanineTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    this.app.report.MezzanineDetails.map((i, index) => {
      setBody.push([
        i.BuildingName,
        "-",
        i.BuiltUpArea,
        i.Percentage,
        i.Area,
        i.ClearHeight,
        i.HeadRoomHeight,
      ]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Built Up Area",
          "Mezzanine %",
          "Mezzanine Area",
          "Mezzanine Clear Height",
          "Mezzanine Headroom Height",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 4 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Maximum", "Provided", "Excess Area", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 16,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 4 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            body: [
              [
                data.cell.raw.max,
                data.cell.raw.provided,
                data.cell.raw.excess,
                data.cell.raw.result,
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 16 },
              1: { cellWidth: 16 },
              2: { cellWidth: 16 },
              3: { cellWidth: 16 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 4; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
        if (data.column.dataKey === 5 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 16,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 5 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            // head: [["Maximum", "Provided", "Result"]],
            body: [
              [data.cell.raw.min, data.cell.raw.Value, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 16 },
              1: { cellWidth: 16 },
              2: { cellWidth: 16 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
        if (data.column.dataKey === 6 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              cellWidth: 16,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 6 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            // head: [["Minimum", "Provided", "Result"]],
            body: [
              [data.cell.raw.min, data.cell.raw.Value, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 16 },
              1: { cellWidth: 16 },
              2: { cellWidth: 16 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (
          (data.column.dataKey === 0 && data.cell.section === "head") ||
          (data.column.dataKey === 1 && data.cell.section === "head") ||
          (data.column.dataKey === 2 && data.cell.section === "head") ||
          (data.column.dataKey === 3 && data.cell.section === "head")
        ) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          data.cell.styles.textColor = "black";
          if (
            data.column.dataKey === 4 ||
            data.column.dataKey === 5 ||
            data.column.dataKey === 6
          ) {
            if (data.cell.raw.result === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        minCellHeight: 14,
        ...this.headerStyles.onlyCenter,
      },
      columnStyles: {
        4: { cellWidth: 64 },
        6: { cellWidth: 48 },
        5: { cellWidth: 48 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + ".  Mezzanine Floor Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  staircaseTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    this.app.report.StairCaseWidth.map((i) => {
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Value === existing[3][1] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
            // console.log(existing);
          }
        });
        if (notFound) {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            "Staircase Width",
            [i.min, i.Value, i.result],
            1,
          ]);
        }
      } else {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          "Staircase Width",
          [i.min, i.Value, i.result],
          1,
        ]);
      }
    });

    this.app.report.StairCaseHandRailHeight.map((i) => {
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Value === existing[3][1] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
          }
        });
        if (notFound) {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            "Handrail Height",
            [i.min, i.Value, i.result],
            1,
          ]);
        }
      } else {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          "Handrail Height",
          [i.min, i.Value, i.result],
          1,
        ]);
      }
    });

    this.app.report.StairCaseHeadRoomHeight.map((i) => {
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Value === existing[3][1] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
          }
        });
        if (notFound) {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            "Head room Height",
            [i.min, i.Value, i.result],
            1,
          ]);
        }
      } else {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          "Head room Height",
          [i.min, i.Value, i.result],
          1,
        ]);
      }
    });

    this.app.report.StairCaseRiser.map((i) => {
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Value === existing[3][1] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
          }
        });
        if (notFound) {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            "Riser Height",
            [i.min, i.Value, i.result],
            1,
          ]);
        }
      } else {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          "Riser Height",
          [i.min, i.Value, i.result],
          1,
        ]);
      }
    });

    /* this.app.report.StairCaseRiser.map((i, index) => {
            subno = subno + 1
            setBody.push(["Riser Height", i.BuildingName, i.FloorName, ["N.A", i.max, i.Value, i.result]])
         }) */
    this.app.report.StairCaseTread.map((i) => {
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Value === existing[3][1] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
          }
        });
        if (notFound) {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            "Tread Width",
            [i.min, i.Value, i.result],
            1,
          ]);
        }
      } else {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          "Tread Width",
          [i.min, i.Value, i.result],
          1,
        ]);
      }
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Parameter",
          "Normal Staircase",
          "No of units",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 20,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 1,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          //console.log("po",data.table.body!

          data.doc.autoTable({
            body: [
              [
                data.cell.raw[0],
                data.cell.raw[1],
                data.cell.raw[2],
                data.cell.raw[3],
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
              2: { cellWidth: 20 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 1,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body" && data.cell.raw !== "") {
                if (
                  data.column.dataKey !== 1 &&
                  data.column.dataKey !== 2 &&
                  data.column.dataKey !== 0
                ) {
                  //console.log("jgjgjg",data.cell.styles)
                  data.cell.styles.halign = "center";
                }
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "head" && data.column.dataKey !== 3) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (
            data.column.dataKey !== 1 &&
            data.column.dataKey !== 2 &&
            data.column.dataKey !== 0
          ) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 14,
      },
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 40 },
        1: { cellWidth: 45 },
        3: { cellWidth: 60 },
        4: { cellWidth: 20 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(sno + "  Staircase Details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  fireStaircaseTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    this.app.report.FireStairCaseWidth.map((i) => {
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Value === existing[3][1] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
          }
        });
        if (notFound) {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            "Staircase Width",
            [i.min, i.Value, i.result],
            1,
          ]);
        }
      } else {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          "Staircase Width",
          [i.min, i.Value, i.result],
          1,
        ]);
      }
    });
    this.app.report.FireStairCaseHandRailHeight.map((i) => {
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Value === existing[3][1] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
          }
        });
        if (notFound) {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            "Handrail Height",
            [i.min, i.Value, i.result],
            1,
          ]);
        }
      } else {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          "Handrail Height",
          [i.min, i.Value, i.result],
          1,
        ]);
      }
    });
    this.app.report.FireStairCaseHeadRoomHeight.map((i) => {
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Value === existing[3][1] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
          }
        });
        if (notFound) {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            "Head room Height",
            [i.min, i.Value, i.result],
            1,
          ]);
        }
      } else {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          "Head room Height",
          [i.min, i.Value, i.result],
          1,
        ]);
      }
    });
    // this.app.report.FireStairCaseRiser.map((i, index) => {
    //     subno = subno + 1
    //     setBody.push(["Riser Height", i.BuildingName, i.FloorName, ["N.A", i.max, i.Value, i.result]])
    // })
    this.app.report.FireStairCaseTread.map((i) => {
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Value === existing[3][1] &&
            notFound
          ) {
            notFound = false;
            existing[4] += 1;
          }
        });
        if (notFound) {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            "Tread Width",
            [i.min, i.Value, i.result],
            1,
          ]);
        }
      } else {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          "Tread Width",
          [i.min, i.Value, i.result],
          1,
        ]);
      }
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Parameter",
          "Fire Staircase",
          "No of units",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 20,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 1,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            body: [
              [
                data.cell.raw[0],
                data.cell.raw[1],
                data.cell.raw[2],
                data.cell.raw[3],
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
              2: { cellWidth: 20 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 1,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.1,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body" && data.cell.raw !== "") {
                if (
                  data.column.dataKey !== 1 &&
                  data.column.dataKey !== 2 &&
                  data.column.dataKey !== 0
                ) {
                  //console.log("jgjgjg",data.cell.styles)
                  data.cell.styles.halign = "center";
                }
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },

      didParseCell: function (data) {
        if (data.section === "head" && data.column.dataKey !== 3) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (
            data.column.dataKey !== 1 &&
            data.column.dataKey !== 2 &&
            data.column.dataKey !== 0
          ) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 14,
      },
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 40 },
        1: { cellWidth: 45 },
        3: { cellWidth: 60 },
        4: { cellWidth: 20 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(sno + "  Staircase Details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  AreaStaircaseTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    this.app.report.StairCaseArea.map((i) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        "Staircase Area",
        i.provided,
        i.no_of_units,
      ]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    // console.log(setBody);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Parameters",
          "Provided",
          "No of units",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.onlyCenter,
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 40 },
        6: { cellWidth: 28 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(sno + ".StairCase Details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  HabitableRoomDoorTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];
    this.app.report.HabitableDoorWidth.map((i) => {
      if (i.Value !== null) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.Value === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              if (!this.checkNOandNA(i.Value)) {
                existing[3] += 1;
              }
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.min, i.Value, i.result],
              this.checkNOandNA(i.Value) ? 0 : 1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.min, i.Value, i.result],
            this.checkNOandNA(i.Value) ? 0 : 1,
          ]);
        }
      }
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.HabitableDoorWidth.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Door Width", "No of Doors"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Habitable Door",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  nonHabitableRoomDoorTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];

    this.app.report.NonHabitableDoorWidth.map((i) => {
      if (i.Value != null) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.Value === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              if (!this.checkNOandNA(i.Value)) {
                existing[3] += 1;
              }
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.min, i.Value, i.result],
              this.checkNOandNA(i.Value) ? 0 : 1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.min, i.Value, i.result],
            this.checkNOandNA(i.Value) ? 0 : 1,
          ]);
        }
      }
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.NonHabitableDoorWidth.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Door Width", "No of Doors"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
            if (
              data.column.dataKey === 3 &&
              data.cell.section === "body" &&
              data.row.cells[2].raw[2] === "Non-Compliant"
            ) {
              data.cell.styles.textColor = "red";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Non-Habitable Door",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  mainDoorTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];

    this.app.report.MainDoorWidth.map((i) => {
      if (i.Value != null) {
        if (setBody.length > 0) {
          let notFound = true;
          setBody.map((existing) => {
            if (
              i.BuildingName === existing[0] &&
              i.FloorName === existing[1] &&
              i.Value === existing[2][1] &&
              notFound
            ) {
              notFound = false;
              if (!this.checkNOandNA(i.Value)) {
                existing[3] += 1;
              }
            }
          });
          if (notFound) {
            setBody.push([
              i.BuildingName,
              i.FloorName,
              [i.min, i.Value, i.result],
              this.checkNOandNA(i.Value) ? 0 : 1,
            ]);
          }
        } else {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.min, i.Value, i.result],
            this.checkNOandNA(i.Value) ? 0 : 1,
          ]);
        }
      }
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.MainDoorWidth.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Door Width", "No of Doors"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 3 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw[2] === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
        columnStyles: {
          0: { cellWidth: 40 },
          2: { cellWidth: 90 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Details of Main Entrance Door",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };
  LiftTable = (start, sno) => {
    let end = 0,
      pg = 0,
      cnt = 1,
      subsno = 0;
    var setBody = this.app.report.Lift.map((i, index) => {
      subsno = subsno + 1;
      return [
        sno + "." + subsno,
        i.BuildingName,
        i.FloorName,
        i.ObjectClassification,
        i.Area,
      ];
    });
    this.doc.autoTable({
      head: [["S.NO", "Building Name", "Floor Name", "Area"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 1 && data.column.dataKey != 2) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 5; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + 9,
      margin: { horizontal: 5, top: 59, bottom: 20 },
      headStyles: {
        valign: "middle",
        fontSize: 12,
        halign: "center",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 70 },
        3: { cellWidth: 35 },
      },
      bodyStyles: {
        fontSize: 10,
        // halign: "center",
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        valign: "middle",
      },
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(sno + ". Lift details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  LiftCountTable = (start, sno) => {
    let end = 0,
      pg = 0,
      cnt = 1,
      subsno = 0;
    var setBody = this.app.report.Lift_Count.map((i, index) => {
      subsno = subsno + 1;
      let temp = [
        "Number of Lifts",
        i.BuildingName,
        "-",
        ["N.A", "N.A", i.Count, "N.A"],
      ];
      let check = true;
      this.app.report.FireLift_Count.map((k, index) => {
        if (k.BuildingName === i.BuildingName) {
          check = false;
          temp.push(["N.A", "N.A", k.Count, "N.A"]);
        }
      });
      if (check) {
        temp.push(["N.A", "N.A", "N.A", "N.A"]);
      }
      return temp;
    });
    this.doc.autoTable({
      head: [
        [
          "Parameter",
          "Building Name",
          "Floor Name",
          "Normal Lift",
          "Fire Lift",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (
          (data.column.dataKey === 4 && data.cell.section === "head") ||
          (data.column.dataKey === 3 && data.cell.section === "head")
        ) {
          data.doc.autoTable({
            head: [["Minimum", "Maximum", "Provided", "Result"]],
            startY: data.cell.y + 8,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              minCellHeight: 7,
              cellWidth: 20,
              fontSize: 9,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (
          (data.column.dataKey === 3 && data.cell.section === "body") ||
          (data.column.dataKey === 4 && data.cell.section === "body")
        ) {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            // head: [["Minimum", "Maximum", "Provided", "Result"]],
            body: [
              [
                data.cell.raw[0],
                data.cell.raw[1],
                data.cell.raw[2],
                data.cell.raw[3],
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
              2: { cellWidth: 20 },
              3: { cellWidth: 20 },
            },
            theme: "grid",
            headStyles: {
              valign: "middle",
              fontSize: 9,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: 7,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 4; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (
          (data.column.dataKey === 0 && data.cell.section === "head") ||
          (data.column.dataKey === 2 && data.cell.section === "head") ||
          (data.column.dataKey === 1 && data.cell.section === "head")
        ) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 3; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + 9,
      margin: { horizontal: 5, top: 59, bottom: 20 },
      headStyles: {
        minCellHeight: 15,
        fontSize: 11,
        halign: "center",
        fillColor: "white",
        textColor: "black",
        lineWidth: 0.25,
        lineColor: [128, 128, 128],
      },
      columnStyles: {
        0: { cellWidth: 45 },
        3: { cellWidth: 80 },
        4: { cellWidth: 80 },
      },
      bodyStyles: {
        fontSize: 9,
        // halign: "center",
        lineColor: [128, 128, 128],
        lineWidth: 0.25,
        valign: "middle",
      },
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(sno + " Lift details", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  OccupancyLoadTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;

    var setBody = [];
    this.app.report.OccupantLoad.map((i, index) => {
      setBody.push([
        i.BuildingName,
        this.app.useOfTheBuildings,
        i.GroosBuiltupArea,
        i.OccupantCoeff,
        i.OccupantLoad,
      ]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Occupancy",
          "Gross Built Up Area",
          "Occupancy Co-efficient",
          "Occupancy Load",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 40 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(sno + ".  Occupancy Load", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  SanitationTable = (start, sno) => {
    let end = 0,
      pg = 0,
      cnt = 1,
      subsno = 0;
    var setBody = [];
    this.app.report.SanitizationDetails.map((i, index) => {
      setBody.push([
        i.BuildingName,
        i.OccupancyLoad,
        i.Male,
        i.FeMale,
        i.WCMale,
        i.WCFeMale,
        i.Urinals,
      ]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Occupancy Load",
          "Male",
          "Female",
          "Water closet for Male",
          "Water closet for Female",
          "Urinals",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (
          (data.column.dataKey === 4 && data.cell.section === "head") ||
          (data.column.dataKey === 5 && data.cell.section === "head") ||
          (data.column.dataKey === 6 && data.cell.section === "head")
        ) {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 14,
              fontSize: data.table.head[0].cells[0].styles.fontSize - 3,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (
          (data.column.dataKey === 5 && data.cell.section === "body") ||
          (data.column.dataKey === 4 && data.cell.section === "body") ||
          (data.column.dataKey === 6 && data.cell.section === "body")
        ) {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            // head: [["Minimum", "Provided", "Result"]],
            body: [
              [data.cell.raw.min, data.cell.raw.provided, data.cell.raw.result],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 14 },
              1: { cellWidth: 14 },
              2: { cellWidth: 14 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 4,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)
              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 3; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (
          (data.column.dataKey === 0 && data.cell.section === "head") ||
          (data.column.dataKey === 1 && data.cell.section === "head") ||
          (data.column.dataKey === 2 && data.cell.section === "head") ||
          (data.column.dataKey === 3 && data.cell.section === "head")
        ) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey != 0) {
            data.cell.styles.halign = "center";
          }
          if (
            data.column.dataKey === 4 ||
            data.column.dataKey === 5 ||
            data.column.dataKey === 6
          ) {
            if (data.cell.raw.result === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 4; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else data.cell.styles.textColor = "black";
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 14,
      },
      columnStyles: {
        0: { cellWidth: 30 },
        4: { cellWidth: 42 },
        5: { cellWidth: 42 },
        6: { cellWidth: 42 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ".  Sanitation details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  exitTable = (start, sno) => {
    let end = 0,
      pg = 0,
      cnt = 1,
      subsno = 0;
    var setBody = this.app.report.TravelDistance.map((i, index) => {
      subsno = subsno + 1;
      return [
        i.BuildingName,
        i.FloorName ? i.FloorName : "-",
        i.max,
        i.Value,
        i.result,
      ];
    });
    if (setBody.length > 0)
      this.doc.autoTable({
        head: [
          ["Building Name", "Floor Name", "Maximum", "Provided", "Result"],
        ],
        body: setBody,
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey != 0 && data.column.dataKey != 1) {
              data.cell.styles.halign = "center";
            }
            if (data.cell.raw === "Non-Compliant") {
              //console.log("hiii",data.cell.styles,data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 6; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                // data.row.cells[i].styles.lineColor = "red";
                // data.row.cells[i].styles.lineWidth = 0.75
              }
            } else data.cell.styles.textColor = "black";
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: this.headerStyles.centerAndMiddle,
        columnStyles: {
          0: { cellWidth: 40 },
          1: { cellWidth: 40 },
          2: { cellWidth: 30 },
          5: { cellWidth: 30 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          //console.log("secondary",this.doc)
          if (pg > 0) {
            this.tableHeader(sno + ". Travel Distance", this.firstTableInPage);
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    else
      end = this.notProviedTable(
        start,
        "Travel distance not provided",
        sno + ". Travel Distance"
      );
    return end;
  };
  DistanceBetween2BuildingTable = (start, sno) => {
    let end = 0,
      pg = 0,
      cnt = 1,
      subsno = 0;
    var setBody = this.app.report.DistanceBetweenBuilding.map((i) => {
      return [i.Building1_Name, i.Building2_Name, i.min, i.Value, i.result];
    });
    this.doc.autoTable({
      head: [
        [
          "Start Building Name",
          "End Building Name",
          "Minimum Distance",
          "Provided Distance",
          "Result",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (
            data.column.dataKey != 0 &&
            data.column.dataKey != 1 &&
            data.column.dataKey != 2
          ) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 45 },
        1: { cellWidth: 40 },
        2: { cellWidth: 40 },
        5: { cellWidth: 30 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ".  Distance Between  Two Buildings",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  CommercialTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];
    this.app.report.CommercialShop.map((i, index) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        [i.AreaRow.max, i.Area, i.AreaRow.result],
        [i.SanitationRow.provided, i.SanitationRow.result],
      ]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Building Name", "Floor Name", "Area", "Sanitation"]],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 2 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Maximum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              fontSize: data.table.head[0].cells[0].styles.fontSize - 1,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              cellWidth: 20,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 2 && data.cell.section === "body") {
          data.doc.autoTable({
            //  head: [["Minimum", "Provided", "Result"]],
            body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
              2: { cellWidth: 20 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 1,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body" && data.cell.raw !== "") {
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 2; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    //data.row.cells[i].styles.lineColor = "red";
                    //data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Provided (Yes/No)", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 20,
              fontSize: data.table.head[0].cells[0].styles.fontSize,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          data.doc.autoTable({
            //  head: [["Minimum", "Provided", "Result"]],
            body: [[data.cell.raw[0], data.cell.raw[1]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
              2: { cellWidth: 20 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body" && data.cell.raw !== "") {
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 2; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    //data.row.cells[i].styles.lineColor = "red";
                    //data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (
          data.column.dataKey === 0 ||
          (data.column.dataKey === 1 && data.cell.section === "head")
        ) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey === 2 || data.column.dataKey === 3) {
            data.cell.styles.halign = "center";
          }
          if (
            data.cell.raw[2] === "Non-Compliant" ||
            data.cell.raw[1] === "Non-Compliant"
          ) {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 2; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              //data.row.cells[i].styles.lineColor = "red";
              //data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      rowPageBreak: "avoid",
      margin: this.margin,
      headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
      columnStyles: {
        // 0: { cellWidth: 20 },
        3: { cellWidth: 40 },
        2: { cellWidth: 60 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        // console.log("pantry",this.doc)
        if (pg > 0) {
          this.tableHeader(sno + ". Commercial Area", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  RefugeTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = this.app.report.RefugeArea.map((i) => {
      subsno = subsno + 1;
      return [
        i.BuildingName,
        i.FloorName,
        i.Height,
        [i.refugeAreaAbvGround, i.heightAbvGround, i.heightResult],
      ];
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);

    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name of Refuge Area",
          "Habitable Building Height",
          "Building Height for Refuge Area",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Required", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 20,
              fontSize: data.table.head[0].cells[0].styles.fontSize,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          data.doc.autoTable({
            //  head: [["Minimum", "Provided", "Result"]],
            body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 20 },
              1: { cellWidth: 20 },
              2: { cellWidth: 20 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body" && data.cell.raw !== "") {
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    //data.row.cells[i].styles.lineColor = "red";
                    //data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "head" && data.column.dataKey != 3)
          data.cell.styles.valign = "middle";
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            // console.log("hiii", data.cell.styles, data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 3; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              //data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: { ...this.headerStyles.onlyCenter, fontSize: 7 },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 50 },
        2: { cellWidth: 50 },
        3: { cellWidth: 60 },
      },
      bodyStyles: { ...this.bodyStyles.noAlign, fontSize: 6 },
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(sno + ". Refuge Area", this.firstTableInPage);
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  CorridorTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0,
      previousFloor = -99999;
    var setBody = [],
      totalArea = 0;

    this.app.report.Corridor.map((i) => {
      if (setBody.length > 0) {
        let notFound = true;
        setBody.map((existing) => {
          if (
            i.BuildingName === existing[0] &&
            i.FloorName === existing[1] &&
            i.Width === existing[2][1] &&
            notFound
          ) {
            notFound = false;
            if (!this.checkNOandNA(i.Value)) {
              existing[3] += 1;
            }
          }
        });
        if (notFound) {
          setBody.push([
            i.BuildingName,
            i.FloorName,
            [i.min, i.Width, i.result],
          ]);
        }
      } else {
        setBody.push([i.BuildingName, i.FloorName, [i.min, i.Width, i.result]]);
      }
      totalArea += i.Area;
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    totalArea = Number(totalArea.toFixed(2));
    if (setBody.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Width"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 2 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 2 && data.cell.section === "body") {
            //console.log("po",data.table.body)
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                //console.log("hiii",data.cell.raw)
                if (data.cell.raw === "Non-Compliant") {
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 2; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            data.column.dataKey === 0 ||
            (data.column.dataKey === 1 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              //console.log("jgjgjg",data.cell.styles)
              data.cell.styles.halign = "center";
            }
            if (data.column.dataKey === 2) {
              if (data.cell.raw[2] === "Non-Compliant") {
                // data.row.cells[0].styles.lineColor = "red";
                // data.row.cells[0].styles.lineWidth = 0.75
                data.row.cells[0].styles.textColor = "red";
                data.row.cells[1].styles.textColor = "red";
                data.row.cells[2].styles.textColor = "red";
              } else {
                data.cell.styles.textColor = "black";
              }
            } else {
              data.cell.styles.textColor = "black";
            }
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: start + this.tableStart,
        margin: this.margin,
        columnStyles: {
          0: { cellWidth: 50 },
          1: { cellWidth: 60 },
          2: { cellWidth: 90 },
        },
        headerStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          //console.log("chowk",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + ". Corridors and passageways",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
      /* if (end > this.pageHeight-25) {
                this.doc.addPage();
                this.pageHeader();
                this.tableHeader(sno + ". Corridors and passageways", this.firstTableInPage)
                end = this.tableStart+this.firstTableInPage
            }

            this.doc.autoTable({
                body: [["Total Area", totalArea, "-"]],
                didParseCell: function (data) {
                    if (data.section === 'body' && data.cell.raw !== "") {

                        if (data.column.dataKey === 1) {
                            data.cell.styles.halign = "center"
                        }
                        data.cell.styles.fontStyle = "bold";

                        data.cell.styles.textColor = "black";

                    }
                },
                rowPageBreak: 'avoid',
                theme: "grid",
                startY: end,
                margin: { horizontal: this.horizontal },
                columnStyles: {

                    0: { cellWidth: 70 },
                    1: { cellWidth: 20 }
                },
                bodyStyles:this.bodyStyles.noAlign,
                didDrawPage: (data) => {

                    end = data.cursor.y
                }
            })*/
    }
    /* else {
            end = start + this.tableStart
            if (end > this.pageHeight-25) {
                this.doc.addPage();
                this.pageHeader();
                this.tableHeader(sno + ". Corridors and passageways", this.firstTableInPage)
                end = this.tableStart+this.firstTableInPage
            }

            this.doc.autoTable({
                body: [["Total Area", totalArea]],
                didParseCell: function (data) {
                    if (data.section === 'body' && data.cell.raw !== "") {
                        if (data.column.dataKey === 1) {
                            data.cell.styles.halign = "center"
                        }
                        data.cell.styles.fontStyle = "bold";

                        data.cell.styles.textColor = "black";

                    }
                },
                rowPageBreak: 'avoid',
                theme: "grid",
                startY: end,
                margin: { horizontal: this.horizontal },
                columnStyles: {

                    1: { cellWidth: 180 },

                },
                bodyStyles: this.bodyStyles.noAlign,
                didDrawPage: (data) => {

                    end = data.cursor.y
                }
            })
        }*/

    return end;
  };
  RampTable = (start, sno) => {
    var setBody = [],
      end = 0,
      pg = 0,
      subsno = 0;
    /*  this.app.report.RampBasementDetails.map((main, index) => {
            main.Area.map(i => {
                setBody.push([i.BuildingName, i.FloorName,"Ramp Area", i.ObjectClassification, i.min, i.provided, i.result])
            })
        })*/
    this.app.report.RampBasementDetails.map((main, index) => {
      main.RampSlope.map((i) => {
        setBody.push([
          i.building,
          i.FloorName,
          "Vehicular Ramp Slope",
          i.ObjectClassification,
          i.min,
          i.provided,
          i.result,
        ]);
      });
    });

    this.app.report.RampBasementDetails.map((main, index) => {
      let sub = 1;
      main.RampWidth.map((i) => {
        setBody.push([
          i.BuildingName,
          i.FloorName,
          "Vehicular Ramp Width",
          i.ObjectClassification,
          i.min,
          i.provided,
          i.result,
        ]);
      });
    });
    this.app.report.RampBasementDetails.map((main, index) => {
      let sub = 1;
      main.Basement.map((i) => {
        setBody.push([
          i.building,
          i.FloorName,
          "Basement Height",
          i.ObjectClassification,
          i.min,
          i.provided,
          i.result,
        ]);
      });
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    // console.log(setBody);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Parameters",
          "Classification",
          "Minimum",
          "Provided",
          "Result",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.onlyCenter,
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 40 },
        6: { cellWidth: 28 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Basement and Ramp Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  BuldingHeightTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0,
      setBody = [];
    this.app.report.OnlyBuildingHeight.map((i) => {
      setBody.push([
        i.BuildingName,
        "Building Total Height",
        i.BuildingTotalHeight,
      ]);
      setBody.push([
        i.BuildingName,
        "Building Habitable Height",
        i.BuildingHabitableHeight,
      ]);
      if (i.BuildingHabitableHeightWithTDR != 0) {
        setBody.push([
          i.BuildingName,
          "Building Habitable Height with TDR floors",
          i.BuildingHabitableHeightWithTDR,
        ]);
      }
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [["Building name", "Parameter", "Height"]],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey === 2) {
            data.cell.styles.halign = "center";
          }
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        1: { cellWidth: 70 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + " Details of Building Height",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    return end;
  };
  ParkingAreaTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0,
      sizeHead = 3,
      sizeBody = 7;
    var setBody = [],
      totalArea = 0;
    this.app.report.VisitorsParkingBasement.map((i, index) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.BuiltupArea,
        [i.columnArea, i.duct, i.incidentArea, i.stairCase, i.lift],
        i.parkingArea,
      ]);
      totalArea = Number(Number(totalArea) + Number(i.parkingArea)).toFixed(2);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Built up Area",
          "Deductions",
          "Parking Area",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Duct", "Accessory buildings"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              /* valign: 'middle',
                            cellWidth: 50,
                            fontSize: data.table.head[0].cells[0].styles.fontSize-3,
                            minCellHeight:data.table.head[0].cells[0].styles.minCellHeight/2,
                            halign: 'center',
                            fillColor: "white",
                            textColor: "black",
                            lineWidth: 0.2,
                            lineColor: [128, 128, 128]*/
              valign: "middle",
              minCellHeight: 8,
              cellWidth: 40,
              fontSize: 8,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          //console.log("po",data.table.body!
          data.doc.autoTable({
            // head: [["Duct", "Incidental Structures", "Staircase", "Lifts"]],
            body: [[data.cell.raw[1], data.cell.raw[2]]],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 40 },
              1: { cellWidth: 40 },
              2: { cellWidth: 20 },
              3: { cellWidth: 20 },
              4: { cellWidth: 20 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize - 1,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },

            didParseCell: function (data) {
              //console.log("hiii",data.cell.raw)

              if (data.cell.raw === "Non-Compliant") {
                data.cell.styles.textColor = "red";
                // data.cell.styles.lineColor = "red";
                // data.cell.styles.lineWidth = 0.75
                for (let i = 0; i < 5; i++) {
                  if (data.row.cells[i])
                    data.row.cells[i].styles.textColor = "red";
                  // data.row.cells[i].styles.lineColor = "red";
                  // data.row.cells[i].styles.lineWidth = 0.75
                }
              } else {
                data.cell.styles.textColor = "black";
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (
          (data.column.dataKey === 0 && data.cell.section === "head") ||
          (data.column.dataKey === 1 && data.cell.section === "head") ||
          (data.column.dataKey === 2 && data.cell.section === "head") ||
          (data.column.dataKey === 4 && data.cell.section === "head")
        ) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0 && data.column.dataKey !== 1) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 14,
      },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 40 },
        2: { cellWidth: 20 },
        3: { cellWidth: 75 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ".   Parking Area Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    if (end > this.pageHeight - 45) {
      this.doc.addPage();
      this.pageHeader();
      this.tableHeader(sno + ". Parking Area Details", this.firstTableInPage);
      end = this.tableStart + this.firstTableInPage;
    }
    var totalsetBody = [];
    const builtup = this.app.report.AreaStatement[0].TotalProposedBuiltupArea;
    const parkingPercentage = Number(
      (Number(totalArea) / Number(builtup)) * 100
    ).toFixed(2);
    totalsetBody.push(["Total Parking Area in sq.m", totalArea]);
    totalsetBody.push(["BuiltUp Area in sq.m", builtup]);
    totalsetBody.push(["Parking Area Percentage", `${parkingPercentage} %`]);
    this.doc.autoTable({
      body: totalsetBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          data.cell.styles.fontStyle = "bold";
          data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: end,
      margin: { horizontal: this.horizontal },
      columnStyles: {
        0: { cellWidth: 165 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("chowk",this.doc)
        // if (pg > 0) {
        //     this.tableHeader(sno + "  Parking Area Details", this.firstTableInPage)
        // }
        // pg = pg + 1
        end = data.cursor.y;
      },
    });

    return end;
  };

  nalaTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    var nala = this.app.report.Nala;

    setBody.push([nala, nala.Area, nala.Width]);

    this.doc.autoTable({
      head: [["Distance From Plot Boundary", "Area", "Width"]],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 0 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 46.6,
              fontSize: data.table.head[0].cells[0].styles.fontSize,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 0 && data.cell.section === "body") {
          //console.log("po",data.table.body!

          data.doc.autoTable({
            body: [
              [
                data.cell.raw.MinDistance,
                data.cell.raw.Distance,
                data.cell.raw.result,
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 46.6 },
              1: { cellWidth: 46.6 },
              2: { cellWidth: 46.6 },
            },
            theme: "grid",
            bodyStyles: {
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body") {
                if (
                  data.column.dataKey !== 1 &&
                  data.column.dataKey !== 2 &&
                  data.column.dataKey !== 0
                ) {
                  //console.log("jgjgjg",data.cell.styles)
                  data.cell.styles.halign = "center";
                }
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "head" && data.column.dataKey !== 0) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.halign = "center";

          if (
            data.cell.raw &&
            data.cell.raw.result &&
            data.cell.raw.result === "Non-Compliant"
          ) {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 3; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
      columnStyles: {
        0: { cellWidth: 140 },
        1: { cellWidth: 30 },
        2: { cellWidth: 30 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + "  Nala Buffer Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  mortgageTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    this.app.report.MortgageArea.map((i, index) => {
      setBody.push([
        i.BuildingName,
        i.Builtup,
        i.Mortgage.minArea,
        i.Mortgage,
        i.Mortgage.result,
      ]);
    });

    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Built Up Area",
          "Minimum Mortgage Area 10 %",
          "Area (Sq.m)",
          "Result",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [
              ["Mortgage Provided", "Deduction (Duct)", "Net Mortgage Area"],
            ],
            startY: data.cell.y + 9,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 40,
              fontSize: data.table.head[0].cells[0].styles.fontSize,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          //console.log("po",data.table.body!

          data.doc.autoTable({
            body: [
              [
                data.cell.raw.provided,
                data.cell.raw.deduction,
                data.cell.raw.Value,
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 40 },
              1: { cellWidth: 40 },
              2: { cellWidth: 40 },
            },
            theme: "grid",
            bodyStyles: {
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              minCellHeight: 10,
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body") {
                if (
                  data.column.dataKey !== 1 &&
                  data.column.dataKey !== 2 &&
                  data.column.dataKey !== 0
                ) {
                  //console.log("jgjgjg",data.cell.styles)
                  data.cell.styles.halign = "center";
                }
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "head" && data.column.dataKey !== 2) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.halign = "center";

          if (
            data.cell.raw &&
            data.cell.raw.result &&
            data.cell.raw.result === "Non-Compliant"
          ) {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 3; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: { ...this.headerStyles.onlyCenter, minCellHeight: 14 },
      columnStyles: {
        0: { cellWidth: 15 },
        1: { cellWidth: 20 },
        2: { cellWidth: 20 },
        3: { cellWidth: 120 },
        4: { cellWidth: 25 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + "  Mortgage Area Details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  VentilationAreaTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];

    this.app.report.VentilationShaft.map((i) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.FloorArea,
        i.provided_min_floor_area,
        i.provided,
        i.result,
      ]);
    });
    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Floor Area",
          "Minimum (2.5%)",
          "Provided",
          "Result",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 0) {
            //console.log("jgjgjg",data.cell.styles)
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else data.cell.styles.textColor = "black";
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.onlyCenter,
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 40 },
        6: { cellWidth: 28 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        //console.log("secondary",this.doc)
        if (pg > 0) {
          this.tableHeader(
            sno + ". Ventilation Area Shaft Details Table",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });

    if ("VentilationShaftTotal" in this.app.report) {
      if (end > this.pageHeight - 45) {
        this.doc.addPage();
        this.pageHeader();
        this.tableHeader(
          sno + "Ventilation Area Shaft Details Table",
          this.firstTableInPage
        );
        end = this.firstTableInPage + this.tableStart;
      }
      var totalsetBody = [];
      totalsetBody.push([
        "Total Floor Area",
        this.app.report.VentilationShaftTotal.total_floor_area,
        this.app.report.VentilationShaftTotal.total_min_floor_area,
        this.app.report.VentilationShaftTotal.total_min_provided_area,
        this.app.report.VentilationShaftTotal.result,
      ]);
      // totalsetBody.push(["Total FSI ", Number(industryFsi + commercialFsi - netIncident).toFixed(2)])
      this.doc.autoTable({
        body: totalsetBody,
        didParseCell: function (data) {
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 0) {
              //console.log("jgjgjg",data.cell.styles)
              data.cell.styles.halign = "center";
            }
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.textColor = "black";
          }
        },
        rowPageBreak: "avoid",
        theme: "grid",
        startY: end,
        margin: { horizontal: this.horizontal },
        columnStyles: {
          0: { cellWidth: 70 },
          1: { cellWidth: 33 },
          2: { cellWidth: 30 },
          3: { cellWidth: 29 },
          4: { cellWidth: 38 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          //console.log("chowk",this.doc)
          /*  if (pg > 0) {
                        this.tableHeader(sno + " . Ventilation Area Shaft Details Table", this.firstTableInPage)
                    }*/
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }

    return end;
  };
  MezzanineAssociationTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    this.app.report.MezzanineFloorAssociation.map((i, index) => {
      setBody.push([
        i.BuildingName,
        i.MezzanineFloorName,
        i.GroundFloorArea,
        i.MezzanineArea,
      ]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name",
          "Lower Floor Area",
          "Mezzanine Floor Area",
        ],
      ],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 3 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Maximum", "Provided", "Result"]],
            startY: data.cell.y + 10,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              cellWidth: 30,
              fontSize: data.table.head[0].cells[0].styles.fontSize,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 3 && data.cell.section === "body") {
          data.doc.autoTable({
            body: [
              [
                data.cell.raw.maxArea,
                data.cell.raw.Value,
                data.cell.raw.result,
              ],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 30 },
              1: { cellWidth: 30 },
              2: { cellWidth: 30 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body") {
                if (
                  data.column.dataKey !== 1 &&
                  data.column.dataKey !== 2 &&
                  data.column.dataKey !== 0
                ) {
                  //console.log("jgjgjg",data.cell.styles)
                  data.cell.styles.halign = "center";
                }
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "head" && data.column.dataKey !== 3) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.halign = "center";

          if (
            data.cell.raw.result &&
            data.cell.raw.result === "Non-Compliant"
          ) {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 3; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 14,
      },
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 40 },
        1: { cellWidth: 45 },
        3: { cellWidth: 90 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + "  Mezannaine floor details",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };

  IndividualFloorHeightTable = (start, sno) => {
    let end = 0,
      pg = 0,
      subsno = 0;
    var setBody = this.app.report.IndividualFloors.map((i) => {
      subsno = subsno + 1;
      return [
        i.BuildingName,
        i.FloorName,
        i.minHeight,
        i.maxHeight,
        i.height,
        i.result,
      ];
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    // console.log(setBody);
    this.doc.autoTable({
      head: [
        [
          "Building Name",
          "Floor Name ",
          "Minimum",
          "Maximum",
          "Provided",
          "Result",
        ],
      ],
      body: setBody,
      didParseCell: function (data) {
        if (data.section === "body" && data.cell.raw !== "") {
          if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
            data.cell.styles.halign = "center";
          }
          if (data.cell.raw === "Non-Compliant") {
            // console.log("hiii", data.cell.styles, data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 6; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              //data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      rowPageBreak: "avoid",
      theme: "grid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: this.headerStyles.centerAndMiddle,
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 50 },
        2: { cellWidth: 30 },
        3: { cellWidth: 30 },
      },
      bodyStyles: this.bodyStyles.noAlign,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + ". Individual Floor Height Details Table",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  GreenStripTable = (start, sno) => {
    let end = start,
      pg = 0,
      subno = 0;
    var setBody = [];
    this.app.report.GreenStrip.map((i, index) => {
      setBody.push([i.plotArea, i.Area, i.greenStrip]);
    });

    this.doc.autoTable({
      head: [["Net Plot Area", "Green Strip Area", "Width (M)"]],
      body: setBody,
      didDrawCell: function (data) {
        if (data.column.dataKey === 2 && data.cell.section === "head") {
          data.doc.autoTable({
            head: [["Minimum Width", "Provided", "Result"]],
            startY: data.cell.y + 7,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            theme: "grid",
            headStyles: {
              valign: "middle",
              fontSize: data.table.head[0].cells[0].styles.fontSize,
              minCellHeight:
                data.table.head[0].cells[0].styles.minCellHeight / 2,
              cellWidth: 40,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.2,
              lineColor: [128, 128, 128],
            },
          });
        }
        if (data.column.dataKey === 2 && data.cell.section === "body") {
          // console.log("spring",data.cell.raw.minWidth);

          data.doc.autoTable({
            body: [
              [
                data.cell.raw.minWidth,
                data.cell.raw.Width,
                data.cell.raw.result,
              ],
              // [data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]],
            ],
            startY: data.cell.y,
            margin: { left: data.cell.x },
            tableWidth: "wrap",
            columnStyles: {
              0: { cellWidth: 40 },
              1: { cellWidth: 40 },
              2: { cellWidth: 40 },
            },
            theme: "grid",
            bodyStyles: {
              minCellHeight: data.table.body[data.row.index].height,
              fontSize: data.table.body[0].cells[0].styles.fontSize,
              halign: "center",
              fillColor: "white",
              textColor: "black",
              lineWidth: 0.25,
              lineColor: [128, 128, 128],
              // lineColor: data.table.body[0].cells[0].styles.lineColor,
              // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
              valign: "middle",
            },
            didParseCell: function (data) {
              if (data.section === "body") {
                if (
                  data.column.dataKey !== 1 &&
                  data.column.dataKey !== 2 &&
                  data.column.dataKey !== 0
                ) {
                  //console.log("jgjgjg",data.cell.styles)
                  data.cell.styles.halign = "center";
                }
                if (data.cell.raw === "Non-Compliant") {
                  //console.log("hiii",data.cell.styles,data)
                  data.cell.styles.textColor = "red";
                  // data.cell.styles.lineColor = "red";
                  // data.cell.styles.lineWidth = 0.75
                  for (let i = 0; i < 3; i++) {
                    if (data.row.cells[i])
                      data.row.cells[i].styles.textColor = "red";
                    // data.row.cells[i].styles.lineColor = "red";
                    // data.row.cells[i].styles.lineWidth = 0.75
                  }
                } else {
                  data.cell.styles.textColor = "black";
                }
              }
            },
          });
        }
      },
      didParseCell: function (data) {
        if (data.section === "head" && data.column.dataKey !== 2) {
          data.cell.styles.valign = "middle";
        }
        if (data.section === "body" && data.cell.raw !== "") {
          data.cell.styles.halign = "center";

          if (
            data.cell.raw.result &&
            data.cell.raw.result === "Non-Compliant"
          ) {
            //console.log("hiii",data.cell.styles,data)
            data.cell.styles.textColor = "red";
            // data.cell.styles.lineColor = "red";
            // data.cell.styles.lineWidth = 0.75
            for (let i = 0; i < 3; i++) {
              if (data.row.cells[i]) data.row.cells[i].styles.textColor = "red";
              // data.row.cells[i].styles.lineColor = "red";
              // data.row.cells[i].styles.lineWidth = 0.75
            }
          } else {
            data.cell.styles.textColor = "black";
          }
        }
      },
      theme: "grid",
      rowPageBreak: "avoid",
      startY: start + this.tableStart,
      margin: this.margin,
      headStyles: {
        ...this.headerStyles.onlyCenter,
        minCellHeight: 14,
      },
      columnStyles: {
        // 0: { cellWidth: 20 },
        0: { cellWidth: 40 },
        2: { cellWidth: 120 },
      },
      bodyStyles: this.bodyStyles.onlyMiddle,
      didDrawPage: (data) => {
        this.pageHeader();
        if (pg > 0) {
          this.tableHeader(
            sno + "  Green Strip Width Table",
            this.firstTableInPage
          );
        }
        pg = pg + 1;
        end = data.cursor.y;
      },
    });
    return end;
  };
  /* GreenStripTable = (start, sno) => {
        let end = start, pg = 0, subno = 0;
        var setBody = []
        this.app.report.GreenStrip.map((i, index) => {
      
            setBody.push([i.plotArea, i.Area,[i.greenStrip.minWidth,i.greenStrip.Width,i.greenStrip.result]])
        })

        setBody = this.mergeCellsUtil(start,setBody,0)
        if (this.app.report.GreenStrip.length > 0) {
            this.doc.autoTable({
                head: [["Plot Area", "Green Strip Area", "Width (M)"]],
                body: setBody,
                didDrawCell: function (data) {
                    if (data.column.dataKey === 2 && data.cell.section === 'head') {
                        data.doc.autoTable({
                            head: [["Minimum", "Provided", "Result"]],
                            startY: data.cell.y + 7,
                            margin: { left: data.cell.x },
                            tableWidth: 'wrap',
                            theme: "grid",
                            headStyles: {
                                valign: 'middle',
                                cellWidth: 30,
                                fontSize: data.table.head[0].cells[0].styles.fontSize,
                                minCellHeight:data.table.head[0].cells[0].styles.minCellHeight/2,
                                halign: 'center',
                                fillColor: "white",
                                textColor: "black",
                                lineWidth: 0.1,
                                lineColor: [128, 128, 128]
                            },

                        });
                    }
                    if (data.column.dataKey === 2 && data.cell.section === 'body') {
                        data.doc.autoTable({
                            // head: [["Minimum", "Provided", "Result"]],
                            body: [
                                [data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]],
                            ],
                            startY: data.cell.y,
                            margin: { left: data.cell.x },
                            tableWidth: 'wrap',
                            columnStyles: {
                                0: { cellWidth: 30 },
                                1: { cellWidth: 30 },
                                2: { cellWidth: 30 }
                            },
                            theme: "grid",
                            bodyStyles: {
                                minCellHeight: data.table.body[data.row.index].height,
                                fontSize: data.table.body[0].cells[0].styles.fontSize,
                                halign: "center",
                                fillColor: "white",
                                textColor: "black",
                                lineWidth: 0.1,
                                lineColor: [128, 128, 128],
                                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                                valign: "middle"
                            },
                            didParseCell: function (data) {
                                if (data.section === 'body' && data.cell.raw !== "") {
                                    if (data.cell.raw === "Non-Compliant") { //console.log("hiii",data.cell.styles,data)
                                        data.cell.styles.textColor = "red";
                                        // data.cell.styles.lineColor = "red";
                                        // data.cell.styles.lineWidth = 0.75
                                        for (let i = 0; i < 2; i++) {
                                            if (data.row.cells[i])
data.row.cells[i].styles.textColor = "red";
                                            //data.row.cells[i].styles.lineColor = "red";
                                            //data.row.cells[i].styles.lineWidth = 0.75
                                        }
                                    }
                                    else {
                                        data.cell.styles.textColor = "black"
                                    }
                                }
                            }
                        });
                    }

                },
                didParseCell: function (data) {
                    if (data.column.dataKey === 0 && data.cell.section === 'head' || data.column.dataKey === 1 && data.cell.section === 'head' || data.column.dataKey === 2 && data.cell.section === 'head') {
                        data.cell.styles.valign = "middle"
                    }
                    if (data.section === 'body' && data.cell.raw !== "") {
                        if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
                            data.cell.styles.halign = "center"
                        }
                        // console.log(data.cell.raw)
                        if (data.cell.raw&&data.cell.raw[2] === "Non-Compliant") {
                            // console.log("hiii", data)
                            data.cell.styles.textColor = "red";
                            // data.cell.styles.lineColor = "red";
                            // data.cell.styles.lineWidth = 0.75
                            for (let i = 0; i < 5; i++) {
                                if (data.row.cells[i])
data.row.cells[i].styles.textColor = "red";
                                //data.row.cells[i].styles.lineColor = "red";
                                //data.row.cells[i].styles.lineWidth = 0.75
                            }
                        }
                        else {
                            data.cell.styles.textColor = "black"
                        }
                        if (data.column.dataKey === 3 && data.cell.section === 'body'
                            && data.row.cells[3].raw[2] === "Non-Compliant") {
                            data.cell.styles.textColor = "red";
                        }
                    }
                },
                theme: "grid",
                rowPageBreak: 'avoid',
                startY: start + this.tableStart,
                margin:this.margin,
                headStyles: {
                    minCellHeight: 14,
                    ...this.headerStyles.onlyCenter
                },
                columnStyles: {
                    0: { cellWidth: 45 },
                    2: { cellWidth: 20 },
                    3: { cellWidth: 90 }
                },
                bodyStyles: this.bodyStyles.noAlign,
                didDrawPage: (data) => {
                    this.pageHeader()
                    // console.log("pantry",this.doc)
                    if (pg > 0) {
                        this.tableHeader(sno + "  Green Strip Width Table", this.firstTableInPage)
                    }
                    pg = pg + 1
                    end = data.cursor.y
                }
            })

        }
        return end
    }*/

  DriveWayWidthTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];

    this.app.report.DriveWayWidth.map((i) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.buildingHeight,
        [i.minWidth, i.Width, i.result],
      ]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.DriveWayWidth.length > 0) {
      this.doc.autoTable({
        head: [["Building Name", "Floor Name", "Building Height", "Width"]],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 3 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 3 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 2 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            // console.log(data.cell.raw)
            if (data.cell.raw && data.cell.raw[2] === "Non-Compliant") {
              // console.log("hiii", data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 5; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
            if (
              data.column.dataKey === 3 &&
              data.cell.section === "body" &&
              data.row.cells[3].raw[2] === "Non-Compliant"
            ) {
              data.cell.styles.textColor = "red";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 45 },
          2: { cellWidth: 20 },
          3: { cellWidth: 90 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Driveway width Details",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };

  DriveWayRadiusTable = (start, sno) => {
    let end = start,
      pg = 0;
    var setBody = [];

    this.app.report.DriveWayTurningRadius.map((i) => {
      setBody.push([
        i.BuildingName,
        i.FloorName,
        i.buildingHeight,
        [i.minTurningRadius, i.provided, i.result],
      ]);
    });

    setBody = this.mergeCellsUtil(start, setBody, 0);
    if (this.app.report.Toilet.length > 0) {
      this.doc.autoTable({
        head: [
          ["Building Name", "Floor Name", "Building Height", "Turning Radius"],
        ],
        body: setBody,
        didDrawCell: function (data) {
          if (data.column.dataKey === 3 && data.cell.section === "head") {
            data.doc.autoTable({
              head: [["Minimum", "Provided", "Result"]],
              startY: data.cell.y + 7,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              theme: "grid",
              headStyles: {
                valign: "middle",
                cellWidth: 30,
                fontSize: data.table.head[0].cells[0].styles.fontSize,
                minCellHeight:
                  data.table.head[0].cells[0].styles.minCellHeight / 2,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
              },
            });
          }
          if (data.column.dataKey === 3 && data.cell.section === "body") {
            data.doc.autoTable({
              // head: [["Minimum", "Provided", "Result"]],
              body: [[data.cell.raw[0], data.cell.raw[1], data.cell.raw[2]]],
              startY: data.cell.y,
              margin: { left: data.cell.x },
              tableWidth: "wrap",
              columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 30 },
                2: { cellWidth: 30 },
              },
              theme: "grid",
              bodyStyles: {
                minCellHeight: data.table.body[data.row.index].height,
                fontSize: data.table.body[0].cells[0].styles.fontSize,
                halign: "center",
                fillColor: "white",
                textColor: "black",
                lineWidth: 0.1,
                lineColor: [128, 128, 128],
                // lineColor: data.table.body[0].cells[0].styles.lineColor,
                // lineWidth: data.table.body[0].cells[0].styles.lineWidth,
                valign: "middle",
              },
              didParseCell: function (data) {
                if (data.section === "body" && data.cell.raw !== "") {
                  if (data.cell.raw === "Non-Compliant") {
                    //console.log("hiii",data.cell.styles,data)
                    data.cell.styles.textColor = "red";
                    // data.cell.styles.lineColor = "red";
                    // data.cell.styles.lineWidth = 0.75
                    for (let i = 0; i < 2; i++) {
                      if (data.row.cells[i])
                        data.row.cells[i].styles.textColor = "red";
                      //data.row.cells[i].styles.lineColor = "red";
                      //data.row.cells[i].styles.lineWidth = 0.75
                    }
                  } else {
                    data.cell.styles.textColor = "black";
                  }
                }
              },
            });
          }
        },
        didParseCell: function (data) {
          if (
            (data.column.dataKey === 0 && data.cell.section === "head") ||
            (data.column.dataKey === 1 && data.cell.section === "head") ||
            (data.column.dataKey === 2 && data.cell.section === "head")
          ) {
            data.cell.styles.valign = "middle";
          }
          if (data.section === "body" && data.cell.raw !== "") {
            if (data.column.dataKey !== 1 && data.column.dataKey !== 0) {
              data.cell.styles.halign = "center";
            }
            // console.log(data.cell.raw)
            if (data.cell.raw && data.cell.raw[2] === "Non-Compliant") {
              // console.log("hiii", data)
              data.cell.styles.textColor = "red";
              // data.cell.styles.lineColor = "red";
              // data.cell.styles.lineWidth = 0.75
              for (let i = 0; i < 5; i++) {
                if (data.row.cells[i])
                  data.row.cells[i].styles.textColor = "red";
                //data.row.cells[i].styles.lineColor = "red";
                //data.row.cells[i].styles.lineWidth = 0.75
              }
            } else {
              data.cell.styles.textColor = "black";
            }
            if (
              data.column.dataKey === 3 &&
              data.cell.section === "body" &&
              data.row.cells[3].raw[2] === "Non-Compliant"
            ) {
              data.cell.styles.textColor = "red";
            }
          }
        },
        theme: "grid",
        rowPageBreak: "avoid",
        startY: start + this.tableStart,
        margin: this.margin,
        headStyles: {
          minCellHeight: 14,
          ...this.headerStyles.onlyCenter,
        },
        columnStyles: {
          0: { cellWidth: 45 },
          2: { cellWidth: 20 },
          3: { cellWidth: 90 },
        },
        bodyStyles: this.bodyStyles.noAlign,
        didDrawPage: (data) => {
          this.pageHeader();
          // console.log("pantry",this.doc)
          if (pg > 0) {
            this.tableHeader(
              sno + "  Driveway Turning Radius Details",
              this.firstTableInPage
            );
          }
          pg = pg + 1;
          end = data.cursor.y;
        },
      });
    }
    return end;
  };

  checkNA = (checkobj) => {
    let t = checkobj.filter(
      (i) =>
        i.AreaRow.provided === "NO" ||
        i.AreaRow.provided === " " ||
        i.AreaRow.provided === null
    );

    return t.length !== checkobj.length;
  };
  show = () =>
    new Promise((resolve, reject) => {
      if (this.doc === null) this.createPdf();

      resolve(this.doc.output("bloburl"));
    });
  download = () =>
    new Promise((resolve, reject) => {
      if (this.doc === null) this.createPdf();
      this.doc.save("Report-" + this.app.applicationId + ".pdf");
      resolve(true);
    });

  createPdf = () => {
    this.doc = new jsPDF("p", "mm", [297, 210]);
    var end = 0,
      start = this.firstTableInPage,
      sub = 0,
      space = this.tableStart;
    var sno = 1;
    this.pageHeader();

    this.firstFiveTableHeader(sno + ". Proposal Details", start);
    end = this.ProposalTable(start, sno);
    sno = sno + 1;

    start = this.checkSpace(end + space);
    this.firstFiveTableHeader(sno + ". Applicant Details", start);
    end =
      this.app.app === "FirstApp"
        ? this.FirstAppApplicantTable(start, sno)
        : this.ApplicantTable(start, sno);
    sno = sno + 1;

    start = this.checkSpace(end + space);
    this.firstFiveTableHeader(sno + ". Site Details", start);
    end =
      this.app.app === "FirstApp"
        ? this.FirstAppSiteTable(start, sno)
        : this.SiteTable(start, sno);

    start = this.checkSpace(end + space);
    this.firstFiveTableHeader(sno + ". Site Requirement Details", start);
    end =
      this.app.app === "FirstApp"
        ? this.FirstAppSiteRequirementTable(start, sno)
        : this.SiteRequirementTable(start, sno);
    sno = sno + 1;

    /* start = this.checkSpace(end + space)
        this.firstFiveTableHeader(sno + ". External Means of Access", start)
        end = this.app.app==='FirstApp'?this.FirstAppexternalMeansTable(start,sno):this.externalMeansTable(start, sno)*/

    start = this.checkSpace(end + space);
    this.firstFiveTableHeader(sno + ". External Means of Access", start);
    end =
      this.app.app === "FirstApp"
        ? this.FirstAppexternalMeansTable1(start, sno)
        : this.externalMeansTable1(start, sno + "." + 1);
    sno = sno + 1;

    if (
      "AreaStatement" in this.app.report &&
      this.app.report.AreaStatement.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Area Statement", start);
      end = this.AreaStatementTableTSIIC(start, sno);
      sno = sno + 1;
    }

    if (this.app.report.ROS && this.app.report.ROS.length > 0) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Recreational Open Spaces", start);
      end = this.RecreationalOpenSpaceTable(start, sno);
      sno = sno + 1;
    }

    if (
      "ros_details" in this.app.report &&
      this.app.report.ros_details.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Recreational Open Spaces", start);
      end = this.RecreationalOpenSpaceSubTable(start, sno);
      sno = sno + 1;
    }

    if ("existing_building_details" in this.app.report) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Existing Building Details Table ", start);
      end = this.BuildingDetailelementsTable(start, sno);
      sno = sno + 1;
    }

    if ("existing_proposed_building_details" in this.app.report) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Existing + Proposed Built Up Area ", start);
      end = this.ExistingBuildingDetailelementsTable(start, sno);
      sno = sno + 1;
    }

    start = this.checkSpace(end + space);
    this.tableHeader(sno + ". Plot External Elements", start);
    end = this.plotExternalelementsTable(start, sno);
    sno = sno + 1;

    if (
      ("HighTensionLine" in this.app.report &&
        this.app.report.HighTensionLine.length > 0) ||
      ("LowTensionLine" in this.app.report &&
        this.app.report.LowTensionLine.length > 0)
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Plot External Elements", start);
      end = this.plotExternalelementsSubTable(start, sno);
      sno = sno + 1;
    }

    if ("Nala" in this.app.report) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Nala Buffer Details", start);
      end = this.nalaTable(start, sno);
      sno = sno + 1;
    }

    if (
      "NormalStairsFlight" in this.app.report &&
      this.app.report.NormalStairsFlight.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Normal Staircase Flight Details Table ", start);
      end = this.NormalStairFlightDetails(start, sno);
      sno = sno + 1;
    }

    if (
      "FireStairsFlight" in this.app.report &&
      this.app.report.FireStairsFlight.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Fire Staircase Flight Details Table ", start);
      end = this.FireStairFlightDetails(start, sno);
      sno = sno + 1;
    }

    if (
      this.app.report.MortgageArea &&
      this.app.report.MortgageArea.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Mortgage Area Details (10 %)", start);
      end = this.mortgageTable(start, sno);
      sno = sno + 1;
    }

    if (
      "OnlyBuildingHeight" in this.app.report &&
      this.app.report.OnlyBuildingHeight.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Details of Building Height", start);
      end = this.BuldingHeightTable(start, sno);
      sno = sno + 1;
    }

    if (
      "IndividualFloors" in this.app.report &&
      this.app.report.IndividualFloors.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Individual Floor Height Details Table", start);
      end = this.IndividualFloorHeightTable(start, sno);
      sno = sno + 1;
    }

    if (
      "MarginDetails" in this.app.report &&
      this.app.report.MarginDetails.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Marginal Open Spaces", start);
      end = this.MarginalOpenSpaceNewTable(start, sno);
      sno = sno + 1;
    }
    //  sub = 1

    if ("BasementSetback" in this.app.report) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Basement Setback", start);
      end = this.BasementSetback(start, sno);
      sno = sno + 1;
    }

    if (
      "ChajjaProjection" in this.app.report &&
      this.app.report.ChajjaProjection.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Chajja/ Cornice/ Weather Shade Details", start);
      end = this.ProjectionIntoMarginalOpenSpaceTable(start, sno);
      sno = sno + 1;
      // sub = sub + 1
    }

    /*  if (this.app.report.CanopyMargins  && this.app.report.CanopyMargins.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Marginal Open Spaces for Structures", start)
            end = this.MarginalOpenSpaceForStructuresTable(start, sno + "." + sub)
            sub = sub + 1
        }*/

    start = this.checkSpace(end + space);
    this.tableHeader(
      sno + ". Accessory Building in Marginal Open Spaces",
      start
    );
    end = this.AccessoryTableinMarginalopenSpaceTable(start, sno);
    sno = sno + 1;
    //sub = sub + 1

    if (
      "WatchmanCabin" in this.app.report &&
      this.app.report.WatchmanCabin.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(
        sno + ". Accessory Building in Marginal Open Spaces",
        start
      );
      end = this.WatchmanCabinTable(start, sno);
      sno = sno + 1;
    }

    /*   if (sub > 1) {
            sno = sno + 1
        }*/

    /*  if (this.app.report.AmenitySpace && this.app.report.AmenitySpace.length > 0) {
            start = this.checkSpace(end + space)
            console.log("before,"+sno);
            this.tableHeader(sno + ". Amenity Space", start)
            end = this.AmenitySpaceTable(start, sno)
            sno = sno + 1
            console.log("efrfrf,"+sno);
        }*/

    /*  if (this.app.report.MezzanineFloorAssociation && this.app.report.MezzanineFloorAssociation.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ".  Mezannaine floor details", start)
            end = this.MezzanineAssociationTable(start, sno)
            sno = sno + 1
        }*/

    /*   if (this.app.report.GreenStrip && this.app.report.GreenStrip.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ".  Green1 Strip Width Table", start)
            end = this.GreenStripTable(start, sno)
            sno = sno + 1
        }*/

    // start = this.checkSpace(end + space)
    // this.tableHeader(sno + ". Details of Structures/Buildings", start)
    // end = DetailsOfstructuresTable(start, sno)
    // sno = sno + 1
    // if(this.app.report.MezzanineDetails&&this.app.report.MezzanineDetails.length>0)
    // {
    //     start = this.checkSpace(end + space)
    //     this.tableHeader(sno + ". Mezzaine Floor Details", start)
    //     end = this.MezzanineTable(start, sno)
    //     sno = sno + 1
    // }
    if (
      "FloorWiseDeductions" in this.app.report &&
      this.app.report.FloorWiseDeductions.length > 0
    ) {
      // console.log("Floor table");
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Floorwise Deduction", start);
      end = this.FloorWiseDetailsTableTsiic(start, sno);
      sno = sno + 1;
    }
    sub = 0;

    if (this.app.report.GreenStrip && this.app.report.GreenStrip.length > 0) {
      sub += 1;
      start = this.checkSpace(end + space);
      this.tableHeader(sno + " . Green Strip Width Table", start);
      end = this.GreenStripTable(start, sno);
      sno = sno + 1;
    }
    sub = 0;
    /*  if ('TempIncidentDetails' in this.app.report && this.app.report.TempIncidentDetails !== null) {
           // console.log("accessory details");
            sub += 1
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Details of Accessory buildings", start)
            end = this.IncidentStructureTable(start, sno)
        }

        sub = 0;*/

    if (
      "AccessoryBuildingArea" in this.app.report &&
      this.app.report.AccessoryBuildingArea.length > 0
    ) {
      sub += 1;
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Accessory Building Area Table ", start);
      end = this.AccessoryBuildingAreaTable(start, sno);
      sno = sno + 1;
    }

    // start = this.checkSpace(end + space)
    // this.tableHeader(sno + "." + 1 + "  Floor Space Area Break Up", start)
    // end = FloorSpaceAreaBreakUp(start, sno + "." + 1)
    // sno = sno + 1
    /*  if (this.app.report.CommercialShop && this.app.report.CommercialShop.length > 0) {
            sub = sub + 1;
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Commercial Area", start)
            end = this.CommercialTable(start, sno + "." + sub)

        }*/

    /* if (sub > 0) {
            sno = sno + 1;
        }
       
      /* if (sub > 0) {
        sno = sno + 1;
    }
    if (this.app.useOfTheBuildings&&this.isResidential(this.app.useOfTheBuildings)&&this.app.report.Tenet && this.app.report.Tenet.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Details of Tenement", start)
            end = this.tenementTable(start, sno)
            sno = sno + 1
        }
        if ('HabitableDetails' in this.app.report  && this.app.report.HabitableDetails.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Habitation Room Details", start)
            end = this.HabitableRoomTable(start, sno)
            sno = sno + 1
        }*/

    if (
      "RefugeArea" in this.app.report &&
      this.app.report.RefugeArea.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Refuge Area", start);
      end = this.RefugeTable(start, sno);
      sno = sno + 1;
    }

    if (
      "HabitableDetails" in this.app.report &&
      this.app.report.HabitableDetails.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Habitation Room Details", start);
      end = this.HabitableRoomTable(start, sno);
      sno = sno + 1;
    }

    if (
      "HabitableDetails" in this.app.report &&
      this.app.report.HabitableDetails.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Habitation Room Details", start);
      end = this.HabitableRoomTable1(start, sno);
      sno = sno + 1;
    }

    if (
      "Balcony" in this.app.report.Balcony &&
      this.app.report.Balcony.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Balcony Details", start);
      end = this.balconyTable(start, sno);
      sno = sno + 1;
    }

    /*  if('BalconyArea' in this.app.report && this.app.report.BalconyArea.length > 0){
        
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Balcony FSI Details", start)
            end = this.balconyAreaTable(start, sno)
            sno = sno + 1
        }*/

    if (
      "VentilationShaft" in this.app.report &&
      this.app.report.VentilationShaft.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Ventilation Area Shaft Details Table ", start);
      end = this.VentilationAreaTable(start, sno);
      sno = sno + 1;
    }

    if (
      "ParkingArea" in this.app.report &&
      this.app.report.ParkingArea.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Parking Information Details Table ", start);
      end = this.ParkingDetailsNewTable(start, sno);
      sno = sno + 1;
    }

    if ("parking_details" in this.app.report) {
      start = this.checkSpace(end + space);
      this.tableHeader(
        sno + ". Parking Percentage Information Details Table ",
        start
      );
      end = this.ParkingDetailsDTable(start, sno);
      sno = sno + 1;
    }

    if ("visitors_parking_details" in this.app.report) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ".Visitor's Parking Details Table ", start);
      end = this.ParkingDetailsVisitorsTable(start, sno);
      sno = sno + 1;
    }

    if (
      "CanopyDetails" in this.app.report &&
      this.app.report.CanopyDetails.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Canopy Details Table ", start);
      end = this.CanopyDetailsTable(start, sno);
      sno = sno + 1;
    }

    if (sub > 0) {
      sno = sno + 1;
    }

    if (
      "NormalStairDetails" in this.app.report &&
      this.app.report.NormalStairDetails.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Normal Staircase Details Table ", start);
      end = this.NormalStairDetails(start, sno);
      sno = sno + 1;
    }

    if (
      "FireStairDetails" in this.app.report &&
      this.app.report.FireStairDetails.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Fire Staircase Details Table ", start);
      end = this.FireStairDetails(start, sno);
      sno = sno + 1;
    }

    if (
      "StairCaseCount" in this.app.report &&
      this.app.report.StairCaseCount.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". StairCase Count Details Table ", start);
      end = this.StairCaseCountDetailsTable(start, sno);
      sno = sno + 1;
    }

    if (
      "LiftCount" in this.app.report &&
      this.app.report.LiftCount.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Lift Count Details Table ", start);
      end = this.LiftCountDetailsTable(start, sno);
      sno = sno + 1;
    }

    if (
      "DriveWayDetails" in this.app.report &&
      this.app.report.DriveWayDetails.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Parking Floor Driveway Details ", start);
      end = this.DrivewayDetailsTable(start, sno);
      sno = sno + 1;
    }

    /*  if(this.app.report.DriveWayWidth &&this.app.report.DriveWayWidth.length > 0)
        {
            sub+=1;
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + "  Driveway width Details", start)
            end = this.DriveWayWidthTable(start, sno + "." + sub)

        }

       if(this.app.report.DriveWayTurningRadius&&this.app.report.DriveWayTurningRadius.length>0)
       {
            sub+=1;

            sub = sub + 1;
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Driveway Turning Radius Details", start)
            end = this.DriveWayRadiusTable(start, sno + "." + sub)
       }*/

    if (
      "RampBasementDetails" in this.app.report &&
      this.app.report.RampBasementDetails.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Basement and Ramp Details", start);
      end = this.RampTable(start, sno);
      sno = sno + 1;
    }

    if ("Corridor" in this.app.report && this.app.report.Corridor.length > 0) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ". Corridors and passageways", start);
      end = this.CorridorTable(start, sno);
      sno = sno + 1;
    }

    if (
      "DistanceBetweenBuilding" in this.app.report &&
      this.app.report.DistanceBetweenBuilding.length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ".  Distance Between  Two Buildings", start);
      end = this.DistanceBetween2BuildingTable(start, sno);
      sno = sno + 1;
    }

    /*  if(this.app.report.TravelDistance&&this.app.report.TravelDistance.length>0)
        {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Travel Distance", start)
            end = this.exitTable(start, sno)
            sno = sno + 1
        }*/

    /*   if (this.app.report.HabitableDoorWidth && this.app.report.HabitableDoorWidth.length > 0) {
            sub = sub + 1
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Details of Non Habitable Door", start)
            end = this.HabitableRoomDoorTable(start, sno + "." + sub)
            sno = sno + 1
        }

     
        if (this.app.report.MainDoorWidth && this.app.report.MainDoorWidth.length > 0) {
            sub = sub + 1
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Details of Main Door", start)
            end = this.mainDoorTable(start, sno + "." + sub)
            sno = sno + 1
        } */

    start = this.checkSpace(end + space);
    this.tableHeader(sno + ".  Site Requirements Details", start);
    end = this.detailTable(start, sno);
    sno = sno + 1;

    if (
      this.app.report.TypicalFloors &&
      Object.keys(this.app.report.TypicalFloors).length > 0
    ) {
      start = this.checkSpace(end + space);
      this.tableHeader(sno + ".  Typical Floor Information Table", start);
      end = this.typicalFloorInformationTable(start, sno);
      sno = sno + 1;
    }

    /* if (this.app.report.DoubleHeightTerraces && this.app.report.DoubleHeightTerraces.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Double Height Terrace FSI Details", start)
            end = this.DoubleHeightterraceTable(start, sno)
            sno = sno + 1
        }

        if (this.app.report.loftDetails && this.app.report.loftDetails.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ".  Loft Details", start)
            end = this.loftTable(start, sno)
            sno = sno + 1
        }*/

    /*  if (this.app.report.NonHabitableDoorWidth&& this.app.report.NonHabitableDoorWidth.length > 0) {
            sub = sub + 1
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + "  Details of Non-Habitable Door", start)
            end = this.nonHabitableRoomDoorTable(start, sno + "." + sub)
        } 
        if (sub > 0) {
            sno = sno + 1
        }
        sub = 1 
        if (this.app.report.StairCaseWidth.length > 0 || this.app.report.StairCaseHandRailHeight.length > 0 || this.app.report.StairCaseHeadRoomHeight.length > 0 || this.app.report.StairCaseRiser.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Staircase Details", start)
            end = this.staircaseTable(start, sno + "." + sub)
            sub = sub + 1
        } 
        if (this.app.report.FireStairCaseWidth.length > 0 || this.app.report.FireStairCaseHandRailHeight.length > 0 || this.app.report.FireStairCaseHeadRoomHeight.length > 0 || this.app.report.FireStairCaseRiser.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Staircase Details", start)
            end = this.fireStaircaseTable(start, sno + "." + sub)
            sub = sub + 1
        }*/

    //staircase area
    /* if ('StairCaseArea' in this.app.report && this.app.report.StairCaseArea.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Staircase Details", start)
            end = this.AreaStaircaseTable(start, sno + "." + sub)
            sub = sub + 1
        }

        if (sub > 1) {
            sno = sno + 1
        }

        start = this.checkSpace(end + space)
        var output;
        output = this.getParkingDetailsTsiic(start, sno)
        end = output.end 

        if (output.subSno > 1) {
            sno = sno + 1
        } */

    /*  if(this.app.report.AccessoryBuildingArea&&this.app.report.AccessoryBuildingArea.length>0)
        {

            start = this.checkSpace(end + this.tableStart)
            this.tableHeader(sno +". Accessory Building Area Table ", start)
            end = this.AccessoryBuildingAreaTable(start, sno)
            sub = sub + 1
            sno = sno + 1
        }

        sub = 0
        if (this.app.report.WC && this.app.report.WC.length > 0 && this.checkNA(this.app.report.WC)) {
            sub += 1
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Details of Water Closet - Area", start)
            end = this.WcAreaTable(start, sno + "." + sub)

            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Details of Water Closet - Width", start)
            end = this.WcWidthTable(start, sno + "." + sub)
        }
        if (this.app.report.Toilet  && this.app.report.Toilet.length > 0 && this.checkNA(this.app.report.Toilet)) {
            sub += 1
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Details of Toilet - Area", start)
            end = this.ToiletAreaTable(start, sno + "." + sub)

            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Details of Toilet - Width", start)
            end = this.ToiletWidthTable(start, sno + "." + sub)
        }
        if (this.app.report.Urinal && this.app.report.Urinal.length > 0 && this.checkNA(this.app.report.Urinal)) {
            sub += 1
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Details of Urinal - Area", start)
            end = this.UrinalAreaTable(start, sno + "." + sub)

            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Details of Urinal - Width", start)
            end = this.UrinalWidthTable(start, sno + "." + sub)
        }
        if (this.app.report.BathRoom && this.app.report.BathRoom.length > 0 && this.checkNA(this.app.report.BathRoom)) {
            sub += 1
            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Details of Bathroom - Area", start)
            end = this.BathroomAreaTable(start, sno + "." + sub)

            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + sub + " Details of Bathroom - Width", start)
            end = this.BathroomWidthTable(start, sno + "." + sub)
        }*/

    /* if (this.app.report.OccupantLoad && this.app.report.OccupantLoad.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ".  Occupancy Load", start)
            end = this.OccupancyLoadTable(start, sno)
            sno = sno + 1
        }


     if (this.app.report.Pantry  && this.app.report.Pantry.length > 0 && this.checkNA(this.app.report.Pantry)) {
            sub += 1
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Details of Pantry - Area", start)
            end = this.PantryAreaTable(start, sno)

            start = this.checkSpace(end + space)
            this.tableHeader(sno + "." + 1 + " Details of Pantry - Width", start)
            end = this.PantryWidthTable(start, sno + "." + 1)
            sno = sno + 1
        }*/

    /* start = this.checkSpace(end + space)
        this.firstFiveTableHeader(sno + ". Architect Details", start)
        end = this.app.app==='FirstApp'? this.FirstAppArchitectTable(start,sno):this.ArchitectTable(start, sno)
        sno = sno + 1*/

    /* if (this.app.report.InteriorChowk && this.app.report.InteriorChowk.length > 0) {
            start = this.checkSpace(end + space)
            this.tableHeader(sno + ". Interior Chowk/ Exterior Chowk Details", start)
            end = this.getChowkTable(start, sno)
            sno = sno + 1
        }*/

    this.footer();
  };
}
