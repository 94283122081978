import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Tab, Tabs } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ApplicantCheckList from "./ApplicOccupancy/ApplicantCheckList";
import ApplicantInformation from "./ApplicOccupancy/ApplicantInformation";
import BuildingDetailsTab from "./ApplicOccupancy/BuildingDetailsTab";
import GeneralInformation from "./ApplicOccupancy/GeneralInformation";
import Header from "../component/Header";
import SideBar from "../component/Sidebar";
import ApplyNOC from "./ApplicOccupancy/ApplyNOC";
import PlotDetails from "./ApplicOccupancy/PlotDetails";
import SiteDetails from "./ApplicOccupancy/SiteDetails";
import UserDocument from "./ApplicOccupancy/UserDocument";
import UserDrawing from "./ApplicOccupancy/UserDrawing";
import ApplicationService from "../services/service/ApplictionService";
import ErrorHandler from "../services/constant/ErrorHandler";
import UserDrawingSaved from "./ApplicOccupancy/UserDrawingSaved";

const ApplicOccupancy = () => {
  const location = useLocation();
  const [applicationID, setApplicationID] = useState(null);
  const [checkBtn, setCheckBtn] = useState(null);
  const [required, setRequired] = useState(null);
  const [userDetaliNo, setUserDetailNo] = useState("");
  const [userOccupancyNo, setOccupancyNo] = useState("");
  const [list, setList] = useState({});

  const getUserDocumentsFind = () => {
    if (userDetaliNo?.length !== 0) {
      let formdata = new FormData();
      formdata.append("file_no", userDetaliNo);
      ApplicationService.occupancyUserDetails(formdata)
        .then((res) => {
          if (res?.data?.user_occupancy_detail) {
            setList(res?.data?.user_occupancy_detail);
          }
        })
        .catch((err) => {
          console.log("err", err);
          ErrorHandler(err);
        });
    }
    setOccupancyNo("");
  };

  const getOccupancyDocumentsFind = () => {
    if (userOccupancyNo?.length !== 0) {
      let formdata = new FormData();
      formdata.append("file_no", userOccupancyNo);
      ApplicationService.occupancyDetails(formdata)
        .then((res) => {
          if (res?.data?.user_occupancy_detail) {
            setList(res?.data?.user_occupancy_detail);
          }
        })
        .catch((err) => {
          console.log("err", err);
          ErrorHandler(err);
        });
    }
    setUserDetailNo("");
  };

  return (
    <>
      <Col className="d-flex">
        {/* <SideBar /> */}
        <Col className="rt-dashboard">
          <Header />

          <Col className="d-flex justify-content-between w-100 px-3 bg-green">
            <Link
              to="/draft-application"
              className="d-flex black align-items-center py-2"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} />
              <p className="mb-0 ms-2">Back</p>
            </Link>
            <Button className="proposal-risk-back">
              Proposal Risk Category
            </Button>
          </Col>

          <Col className="p-2">
            <Col className="align-items-end d-flex flex-wrap border-1 border-color-lit-white border-solid bg-lt-white-f7">
              <Col xs="12" className="px-2 app-inform mb-3">
                {/* <div className="d-flex align-items-center"> */}

                <p className="f14 mb-0 c1">
                  {/* *Do you have DPMS building permit No? */}
                  *Do you have Autometry’s building permit Number?
                </p>

                <div className="d-flex align-items-center mt-3">
                  <div className="d-flex justify-content-lg-center align-items-center ms-4">
                    <input
                      type="Radio"
                      onClick={() => {
                        setCheckBtn(1);
                        setOccupancyNo("");
                      }}
                      checked={checkBtn == 1 ? true : false}
                    />
                    <label className="ms-1 me-2 f14">Yes</label>
                  </div>
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      onClick={() => {
                        setCheckBtn(0);
                        setOccupancyNo("");
                      }}
                      checked={checkBtn == 0 ? true : false}
                    />
                    <label className="ms-1 f14">No</label>
                  </div>
                </div>
              </Col>
              {checkBtn == 1 && (
                <>
                  {" "}
                  <Col xs={8} md={6} className="px-2 pt-2 mb-2">
                    <p className="f13 mb-1">Enter Building Permit Number*</p>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      onChange={(e) => setUserDetailNo(e.target.value)}
                      value={userDetaliNo}
                    />
                  </Col>
                  <Col xs={4} md={6} className="p-0 mb-2">
                    <button
                      className="bg1 text-light rounded-1 f14 px-3 py-2 d-inline-block border-0"
                      style={{ height: "35px" }}
                      onClick={() => getUserDocumentsFind()}
                    >
                      Find
                    </button>
                  </Col>
                </>
              )}

              <Col xs="12" className="px-2 app-inform mb-3">
                {/* <div className="d-flex align-items-center"> */}

                <p className="f14 mb-0 c1">
                  {/* *Do you have DPMS building permit No? */}
                  *Do you have Autometry’s Occupancy Certificate Number?
                </p>

                <div className="d-flex align-items-center mt-3">
                  <div className="d-flex justify-content-lg-center align-items-center ms-4">
                    <input
                      type="Radio"
                      onClick={() => {
                        setCheckBtn(3);
                        setUserDetailNo("");
                      }}
                      checked={checkBtn == 3 ? true : false}
                    />
                    <label className="ms-1 me-2 f14">Yes</label>
                  </div>
                  <div className="d-flex justify-content-lg-center align-items-center">
                    <input
                      type="Radio"
                      onClick={() => {
                        setCheckBtn(4);
                        setUserDetailNo("");
                      }}
                      checked={checkBtn == 4 ? true : false}
                    />
                    <label className="ms-1 f14">No</label>
                  </div>
                </div>
              </Col>
              {checkBtn == 3 && (
                <>
                  {" "}
                  <Col xs={8} md={6} className="px-2 pt-2 mb-2">
                    <p className="f13 mb-1">
                      Enter Occupancy Certificate Number*
                    </p>
                    <input
                      className="form-control px-2 py-1 rounded-0"
                      onChange={(e) => setOccupancyNo(e.target.value)}
                      value={userOccupancyNo}
                    />
                  </Col>
                  <Col xs={4} md={6} className="p-0 mb-2">
                    <button
                      className="bg1 text-light rounded-1 f14 px-3 py-2 d-inline-block border-0"
                      style={{ height: "35px" }}
                      onClick={() => getOccupancyDocumentsFind()}
                    >
                      Find
                    </button>
                  </Col>
                </>
              )}
            </Col>
          </Col>
        </Col>
      </Col>

      {checkBtn !== null && (
        <Col className="rt-dashboard mt-5">
          <Col>
            <Tabs id="uncontrolled-tab-example" className="mb-0">
              <Tab
                className="userApplic"
                eventKey="userapplicationform"
                title="Application Form"
              >
                <Col className="px-2 app-inform mt-1 bg-white">
                  <Tabs
                    id="uncontrolled-tab-example aria-labelledby"
                    className="mb-0 bg-white"
                  >
                    <Tab
                      eventKey="generalInformation"
                      title="General Information"
                    >
                      <GeneralInformation
                        setApplicationID={setApplicationID}
                        type="new"
                        list={list}
                      />
                    </Tab>
                    <Tab
                      eventKey="applicationInformation"
                      title="Applicant Information"
                    >
                      <ApplicantInformation />
                    </Tab>

                    <Tab eventKey="plotDetails" title="Proposed Use">
                      <PlotDetails applicationID={applicationID} type="new" />
                    </Tab>
                    <Tab
                      eventKey="buildingDetails"
                      title="Construction Completion Details"
                    >
                      <BuildingDetailsTab
                        applicationID={applicationID}
                        type="new"
                      />
                    </Tab>
                  </Tabs>
                </Col>
              </Tab>
              <Tab
                className="userApplic"
                eventKey="userdocument"
                title="Document"
              >
                <UserDocument applicationID={applicationID} type="new" />
              </Tab>
              <Tab eventKey="userdrawing" title="Site Inspections">
                <UserDrawing applicationID={applicationID} type="new" />
              </Tab>
              {list?.id && (
                <Tab eventKey="userdrawing_old" title="Saved Site Inspections">
                  <UserDrawingSaved
                    applicationID={applicationID}
                    list={list}
                    type="new"
                  />
                </Tab>
              )}
            </Tabs>
          </Col>
        </Col>
      )}
    </>
  );
};

export default ApplicOccupancy;
