import React, { useState } from 'react';
import { HIGH_RISE } from './Constant';
export default function HighRise() {
    const getHighRiseStatus = () => {
        const data = localStorage.getItem(HIGH_RISE);
        return data
    };

    const [highRiseStatus, setHighRiseStatus] = useState(getHighRiseStatus());

    const saveHighRiseStatus = data => {
        const temp = localStorage.setItem(HIGH_RISE, data);
        setHighRiseStatus(temp);
    };
    return {
        saveHighRiseStatus: saveHighRiseStatus,
        highRiseStatus,
    }
}
