import { faEllipsisH, faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Button, Col, Form, Row, FormControl, InputGroup } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationRejectService from "../../services/service/ApplicationRejectService";
import ApplicationService from "../../services/service/ApplictionService";

const SiteDetails = (props) => {
  const [update, setUpdate] = useState(false);
  const [siteDetailsList, setSiteDetailsList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [list, setList] = useState([]);
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = () => {
    let test = 1;
    ApplicationService.viewApplication({ user_application_id: props.data.id, type: 9 }).then(result => {
      let response = result.data.user_application_site_detail;
      if (response.length > 0) {
        test = test + 1;
        setList(response);
        setUpdate(true);
        var temp = [];
        for (let i = 0; i < response.length; i++) {
          temp.push({ site_detail_question_id: response[i].site_detail_question_id, architect_input: response[i].architect_input, });
        }
        setAnswerList(temp);
      }
    }).catch(function (error) {
      ErrorHandler(error);
    });
    ApplicationService.getSiteDetailsList().then(result => {
      setSiteDetailsList(result.data.site_detail_question);
      if (test == 1) {
        var ans = [];
        result.data.site_detail_question.map((item, index) => {
          ans.push({ site_detail_question_id: item.id, architect_input: "" });
        });
        setAnswerList(ans);
      }
    }).catch(function (error) {
      ErrorHandler(error);
    })
  }
  const changeInput = (e, index) => {
    var temp = [...answerList];
    temp[index].architect_input = e.target.value;
    setAnswerList(temp);
  }
  const save = () => {
    if (update) {
      var valid = 1;
      answerList.map((item, index) => {
        if (item.architect_input == "") {
          valid = valid + 1;
        }
      });
      if (valid == 1) {
        var payload = {
          user_application_id: props.data.id,
          site_detail_questions: answerList,
        }
        ApplicationService.saveSiteDetails(payload).then(result => {
          alert(result.data.message);
          getDetails();
        }).catch(function (error) {
          ErrorHandler(error);
        })
      } else {
        alert("Please fill all details");
      }
    } else {
      var valid = 1;
      answerList.map((item, index) => {
        if (item.architect_input == "") {
          valid = valid + 1;
        }
      });
      if (valid == 1) {
        var payload = {
          user_application_id: props.data.id,
          site_detail_questions: answerList,
        }
        ApplicationService.saveSiteDetails(payload).then(result => {
          alert(result.data.message);
          getDetails();
        }).catch(function (error) {
          ErrorHandler(error);
        })
      } else {
        alert("Please fill all details");
      }
    }
  }
  const upload = (index) => {
    if (answerList[index].architect_input != "") {
      var payload = {
        user_application_id: props.data.id,
        site_detail_question_id: answerList[index].site_detail_question_id,
        architect_input: answerList[index].architect_input,
      }
      if (list[index].commissioner_approval == 2) {
        payload.type = 1;
      }
      if (list[index]?.zm_approval == 2) {
        payload.type = 2;
      }
      ApplicationRejectService.siteDetailsChange(list[index].id, payload).then(result => {
        alert(result.data.message);
        getDetails();
      }).catch(function (error) {
        ErrorHandler(error);
      })
    } else {
      alert("Please enter your input");
    }
  }
  return (
    <Col className="prop-norm">
      {/* <Col className="d-flex justify-content-end mb-2">
        <Button
          onClick={save}
          className="bg-white px-2 py-1 me-1 mb-1 border-1 border-solid border-color-lit-white rounded-2"
          style={{
            color: "#2f6db5",
            fontSize: 13,
          }}>
          <FontAwesomeIcon className="f15 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col> */}

      <Container>

        <Row className="justify-content-center">
          <Col className="col-12 col-md-11 my-2 ps-0 pe-0">
            <div className="text-start mb-3">
              <div className="title-section rounded">
                <h5 className="f15 mb-0">
                  Site Details
                </h5>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          {siteDetailsList.map((item, index) => {
            return (
              <Col key={index} className="col-12 col-md-11 my-2 bg-white shadow py-3">
                <Row>
                  <Col className="col-12 col-md-8 mb-2">
                    <Form.Label className="mt-3 f14 ps-2 d-flex  col-md-12 align-items-center">
                      {index + 1}. {item.question} ?
                    </Form.Label>
                    <div class="ps-3" style={{ color: "#999999" }}>
                      <FontAwesomeIcon icon={faEllipsisH} className="rounded-circle bg-secondary p-1 f20" />
                    </div>
                  </Col>
                  <Col className="col-12 col-md-4 mb-2=">
                    <Form.Label className="mt-3 f13 px-4">
                      Input by Architect
                    </Form.Label>
                    <InputGroup className="mb-1 justify-content-center" style={{ width: "50%" }}>
                      <FormControl disabled={list[index]?.commissioner_approval == 2 || list[index]?.zm_approval == 2 ? false : true} value={answerList[index]?.architect_input} className="inputBox" placeholder="..." onChange={(e) => changeInput(e, index)} />
                    </InputGroup>
                    {list[index]?.commissioner_approval == 2 || list[index]?.zm_approval == 2 ?
                      <Button
                        onClick={() => upload(index)}
                        className="bg-white px-2 py-1 me-1 mb-1 border-1 border-solid border-color-lit-white rounded-2"
                        style={{
                          color: "#2f6db5",
                          fontSize: 13,
                        }}>
                        {/* <FontAwesomeIcon className="f15 me-2" icon={faFileAlt} /> */}
                        Save
                      </Button>
                      : null
                    }
                  </Col>
                </Row>
              </Col>
            )
          })
          }
        </Row>
      </Container>
    </Col>
  );
};

export default SiteDetails;
