import React, { useState } from "react";
import { Button, Row, Col, Pagination, Table, Dropdown, Form, ButtonGroup, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSyncAlt, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import Header from "../Header";

const ProceedingIssuedProgressView = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  return (
    <Col className="rt-dashboard">
      <Header />

      <Col className="col-12 mt-2 px-3">
        <Table striped responsive className="common-table-view">
          <thead>
            <tr className="bg5">
              <th className="f14 fw-normal white cursor-pointer">
                <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                File No <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Permission Type
                <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Show Progress <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Risk Level <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Application Type <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer min-width120">
                Applicant <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Architect <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
              <th className="f14 fw-normal white cursor-pointer">
                Village <FontAwesomeIcon icon={faSort} className="mt-1" />
              </th>
            </tr>
            <tr>
              <th className="f14 fw-normal white cursor-pointer"></th>
              <th className="f14 fw-normal white cursor-pointer"></th>

              <th className="f14 fw-normal white cursor-pointer py-1">
                <Dropdown as={ButtonGroup} className="" align="end">
                  <Form.Control type="text" placeholder="search" className="py-0" />
                  <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="filter-dropdown">
                    <div className="px-0">
                      <div className="filter-dropdown-item">
                        <Row className="py-2 border-bottom">
                          <Col md={10} className="text-start pe-0">
                            Filter On
                          </Col>
                          <Col md={2} className="text-end ps-0">
                            <Form.Check aria-label="option 1" />
                          </Col>
                        </Row>
                      </div>

                      <Row className="py-2">
                        <Col md={8} className="text-start ps-2 pe-0">
                          <Button variant="secondary" size="sm">
                            Select All
                          </Button>
                        </Col>
                        <Col md={4} className="text-end ps-0">
                          <Button variant="secondary" size="sm">
                            Ok
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </th>

              <th className="f14 fw-normal white cursor-pointer py-1">
                <Dropdown as={ButtonGroup} className="" align="end">
                  <Form.Control type="text" placeholder="search" className="py-0" />
                  <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="filter-dropdown">
                    <Dropdown.Item className="filter-dropdown-item">Off</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Equal</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Not Equal</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Less than</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Less tha or Equal</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Greater than</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">greater than or Equal</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Begins With</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Do not Begins with</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Ends With</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Do not Ends with</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Contains</Dropdown.Item>
                    <Dropdown.Item className="filter-dropdown-item">Do not Contains</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </th>

              <th className="f14 fw-normal white cursor-pointer py-1">
                <Dropdown as={ButtonGroup} className="" align="end">
                  <Form.Control type="text" placeholder="search" className="py-0" />
                  <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="filter-dropdown">
                    <div className="px-0">
                      <div className="filter-dropdown-item">
                        <Row className="py-2 border-bottom">
                          <Col md={10} className="text-start pe-0">
                            Filter On
                          </Col>
                          <Col md={2} className="text-end ps-0">
                            <Form.Check aria-label="option 1" />
                          </Col>
                        </Row>
                      </div>

                      <Row className="py-2">
                        <Col md={8} className="text-start ps-2 pe-0">
                          <Button variant="secondary" size="sm">
                            Select All
                          </Button>
                        </Col>
                        <Col md={4} className="text-end ps-0">
                          <Button variant="secondary" size="sm">
                            Ok
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </th>

              <th className="f14 fw-normal white cursor-pointer py-1">
                <Dropdown as={ButtonGroup} className="" align="end">
                  <Form.Control type="text" placeholder="search" className="py-0" />
                  <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="filter-dropdown">
                    <div className="px-0">
                      <div className="filter-dropdown-item">
                        <Row className="py-2 border-bottom">
                          <Col md={10} className="text-start pe-0">
                            Filter On
                          </Col>
                          <Col md={2} className="text-end ps-0">
                            <Form.Check aria-label="option 1" />
                          </Col>
                        </Row>
                      </div>

                      <Row className="py-2">
                        <Col md={8} className="text-start ps-2 pe-0">
                          <Button variant="secondary" size="sm">
                            Select All
                          </Button>
                        </Col>
                        <Col md={4} className="text-end ps-0">
                          <Button variant="secondary" size="sm">
                            Ok
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </th>

              <th className="f14 fw-normal white cursor-pointer"></th>

              <th className="f14 fw-normal white cursor-pointer py-1">
                <Dropdown as={ButtonGroup} className="" align="end">
                  <Form.Control type="text" placeholder="search" className="py-0" />
                  <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="filter-dropdown">
                    <div className="px-0">
                      <div className="filter-dropdown-item">
                        <Row className="py-2 border-bottom">
                          <Col md={10} className="text-start pe-0">
                            Filter On
                          </Col>
                          <Col md={2} className="text-end ps-0">
                            <Form.Check aria-label="option 1" />
                          </Col>
                        </Row>
                      </div>

                      <Row className="py-2">
                        <Col md={8} className="text-start ps-2 pe-0">
                          <Button variant="secondary" size="sm">
                            Select All
                          </Button>
                        </Col>
                        <Col md={4} className="text-end ps-0">
                          <Button variant="secondary" size="sm">
                            Ok
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </th>

              <th className="f14 fw-normal white cursor-pointer py-1">
                <Dropdown as={ButtonGroup} className="" align="end">
                  <Form.Control type="text" placeholder="search" className="py-0" />
                  <Dropdown.Toggle split variant="light" className="px-1 py-0 mx-2 text-primary" id="dropdown-split-basic">
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="filter-dropdown">
                    <div className="px-0">
                      <div className="filter-dropdown-item">
                        <Row className="py-2 border-bottom">
                          <Col md={10} className="text-start pe-0">
                            Filter On
                          </Col>
                          <Col md={2} className="text-end ps-0">
                            <Form.Check aria-label="option 1" />
                          </Col>
                        </Row>
                      </div>

                      <Row className="py-2">
                        <Col md={8} className="text-start ps-2 pe-0">
                          <Button variant="secondary" size="sm">
                            Select All
                          </Button>
                        </Col>
                        <Col md={4} className="text-end ps-0">
                          <Button variant="secondary" size="sm">
                            Ok
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="f14 c4">1.</td>
              <td className="f14 c4">IIC/0758/2021
              </td>
              <td className="f14 c4">Building Permission</td>
              <td className="f14 c4">Equal</td>
              <td className="f14 c4">Risk Level</td>
              <td className="f14 c4">Application Type</td>
              <td className="f14 c4">Applicant </td>
              <td className="f14 c4">Arunachalam</td>
              <td className="f14 c4">1001</td>
            </tr>
            <tr>
              <td className="f14 c4">2.</td>
              <td className="f14 c4">IIC/0758/2021
              </td>
              <td className="f14 c4">Building Permission</td>
              <td className="f14 c4">Equal</td>
              <td className="f14 c4">Risk Level</td>
              <td className="f14 c4">Application Type</td>
              <td className="f14 c4">Applicant</td>
              <td className="f14 c4">Arunachalam</td>
              <td className="f14 c4">1001</td>
            </tr>
          </tbody>
        </Table>

        <Row className="my-2">
          <Col className="col-12 col-sm-6 mb-3 mb-sm-0">
            <div className="d-flex">
              <Button className="bg-white border-0">
                <FontAwesomeIcon icon={faFileExcel} className="text-success" onClick={handleShow} /> &nbsp;
                <FontAwesomeIcon style={{ color: "green" }} className="black" icon={faSyncAlt} />
              </Button>
              <Pagination className="mb-0">{items}</Pagination>
            </div>
          </Col>
          <Col className="col-12 col-sm-6 text-end">
            <p className="mb-0">Total Proposal(s) : 1</p>
          </Col>
        </Row>


      </Col>


      <Modal size="sm" scrollable show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="f15">Select columns to export</div>
        </Modal.Header>

        <Modal.Body>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              SN
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              File No
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              PT Type
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              SP
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              RL
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              AType
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              Applicant
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              Arhitech
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              Village
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              ProposalDate
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              ReceiveDate
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              FileB
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              SiteVisit
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              ProjectType
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              PriorityFlag
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              Modified
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              DocID
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              ProposalID
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              FlowID
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              PTID
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              CurMsgID
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              NewMsgID
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              SiteMsgID
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              Stage
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              Status
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              Status1
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              MsgSubTypId
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

          <Row className="py-2 border-top">
            <div className="f15 text-primary text-center py-3">
              Export options
            </div>
            <Col>
              <Dropdown as={ButtonGroup}>
                File Format

                <Dropdown.Toggle split variant="light" id="dropdown-split-basic" className="px-1 py-0 mx-2 text-primary shadow" />

                <Dropdown.Menu>
                  <Dropdown.Item className="filter-dropdown-item">XL</Dropdown.Item>
                  <Dropdown.Item className="filter-dropdown-item">XLS</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>

          <Row className="py-2">
            <Col md={10} className="text-start pe-0 f14">
              Export all rows (unfiltered)
            </Col>
            <Col md={2} className="text-end ps-0">
              <Form.Check aria-label="option 1" />
            </Col>
          </Row>

        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={handleClose}>
            Ok
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Hide All
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default ProceedingIssuedProgressView;
