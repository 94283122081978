import { faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import ErrorHandler from "../../../services/constant/ErrorHandler";
import ApplicationService from "../../../services/service/ApplictionService";
import UserApplication from "../../../services/storage/UserApplication";

const GeneralInformationView = (props) => {
  const { setUserApplicationId } = UserApplication();
  const [projectType, setProjectType] = useState("Buiding Permission");
  const [applicationType, setApplicationType] = useState("Residential");
  const [caseType, setCaseType] = useState("New");
  const [applicationFor, setApplicationFor] = useState("TGIIC");
  const [zone, setZone] = useState(0);
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [zoneList, setZoneList] = useState([]);
  const [district, setDistrict] = useState(0);
  const [districtList, setDistrictList] = useState([]);
  const [iala, setIala] = useState(0);
  const [ialaList, setIalaList] = useState([]);
  const [industrialPark, setIndustrialPark] = useState(0);
  const [industrialParkList, setIndustrialParkList] = useState([]);
  const [mandal, setMandal] = useState(0);
  const [mandalList, setMandalList] = useState([]);
  const [village, setVillage] = useState(0);
  const [villageList, setVillageList] = useState([]);
  const [siteNumberType, setSiteNumberType] = useState(0);
  const [siteNumber, setSiteNumber] = useState("");
  const [siteLocation, setSiteLocation] = useState("");
  const [siteLocationType, setSiteLocationType] = useState(0);
  const [road, setRoad] = useState("");
  const [partialPayment, setPartialPayment] = useState(0);
  const [amaligamation, setAmaligamation] = useState(0);
  const [fireNOC, setFireNOC] = useState(0);
  const [proposedUse, setProposedUse] = useState("");

  const [mandalErr, setMandalErr] = useState(null);
  const [villageErr, setVillageErr] = useState(null);
  const [cityErr, setCityErr] = useState(null);
  const [siteNumberErr, setSiteNumberErr] = useState(null);
  const [siteLocationErr, setSiteLocationErr] = useState(null);
  const [roadErr, setRoadErr] = useState(null);
  const [proposedUseErr, setProposedUseErr] = useState(null);

  const changeMandal = (e) => {
    setMandal(e.target.value);
    if (e.target.value != 0) {
      getSubList(e.target.value, "village");
    }
  };

  const changeProposedUse = (e) => {
    setProposedUse(e.target.value);
    if (e.target.value != "") {
      setProposedUseErr(false);
    } else {
      setProposedUseErr(true);
    }
  };

  const changeCity = (e) => {
    setCity(e.target.value);
    if (e.target.value != "") {
      setCityErr(false);
    } else {
      setCityErr(true);
    }
  };

  const changeVillage = (e) => {
    setVillage(e.target.value);
    if (e.target.value != "") {
      setVillageErr(false);
    } else {
      setVillageErr(true);
    }
  };
  const changeSiteNumber = (e) => {
    setSiteNumber(e.target.value);
    if (e.target.value != "") {
      setSiteNumberErr(false);
    } else {
      setSiteNumberErr(true);
    }
  };
  const changeSiteLocation = (e) => {
    setSiteLocation(e.target.value);
    if (e.target.value != "") {
      setSiteLocationErr(false);
    } else {
      setSiteLocationErr(true);
    }
  };
  const changeRoad = (e) => {
    setRoad(e.target.value);
    if (e.target.value != "") {
      setRoadErr(false);
    } else {
      setRoadErr(true);
    }
  };
  useEffect(() => {
    ApplicationService.getZoneList()
      .then((result) => {
        var response = result.data;
        setZoneList(response.place);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const changeZone = (e) => {
    setZone(e.target.value);
    if (e.target.value != 0) {
      getSubList(e.target.value, "zone");
    }
  };
  const changeDistrict = (e) => {
    setDistrict(e.target.value);
    if (e.target.value != 0) {
      getSubList(e.target.value, "district");
    }
  };
  const changeIala = (e) => {
    setIala(e.target.value);
    if (e.target.value != 0) {
      getSubList(e.target.value, "iala");
    }
  };
  const changeIndustrialPark = (e) => {
    setIndustrialPark(e.target.value);
    if (e.target.value != 0) {
      getSubList(e.target.value, "mandal");
    }
  };
  const getSubList = (value, type) => {
    if (value != null && value != "") {
      ApplicationService.getZoneSubList(value)
        .then((result) => {
          var response = result.data;
          if (type == "zone") {
            setDistrictList(response.sub_place);
          } else if (type == "district") {
            setIalaList(response.sub_place);
          } else if (type == "iala") {
            setIndustrialParkList(response.sub_place);
          } else if (type == "mandal") {
            setMandalList(response.sub_place);
          } else if (type == "village") {
            setVillageList(response.sub_place);
          }
          // setIalaList(response);
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    }
  };
  const save = () => {
    if (
      zone != 0 &&
      district != 0 &&
      iala != 0 &&
      industrialPark != 0 &&
      mandal != 0 &&
      village != 0 &&
      siteNumberType != 0 &&
      siteNumber?.length !== 0 &&
      siteLocationType != 0 &&
      siteLocation?.length !== 0 &&
      city?.length !== 0 &&
      states?.length !== 0 &&
      ownerName?.length !== 0 &&
      // email?.length !== 0 &&
      // mobile?.length !== 0 &&
      address1?.length !== 0 &&
      address2?.length !== 0 &&
      proposedUse?.length !== 0
    ) {
      var payload = {
        zone: zone,
        district: district,
        iala: iala,
        industrial_park: industrialPark,
        mandal: mandal,
        village: village,
        plot_house_no: siteNumberType,
        plot_house_value: siteNumber,
        location_by: siteLocationType,
        location_by_value: siteLocation,
        owner_name: ownerName,
        address1: address1,
        address2: address2,
        city: city,
        state: states,
        proposed_use: proposedUse,
        // user_application_id:'',
        // parent_id:""
      };

      if (props.list?.id) {
        payload.parent_id = props.list?.id;
      }

      ApplicationService.occupancyGeneralInfoEdit(payload, props.list?.id)
        .then((result) => {
          setUserApplicationId(result.data.user_occupancy_id);
          props.setApplicationID(result.data.user_occupancy_id);
          alert("General Information Page has been updated");
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("Please fill all details ?");
    }
  };

  useEffect(() => {
    if (props.list?.id) {
      let list = props.list?.user_occupancy_detail;
      setOwnerName(list?.owner_name);
      setAddress1(list?.address1);
      setAddress2(list?.address2);
      setCity(list?.city);
      setStates(list?.state);
      setEmail(list?.email);
      setMobile(list?.mobile);
      setSiteNumberType(list?.plot_house_no);
      setSiteNumber(list?.plot_house_value);
      setSiteLocationType(list?.location_by);
      setSiteLocation(list?.location_by_value);
      setProposedUse(list?.proposed_use);
      let tempZone = [];
      let tempDistrict = [];
      let tempIala = [];
      let tempIndustrialPark = [];
      let tempMandal = [];
      let tempVillage = [];
      tempZone.push(list?.zone_place);
      tempDistrict.push(list?.district_place);
      tempIala.push(list?.iala_place);
      tempIndustrialPark.push(list?.industrial_park_place);
      tempMandal.push(list?.mandal_place);
      tempVillage.push(list?.village_place);
      setZoneList(tempZone);
      setDistrictList(tempDistrict);
      setIalaList(tempIala);
      setIndustrialParkList(tempIndustrialPark);
      setMandalList(tempMandal);
      setVillageList(tempVillage);
      setMandal(list?.mandal_place?.id);
      setVillage(list?.village_place?.id);
      setZone(list?.zone_place?.id);
      setDistrict(list?.district_place?.id);
      setIala(list?.iala_place?.id);
      setIndustrialPark(list?.industrial_park_place?.id);
    }
  }, [props.list]);

  return (
    <Col className="prop-norm">
      {/* <Col className="d-flex justify-content-end mb-2">
      <Button
        onClick={save}
        className="bg-white px-2 py-1 me-1 border-1 border-solid border-color-lit-white rounded-2"
        style={{
          color: "#2f6db5",
          fontSize: 13,
        }}>
        <FontAwesomeIcon className="f15 me-2" icon={faFileAlt} />
        Save
      </Button>
    </Col> */}
      <Accordion>
        <Accordion.Item>
          <Accordion.Header>
            <h5 className="f14 mb-0">Proposal Information</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col lg={12} className="d-md-flex flex-wrap">
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Owner Name*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    value={ownerName}
                    onChange={(e) => setOwnerName(e.target.value)}
                    disabled
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Address Line 1*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    disabled
                  />
                </Col>
              </Col>

              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Address Line 2*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                    disabled
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">City*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    disabled
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">State/Province/Region*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    value={states}
                    onChange={(e) => setStates(e.target.value)}
                    disabled
                  />
                </Col>
              </Col>
              {/* <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Email*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-0">Mobile*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    type={"number"}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      if (e.target.value.length !== 10) {
                        e.target.setCustomValidity("invalid Number");
                      } else if (e.target.value.length == 10) {
                        e.target.setCustomValidity("");

                        setMobile(e.target.value);
                      }
                    }}
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    maxLength={10}
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </Col>
              </Col> */}
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Zone*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={zone}
                    onChange={changeZone}
                    disabled
                  >
                    <option selected="selected" value="0">
                      Select
                    </option>
                    {zoneList.length > 0 &&
                      zoneList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">District*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={district}
                    onChange={changeDistrict}
                    disabled
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    {districtList.length > 0 &&
                      districtList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">IALA*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={iala}
                    onChange={changeIala}
                    disabled
                  >
                    <option value="0">Select</option>
                    {ialaList.length > 0 &&
                      ialaList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Industrial Park*</p>
                </Col>
                <Col>
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={industrialPark}
                    onChange={changeIndustrialPark}
                    disabled
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    {industrialParkList.length > 0 &&
                      industrialParkList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Mandal*</p>
                </Col>
                <Col>
                  {/* <FormControl disabled className="inputBox" value={mandal} onChange={changeMandal} /> */}
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={mandal}
                    onChange={changeMandal}
                    disabled
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    {mandalList.length > 0 &&
                      mandalList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1"> Village*</p>
                </Col>
                <Col>
                  {/* <FormControl disabled className="inputBox" value={village} onChange={changeVillage} /> */}
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={village}
                    onChange={(e) => setVillage(e.target.value)}
                    disabled
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    {villageList.length > 0 &&
                      villageList.map((item, index) => {
                        return (
                          <option
                            key={index}
                            className="f14 py-2"
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Col>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item>
          <Accordion.Header>
            <h5 className="f14 mb-0">Site Details</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col className="d-md-flex flex-wrap">
              <Col
                lg={3}
                md={6}
                className="ps-0 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Form.Select
                  style={{ fontSize: "15px" }}
                  aria-label="Default select example"
                  className="form-control px-2 py-1 rounded-0"
                  value={siteNumberType}
                  onChange={(e) => setSiteNumberType(e.target.value)}
                  disabled
                >
                  <option value="0">Select Plot/House No.</option>
                  <option className="f14 py-2" value="1">
                    NA
                  </option>
                  <option className="f14 py-2" value="2">
                    Plot No.
                  </option>
                  <option className="f14 py-2" value="3 ">
                    House No./ Door No.
                  </option>
                  <option className="f14 py-2" value="4">
                    Shed Number
                  </option>
                </Form.Select>
              </Col>
              <Col
                lg={3}
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <input
                  className="form-control px-2 py-1 rounded-0"
                  value={siteNumber}
                  onChange={changeSiteNumber}
                  disabled
                />
              </Col>
              <Col
                lg={3}
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Form.Select
                  style={{ fontSize: "15px" }}
                  aria-label="Default select example"
                  className="form-control px-2 py-1 rounded-0"
                  value={siteLocationType}
                  onChange={(e) => setSiteLocationType(e.target.value)}
                  disabled
                >
                  <option className="f14 py-2" value="0">
                    Select Location By
                  </option>
                  <option className="f14 py-2" value="1">
                    Survey Number
                  </option>
                  <option className="f14 py-2" value="2">
                    TS Number
                  </option>
                  <option className="f14 py-2" value="3">
                    Gram khantam
                  </option>
                  <option className="f14 py-2" value="4">
                    Abadi
                  </option>
                </Form.Select>
              </Col>
              <Col
                lg={3}
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <input
                  className="form-control px-2 py-1 rounded-0"
                  value={siteLocation}
                  onChange={changeSiteLocation}
                  disabled
                />
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Proposed Use*</p>
                </Col>
                <Col>
                  {/* <FormControl disabled className="inputBox" value={village} onChange={changeVillage} /> */}
                  <Form.Select
                    style={{ fontSize: "15px" }}
                    aria-label="Default select example"
                    className="form-control px-2 py-1 rounded-0"
                    value={proposedUse}
                    onChange={(e) => setProposedUse(e.target.value)}
                    disabled
                  >
                    <option className="f14 py-2" value="0">
                      Select
                    </option>
                    {["Residential", "Commercial", "Mixed"].map(
                      (item, index) => {
                        return (
                          <option key={index} className="f14 py-2" value={item}>
                            {item}
                          </option>
                        );
                      }
                    )}
                  </Form.Select>
                </Col>
              </Col>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>
  );
};

export default GeneralInformationView;
