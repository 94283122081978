import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Table, Col, Button } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationService from "../../services/service/ApplictionService";

const ApplicantCheckList = (props) => {
  const [checkList, setcheckList] = useState([]);
  const [checkListAnswer, setcheckListAnswer] = useState([]);
  const [update, setUpdate] = useState(false);
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = () => {
    let test = 1;
    ApplicationService.viewApplication({ user_application_id: props.data.id, type: 3 }).then(result => {
      let response = result.data.user_application_checklist;
      if (response.length > 0) {
        test = test + 1;
        setUpdate(true);
        var temp = [];
        for (let i = 0; i < response.length; i++) {
          temp.push({ application_checklist_id: response[i].application_checklist_id, value: response[i].value, remark: response[i].remark });
        }
        setcheckListAnswer(temp);
      }
    }).catch(function (error) {
      ErrorHandler(error);
    });
    ApplicationService.getCheckList().then(result => {
      let response = result.data.application_checklists;
      setcheckList(response);
      if (test == 1) {
        var answer = [];
        for (let i = 0; i < response.length; i++) {
          answer.push({ application_checklist_id: response[i].id, value: '', remark: null });
        }
        setcheckListAnswer(answer);
      }
    }).catch(function (error) {
      ErrorHandler(error);
    });

  }
  const handleChange = (e, index) => {
    let temp = [...checkListAnswer];
    temp[index].value = e;
    setcheckListAnswer(temp);
  }
  const changeRemarks = (e, index) => {
    let temp = [...checkListAnswer];
    temp[index].remark = e;
    setcheckListAnswer(temp);
  }
  const save = () => {
    if (update) {
      let count = 0;
      for (let i = 0; i < checkListAnswer.length; i++) {
        if (checkListAnswer[i].value != '') {
          count = count + 1;
        }
      }
      if (count == checkListAnswer.length) {
        var payload = {
          user_application_id: props.data.id,
          checklists: checkListAnswer,
        }
        ApplicationService.postCheckList(payload).then(result => {
          alert(result.data.message);
          getDetails();
        }).catch(function (error) {
          ErrorHandler(error);
        });
      } else {
        alert("Please answer all Questions ?");
      }
    } else {
      let count = 0;
      for (let i = 0; i < checkListAnswer.length; i++) {
        if (checkListAnswer[i].value != '') {
          count = count + 1;
        }
      }
      if (count == checkListAnswer.length) {
        var payload = {
          user_application_id: props.data.id,
          checklists: checkListAnswer,
        }
        ApplicationService.postCheckList(payload).then(result => {
          alert(result.data.message);
          getDetails();
        }).catch(function (error) {
          ErrorHandler(error);
        });
      } else {
        alert("Please answer all Questions ?");
      }
    }
  }
  return (
    <>
      <Col className="col-12">
        {/* <Col className="d-flex justify-content-end mb-2">
          <Button
            onClick={save}
            className="bg-white px-2 py-1 me-1 border-1 border-solid border-color-lit-white rounded-2"
            style={{
              color: "#2f6db5",
              fontSize: 13,
            }}>
            <FontAwesomeIcon className="f15 me-2" icon={faFileAlt} />
            Save
          </Button>
        </Col> */}
        <Table responsive className="common-table-view">
          <thead>
            <tr>
              <th className="f14 fw-normal">#</th>
              <th className="f14 fw-normal">Description</th>
              <th className="f14 fw-normal">Value</th>
              <th className="f14 fw-normal">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {checkList.length > 0 &&
              checkList.map((item, index) => {
                return (
                  <tr key={index} className="">
                    <td className="f14">{index + 1}</td>
                    <td className="f14">{item.description}</td>
                    <td className="">
                      <div className="d-flex justify-content-lg-center align-items-center">
                        <div className="d-flex justify-content-lg-center align-items-center">
                          <input disabled checked={checkListAnswer[index]?.value == 1} onClick={(e) => handleChange(e.target.value, index)} value={1} type="Radio" name={index} />
                          <label className="ms-1 me-2 f14">Yes</label>
                        </div>
                        <div className="d-flex justify-content-lg-center align-items-center">
                          <input disabled checked={checkListAnswer[index]?.value == 2} onClick={(e) => handleChange(e.target.value, index)} value={2} type="Radio" name={index} />
                          <label className="ms-1 f14">No</label>
                        </div>
                      </div>
                    </td>
                    <td className="f14">
                      <input disabled type="text" value={checkListAnswer[index]?.remark} onChange={(e) => changeRemarks(e.target.value, index)} />
                    </td>
                  </tr>
                )
              })
            }

          </tbody>
        </Table>
      </Col>
    </>
  );
};

export default ApplicantCheckList;
