import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationService from "../../services/service/ApplictionService";
import ReportOneg from '../Report/thirdchangeReport';
import pdfgenerate from '../Report/pdfgenerate';
import rjson4 from "../Report/rjson4";
import UserDetails from "../../services/storage/UserDetails";
import ApplicationUpdateService from "../../services/service/ApplicationUpdateService";
import PaymentService from "../../services/service/PaymentService";

const UserDrawing = (props) => {
  const { userDetails } = UserDetails();
  const [file, setFile] = useState('');
  const [fileURL, setFileURL] = useState('');
  const [modal, setModal] = useState(false);
  const [jsonData, setJsonData] = useState('');
  const [update, setUpdate] = useState(false);
  const [drawingID, setDrawingID] = useState('');
  const [reportData, setReportData] = useState('');
  const [status, setStatus] = useState('');
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentData, setPaymentData] = useState('');
  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setFileURL('');
  }
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = () => {
    ApplicationService.viewApplication({ user_application_id: props.data.id, type: 8 }).then(result => {
      let response = result.data.user_application_drawing;
      if (response != null) {
        setUpdate(true);
        // setFileURL(response.drawing);
        setDrawingID(response.id);
        setStatus(response.status);
        // handleClick();
      }
    }).catch(function (error) {
      ErrorHandler(error);
    });
  }
  const handleClick = () => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: props.data.id }).then(result => {
      let response = result.data;
      setReportData(response);
    }).catch(function (error) {
      ErrorHandler(error);
    });
  };
  const viewReport = async () => {
    var temp = {};
    temp.owner = { firstName: 'test', lastName: 'test2', email: 'test@gmail.com', mobile: '9566673188' };
    temp.mobile = "9266673188";
    temp.architect = { name: 'test', email: 'test@gmail.com', mobile: '9566673188' };
    // temp.report = JSON.parse(reportData.rule_engine_json_response);
    temp.report = rjson4;
    let newreport = new ReportOneg(temp);
    let results = await newreport.show();
    setJsonData(results);
    setModal(true);
  }
  const viewPDF = () => {
    setJsonData(reportData.scrutiny_report_pdf);
    setModal(true);
  }
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const save = () => {
    if (update) {
      if (file != "") {
        var form_data = new FormData();
        form_data.append('user_application_id', props.data.id);
        form_data.append('drawing', file);
        ApplicationUpdateService.updateDrawing(drawingID, form_data).then(result => {
          alert(result.data.message ? result.data.message : "Application saved successfully");
          getDetails();
        }).catch(function (error) {
          ErrorHandler(error);
        });
      } else {
        alert("Please select a Drawing File ?");
      }
    } else {
      ApplicationService.viewApplication({ user_application_id: props.data.id, type: 8 }).then(result => {
        if (file != "") {
          var form_data = new FormData();
          form_data.append('user_application_id', props.data.id);
          form_data.append('drawing', file);
          ApplicationService.saveDrawing(form_data).then(result => {
            alert(result.data.message ? result.data.message : "Application saved successfully");
            paymentInitiate();
          }).catch(function (error) {
            ErrorHandler(error);
          });
        } else {
          alert("Please select a Drawing File ?");
        }
      }).catch(function (error) {
        ErrorHandler(error);
      });
    }
  }
  const paymentInitiate = () => {
    PaymentService.payment_initiate({ user_application_id: props.data.id }).then(result => {
      console.log(result.data);
      setPaymentData(result.data);
      setPaymentModal(true);
      // getDetails();
    }).catch(function (error) {
      ErrorHandler(error);
    });
  }
  return (
    <>
      <Col>
        <Col className="d-flex w-100 mt-3">
          <Col lg={12} className="d-flex align-items-center my-auto">
            {/* <Col className="px-2 py-1 bg-lt-white-f7 mx-2 my-2">
              <label className="c1 fw-bold f14">Upload your Drawing file:</label>
            </Col> */}
            {/* {fileURL != "" &&
            <Button
              style={{ fontSize: "14px" }}
              className="bg-transparent border-0 text-primary ms-auto me-0"
              onClick={handleClick}
            >
              View
            </Button>
          } */}
            <input
              style={{ width: "190px", fontSize: "13px", margin: 'auto' }}
              type="file"
              accept=".dwg,.DWG"
              onChange={handleChange}
            />
          </Col>
        </Col>
        {modal === true ? (
          <>
            <Modal
              show={modal}
              onHide={() => {
                setModal(false);
              }}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                  Report
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <iframe src={jsonData} style={{ height: "500px" }}></iframe>
                </Row>
              </Modal.Body>
            </Modal>
          </>
        ) : null}
        {paymentModal === true ? (
          <>
            <Modal
              show={paymentModal}
              onHide={() => {
                setPaymentModal(false);
                getDetails();
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                  Payment Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul>
                  <li>
                    Account Number : {paymentData?.virtual_account_number}
                  </li>
                  <li>
                    IFSC code : {paymentData?.ifsc_code}
                  </li>
                  <li>
                    Processing fees : {paymentData?.processing_fees}
                  </li>
                </ul>
              </Modal.Body>
            </Modal>
          </>
        ) : null}
      </Col>

      <Col className="d-flex justify-content-center mb-2 bg-white">
        <Button
          onClick={save}
          className="tab-blu-back "
          style={{
            color: "#fff",
            fontSize: 15,
            marginTop: 30,
            padding: "6px 14px",
            backgroundColor: "#5593db",
          }}
        >
          <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
          Submit Drawing
        </Button>
      </Col>
    </>
  );
};

export default UserDrawing;