import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Tab, Tabs } from "react-bootstrap";
import ErrorHandler from "../../../services/constant/ErrorHandler";
import ApplicationService from "../../../services/service/ApplictionService";
import HighRise from "../../../services/constant/HighRise";
import { HIGH_RISE } from "../../../services/constant/Constant";
import { useLocation } from "react-router-dom";
import PrimaryView from "./Document/PrimaryView";
const OccupancyPaymentRecipt = (props) => {
  const location = useLocation();
  const { highRiseStatus } = HighRise();
  const [documentList, setDocumentList] = useState([]);
  const [documents, setDocuments] = useState("");

  const download = () => {
    var form_data = new FormData();
    form_data.append(`user_occupancy_id`, props.applicationID);
    ApplicationService.occupancy_fee_download(form_data)
      .then((response) => {
        if (response.data.status == "success") {
          const link = document.createElement("a");
          if (response.data.occupancy_fee_receipt_url != null) {
            link.href = response.data.occupancy_fee_receipt_url;
            link.setAttribute("download", "");
            link.setAttribute("target", "_blank");
            document.body.appendChild(link);
            link.click();
          } else {
            alert(response.data.message);
          }
        } else {
          alert(response.data.message);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const save = () => {
    var count = 1;

    if (documents == "") {
      count = count + 1;
    }

    if (count == 1) {
      var form_data = new FormData();
      form_data.append("user_occupancy_id", props.applicationID);
      form_data.append(`occupancy_fee_receipt`, documents);

      ApplicationService.occupancy_fee_upload(form_data)
        .then((result) => {
          if (result.data.status == "success") {
            alert(result.data.message);
            // props.getViewOccupancy(location?.state?.id);
            window.location.href = "/";
          } else {
            alert(result.data.message);
          }
        })
        .catch(function (error) {
          ErrorHandler(error);
        });
    } else {
      alert("please Upload File ?");
    }
  };

  return (
    <>
      <Col style={{ background: "white" }}>
        <Col className="mt-3">
          <Col className="ps-2 py-0  col-md-12 mb-2 align-items-center">
            <Col md={12} className={"mt-3"}>
              <div className="d-flex align-items-center f14 c4  draw-pdf-btn py-3 p-1">
                <button onClick={download}>Payment Receipt Download</button>
              </div>
            </Col>

            {props?.list?.status == 12 && (
              <>
                {" "}
                <Col md={8}>
                  <div className="mt-3">
                    <div class="input-group">
                      <input
                        type="file"
                        class="form-control"
                        id="inputGroupFile04"
                        aria-describedby="inputGroupFileAddon04"
                        accept="application/pdf"
                        aria-label="Upload"
                        onChange={(e) => setDocuments(e.target.files[0])}
                      />
                    </div>
                  </div>
                </Col>
                <Col className="d-flex justify-content-center mb-2">
                  <Button
                    onClick={save}
                    className="tab-blu-back mt-4"
                    style={{
                      color: "#fff",
                      fontSize: 15,
                      marginTop: 10,
                      padding: "6px 14px",
                      backgroundColor: "#5593db",
                    }}
                  >
                    <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
                    Payment Receipt Upload
                  </Button>
                </Col>
              </>
            )}
          </Col>
        </Col>
      </Col>
    </>
  );
};

export default OccupancyPaymentRecipt;
