import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ErrorHandler from "../../services/constant/ErrorHandler";
import ApplicationRejectService from "../../services/service/ApplicationRejectService";

const Primary = (props) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState('');
  const hiddenFileInput = React.useRef(null);
  const hiddenDateInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (e, index) => {
    if (e.target.files[0].size < 26214400) {
      var temp = [...props.documents];
      temp[index].doc_file = e.target.files[0];
      temp[index].preview = URL.createObjectURL(e.target.files[0]);
      props.setDocuments(temp);
    } else {
      alert("File size should be less than 25MB ");
    }
  }
  const handleView = (index) => {
    setModalData(props.documents[index]?.doc_file);
    setModal(true);
  }
  const handlePreview = (index) => {
    setModalData(props.documents[index]?.preview);
    setModal(true);
  }
  const save = (index) => {
    if (props.documents[index]?.doc_file != "" && typeof props.documents[index]?.doc_file != "string") {
      var form_data = new FormData();
      form_data.append('user_application_id', props.list[index]?.user_application_id);
      form_data.append('application_document_id', props.list[index].application_document_id?.id);
      form_data.append('doc_file', props.documents[index].doc_file);
      if (props.list[index]?.commissioner_approval == 2) {
        form_data.append('type', 1);
      }
      if (props.list[index]?.zm_approval == 2) {
        form_data.append('type', 2);
      }
      ApplicationRejectService.documentChange(form_data).then(result => {
        alert(result.data.message);
        props.getDetails();
      }).catch(function (error) {
        ErrorHandler(error);
      })
    } else {
      alert("Please select file !");
    }
  }
  return (
    <Col>
      {props.data.map((item, index) => {
        if (item.type == 1) {
          return (
            <Col key={index} className="ps-2 py-0 d-flex col-md-12 mb-2 align-items-center justify-content-between">
              <Col md={6}>
                <div className="d-flex align-items-center">
                  {/* <input type="checkbox" disabled /> */}
                  <label className="f14 mb-0 ms-2">{item.name}</label>
                </div>
              </Col>
              <Col md={6}>
                <Row>
                  <Col>
                    {props.documents[index]?.doc_file != "" && typeof props.documents[index]?.doc_file == "string" &&
                      <Button
                        style={{ fontSize: "14px" }}
                        className="bg-transparent border-0 text-primary ms-auto me-0"
                        onClick={() => handleView(index)}
                      >
                        View
                      </Button>
                    }
                  </Col>
                  <Col>
                    {props.list[index]?.commissioner_approval == 2 || props.list[index]?.zm_approval == 2 ?
                      <input
                        type="file"
                        accept=".pdf,.PDF"
                        onChange={(e) => handleChange(e, index)}
                      />
                      : null}
                  </Col>
                  <Col>
                    {props.list[index]?.commissioner_approval == 2 || props.list[index]?.zm_approval == 2 ?
                      <Button
                        onClick={() => save(index)}
                        className="bg-white px-2 py-1 me-1 mb-1 border-1 border-solid border-color-lit-white rounded-2"
                        style={{
                          color: "#2f6db5",
                          fontSize: 13,
                        }}>
                        {/* <FontAwesomeIcon className="f15 me-2" icon={faFileAlt} /> */}
                        Upload
                      </Button>
                      : null}
                  </Col>
                  <Col>
                    {props.documents[index]?.preview && props.documents[index]?.preview != "" ?
                      <Button
                        style={{ fontSize: "14px" }}
                        className="bg-transparent border-0 text-primary ms-auto me-0"
                        onClick={() => handlePreview(index)}
                      >
                        Preview
                      </Button>
                      : null}
                  </Col>
                </Row>
              </Col>
            </Col>
          )
        }
      })
      }
      {
        modal === true ?
          <>
            <Modal
              show={modal}
              onHide={() => { setModalData(''); setModal(false); }}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16" >File</Modal.Title>
              </Modal.Header>
              <Modal.Body >
                <Row>
                  <iframe src={modalData} style={{ height: '500px' }} ></iframe>
                </Row>
              </Modal.Body>
            </Modal>

          </> : null
      }
    </Col >
  );
};

export default Primary;
