import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Table, Col, Button } from "react-bootstrap";
import ErrorHandler from "../services/constant/ErrorHandler";
import ApplicationService from "../services/service/ApplictionService";

const ApplicantCheckList = (props) => {
  const [checkList, setcheckList] = useState([]);
  const [checkListAnswer, setcheckListAnswer] = useState([]);
  useEffect(() => {
    ApplicationService.getCheckList()
      .then((result) => {
        let response = result.data.application_checklists;
        setcheckList(response);
        var answer = [];
        for (let i = 0; i < response.length; i++) {
          answer.push({
            application_checklist_id: response[i].id,
            value: "",
            remark: null,
          });
        }
        setcheckListAnswer(answer);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  const handleChange = (e, index) => {
    let temp = [...checkListAnswer];
    temp[index].value = e;
    setcheckListAnswer(temp);
  };
  const changeRemarks = (e, index) => {
    let temp = [...checkListAnswer];
    temp[index].remark = e;
    setcheckListAnswer(temp);
  };
  const save = () => {
    if (props.applicationID) {
      let count = 0;
      for (let i = 0; i < checkListAnswer.length; i++) {
        if (checkListAnswer[i].value != "") {
          count = count + 1;
        }
      }
      if (count == checkListAnswer.length) {
        var payload = {
          user_application_id: props.applicationID,
          checklists: checkListAnswer,
        };
        ApplicationService.postCheckList(payload)
          .then((result) => {
            alert(result.data.message);
          })
          .catch(function (error) {
            ErrorHandler(error);
          });
      } else {
        alert("Please answer all Questions ?");
      }
    } else {
      alert("please fill GeneralInformation !");
    }
  };
  return (
    <>
      <Col className="col-12">
        <Table responsive className="common-table-view">
          <thead>
            <tr>
              <th className="f14 fw-normal">#</th>
              <th className="f14 fw-normal">Description</th>
              <th className="f14 fw-normal">Value</th>
              <th className="f14 fw-normal">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {checkList.length > 0 &&
              checkList.map((item, index) => {
                return (
                  <tr key={index} className="">
                    <td className="f14">{index + 1}</td>
                    <td className="f14">{item.description}</td>
                    <td className="">
                      <div className="d-flex justify-content-lg-center align-items-center">
                        <div className="d-flex justify-content-lg-center align-items-center">
                          <input
                            onClick={(e) => handleChange(e.target.value, index)}
                            value={1}
                            type="Radio"
                            name={index}
                          />
                          <label className="ms-1 me-2 f14">Yes</label>
                        </div>
                        <div className="d-flex justify-content-lg-center align-items-center">
                          <input
                            onClick={(e) => handleChange(e.target.value, index)}
                            value={2}
                            type="Radio"
                            name={index}
                          />
                          <label className="ms-1 f14">No</label>
                        </div>
                      </div>
                    </td>
                    <td className="f14">
                      <input
                        type="text"
                        onChange={(e) => changeRemarks(e.target.value, index)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Col className="d-flex justify-content-center mb-2">
          <Button
            onClick={save}
            className="tab-blu-back"
            style={{
              color: "#fff",
              fontSize: 15,
              marginTop: 10,
              padding: "6px 14px",
              backgroundColor: "#5593db",
            }}
          >
            <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
            Save
          </Button>
        </Col>
      </Col>
    </>
  );
};

export default ApplicantCheckList;
