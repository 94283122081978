import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import ApplicationSubmissionService from "../services/service/ApplicationSubmissionService";
import ErrorHandler from "../services/constant/ErrorHandler";
import UserDetails from "../services/storage/UserDetails";
import DashboardLink from "./DashboardLink";
import MUIDataTable from "mui-datatables";
import ApplicationService from "../services/service/ApplictionService";
import ApplicationUpdateService from "../services/service/ApplicationUpdateService";
import ReportOneg from "./Report/thirdchangeReport";
import BuildingPermissionOrder from "../screens/form/BuildingPermissionOrder";
import DemandNoticeSample from "../screens/form/DemandNoticeSample";
import { PDFExport } from "@progress/kendo-react-pdf";
import HighRise from "../services/constant/HighRise";

const DraftAppRit = () => {
  const { saveHighRiseStatus } = HighRise();
  const { userDetails } = UserDetails();
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const history = useHistory();
  useEffect(() => {
    ApplicationService.getApplicationList({ type: "draft" })
      .then((result) => {
        setList(result.data.user_application_draft);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const viewPreCheckReport = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalData(response.precheck_errors);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewDrawingPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalData(response.scrutiny_report_pdf);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewScrutinyReportPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        viewReport(response);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewReport = async (values) => {
    let temp = {};
    // let details = values.scrutiny_report_datas;
    // let anpdf = values.scrutiny_report_pdf;
    // temp.owner = { firstName: details.applicant_name, lastName: details.applicant_name, email: details.email, mobile: details.mobile };
    // temp.mobile = details.mobile;
    // temp.architect = { name: details.applicant_name, email: details.email, mobile: details.mobile };
    // temp.appliedOn = details.receipt_date;
    // temp.applicationId = details.application_number;
    // temp.report = drawing;
    let drawing = JSON.parse(values.rule_engine_json_response);
    let details = values.scrutiny_report_datas;
    let anpdf = values.scrutiny_report_pdf;
    temp.owner = {
      firstName: details.applicant_name,
      lastName: details.applicant_name,
      email: details.email,
      address: details.address,
    };
    temp.mobile = details.mobile;
    temp.surveyNo = details.sector_number;
    temp.doorNo = details.plot_number;
    temp.locality = details.mandal_name;
    temp.village = details.village_name;
    temp.siteaddress = details.site_address;
    temp.useOfTheBuildings = details.land_use_category;
    temp.subuseOfTheBuildings = details.land_subuse_category;
    temp.existingRoadType = details.road_type;
    temp.proposedRoadType = details.land_subuse_category;
    temp.architect = {
      name: details.applicant_name,
      email: details.email,
      mobile: details.mobile,
    };
    temp.appliedOn = details.receipt_date;
    temp.applicationId = details.application_number;
    temp.report = drawing;
    // var temp = {};
    // temp.owner = { firstName: 'test', lastName: 'test2', email: 'test@gmail.com', mobile: '9566673188' };
    // temp.mobile = "9266673188";
    // temp.architect = { name: 'test', email: 'test@gmail.com', mobile: '9566673188' };
    // temp.report = JSON.parse(values.rule_engine_json_response);
    // temp.report = test1;
    let newreport = new ReportOneg(temp);
    let results = await newreport.show();
    setModalData(results);
    setModal(true);
  };
  const columns = [
    {
      name: "sl",
      label: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{tableMeta.rowIndex + 1}</div>;
        },
      },
    },
    {
      name: "file_no",
      label: "File No",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Link
                to={{
                  pathname: "/user-application",
                  state: list[tableMeta.rowIndex],
                }}
                onClick={() => {
                  saveHighRiseStatus(
                    list[tableMeta.rowIndex]?.is_high_rise
                      ? list[tableMeta.rowIndex].is_high_rise
                      : 0
                  );
                }}
              >
                {value}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "application_type",
      label: "Building Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "case_type",
      label: "Case Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "iala_name",
      label: "IALA",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pre_check_report",
      label: "Pre-Check Report",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status == 4
                  ? "f14 c4 text-center pre-pdf-btn"
                  : "f14 c4 text-center pre-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status == 4 ? false : true}
                onClick={() => viewPreCheckReport(list[tableMeta.rowIndex])}
                className="view-btn"
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "drawing_pdf",
      label: "Drawing PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status == 5
                  ? "f14 c4 text-center draw-pdf-btn"
                  : "f14 c4 text-center draw-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status == 5 ? false : true}
                onClick={() => viewDrawingPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "secrutiny_report_pdf",
      label: "Scrutiny Report PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status == 5
                  ? "f14 c4 text-center sec-pdf-btn"
                  : "f14 c4 text-center sec-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status == 5 ? false : true}
                onClick={() => viewScrutinyReportPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Date Of Submission",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    selectableRows: false,
    filter: true,
    filterType: "dropdown",
  };
  const pdfExportComponent = React.useRef(null);
  const pdfpermission = React.useRef(null);
  return (
    <Col className="col-12 mt-0 px-3">
      <MUIDataTable
        title={"Draft Applications"}
        data={list}
        columns={columns}
        options={options}
      />
      {/* <BuildingPermissionOrder /> */}
      <div>
        {/* <div className="example-config">
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={() => {
              if (pdfExportComponent.current) {
                pdfExportComponent.current.save();
              }
            }}
          >
            Export PDF
          </button>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={() => {
              if (pdfpermission.current) {
                pdfpermission.current.save();
              }
            }}
          >
            Building Permission Download
          </button>
        </div>
        <div 
        // style={{
        //   position: "absolute",
        //   left: "-10000px",
        //   top: 0,
        // }}
        >
          <PDFExport paperSize="A4" ref={pdfExportComponent}>
            <DemandNoticeSample />
          </PDFExport>
        </div>*/}
        <div
        // style={{
        //   position: "absolute",
        //   left: "-10000px",
        //   top: 0,
        // }}
        >
          {/* <PDFExport paperSize="A4" ref={pdfpermission}>
            <BuildingPermissionOrder />
          </PDFExport> */}
        </div>
      </div>
      {/* <MUIDataTable
        title={"Draft Application"}
        data={data}
        columns={columns}
        options={options}
      />
      {/* <Table responsive className="common-table-view">
        <thead>
          <tr className="tab-blu-back">
            <th className="f14 fw-normal white cursor-pointer">
              S.No <FontAwesomeIcon icon={faSort} className="mt-1" />
            </th>
            <th className="f14 fw-normal white cursor-pointer">
              File No <FontAwesomeIcon icon={faSort} className="mt-1" />
            </th>
            <th className="f14 fw-normal white cursor-pointer">
              Case Type <FontAwesomeIcon icon={faSort} className="mt-1" />
            </th>
            <th className="f14 fw-normal white cursor-pointer">
              Building Type
              <FontAwesomeIcon icon={faSort} className="mt-1" />
            </th>
            <th className="f14 fw-normal white cursor-pointer">
              IALA <FontAwesomeIcon icon={faSort} className="mt-1" />
            </th>
            <th className="f14 fw-normal white cursor-pointer">
              Pre-Check Report
              <FontAwesomeIcon icon={faSort} className="mt-1" />
            </th>
            <th className="f14 fw-normal white cursor-pointer">
              Drawing PDF <FontAwesomeIcon icon={faSort} className="mt-1" />
            </th>
            <th className="f14 fw-normal white cursor-pointer">
              Scrutiny Report PDF
              <FontAwesomeIcon icon={faSort} className="mt-1" />
            </th>
            <th className="f14 fw-normal white cursor-pointer">
              Date Of Submission
              <FontAwesomeIcon icon={faSort} className="mt-1" />
            </th>
          </tr>
          <tr className="">
            <th className="f14 fw-normal white cursor-pointer "></th>
            <th className="f14 fw-normal white cursor-pointer"></th>
            <th className="f14 fw-normal white cursor-pointer py-1">
              <Dropdown as={ButtonGroup} className="" align="end">
                <Form.Control
                  type="text"
                  placeholder="search"
                  className="py-0"
                  style={{ width: "100px" }}
                />
                <Dropdown.Toggle
                  split
                  variant="light"
                  className="px-1 py-0 mx-0 text-primary"
                  id="dropdown-split-basic"
                ></Dropdown.Toggle>

                <Dropdown.Menu className="filter-dropdown">
                  <div className="px-0">
                    <div className="filter-dropdown-item">
                      <Row className="py-2 border-bottom">
                        <Col md={10} className="text-start pe-0">
                          Filter On
                        </Col>
                        <Col md={2} className="text-end ps-0">
                          <Form.Check aria-label="option 1" />
                        </Col>
                      </Row>
                    </div>

                    <Row className="py-2">
                      <Col md={8} className="text-start ps-2 pe-0">
                        <Button variant="secondary" size="sm">
                          Select All
                        </Button>
                      </Col>
                      <Col md={4} className="text-end ps-0">
                        <Button variant="secondary" size="sm">
                          Ok
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th className="f14 fw-normal white cursor-pointer"></th>
            <th className="f14 fw-normal white cursor-pointer py-1">
              <Dropdown as={ButtonGroup} className="" align="end">
                <Form.Control
                  type="text"
                  placeholder="search"
                  className="py-0"
                  style={{ width: "100px" }}
                />
                <Dropdown.Toggle
                  split
                  variant="light"
                  className="px-1 py-0 mx-0 text-primary"
                  id="dropdown-split-basic"
                ></Dropdown.Toggle>

                <Dropdown.Menu className="filter-dropdown">
                  <div className="px-0">
                    <div className="filter-dropdown-item">
                      <Row className="py-2 border-bottom">
                        <Col md={10} className="text-start pe-0">
                          Filter On
                        </Col>
                        <Col md={2} className="text-end ps-0">
                          <Form.Check aria-label="option 1" />
                        </Col>
                      </Row>
                    </div>

                    <Row className="py-2">
                      <Col md={8} className="text-start ps-2 pe-0">
                        <Button variant="secondary" size="sm">
                          Select All
                        </Button>
                      </Col>
                      <Col md={4} className="text-end ps-0">
                        <Button variant="secondary" size="sm">
                          Ok
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </th>
            <th className="f14 fw-normal white cursor-pointer"></th>
            <th className="f14 fw-normal white cursor-pointer"></th>
            <th className="f14 fw-normal white cursor-pointer py-1">
              <Dropdown as={ButtonGroup} className="" align="end">
                <Form.Control
                  type="text"
                  placeholder="search"
                  className="py-0"
                  style={{ width: "100px" }}
                />
                <Dropdown.Toggle
                  split
                  variant="light"
                  className="px-1 py-0 mx-0 text-primary"
                  id="dropdown-split-basic"
                ></Dropdown.Toggle>

                <Dropdown.Menu className="filter-dropdown">
                  <div className="px-0">
                    <div className="filter-dropdown-item">
                      <Row className="py-2 border-bottom">
                        <Col md={10} className="text-start pe-0">
                          Filter On
                        </Col>
                        <Col md={2} className="text-end ps-0">
                          <Form.Check aria-label="option 1" />
                        </Col>
                      </Row>
                    </div>

                    <Row className="py-2">
                      <Col md={8} className="text-start ps-2 pe-0">
                        <Button variant="secondary" size="sm">
                          Select All
                        </Button>
                      </Col>
                      <Col md={4} className="text-end ps-0">
                        <Button variant="secondary" size="sm">
                          Ok
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 &&
            list.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="f14 c4">{index + 1}.</td>
                  <td className="f14 c4">
                    <Link to={{ pathname: "/user-application", state: item }}>
                      {item.file_no}
                    </Link>
                  </td>
                  <td className="f14 c4">{item.project_type}</td>
                  <td className="f14 c4">{item.case_type} </td>
                  <td className="f14 c4">{item.iala.name} </td>
                  <td className="f14 c4 text-center pre-pdf-btn">
                    <button className="view-btn">View</button>
                    {/* <button className="dwn-btn">Download</button> 
                  </td>
                  <td className="f14 c4 text-center draw-pdf-btn">
                    <button>View</button>
                  </td>
                  <td className="f14 c4 text-center sec-pdf-btn">
                    <button disabled className="opacity-50">
                      View
                    </button>
                  </td>
                  <td className="f14 c4">10.2.2021 </td>
                </tr>
              );
            })}
          <tr>
              <td className="f14 c4">2.</td>
              <td className="f14 c4">IIC/0758/2021</td>
              <td className="f14 c4">Building Permission</td>
              <td className="f14 c4">New </td>
              <td className="f14 c4">kumaravel</td>
              <td className="f14 c4">kumarvel</td>
              <td className="f14 c4">Arunachalam</td>
            </tr> 
        </tbody>
      </Table> */}

      {/* <Row className="my-3">
        <Col className="col-12 col-sm-6 mb-3 mb-sm-0">
          <div className="d-flex">
            <Button className="bg-white border-0">
              <FontAwesomeIcon
                style={{ color: "green" }}
                className="black"
                icon={faSyncAlt}
              />
            </Button>
            <Pagination className="mb-0">{items}</Pagination>
          </div>
        </Col>
        <Col className="col-12 col-sm-6 text-end">
          <p className="mb-0">Total Proposal(s) : 1</p>
        </Col>
      </Row> */}
      {modal === true ? (
        <>
          <Modal
            show={modal}
            onHide={() => {
              setModal(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                Report
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <iframe src={modalData} style={{ height: "500px" }}></iframe>
              </Row>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </Col>
  );
};

export default DraftAppRit;
