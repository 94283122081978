import React, { useState, useEffect } from "react";
import "./App.css";
import Routes from "./routes/Routes";
import "./screens/Style.scss";
import "./screens/ninos.css";
import "./screens/responsive.css";
import Menubar from "./component/Menubar";
import { Modal, Spinner } from "react-bootstrap";
import httpClient from "./services/constant/httpClient";
import { Provider } from "react-redux";
import store from "./services/redux/store";

const App = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    httpClient.interceptors.request.use(function (config) {
      // spinning start to show
      setLoading(true);
      return config
    }, function (error) {
      setLoading(false);
      return Promise.reject(error);
    });

    httpClient.interceptors.response.use(function (response) {
      // spinning hide
      setLoading(false);
      return response;
    }, function (error) {
      setLoading(false);
      return Promise.reject(error);
    });
  }, []);
  return (
    <Provider store={store}>
      <div>
        {loading &&
          <div style={{ zIndex: 1500, position: 'fixed', top: '45%', left: '49%' }} >
            <Spinner animation="border" variant="primary" />
            <span style={{ marginLeft: 10, }}>Loading...</span>
          </div>
        }
        <Routes />
      </div>
    </Provider>
  );
};
export default App;
