import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Tab, Tabs } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import ApplicantCheckList from "./ApplicOccupancy/ApplicantCheckList";
import ApplicantInformation from "./ApplicOccupancy/ApplicantInformation";
import BuildingDetailsTab from "./ApplicOccupancy/BuildingDetailsTab";
import GeneralInformation from "./ApplicOccupancy/GeneralInformation";
import Header from "../component/Header";
import SideBar from "../component/Sidebar";
import ApplyNOC from "./ApplicOccupancy/ApplyNOC";
import PlotDetails from "./ApplicOccupancy/PlotDetails";
import SiteDetails from "./ApplicOccupancy/SiteDetails";
import UserDocument from "./ApplicOccupancy/UserDocument";
import UserDrawing from "./ApplicOccupancy/UserDrawing";
import ApplicationService from "../services/service/ApplictionService";
import ErrorHandler from "../services/constant/ErrorHandler";
import GeneralInformationEdit from "./ApplicOccupancy/EditOccupancy/GeneralInformationEdit";
import ApplicantInformationEdit from "./ApplicOccupancy/EditOccupancy/ApplicantInformationEdit";
import BuildingDetailsTabEdit from "./ApplicOccupancy/EditOccupancy/BuildingDetailsTabEdit";
import PlotDetailsEdit from "./ApplicOccupancy/EditOccupancy/PlotDetailsEdit";
import UserDocumentEdit from "./ApplicOccupancy/EditOccupancy/UserDocumentEdit";
import UserDrawingEdit from "./ApplicOccupancy/EditOccupancy/UserDrawingEdit";

const ApplicOccupancyEdit = () => {
  const location = useLocation();
  const history = useHistory();
  const [applicationID, setApplicationID] = useState(null);
  const [list, setList] = useState({});

  const getViewOccupancy = (id) => {
    ApplicationService.get_occupancy_view(id)
      .then((res) => {
        setApplicationID(res?.data?.user_occupancy?.id);
        setList(res?.data?.user_occupancy);
      })
      .catch((err) => {
        ErrorHandler(err);
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (location?.state?.id) {
      getViewOccupancy(location?.state?.id);
    } else {
      history.goBack();
    }
  }, []);

  return (
    <>
      <Col className="d-flex">
        {/* <SideBar /> */}
        <Col className="rt-dashboard">
          <Header />

          <Col className="d-flex justify-content-between w-100 px-3 bg-green">
            <Link
              to="/draft-application"
              className="d-flex black align-items-center py-2"
            >
              <FontAwesomeIcon icon={faArrowCircleLeft} />
              <p className="mb-0 ms-2">Back</p>
            </Link>
            <Button className="proposal-risk-back">
              Proposal Risk Category
            </Button>
          </Col>
        </Col>
      </Col>

      <Col className="rt-dashboard mt-5">
        <Col>
          <Tabs id="uncontrolled-tab-example" className="mb-0">
            <Tab
              className="userApplic"
              eventKey="userapplicationform"
              title="Application Form"
            >
              <Col className="px-2 app-inform mt-1 bg-white">
                <Tabs
                  id="uncontrolled-tab-example aria-labelledby"
                  className="mb-0 bg-white"
                >
                  <Tab
                    eventKey="generalInformation"
                    title="General Information"
                  >
                    <GeneralInformationEdit
                      list={list}
                      setApplicationID={setApplicationID}
                      type="new"
                    />
                  </Tab>
                  <Tab
                    eventKey="applicationInformation"
                    title="Applicant Information"
                  >
                    <ApplicantInformationEdit
                      list={list}
                      getViewOccupancy={getViewOccupancy}
                    />
                  </Tab>
                  <Tab eventKey="plotDetails" title="Proposed Use">
                    <PlotDetailsEdit
                      list={list}
                      applicationID={applicationID}
                      getViewOccupancy={getViewOccupancy}
                      type="new"
                    />
                  </Tab>
                  <Tab
                    eventKey="buildingDetails"
                    title="Construction Completion Details"
                  >
                    <BuildingDetailsTabEdit
                      list={list}
                      applicationID={applicationID}
                      getViewOccupancy={getViewOccupancy}
                      type="new"
                    />
                  </Tab>
                </Tabs>
              </Col>
            </Tab>
            <Tab
              className="userApplic"
              eventKey="userdocument"
              title="Document"
            >
              <UserDocumentEdit
                applicationID={applicationID}
                getViewOccupancy={getViewOccupancy}
                list={list}
                type="new"
              />
            </Tab>
            <Tab eventKey="userdrawing" title="Site Inspections">
              <UserDrawingEdit
                applicationID={applicationID}
                list={list}
                type="new"
              />
            </Tab>
          </Tabs>
        </Col>
      </Col>
    </>
  );
};

export default ApplicOccupancyEdit;
