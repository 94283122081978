import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import Pdf from "../assets/images/CommonAffidavitUndertaking.pdf";
import UserDetails from "../services/storage/UserDetails";
import ApplicationSubmissionService from "../services/service/ApplicationSubmissionService";
import ErrorHandler from "../services/constant/ErrorHandler";
import DashboardLink from "./DashboardLink";
import ApplicationService from "../services/service/ApplictionService";
import MUIDataTable from "mui-datatables";
import ReportOneg from "./Report/thirdchangeReport";
import ResidentialReportOneg from "./Report/residentialReport.js";
import ApplicationUpdateService from "../services/service/ApplicationUpdateService";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { logoImage } from "../assets/images/Index.js";
import moment from "moment";
const SubmittedApplicationsView = () => {
  const doc = new jsPDF();
  const { userDetails } = UserDetails();
  const [submittedList, setSubmittedList] = useState([]);
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [applicationDetatils, setApplicationDetatils] = useState("");
  useEffect(() => {
    ApplicationService.getApplicationList({ type: "payment_paid" })
      .then((result) => {
        setList(result.data.user_application_payment_paid);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  }, []);
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const [modalType, setModalType] = useState("");
  const viewPreCheckReport = (value, type) => {
    setApplicationDetatils(value);
    console.log(value);
    //type=1 view, type=2 dwg file download
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        if (type == 1) {
          setModalType("error");
          setModalData(response.precheck_errors);
          setModal(true);
        } else {
          window.location.href = response.precheck_error_dwg;
        }
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewDrawingPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("report");
        setModalData(response.scrutiny_report_pdf);
        setModal(true);
      })
      .catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewScrutinyReportPDF = (value) => {
    ApplicationUpdateService.viewReportDetail({ user_application_id: value.id })
      .then((result) => {
        let response = result.data;
        setModalType("report");
        setModalData(response.scrutiny_report);
        setModal(true);
        // viewReport(response);
      }).catch(function (error) {
        ErrorHandler(error);
      });
  };
  const viewReport = async (values) => {
    let temp = {};
    let drawing = JSON.parse(values.rule_engine_json_response);
    let details = values.scrutiny_report_datas;
    let anpdf = values.scrutiny_report_pdf;
    temp.owner = {
      firstName: details.applicant_name,
      lastName: details.applicant_name,
      email: details.email,
      address: details.address,
    };
    temp.mobile = details.mobile;
    temp.surveyNo = details.sector_number;
    temp.doorNo = details.plot_number;
    temp.locality = details.mandal_name;
    temp.village = details.village_name;
    temp.siteaddress = details.site_address;
    temp.useOfTheBuildings = details.land_use_category;
    temp.subuseOfTheBuildings = details.land_subuse_category;
    temp.existingRoadType = details.road_type;
    temp.proposedRoadType = details.land_subuse_category;
    temp.architect = {
      name: details.applicant_name,
      email: details.email,
      mobile: details.mobile,
    };
    temp.appliedOn = details.receipt_date;
    temp.applicationId = details.application_number;
    temp.report = drawing;
    let newreport =
      details.application_type == "Residential"
        ? new ResidentialReportOneg(temp)
        : new ReportOneg(temp);
    let results = await newreport.show();
    setModalType("report");
    setModalData(results);
    setModal(true);
  };
  const columns = [
    {
      name: "sl",
      label: "S.No",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{tableMeta.rowIndex + 1}</div>;
        },
      },
    },
    {
      name: "file_no",
      label: "File No",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <Link
                to={{
                  pathname: "/application-view",
                  state: list[tableMeta.rowIndex],
                }}
              >
                {value}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "application_type",
      label: "Building Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "case_type",
      label: "Case Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "iala_name",
      label: "IALA",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "pre_check_report",
      label: "Pre-Check Report",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status == 4
                  ? "f14 c4 text-center pre-pdf-btn"
                  : "f14 c4 text-center pre-pdf-btn opacity-50"
              }
            >
              <div className="row">
                <button
                  disabled={list[tableMeta.rowIndex].status == 4 ? false : true}
                  onClick={() =>
                    viewPreCheckReport(list[tableMeta.rowIndex], 1)
                  }
                  className="view-btn"
                >
                  View
                </button>
              </div>
              <div className="row mt-2">
                <button
                  disabled={list[tableMeta.rowIndex].status == 4 ? false : true}
                  onClick={() =>
                    viewPreCheckReport(list[tableMeta.rowIndex], 2)
                  }
                  className="dwn-btn"
                >
                  Download
                </button>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "drawing_pdf",
      label: "Drawing PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status == 5
                  ? "f14 c4 text-center draw-pdf-btn"
                  : "f14 c4 text-center draw-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status == 5 ? false : true}
                onClick={() => viewDrawingPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "secrutiny_report_pdf",
      label: "Scrutiny Report PDF",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className={
                list[tableMeta.rowIndex].status == 5
                  ? "f14 c4 text-center sec-pdf-btn"
                  : "f14 c4 text-center sec-pdf-btn opacity-50"
              }
            >
              <button
                disabled={list[tableMeta.rowIndex].status == 5 ? false : true}
                onClick={() => viewScrutinyReportPDF(list[tableMeta.rowIndex])}
              >
                View
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Date Of Submission",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const options = {
    selectableRows: false,
    filter: true,
    filterType: "dropdown",
  };

  return (
    <Col className="rt-dashboard">
      <MUIDataTable
        title={"Submitted Applications"}
        data={list}
        columns={columns}
        options={options}
      />
      {modal === true ? (
        <>
          <Modal
            show={modal}
            onHide={() => {
              setModal(false);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title className="profile-modal-header mt-1 mb-1 f1-16">
                {modalType == "report" ? "Report" : "Pre-Check-Errors"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="draw-pdf-btn">
                {modalType == "report" ? (
                  <iframe src={modalData} style={{ height: "500px" }}></iframe>
                ) : (
                  modalType == "error" && (
                    <div id="error" style={{ overflow: "scroll" }}>
                      <div className="row mb-3">
                        <div className="col-4">
                          <img
                            src={logoImage}
                            style={{ height: "70px", width: "70px" }}
                          />
                        </div>
                        <div className="col">
                          <h5 className="mt-4">Pre-Check-Errors</h5>
                        </div>
                      </div>
                      <Table striped bordered hover id="application-details">
                        <thead
                          className="text-center"
                          style={{ background: "#ff99dd" }}
                        >
                          <tr>
                            <th colSpan={2}>Application Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Date of receipt of application</td>
                            <td>
                              {applicationDetatils?.created_at
                                ? moment(applicationDetatils.created_at).format(
                                    "DD-MM-YYYY"
                                  )
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>Application Number</td>
                            <td>
                              {applicationDetatils?.file_no
                                ? applicationDetatils.file_no
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <Table striped bordered hover id="error-details">
                        <thead
                          className="text-center"
                          style={{ background: "#ff99dd" }}
                        >
                          <tr>
                            <th>Pre-Check-Errors</th>
                          </tr>
                        </thead>
                        <tbody>
                          {modalData.map((item, index) => {
                            return (
                              <tr>
                                <td>{item}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <div style={{ marginLeft: "45%" }}>
                        <button
                          className="align-center mt-2"
                          onClick={() => {
                            // doc.addPage({html:"#error"})
                            doc.setFontSize(15);
                            doc.text(85, 22, "Pre-Check-Errors");
                            doc.addImage(logoImage, "JPEG", 10, 10, 20, 20);
                            doc.autoTable({
                              startY: 35,
                              html: "#application-details",
                              useCss: true,
                            });
                            doc.autoTable({
                              startY: doc.lastAutoTable.finalY + 10,
                              html: "#error-details",
                              useCss: true,
                            });
                            doc.save("Pre-Check-Errors.pdf");
                          }}
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  )
                )}
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : null}
    </Col>
  );
};

export default SubmittedApplicationsView;
