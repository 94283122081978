import React from "react";
import {
  Button,
  Row,
  Col,
  Pagination,
  Table,
  Dropdown,
  Form,
  ButtonGroup,
} from "react-bootstrap";
import SideBar from "../component/Sidebar";
import SubmittedApplicationsView from "../component/SubmittedApplicationsView";

const SubmittedApplications = () => {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 3; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  return (
    <Col className="d-lg-flex">
      {/* <SideBar /> */}
      <SubmittedApplicationsView />
    </Col>
  );
};

export default SubmittedApplications;
