import React from "react";
import httpClient from "../constant/httpClient";
import { URL } from "../constant/URL";

const updateGeneralInformation = (application_id, payload) => {
  return httpClient.post(
    URL.GENERALINFORMATION_UPDATE + application_id,
    payload
  );
};
const updatePlotDetails = (application_id, payload) => {
  return httpClient.post(URL.PLOT_DETAILS_UPDATE + application_id, payload);
};
const updateBulidingDetails = (
  user_application_building_detail_id,
  payload
) => {
  return httpClient.post(
    URL.BUILDING_DETAIL_UPDATE + user_application_building_detail_id,
    payload
  );
};
const updateFloorsDetails = (user_application_building_floor_id, payload) => {
  return httpClient.post(
    URL.FLOORS_UPDATE + user_application_building_floor_id,
    payload
  );
};
const updateNOC = (payload) => {
  return httpClient.post(URL.NOC_UPDATE, payload);
};
const updateDocument = (payload) => {
  return httpClient.post(URL.DOCUMENT_UPDATE, payload);
};
const updateDrawing = (drawing_id, payload) => {
  return httpClient.post(URL.DRAWING_UPDATE + drawing_id, payload);
};
const viewReportDetail = (payload) => {
  return httpClient.post(URL.REPORT_DETAIL, payload);
};
const viewReportDigitalLatter = (payload) => {
  return httpClient.get(URL.SANCTION_DIGITAL_LETTER + payload);
};
const viewSanctionLetter = (payload) => {
  return httpClient.post(URL.SANCTION_LETTER, payload);
};
const paymetCompleteFileUpload = (payload) => {
  return httpClient.post(URL.DEMAND_NOTE_PAYMENT_FILES_UPLOAD, payload);
};
const paymentCompleteFileView = (payload) => {
  return httpClient.post(URL.DEMAND_NOTE_PAYMENT_FILES_VIEW, payload);
};
const sanctionLetterAccept = (payload) => {
  return httpClient.post(URL.SANCTION_LETTER_ACCEPT, payload);
};
const paymetCompleteFileREUpload = (id, payload) => {
  return httpClient.post(URL.DEMAND_NOTE_PAYMENT_FILES_REUPLOAD + id, payload);
};
const pdfLink = (id, type) => {
  return httpClient.get(URL.PDF_LINK + id + "/" + type);
};

const sanctionPdfLink = (id, type) => {
  return httpClient.post(URL.SANCTION_PDF_LINK, id);
};

const msbPdfDownload = (data) => {
  return httpClient.post(URL.MSB_PDF_DOWNLOAD, data);
};

const momPdfDownload = (data) => {
  return httpClient.post(URL.MOM_PDF_DOWNLOAD, data);
};

const taPdfDownload = (data) => {
  return httpClient.post(URL.TA_PDF_DOWNLOAD, data);
};

// DELELOPMET ONLY

const changePaymetStatus = (id) => {
  return httpClient.get(URL.CHANGE_PAYMENT_COMPLETED + id);
};

const rejectionLetterDownload = (payload) => {
  return httpClient.post(URL.OCCUPANCY_REJECTION_LETTER_DOWNLOAD, payload);
};

const occupancyCertificateDownload = (payload) => {
  return httpClient.post(URL.OCCUPANCY_CERTIFICATE_DOWNLOAD, payload);
};

const occupancyDemondNoteSignatureDownload = (payload) => {
  return httpClient.post(URL.OCCUPANCY_DEMOND_NOTE_PAYMENT_DOWNLOAD, payload);
};

const occupancyUserAccountDetail = (payload) => {
  return httpClient.post(URL.OCCUPANCY_USER_ACCOUNT_DETAIL, payload);
};

const ApplicationUpdateService = {
  changePaymetStatus,
  updateGeneralInformation,
  updatePlotDetails,
  updateBulidingDetails,
  updateFloorsDetails,
  updateNOC,
  updateDocument,
  updateDrawing,
  viewReportDetail,
  viewSanctionLetter,
  paymetCompleteFileUpload,
  msbPdfDownload,
  paymentCompleteFileView,
  sanctionLetterAccept,
  viewReportDigitalLatter,
  paymetCompleteFileREUpload,
  pdfLink,
  sanctionPdfLink,
  momPdfDownload,
  taPdfDownload,
  rejectionLetterDownload,
  occupancyCertificateDownload,
  occupancyDemondNoteSignatureDownload,
  occupancyUserAccountDetail,
};
export default ApplicationUpdateService;
