import React from "react";
import httpClient from "../constant/httpClient";
import { URL } from "../constant/URL";
const get_dashboard = () => {
  return httpClient.post(URL.DASHBOARD);
};
const get_download_link = () => {
  return httpClient.get(URL.DOWNLOAD_LINKS);
};

const get_dashboard_occupancy = () => {
  return httpClient.get(URL.DASHBOARD_OCCUPANCY_LIST);
};

const DashBoardService = {
  get_dashboard,
  get_download_link,
  get_dashboard_occupancy,
};
export default DashBoardService;
