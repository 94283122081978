import { faFile, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import ErrorHandler from "../../../services/constant/ErrorHandler";
import ApplicationService from "../../../services/service/ApplictionService";

const PlotDetailsEdit = (props) => {
  const [disable, setDisable] = useState(false);
  const [proposedUse, setProposedUse] = useState(1);
  const [net_plot_area, setNet_plot_area] = useState("");
  const [site_area_per_doc, setSite_area_per_doc] = useState("");
  const [site_area_plan, setSite_area_per_plan] = useState("");
  const [parking_space_provions, setParking_space_provions] = useState("");
  const [road_widending, setRoad_widending] = useState("");
  const [rwh_pits, setRwh_pits] = useState("");
  const [tot_lot_area, setTot_lot_area] = useState("");

  const save = () => {
    if (props.applicationID) {
      if (
        net_plot_area?.length !== 0 &&
        site_area_per_doc?.length !== 0 &&
        site_area_plan?.length !== 0 &&
        parking_space_provions?.length !== 0 &&
        road_widending?.length !== 0 &&
        rwh_pits?.length !== 0 &&
        tot_lot_area?.length !== 0
      ) {
        var form_data = new FormData();
        form_data.append("user_occupancy_id", props.applicationID);
        form_data.append("net_plot_area", net_plot_area);
        form_data.append("site_area_plan", site_area_plan);
        form_data.append("site_area_doc", site_area_per_doc);
        form_data.append("parking_space", parking_space_provions);
        form_data.append("road_widening", road_widending);
        form_data.append("rwh_pits", rwh_pits);
        form_data.append("total_lot_area", tot_lot_area);

        if (props?.list?.user_occupancy_main_detail?.id) {
          ApplicationService.occupancyProposedUseEdit(
            form_data,
            props?.list?.id
          )
            .then((result) => {
              alert("Proposed Use Page has been updated");
              // props.getViewOccupancy(props.applicationID);
            })
            .catch(function (error) {
              ErrorHandler(error);
            });
        } else {
          ApplicationService.occupancyProposedUseAdd(form_data)
            .then((result) => {
              alert("Proposed Use Page has been saved");
            })
            .catch(function (error) {
              ErrorHandler(error);
            });
        }
      } else {
        alert("please fill all Details ?");
      }
    } else {
      alert("please fill General Information Details ?");
    }
  };

  useEffect(() => {
    if (props.list?.id) {
      let list = props.list?.user_occupancy_main_detail;
      if (list) {
        if (list?.net_plot_area) {
          setNet_plot_area(list?.net_plot_area);
        }

        if (list?.site_area_doc) {
          setSite_area_per_doc(list?.site_area_doc);
        }

        if (list?.site_area_plan) {
          setSite_area_per_plan(list?.site_area_plan);
        }

        if (list?.parking_space) {
          setParking_space_provions(list?.parking_space);
        }

        if (list?.road_widening) {
          setRoad_widending(list?.road_widening);
        }

        if (list?.rwh_pits) {
          setRwh_pits(list?.rwh_pits);
        }

        if (list?.total_lot_area) {
          setTot_lot_area(list?.total_lot_area);
        }

        if (props.list?.user_occupancy_detail?.proposed_use == "Residential") {
          setProposedUse(1);
        } else if (
          props.list?.user_occupancy_detail?.proposed_use == "Commercial"
        ) {
          setProposedUse(2);
        } else if (props.list?.user_occupancy_detail?.proposed_use == "Mixed") {
          setProposedUse(3);
        }
      }
    }
  }, [props.list]);

  return (
    <Col className="col-12 prop-norm">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h5 className="f14 mb-0">Main Details</h5>
          </Accordion.Header>
          <Accordion.Body className="px-2 py-1">
            <Col lg={12} className="d-md-flex flex-wrap">
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Net Plot Area(Sq.Mt.)*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    type={"number"}
                    value={net_plot_area}
                    onChange={(e) => setNet_plot_area(e.target.value)}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Site Area(As per Doc.)*</p>
                </Col>
                <Col>
                  <input
                    type={"number"}
                    className="form-control px-2 py-1 rounded-0"
                    value={site_area_per_doc}
                    onChange={(e) => setSite_area_per_doc(e.target.value)}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Site Area(As per plan)*</p>
                </Col>
                <Col>
                  <input
                    type={"number"}
                    className="form-control px-2 py-1 rounded-0"
                    value={site_area_plan}
                    onChange={(e) => setSite_area_per_plan(e.target.value)}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Parking Space Provisions*</p>
                </Col>
                <Col>
                  <input
                    className="form-control px-2 py-1 rounded-0"
                    value={parking_space_provions}
                    onChange={(e) => setParking_space_provions(e.target.value)}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Road Widening*</p>
                </Col>
                <Col>
                  <input
                    type={"number"}
                    className="form-control px-2 py-1 rounded-0"
                    value={road_widending}
                    onChange={(e) => setRoad_widending(e.target.value)}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">RWH Pits(nos)*</p>
                </Col>
                <Col>
                  <input
                    type="number"
                    className="form-control px-2 py-1 rounded-0"
                    value={rwh_pits}
                    onChange={(e) => setRwh_pits(e.target.value)}
                  />
                </Col>
              </Col>
              <Col
                md={6}
                className="ps-2 py-0 d-flex bg-lt-white-f7 col-md-6 mb-2 align-items-center"
              >
                <Col>
                  <p className="f13 mb-1">Tot-lot area (m2)*</p>
                </Col>
                <Col>
                  <input
                    type={"number"}
                    className="form-control px-2 py-1 rounded-0"
                    value={tot_lot_area}
                    onChange={(e) => setTot_lot_area(e.target.value)}
                  />
                </Col>
              </Col>
            </Col>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Col className="d-flex justify-content-center mb-2">
        <Button
          onClick={save}
          className="tab-blu-back"
          style={{
            color: "#fff",
            fontSize: 15,
            marginTop: 10,
            padding: "6px 14px",
            backgroundColor: "#5593db",
          }}
        >
          <FontAwesomeIcon className="f17 me-2" icon={faFileAlt} />
          Save
        </Button>
      </Col>
    </Col>
  );
};

export default PlotDetailsEdit;
